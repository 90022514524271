import { i18nTextId } from "./i18nText";

const i18n_deAt: { [id in i18nTextId]: string } = {
  PAGE_REGISTER_TITLE: "Registrieren",
  PAGE_REGISTER_LABEL_LANGUAGE: "Sprache",
  PAGE_REGISTER_LABEL_EMAIL: "E-Mail",
  PAGE_REGISTER_LABEL_USERNAME: "Benutzer",
  PAGE_REGISTER_LABEL_PASSWORD: "Passwort",
  PAGE_REGISTER_LABEL_CONFIRM_PASSWORD: "Passwort bestätigen",
  PAGE_REGISTER_LABEL_FIRST_NAME: "Name",
  PAGE_REGISTER_LABEL_LAST_NAME: "Nachname",
  PAGE_REGISTER_SUBMIT_BUTTON_TEXT: "Registrieren",
  PAGE_REGISTER_USERNAME_IN_USE: "Benutzername nicht verfügbar",
  PAGE_REGISTER_TOKEN_ERROR: "Token ist abgelaufen oder ungültig",
  PAGE_REGISTER_PASSWORD_UNMATCH:
    "Passwort und Passwort bestätigen stimmen nicht überein",
  VALIDATION_FIELD_REQUIRED: "Pflichtfeld",
  VALIDATION_FIELD_INVALID: "Ungültiges Feld",
  VALIDATION_FIELD_MIN: "Minimum ${} Ziffern",
  VALIDATION_FIELD_MAX: "Maximal ${} Ziffern",
  VALIDATION_STRONGER_PASSWORD: "Nicht stark genug",
  SELECTED_FARM_MAP_LABEL: "Karte",
  SELECTED_FARM_EDIT_BUTTON_TEXT: "Edit",
  SELECTED_FARM_FILTER_BUTTON_TEXT: "Filter",
  SELECTED_FARM_FILTER_NAME: "Name",
  SELECTED_FARM_FILTER_PROBLEM: "Problem",
  SELECTED_FARM_PIVOTS: "Pivots",
  SELECTED_FARM_IRPDS: "Pumpen",
  SELECTED_FARM_SWEEP_BUTTON_TEXT: "Fegen",
  SELECTED_FARM_SWEEP_EXECUTING_TEXT: "Ausführen von Sweep...",
  SELECTED_FARM_SWEEP_END_TEXT: "Kehren beendet!",
  SELECTED_FARM_SWEEP_GENERIC_ERROR: "Geräte-Sweep fehlgeschlagen",
  DEVICE_DRAWER_TITLE: "Geräte",
  DEVICE_BOX_VERY_STRONG: "Sehr stark",
  DEVICE_BOX_STRONG: "Stark",
  DEVICE_BOX_MODERATE: "Mäßig",
  DEVICE_BOX_WEAK: "Schwach",
  DEVICE_BOX_ERROR: "Error",
  DEVICE_NO_LOGS: "Sweep ausführen, um Protokolleinträge zu sehen.",
  EDIT_FARM_LABEL: "Bauernhof bearbeiten",
  EDIT_FARM_GENERAL_INFORMATION: "Allgemeine Informationen",
  EDIT_FARM_GENERAL_CONTACT: "Kontakt",
  EDIT_FARM_ENERGY_RANGES: "Leistungsbereiche",
  EDIT_FARM_ENERGY_RANGES_GENERATED: "Automatisch generierte Restzeit",
  EDIT_FARM_ENERGY_RANGES_ADD_BUTTON: "Add",
  EDIT_FARM_ENERGY_RANGES_REMOVE_BUTTON: "Entfernen",
  EDIT_FARM_USERS: "Benutzer",
  EDIT_FARM_USER: "Benutzer",
  EDIT_FARM_VISITORS: "BESUCHER",
  EDIT_FARM_OPERATORS: "Operatoren",
  EDIT_FARM_CODE: "Farm Code: ",
  EDIT_FARM_EMPLOYEES: "Benutzer",
  EDIT_FARM_RESALE: "Wiederverkauf",
  EDIT_FARM_APPROVE: "Genehmigen",
  EDIT_FARM_PERMISSIONS: "Erlaubnisse",
  EDIT_FARM_VIEW: "Ansicht ",
  EDIT_FARM_SEND_INVITE_AGAIN: "Einladung erneut senden",
  EDIT_FARM_RESEND_INVITATION_SUCCESS: "Einladung verschickt.",
  EDIT_FARM_RESEND_INVITATION_FAILED:
    "Einladung konnte nicht erneut gesendet werden. Bitte versuchen Sie es später noch einmal.",
  EDIT_FARM_REMOVE: "Entfernen",
  EDIT_FARM_REMOVE_INFO: "Möchten Sie den Benutzer ${} entfernen?",
  EDIT_FARM_REMOVE_REPEATER_INFO: "Möchten Sie den Repeater ${} entfernen?",
  EDIT_FARM_BUTTON_CANCEL: "Abbrechen",
  EDIT_FARM_BUTTON_DETELE: "Löschen",
  EDIT_FARM_TITLE_PERMISSIONS: "Berechtigungen bearbeiten",
  EDIT_FARM_TAG_VIEW: "Ansicht",
  EDIT_FARM_TAG_OPERATE: "Operieren",
  EDIT_FARM_BUTTON_SAVE: "Speichern",
  EDIT_FARM_GROUPS: "Gruppen",
  EDIT_FARM_GROUPS_INFO:
    "Richten Sie Pivot-Gruppen ein, um Statistiken und Berichte nach Gruppen zu erhalten.",
  EDIT_FARM_BUTTON_CREATE_GROUP: "Pivot-Gruppe erstellen",
  EDIT_FARM_BUTTON_ADD_POWER_RANGE: "Leistungsbereiche hinzufügen",
  SELECTED_FARM_DELETE_BUTTON_TEXT: "Löschen",
  EDIT_FARM_ADMINISTRATOR: "Administrator",
  EDIT_FARM_TEMPORARY_ADMINISTRATOR: "Vorläufiger Verwalter:",
  EDIT_FARM_REGISTER_USER:
    "Geben Sie den Namen, den Benutzernamen oder die E-Mail-Adresse des einzuladenden Benutzers ein.",
  EDIT_FARM_REGISTER_USER_LANG: "Einladungssprache",
  EDIT_FARM_BUTTON_REGISTER_USER: "Benutzer registrieren",
  EDIT_FARM_BUTTON_EDIT: "Edit",
  EDIT_FARM_NAME_GROUP: "Name der Gruppe",
  EDIT_FARM_REPEATERS: "Wiederholer",
  EDIT_FARM_BUTTON_REGISTER_REPEATERS: "Wiederholer registrieren",
  EDIT_FARM_USER_PENDING: "Ausstehend",
  EDIT_FARM_GROUP_NAME: "Name der Gruppe",
  EDIT_FARM_EDIT_REPEATER: "Wiederholer bearbeiten",
  EDIT_FARM_USER_DELETE_SUCCESS: "Benutzer entfernt",
  EDIT_FARM_USER_PERMISSION_SUCCESS: "Berechtigungen erfolgreich gespeichert",
  EDIT_FARM_GROUP_SUCCESS: "Gruppe erstellt",
  EDIT_FARM_EDIT_GROUP_SUCCESS: "Gruppe bearbeitet",
  EDIT_FARM_EDIT_GROUP_REMOVED: "Gruppe entfernt",
  EDIT_FARM_GROUP_NAME_ERROR: "Gruppenname nicht ausgefüllt",
  EDIT_FARM_GROUP_PIVOT_ERROR: "Pivots nicht ausgewählt",
  EDIT_FARM_REPEATER_SUCCESS: "Registrierter Wiederholer",
  EDIT_FARM_REPEATER_ERROR: "Fehler bei der Registrierung des Repeaters",
  EDIT_FARM_REPEATER_DELETE_SUCCESS: "Wiederholer entfernt",
  EDIT_FARM_EDIT_REPEATER_SUCCESS: "Wiederholer bearbeitet",
  EDIT_FARM_REPEATER_NAME_ERROR: "Repeater-Name nicht gefüllt",
  EDIT_FARM_TIMEZONE: "Zeitzone",
  EDIT_FARM_NO_REGISTERED_USERS: "Sie haben keine registrierten Benutzer.",
  EDIT_FARM_NO_REGISTERED_GROUPS: "Sie haben keine registrierten Gruppen.",
  EDIT_FARM_NO_REGISTERED_REPEATERS:
    "Sie haben keine registrierten Wiederholer.",
  HISTORIC_SCHEDULE_IRRIGATION_INDEX: "Zeitplan ${}:",
  HISTORIC_SCHEDULE_IRRIGATION_MODE: "Modus: ",
  HISTORIC_SCHEDULE_IRRIGATION_PERCENT: "Prozentsatz: ",
  HISTORIC_SCHEDULE_IRRIGATION_DIRECTION: "Richtung: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_DATE: "Startdatum: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_DATE: "Enddatum: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ROUNDS: "Ende nach: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ANGLE: "Endwinkel: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_SECTOR:
    "Wird am Ende des Sektors abgeschlossen",
  HISTORIC_SCHEDULE_IRRIGATION_END_MODE: "Endmodus: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_END: "Start - Ende: ${} - ${}",
  FORGET_PASSWORD_OPTION: "Wählen Sie eine Option",
  FORGET_PASSWORD_OPTION_USER:
    "Ich habe meinen Benutzernamen oder meine E-Mail-Adresse vergessen",
  ORGET_PASSWORD_OPTION_PASSWORD: "Ich habe mein Passwort vergessen",
  FORGET_PASSWORD_TYPE_EMAIL: "Geben Sie Ihre E-Mail ein",
  FORGET_PASSWORD_INFO_USER:
    "Wenden Sie sich an Ihren Landwirt oder an Ihren Händler vor Ort.",
  FORGET_PASSWORD_INFO_EMAIL:
    "Es wurde eine E-Mail an die Adresse:\n\n${}\n\nBefolgen Sie die Anweisungen in der E-Mail und stellen Sie Ihr Passwort wieder her.",
  FORGET_PASSWORD_RECOVEY: "Geben Sie Ihr neues Passwort ein.",
  FORGET_PASSWORD_NEW_PASSWORD: "Neues Passwort",
  FORGET_PASSWORD_REPEAT_NEW_PASSWORD: "Wiederholen Sie das neue Passwort",
  FORGET_PASSWORD_SAVE: "Passwort speichern",
  FORGET_PASSWORD_OK: "OK",
  FORGET_PASSWORD_MODAL_SUCCESS: "Passwort erfolgreich geändert!",
  CHANGE_PASSWORD_ERROR:
    "Fehler beim Ändern des Passworts, vielleicht ist dies nicht Ihr aktuelles Passwort",
  FORGET_PASSWORD_DIFFERENT:
    "Neues Passwort und Bestätigungspasswort sind unterschiedlich",
  FORGET_PASSWORD_MODAL_EMAIL_FAILED:
    "Es gibt keine Registrierung mit dieser E-Mail",
  EDIT_PIVOT_LABEL: "Pivot bearbeiten",
  EDIT_MONITOR_LABEL: "Edit Pivot Monitor",
  EDIT_PIVOT_LATITUDE: "Breitengrad",
  EDIT_PIVOT_LONGITUDE: "Längengrad",
  EDIT_PIVOT_SAVE_BUTTON: "Speichern",
  EDIT_PIVOT_LOAD_BUTTON: "Laden",
  EDIT_PIVOT_LOAD_SUCCESS: "Konfiguration erfolgreich geladen!",
  EDIT_PIVOT_LOAD_ERROR: "Fehler beim Laden der Konfiguration.",
  EDIT_PIVOT_LABEL_GENERAL: "Allgemein",
  EDIT_PIVOT_LABEL_LOCATION: "Standort",
  EDIT_PIVOT_IMANAGE_RADIO: "iManage Radio",
  EDIT_IRPD_DATASHEET_WARNING:
    "In den Einstellungen dieses Geräts gibt es Felder, deren Werte vom aktuellen Datenblatt abweichen.",
  EDIT_IRPD_LABEL_LINKED_METER: "Verbundener Zähler",
  EDIT_METER_LABEL_LINKED_IRPD: "Verbundene IRPDs",
  EDIT_METER_SUBTITLE_LINK_IRPD:
    "Verbinden Sie bis zu 10 IRPD-Geräte mit diesem Imeter-Gerät",
  EDIT_PIVOT_SENSOR: "Sensoren",
  EDIT_PIVOT_LABEL_CLOCK: "Uhr",
  EDIT_PIVOT_LABEL_PUMP: "Druck",
  EDIT_PIVOT_LABEL_PAUSE_TIME: "Zeit anhalten",
  EDIT_PIVOT_LABEL_PLUVIOMETER: "Regenmesser",
  EDIT_PIVOT_LABEL_END_GUN: "Endgun",
  EDIT_PIVOT_LABEL_GENERAL_SETTINGS: "Allgemeine Einstellungen",
  EDIT_PIVOT_LABEL_PAUSE_TIME_SETTINGS: "Pausenzeiteinstellungen",
  EDIT_PIVOT_LABEL_SETTING_HISTORIC: "Geschichte der Konfigurationen",
  HISTORIC_SIMPLE_IRRIGATION_MODE: "Modus: ",
  HISTORIC_SIMPLE_IRRIGATION_DIRECTION: "Richtung: ",
  HISTORIC_SIMPLE_IRRIGATION_PERCENT: "Niederschlag: ",
  HISTORIC_SIMPLE_IRRIGATION_START_MODE: "Startmodus: ${}",
  HISTORIC_SIMPLE_IRRIGATION_STOP_MODE: "Stoppmodus: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ANGLE: "Anschlagswinkel: ",
  HISTORIC_SIMPLE_IRRIGATION_START_DATE: "Startdatum: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ROUNDS: "Stopprunden: ",
  HISTORIC_SIMPLE_IRRIGATION_DONT_STOP: "Nicht aufhören",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ANGLE: "Halt im Winkel",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_SECTOR: "Halt im Sektor",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ROUNDS:
    "Schauen Sie bei der Visite vorbei",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_ON: "On",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_OFF: "Aus",
  GPS_STREAM_TABLE_START: "Erstellt am",
  GPS_STREAM_TABLE_ANGLE: "Winkel",
  GPS_STREAM_TABLE_POSITION: "Stellung",
  GPS_STREAM_TABLE_DIRECTION: "Richtung",
  GPS_STREAM_TABLE_SPEED: "Niederschlag",
  GPS_STREAM_TABLE_STATUS: "Status",
  GPS_STREAM_TABLE_WATER_PRESSURE: "Druck - Letzter Turm",
  GPS_STREAM_TABLE_CENTER_PRESSURE: "Druck - mittlerer Turm",
  GPS_STREAM_TABLE_BATERY: "Batterie",
  GPS_STREAM_TABLE_HOURMETER: "Stundenzähler",
  GPS_STREAM_TABLE_TITLE: "GPS-Informationsliste",
  USER_HAS_NO_FARM: "Sie haben keinen Zugang zu den Farmen.",
  SELECTED_IRPD_START: "Pumpe starten",
  SELECTED_IRPD_STOP: "Pumpe anhalten",
  LANGUAGE_SELECTOR_ENGLISH: "ENGLISH (US)",
  LANGUAGE_SELECTOR_FRANCE: "FRENCH (FR)",
  LANGUAGE_SELECTOR_SPANISH: "ESPAÑOL (ES)",
  LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL: "PORTUGUÊS (BR)",
  LANGUAGE_SELECTOR_RUSSIAN: "РУССКИЙ (RU)",
  LANGUAGE_SELECTOR_GERMAN: "DEUTSCH (AT)",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER: "Zentrum",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE: "Startreferenz",
  PIVOT_CONFIG_V5_FIELD_GP_END_REFERENCE: "Endreferenz",
  PIVOT_CONFIG_V5_FIELD_RADIUS: "Radius",
  PIVOT_CONFIG_V5_FIELD_FLOW: "Durchflussmenge",
  PIVOT_CONFIG_V5_FIELD_DEPTH_WELL: "Sensorskala",
  PIVOT_CONFIG_V5_FIELD_SPEED: "Turmgeschwindigkeit beenden",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_STABLE_TIME: "Stabile Spannung Stabil",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_REF: "Referenzspannung",
  PIVOT_CONFIG_V5_FIELD_CLOCK: "Geräteuhr manuell einstellen",
  PIVOT_CONFIG_V5_FIELD_AUTO_SET_CLOCK: "Geräteuhr automatisch stellen",
  PIVOT_CONFIG_V5_FIELD_PUMP_SOFT_START_TIME: "Saftanlauf-Zeit",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_OFF: "Aus",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SWITCH: "Durch Druckschalter",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SENSOR: "Per Drucksensor",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Maximale Reichweite des Drucksensors",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_FACTOR: "Drucksensorfaktor",
  PIVOT_CONFIG_V5_FIELD_PERIODIC_STREAM_TIMER:
    "Periodischer Stream-Timer (Sekunden)",
  PIVOT_CONFIG_V5_FIELD_ANGLE_RANGE: "Winkelbereich",
  PIVOT_CONFIG_V5_PIVOT_SEGMENTS: "Segmente",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_FORWARD: "Vorwärts-Modus",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_REVERSE: "Umkehrmodus",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_FORWARD: "% Vorwärts",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_REVERSE: "% Rückwärts",
  PIVOT_CONFIG_V5_FIELD_PILOT_AVERAGE_POWER: "Pivot-Durchschnittsleistung",
  PIVOT_CONFIG_V5_FIELD_CANAL_AVERAGE_POWER: "Gut durchschnittliche Leistung",
  PIVOT_CONFIG_V5_FIELD_SEGMENT: "Segment",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_ENGLISH: "Englisch",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_PORTUGUESE: "Portugiesisch",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_GERMAN: "Deutsch",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_SPANISH: "Spanisch",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_RUSSIAN: "Russisch",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_NONE: "Keine",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_WET: "Nass",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_DRY: "Trocken",
  PIVOT_CONFIG_V5_TITLE_ANGLE: "Winkel",
  PIVOT_CONFIG_V5_FORWARD: "Vorwärts",
  PIVOT_CONFIG_V5_REVERSE: "Umgekehrt",
  PIVOT_CONFIG_V5_START: "Start",
  PIVOT_CONFIG_V5_END: "Ende",
  NOTIFICATIONS: "Benachrichtigungen",
  NO_NOTIFICATION:
    "Sie haben keine Benachrichtigungen.\nKlicken Sie auf die Schaltfläche unten, um eine zu erstellen.",
  ALERT_ANDROID_ONLY_NOTIFY: "Diese Option ist nur für Android verfügbar.",
  ALERT_ITEM_TITLE: "${} Pivot(s)",
  ALERT_ITEM_DO_ENABLE: "Aktivieren",
  ALERT_ITEM_DO_DISABLE: "Deaktivieren",
  ALERT_ITEM_ENABLED: "Aktiviert",
  ALERT_ITEM_DISABLED: "Deaktiviert",
  ALERT_ITEM_EDIT: "Edit",
  ALERT_ITEM_DELETE: "Löschen",
  ALERT_BOX_TIP:
    "**Tipp: Doppeltippen Sie auf die Karte, um sie zu bearbeiten/löschen",
  SELECTED_ALERT_TIME_RANGE: "Zeitbereich",
  SELECTED_ALERT_TIME_BETWEEN: "Zwischen ${} und ${}",
  SELECTED_ALERT_SELECT_PIVOTS: "Pivots auswählen",
  SELECTED_ALERT_SELECT_REASONS: "Gründe auswählen",
  SELECTED_ALERT_REASONS: "Gründe",
  EDIT_ALERT_ERROR_MSG: "Fehler. Prüfen Sie, ob alle Felder ausgefüllt sind.",
  NEW_ALERT_CREATE_NOTIFICATION: "Benachrichtigung erstellen",
  NEW_ALERT_EDIT_SELECTED: "${} ausgewählt",
  NEW_ALERT_EDIT_NOTIFICATION: "Benachrichtigung bearbeiten",
  NOTIFICATION: "Benachrichtigung",
  ALARM: "Alarm",
  BASIC_INFO_BOX_COMMING_SOON: "Demnächst",
  BASIC_INFO_BOX_UNEXPECTED_STOPS: "Unerwartete Stopps",
  BASIC_INFO_BOX_LACK_OF_PRESSURE: "Niedriger Druck",
  BASIC_INFO_BOX_POWER_OUTAGE: "Stromausfall",
  BASIC_INFO_BOX_MISALIGNMENT: "Sicherheit",
  BASIC_INFO_BOX_POWER_SURGE: "Stromstoß",
  BASIC_INFO_BOX_ACTIONS_TITLE: "Aktionen",
  BASIC_INFO_BOX_ACTIONS_BY_WEB: "Per Web",
  BASIC_INFO_BOX_ACTIONS_BY_PANEL: "Durch das Gremium",
  BASIC_INFO_BOX_NON_EXECUTED_ACTIONS: "Nicht ausgeführt",
  BASIC_INFO_BOX_HOURS: "Stunden",
  BASIC_INFO_BOX_WET: "Nass",
  BASIC_INFO_BOX_DRY: "Trocken",
  BASIC_INFO_BOX_TOTAL: "Total",
  BASIC_INFO_BOX_WET_ENERGY_CONSUMPTION: "Energieverbrauch im Nassbetrieb",
  BASIC_INFO_BOX_TYPE: "Typus",
  BASIC_INFO_BOX_KWH_PRICE: "kW/h Preis",
  BASIC_INFO_BOX_WATER_CONSUMPTION: "Verbrauch",
  BASIC_INFO_BOX_PEAK_HOUR: "Zeit anhalten",
  BASIC_INFO_BOX_OUT_OF_PEAK_HOUR: "Außerhalb der Pausenzeit",
  BASIC_INFO_BOX_AT_NIGHTTIME: "Zur Nachtzeit",
  BASIC_INFO_BOX_AVERAGE_WATER_BLADE: "Durchschnittlicher Wasserniederschlag",
  BASIC_INFO_BOX_REPORT_GENERATED_BETWEEN:
    "Bericht erstellt zwischen ${} und ${}",
  BASIC_INFO_BOX_WATER_BLADE_RADAR: "Niederschlagskarte",
  BASIC_INFO_BOX_WORK_HOURS: "Nasse Arbeitszeiten",
  BASIC_INFO_BOX_TOTAL_COST: "Gesamtkosten",
  BASIC_INFO_BOX_PONTA: "Spitze",
  BASIC_INFO_BOX_FORA_DE_PONTA: "Aus der Spitze",
  BASIC_INFO_BOX_REDUZIDO: "Night Time",
  BASIC_INFO_BOX_FLOW: "Fluss:",
  BASIC_INFO_BOX_TOTAL_VALUE: "Total: $",
  BASIC_INFO_BOX_TIME_RANGES: "Zeitspannen",
  BASIC_INFO_BOX_WATER_BLADE_MM: "Wasserschaufel (mm)",
  BASIC_INFO_BOX_TOTAL_FEES: "Total:",
  BASIC_INFO_BOX_TOTAL_VOLUME: "Gesamtvolumen:",
  BASIC_INFO_BOX_TENSION_TITLE: "Pivot-Spannungen (V)",
  BASIC_INFO_BOX_LATEST_PRESSURE_BY_ANGLE_TITLE: "Vergleichsdruck (bar)",
  BASIC_INFO_BOX_TENSION_INFO:
    "Spannungen von mehr als 700 V und weniger als 300 V wurden aussortiert.",
  BASIC_INFO_BOX_TENSION_INFO_MIN: "Min",
  BASIC_INFO_BOX_TENSION_INFO_AVERAGE: "Avg",
  BASIC_INFO_BOX_TENSION_INFO_MAX: "Max",
  BASIC_INFO_BOX_TENSION: "Spannungen (V)",
  BASIC_INFO_BOX_TENSION_DATES: "Datum",
  BASIC_INFO_BOX_PRESSURE: "Druck (bar)",
  BASIC_INFO_BOX_LATEST_PRESSURE: "Druck",
  BASIC_INFO_BOX_CURRENT_PRESSURE: "Vergleichsdruck",
  BASIC_INFO_BOX_CHART: "Diagramme",
  BASIC_INFO_BOX_TABLE: "Tische",
  BASIC_INFO_BOX_NOT_AVAILABLE:
    "Nicht verfügbar für Pivots mit Protokoll vor 4.1.",
  BASIC_INFO_BOX_ENABLE_OPERATIONS_SUMMARY:
    "Um diese Daten zu sehen, gehen Sie auf die Seite 'Farm bearbeiten' und klicken Sie auf die Schaltfläche 'aktivieren' auf der Registerkarte 'Pivot-Berichte'.",
  BASIC_INFO_BOX_ENABLE_IRPD_INFOS:
    "Um diese Daten zu sehen, gehen Sie auf die Seite 'Farm bearbeiten' und klicken Sie auf die Schaltfläche 'aktivieren' auf der Registerkarte 'Pumpenberichte'.",
  BASIC_INFO_BOX_OPERATION_SUMMARY_CALCULATED:
    "Die Operations Summary wird für die Daten berechnet, die bereits im System vorhanden sind, was sehr lange dauern kann.",
  BASIC_INFO_BOX_GO_TO_EDIT_FARM: "Gehe zu Farm bearbeiten",
  BASIC_INFO_BOX_NOTIFY_DESKTOP_ONLY:
    "Funktionalität nur in der Desktop-Version verfügbar.",
  SCHEDULE_IRRIGATION_FIELD_DIRECTION: "Richtung",
  SCHEDULE_IRRIGATION_FIELD_MODE: "Betriebsmodus",
  SCHEDULE_IRRIGATION_FIELD_PERCENT: "Prozentmesser",
  SCHEDULE_IRRIGATION_FIELD_PRECIP: "Wasser-Klinge",
  SCHEDULE_IRRIGATION_VALUE_FORWARD: "Vorwärts",
  SCHEDULE_IRRIGATION_VALUE_REVERSE: "Umgekehrt",
  SCHEDULE_IRRIGATION_VALUE_DRY: "Trocken",
  SCHEDULE_IRRIGATION_VALUE_WET: "Nass",
  SCHEDULE_IRRIGATION_BUTTON_TURN_ON: "Einschalten",
  SCHEDULE_IRRIGATION_BUTTON_ADD: "Add",
  SCHEDULE_IRRIGATION_BUTTON_REMOVE: "Entfernen",
  SCHEDULE_IRRIGATION_DATE_START_LABEL: "Spardatum",
  SCHEDULE_IRRIGATION_START_DATE_INVALID:
    "Sie können kein Startdatum zwischen bereits festgelegten Daten erstellen.",
  SCHEDULE_IRRIGATION_END_DATE_INVALID:
    "Sie können kein Enddatum zwischen bereits festgelegten Daten erstellen.",
  GEOLOCATION_GETTER_GET_POSITION:
    "Holen Sie sich die Position, die Sie wollen.",
  GEOLOCATION_GETTER_LATITUDE: "Breitengrad:",
  GEOLOCATION_GETTER_LONGITUDE: "Längengrad:",
  GEOLOCATION_GETTER_GET: "POSITION ERHALTEN",
  GEOLOCATION_GETTER_WAIT:
    "Warten Sie ein wenig, um eine bessere GPS-Genauigkeit zu erhalten.",
  GEOLOCATION_GETTER_ACCURACY_LABEL: "Genauigkeit:",
  GEOLOCATION_GETTER_ACCURACY_VALUE: "${} Meter",
  COUNTRY_SELECTOR_DDI: "Wählen Sie ein Land",
  CREATE_USER_MODAL_TITLE: "Benutzer registrieren",
  CREATE_USER_MODAL_ADMIN: "Verwalter",
  CREATE_USER_MODAL_EMAIL: "E-Mail",
  CREATE_USER_MODAL_BUTTON_SAVE: "Speichern",
  CREATE_USER_MODAL_SUCCESS: "Die Einladung wurde verschickt.",
  CREATE_USER_MODAL_ERROR:
    "Beim Senden der Einladung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  CREATE_USER_MODAL_ALREADY_REGISTERED:
    "Der Benutzer ist bereits auf der Farm registriert.",
  CREATE_USER_MODAL_INVALID_EMAIL: "Ungültige E-Mail.",
  PIVOT_CONFIG_FIELD_CENTER: "Zentrum",
  PIVOT_CONFIG_FIELD_REFERENCE_START: "Startreferenz",
  PIVOT_CONFIG_FIELD_REFERENCE_END: "Endreferenz",
  PIVOT_CONFIG_FIELD_RADIUS: "Radius zum letzten Turm",
  PIVOT_CONFIG_FIELD_TOTAL_RADIUS: "Bewässerungsradius insgesamt",
  PIVOT_CONFIG_FIELD_FLOW: "Durchflussmenge",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_STOP_MODE: "Stoppmodus",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_MM_TO_STOP: "Stopp bei (mm)",
  PIVOT_CONFIG_VALUE_ENDGUN_WITHOUT: "Aus",
  PIVOT_CONFIG_VALUE_ENDGUN_ON: "On",
  PIVOT_CONFIG_FIELD_DATE_TIME: "Datum und Uhrzeit",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_STOP: "Komm vorbei, mm",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_DECREASE: "Niederschlagsabnahme",
  SELECTED_PIVOT_SEGMENT_MODAL_LEFT_TITLE: "Ausgewählte Segmenteinstellungen",
  SELECTED_PIVOT_SEGMENT_TITLE: "Ausgewähltes Segment",
  CLICK_ON_SEGMENT:
    "Klicken Sie auf das Segment, um die Informationen zu bearbeiten.",
  SELECTED_PIVOT_SEGMENT_MODAL_RIGHT_TITLE: "Segmentbewässerungsparameter",
  SELECTED_PIVOT_SEGMENT_MODAL_TITLE: "Segmentbewässerung senden",
  SELECTED_PIVOT_SEGMENT_MODAL_SUCCESS:
    "Segment Bewässerung erfolgreich gesendet",
  SELECTED_PIVOT_SEGMENT_MODAL_FAILURE:
    "Segmentbewässerung konnte nicht gesendet werden",
  SEGMENT_IRRIGATION_FIELD_START_MODE: "Startmodus",
  SEGMENT_IRRIGATION_SHOW_CONFIGURED_SEGMENTS:
    "Konfigurierte Segmente anzeigen",
  SEGMENT_IRRIGATION_FIELD_END_MODE: "Modus beenden",
  SEGMENT_IRRIGATION_FIELD_START_DATE: "Startdatum",
  SEGMENT_IRRIGATION_FIELD_END_DATE: "Enddatum",
  SEGMENT_IRRIGATION_FIELD_END_ANGLE: "End-Winkel",
  SEGMENT_IRRIGATION_FIELD_END_ROUNDS: "Rundungen",
  SEGMENT_IRRIGATION_MODE_NOW: "Jetzt",
  SEGMENT_IRRIGATION_ON_DATE: "Bei einem Date",
  SEGMENT_IRRIGATION_NEVER_ENDS: "Don't Stop",
  SEGMENT_IRRIGATION_BY_ANGLE: "Nach Winkel",
  SEGMENT_IRRIGATION_BY_DATE: "Nach Datum",
  SEGMENT_IRRIGATION_END_SECTOR: "Endsektor",
  SEGMENT_IRRIGATION_BY_ROUNDS: "Nach Runden",
  SEGMENT_IRRIGATION_ROUND_FIELD_ERROR:
    "Fehler. Die Anzahl der Runden muss größer als Null sein",
  SEGMENT_IRRIGATION_ERROR_END_ANGLE_OUT_OF_RANGE:
    "Stoppwinkel außerhalb des Bereichs für diesen Drehpunkt",
  SEGMENT_IRRIGATION_ERROR_LOADING_LATEST:
    "Kann das letzte Segment Bewässerung nicht laden",
  SEGMENT_IRRIGATION_INCOMPATIBLE_IRRIGATIONS:
    "Die letzte Bewässerung konnte nicht geladen werden. Die Segmente unterscheiden sich.",
  SEGMENT_IRRIGATION_MISSING_SEGMENTS:
    "Es sind keine Segmente im aktuellen Pivot gespeichert. Speichere sie und versuche es dann erneut.",
  SELECTED_PIVOT_GENERAL: "Allgemein",
  SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES: "Geschichte bearbeiten",
  SELECTED_PIVOT_HARVEST: "Ernte",
  SELECTED_PIVOT_BASIC_INFO: "Zusammenfassung der Operationen",
  SELECTED_PIVOT_PUMP_BOX: "Pumpen",
  SELECTED_PIVOT_HISTORIC: "Geschichte",
  SELECTED_PIVOT_RECOMMENDATION: "Empfehlung",
  SELECTED_PIVOT_SETUP_IRRIGATION: "Bewässerung einrichten",
  SELECTED_PIVOT_CHOOSE_IRRIGATION_MODE: "Bewässerungsmodus wählen:",
  SELECTED_PIVOT_STANDARD_IRRIGATION: "Einfach",
  SELECTED_PIVOT_BY_ANGLE_IRRIGATION: "Nach Winkel",
  SELECTED_PIVOT_VRI_IRRIGATION: "Segmente",
  SELECTED_PIVOT_SCHEDULE_IRRIGATION: "Zeitplan",
  SELECTED_PIVOT_STOP_ACTION: "Stop Pivot",
  SELECTED_PIVOT_EDIT_BUTTON_TEXT: "Edit",
  SELECTED_PIVOT_BACK_BUTTON_TEXT: "Zurück",
  SELECTED_PIVOT_SEGMENT_IRRIGATION_BUTTON: "Segmente",
  SELECTED_PIVOT_HISTORIC_SWITCH: "Fortgeschritten",
  PIVOT_CONFIG_DASHBOARD_NAME: "${}",
  PIVOT_CONFIG_REPORT_PREFERENCES: "Zusammenfassung bearbeiten",
  PIVOT_REPORT_EXCEL: "Excel",
  IRPD_REPORT_CENTRAL: "Betriebsbericht",
  IRPD_CENTRAL_GH_REPORT:
    "Zentraler Betriebsbericht erstellt. Er wird in Kürze in Ihrem E-Mail-Postfach verfügbar sein.",
  IRPD_CENTRAL_GH_REPORT_ERROR:
    "Fehler bei der Anforderung des zentralen Betriebsberichts. Bitte versuchen Sie es später erneut.",
  PIVOT_REPORT_EXCEL_ERROR:
    "Es gibt keine Daten, um die Excel-Tabelle zu erstellen.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL:
    "Excel-Datei wird heruntergeladen. Bitte warten Sie.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_ERROR:
    "Etwas ist schief gelaufen. Bitte, später noch einmal.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_SUCCESS:
    "Download beendet. Sie können dieses Fenster schließen.",
  REPORT_TABLE_START_DATE: "Start",
  REPORT_TABLE_END_DATE: "Ende",
  REPORT_TABLE_START_TOTAL: "Total",
  REPORT_TABLE_START_WATER_BLADE: "Wasser-Klinge",
  REPORT_TABLE_START_DETAILS: "Einzelheiten",
  HYDRIC_MANAGEMENT_CREATE_LABEL: "Wasserwirtschaftspumpe",
  HYDRIC_REPORT_TABLE_START_DATE: "Beginn des Betriebs",
  HYDRIC_REPORT_TABLE_END_DATE: "Ende des Betriebs",
  HYDRIC_REPORT_TABLE_DURATION: "Betriebsdauer",
  HYDRIC_REPORT_TABLE_CALC_VOLUME: "Berechnetes Volumen",
  HYDRIC_REPORT_TABLE_TOTAL_VOLUME: "Hydrometervolumen",
  SELECTED_PIVOT_IRRIFAST: "Towers alignment",
  IRRIFAST_TABLE_COL_SPEC: "Spezifikation",
  IRRIFAST_TABLE_COL_VALUE: "Wert",
  IRRIFAST_TABLE_PROPS_TENSION: "Spannung (DC)",
  IRRIFAST_TABLE_PROPS_FREQ: "Ausgangsfrequenz",
  IRRIFAST_TABLE_PROPS_CURRENT: "Ausgangsstrom",
  IRRIFAST_TABLE_PROPS_TENSION_SUFFIX: "V",
  IRRIFAST_TABLE_PROPS_FREQ_SUFFIX: "Hz",
  IRRIFAST_TABLE_PROPS_CURRENT_SUFFIX: "A",
  IRRIFAST_TABLE_ACTION_COLLAPSE: "Detaillierte Informationen",
  IRRIFAST_TABLE_LABEL_NONE:
    "Bitte wählen Sie einen Turm aus, um detaillierte Daten zu überprüfen.",
  IRRIFAST_TABLE_STATUS_OFF: "Aus",
  IRRIFAST_TABLE_STATUS_ALIGNED: "Ausgerichtet",
  IRRIFAST_TABLE_STATUS_NOTALIGNED: "Nicht ausgerichtet",
  DEVICE_BOX_ANALYZE: "Analysieren:",
  DEVICE_BOX_CONTROL: "Controller",
  DEVICE_BOX_GPS: "GPS",
  DEVICE_BOX_PIVOT: "Drehpunkt",
  DEVICE_BOX_SHOW_GPS: "GPS anzeigen",
  DEVICE_BOX_SEARCH_RADIO: "Radio suchen",
  DEVICE_BOX_KEEP_LINES: "Leinen halten",
  SELECTED_PIVOT_SCHEDULE_MODAL_SUCCESS:
    "Bewässerungszeitplan erfolgreich gesendet",
  SELECTED_PIVOT_SCHEDULE_MODAL_FAILURE:
    "Fairfield zum Senden von Bewässerungsplänen",
  SELECTED_PIVOT_SCHEDULE_MODAL_ROW_LIMIT:
    "Sie können nur 7 Zeilen hinzufügen.",
  BY_ANGLE_IRRIGATION_BUTTON_FORWARD: "VORWÄRTS",
  BY_ANGLE_IRRIGATION_BUTTON_BACKWARD: "REVERSE",
  BY_ANGLE_IRRIGATION_FIELD_PERCENTAGE: "Prozentsatz",
  BY_ANGLE_IRRIGATION_FIELD_PERCENT: "Prozent",
  BY_ANGLE_IRRIGATION_FIELD_MILILITER: "Milimeter",
  BY_ANGLE_IRRIGATION_ERROR:
    "Fehler. Der informierte Wert ist geringer als der erwartete Mindestwert.",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_MODE: "Endgun-Modus",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE: "Betriebsmodus",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION: "Richtung",
  BY_ANGLE_IRRIGATION_FIELD_DEFAULT_ERROR:
    "Fehler. Prüfen Sie, ob alle Felder ausgefüllt sind.",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_FORWARD: "Vorwärts",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_REVERSE: "Umgekehrt",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_ON: "On",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_OFF: "Aus",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_DRY: "Trocken",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_WET: "Nass",
  BY_ANGLE_IRRIGATION_BUTTON_SEND: "Befehl senden",
  IRRIGATION_END_DURATION: "Vorhersage der Betriebsdauer: ",
  IRRIGATION_END_DATE_PREDICTION: "Voraussichtliches Enddatum: ",
  BY_ANGLE_IRRIGATION_BUTTON_CANCEL: "Abbrechen",
  ALERT_DIALOG_YES: "Ja",
  ALERT_DIALOG_NO: "Nein",
  ALERT_DIALOG_CONFIRMATION: "Konfirmation",
  FIRST_STEPS: "Erste Schritte",
  FIRST_STEPS_SELECT_OPTION: "Wählen Sie eine Option",
  FIRST_STEPS_OPTION_ADM:
    "Ich bin der Verwalter des Betriebs und muss meine Geräte anmelden.",
  FIRST_STEPS_OPTION_EMPLOYEE: "Ich bin Landarbeiter und muss mich anmelden.",
  FIRST_STEPS_OPTION_ADM_INFO:
    "Scannen Sie mit Ihrer Kamera den QR-Code im Inneren der Zentrale oder geben Sie unten die 16-stellige Zahlenfolge ein, die der ID der Zentrale entspricht. ",
  FIRST_STEPS_OPTION_EMPLOYEE_INFO:
    "Fragen Sie Ihren Administrator nach dem Farmcode, um die Registrierung fortzusetzen. ",
  FIRST_STEPS_FARM_CODE: "Bauernhof-Code",
  FIRST_STEPS_CENTRAL_ID: "Zentraler Ausweis",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE: "Profil erstellen",
  FIRST_STEPS_CREATE_PROFILE_ADM: "Administrator erstellen",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE_INFO:
    "Geben Sie Ihre Profildaten ein. Ihre Anmeldung wird nach Bestätigung der Bestätigung-E-Mail abgeschlossen.",
  FIRST_STEPS_CREATE_PROFILE_ADM_INFO:
    "Geben Sie die Benutzerdaten des Verwalters ein. Ihre Anmeldung wird nach Bestätigung der Bestätigung-E-Mail abgeschlossen.",
  GENERAL_BOX_NO_PANEL_STREAM: "Noch keine Aktualisierungen",
  GENERAL_BOX_LAST_UPDATE: "Letzte Mitteilung: ",
  GENERAL_BOX_STOP_PIVOT_SUCCESS: "Stopp-Befehl gesendet.",
  GENERAL_BOX_STOP_PIVOT_FAILURE: "Die Drehung nicht stoppen können",
  GENERAL_BOX_PIVOT_IS_ONLINE_FAILURE:
    "Prüfung, ob der Pivot online ist, fehlgeschlagen.",
  EDIT_IRPD_SEND_SUCCESS: "Senden",
  EDIT_IRPD_SEND_CANCEL: "Abbrechen",
  GENERAL_BOX_IRPD_IS_ONLINE_FAILURE:
    "Überprüfung fehlgeschlagen, ob der irpd online ist",
  IRPD_BOX_STOP_COMMAND_SENT: "Stopp-Befehl erfolgreich gesendet.",
  IRPD_BOX_COMMAND_ERROR: "Fehler beim Senden des Befehls.",
  IRPD_BOX_COMMAND_SENT: "Befehl erfolgreich gesendet.",
  IRPD_BOX_CONFIGURATION_SAVED: "Konfiguration erfolgreich gesendet.",
  IRPD_BOX_CONFIGURATION_ERROR: "Fehler beim Senden der Konfiguration.",
  EDIT_FARM_CENTER_MAP_BUTTON: "Zentrum auf der Karte",
  EDIT_FARM_SAVE_SUCCESS: "Farm erfolgreich gespeichert",
  EDIT_FARM_ERROR_NAME: "Ungültige Namensgröße",
  EDIT_FARM_ERROR_BILLING_DATE: "Ungültiges Rechnungsdatum",
  EDIT_FARM_ERROR_INITIAL_LAT: "Ungültiger anfänglicher Breitengrad",
  EDIT_FARM_ERROR_INITIAL_LNG: "Ungültiger anfänglicher Längengrad",
  EDIT_FARM_ERROR_LAT: "Ungültiger Breitengrad",
  EDIT_FARM_ERROR_LNG: "Ungültiger Längengrad",
  EDIT_FARM_ERROR_ADDRESS: "Ungültige Adresse",
  EDIT_FARM_ERROR_POSTAL_CODE: "Ungültige Postleitzahl",
  EDIT_FARM_ERROR_CITY: "Ungültige Stadt",
  EDIT_FARM_ERROR_STATE: "Ungültiger Zustand",
  EDIT_FARM_ERROR_COUNTRY: "Ungültiges Land",
  EDIT_FARM_ERROR_PHONE: "Ungültige Rufnummer",
  EDIT_FARM_ERROR_INCOMPLETE_POWER_RANGE:
    "Es gibt fehlende Tage in Ihren Leistungsbereichen. Füllen Sie alle Tage der Woche.",
  EDIT_FARM_REPEATED_DAYS:
    "Dieser Tag ist bereits in die Liste aufgenommen worden.",
  EDIT_FARM_POWER_RANGES: "Leistungsbereiche",
  EDIT_FARM__POWER_RANGES_DESCRIPTION:
    "Die Leistungsbereiche werden zur Berechnung der Energiekosten für die Berichte verwendet.",
  EDIT_FARM_ADD_POWER_RANGES: "Leistungsbereiche hinzufügen",
  EDIT_FARM_HOLLIDAYS: "URLAUB",
  EDIT_FARM_ADD_HOLLIDAYS: "URLAUB HINZUFÜGEN",
  DATE_PICKER_HOLIDAYS: "Wählen Sie ein Datum",
  EDIT_FARM_DAY: "Tag",
  EDIT_FARM_MONTH: "Monat",
  EDIT_FARM_START_TIME: "Startzeit",
  EDIT_FARM_END_TIME: "Endzeit",
  EDIT_FARM_INTERVAL: "Intervall",
  EDIT_FARM_DURATION: "Dauer",
  EDIT_FARM_TYPE: "Typus",
  EDIT_FARM_OPERATION_SUMMARY_POWER_RANGES_CHANGED_WARNING:
    "Die Leistungsbereiche haben sich geändert, speichern Sie die vorherigen Änderungen, um die folgenden Schaltflächen zu aktivieren.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_LABEL: "Berichte aktivieren",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_TEXT:
    "Drücken Sie die Taste, um die Erstellung von Berichten für die Farm-Pivots zu aktivieren.",
  EDIT_FARM_OPERATION_SUMMARY_INVALID_POWER_RANGES:
    "Registrieren Sie gültige Leistungsbereiche, um die Berichte zu aktivieren.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATING:
    "Ihre Berichte werden erstellt.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_ENABLED:
    "Ihre Berichte sind bereits aktiviert.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATED:
    "Ihre Berichte sind aktiviert.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_LABEL: "Berichte neu berechnen",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION:
    "Sind Sie sicher, dass Sie Ihre Berichte neu berechnen wollen?",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_TEXT:
    "Drücken Sie den Knopf, um die Berichte für die Farm-Pivots neu zu berechnen.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE: "Neu berechnen",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_SUCESS: "Berichte aktiviert.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_ERROR:
    "Fehler beim Aktivieren von Berichten.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_SUCCESS:
    "Die Berichte werden neu berechnet.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_ERROR:
    "Fehler beim Versuch, die Berichte neu zu berechnen.",
  EDIT_FARM_PIVOT_REPORTS: "Pivot-Berichte",
  EDIT_FARM_IRPD_REPORTS: "Pumpenberichte",
  PIVOT: "Drehpunkt",
  PIVOT_PLURAL: "Pivots",
  PIVOT_STATUS_STOPPED: "Angehalten",
  PIVOT_STATUS_IN_MOVING: "Umzug im Trockenen",
  PIVOT_STATUS_IRRIGATING: "Bewässerung",
  PIVOT_STATUS_PROBLEM: "Problem",
  PIVOT_STATUS_PROGRAMMED: "Programmiert",
  PIVOT_STATUS_PANEL_ON: "Gremium ein",
  PIVOT_STATUS_PANEL_OFF: "Paneel aus",
  PIVOT_STATUS_PRESSURIZING: "Warten auf Druck",
  PIVOT_FIELD_NAME: "Name",
  PAINEL_STREAM_REASON_0: "Gremium ein",
  PAINEL_STREAM_REASON_1: "Bewegung im Trockenen",
  PAINEL_STREAM_REASON_2: "Nassmodus nach Druckbeaufschlagung",
  PAINEL_STREAM_REASON_4: "Nassmodus nach Stromausfall",
  PAINEL_STREAM_REASON_5: "Trockenlauf nach Stromausfall",
  PAINEL_STREAM_REASON_6:
    "Nach Stromausfall eingeschaltet und mit Druck beaufschlagt",
  PAINEL_STREAM_REASON_8: "Nassmodus nach Pausenzeit",
  PAINEL_STREAM_REASON_9: "Trockenmodus nach Pausenzeit",
  PAINEL_STREAM_REASON_10: "Nassmodus nach Pausenzeit und unter Druck",
  PAINEL_STREAM_REASON_11:
    "Trockenmodus nach Beginn der planmäßigen Bewässerung",
  PAINEL_STREAM_REASON_16: "Nach der planmäßigen Bewässerung",
  PAINEL_STREAM_REASON_18: "Nassmodus nach Druckbeaufschlagung",
  PAINEL_STREAM_REASON_21: "Pivot geplant im Trockenmodus",
  PAINEL_STREAM_REASON_22: "Pivot on und planmäßig nach Druckbeaufschlagung",
  PAINEL_STREAM_REASON_32: "Pivot im Nassmodus geplant",
  PAINEL_STREAM_REASON_33: "Trockenmodus nach Plan",
  PAINEL_STREAM_REASON_34: "Nassmodus nach geplanter Bewässerung",
  PAINEL_STREAM_REASON_38: "Pivot nass nach Stromausfall",
  PAINEL_STREAM_REASON_39: "Befruchtung begonnen",
  PAINEL_STREAM_REASON_65: "Trockener Zeitplan wird fortgesetzt",
  PAINEL_STREAM_REASON_66: "Fortsetzung des nassen Zeitplans",
  PAINEL_STREAM_REASON_98: "Bewässerung",
  PAINEL_STREAM_REASON_100: "Panel einschalten",
  PAINEL_STREAM_REASON_101: "Paneel aus",
  PAINEL_STREAM_REASON_102: "Warten auf Druckbeaufschlagung",
  PAINEL_STREAM_REASON_103: "Drehung mit Druck gestoppt",
  PAINEL_STREAM_REASON_104: "Befruchtung gestoppt",
  PAINEL_STREAM_REASON_240: "Vom Regenmesser gestoppt",
  PAINEL_STREAM_REASON_241: "Vor dem nächsten Termin gestoppt",
  PAINEL_STREAM_REASON_242: "Gestoppt durch unbekannten Faktor",
  PAINEL_STREAM_REASON_243: "Bei Endsektor gestoppt",
  PAINEL_STREAM_REASON_244: "Zeitplan für Bewässerung abgeschlossen",
  PAINEL_STREAM_REASON_245: "Autoreversion durchführen",
  PAINEL_STREAM_REASON_246: "Nach Stromstoß gestoppt",
  PAINEL_STREAM_REASON_247: "Angehalten wegen Pausenzeit",
  PAINEL_STREAM_REASON_248: "Wegen Unterdruck angehalten",
  PAINEL_STREAM_REASON_249: "Gestoppt über das Web",
  PAINEL_STREAM_REASON_250: "Gestoppt durch Druck-Timeout",
  PAINEL_STREAM_REASON_251: "Nach Auslösen des Thermorelais gestoppt",
  PAINEL_STREAM_REASON_252: "Nach Beendigung der Bewässerung gestoppt",
  PAINEL_STREAM_REASON_253: "Angehalten nach Stromausfall",
  PAINEL_STREAM_REASON_254: "STOP-Taste gedrückt",
  PAINEL_STREAM_REASON_255: "Angehalten nach Fehlausrichtung",
  PAINEL_STREAM_REASON_256: "Keine Kommunikation",
  PANEL_STREAM_STATUS_0: "Gestoppt durch Web",
  PANEL_STREAM_STATUS_1: "Vorwärtskommen",
  PANEL_STREAM_STATUS_2: "Rückwärtsgang",
  PANEL_STREAM_STATUS_3: "Wartedruck",
  PANEL_STREAM_STATUS_4: "Bewässerung",
  PANEL_STREAM_STATUS_5: "Wartedruckverzögerung",
  PANEL_STREAM_STATUS_6: "Trockener Umzug",
  PANEL_STREAM_STATUS_7: "Programmiert",
  PANEL_STREAM_STATUS_8: "Vorbereiten zum Einschalten",
  PANEL_STREAM_STATUS_9: "Angehalten von der Pausenzeit",
  PANEL_STREAM_STATUS_10: "Bewässerung beendet",
  PANEL_STREAM_STATUS_11: "Gestoppt durch Autoreversion",
  PANEL_STREAM_STATUS_12: "Gestoppt durch das Internet",
  PANEL_STREAM_STATUS_13: "Manueller Stopp",
  PANEL_STREAM_STATUS_14: "Gremium ein",
  PANEL_STREAM_STATUS_14_2: "Pivot on",
  PANEL_STREAM_STATUS_15: "Sicherheit",
  PANEL_STREAM_STATUS_16: "Druckausfall",
  PANEL_STREAM_STATUS_17: "Ungültige Bewässerungsrichtung",
  PANEL_STREAM_STATUS_18: "Stromausfall",
  PANEL_STREAM_STATUS_19: "Spannung außerhalb des Bereichs",
  PANEL_STREAM_STATUS_20: "Parameterfehler",
  PANEL_STREAM_STATUS_21: "Unbekannter Fehler",
  PANEL_STREAM_STATUS_22: "Schalttafel aus",
  PANEL_STREAM_STATUS_23: "Druck-Timeout",
  PANEL_STREAM_STATUS_24: "Bewässerung gestartet",
  PANEL_STREAM_STATUS_25: "Stopptaste gedrückt",
  PANEL_STREAM_STATUS_32: "Pumpenfehler",
  PANEL_STREAM_STATUS_33: "Trockenlegung durch Pluviometer",
  PANEL_STREAM_STATUS_34: "Gestoppt durch Pluviometer",
  PANEL_STREAM_STATUS_35: "Angehalten durch Uhrfehler",
  PANEL_STREAM_STATUS_36: "Befruchtung begonnen",
  PANEL_STREAM_STATUS_37: "Befruchtung gestoppt",
  PANEL_STREAM_STATUS_38: "Keine Kommunikation",
  PANEL_STREAM_STATUS_POWER_OFF: "Ausgeschaltet",
  PANEL_STREAM_STATUS_NONE: "Unbekannter Status",
  IRPD_STATUS_STOPPED: "Angehalten",
  IRPD_STATUS_PUMPING: "Pumpen",
  IRPD_STATUS_PROBLEM: "Problem",
  IRPD_STATUS_PROGRAMMED: "Programmiert",
  IRPD_STATUS_ENERGIZED: "Energiegeladen",
  IRPD_STATUS_WITHOUT_ENERGY: "Ohne Energie",
  SELECTED_IRPD_BACK_BUTTON_TEXT: "Zurück",
  SELECTED_IRPD_SAVE_BUTTON_TEXT: "Speichern",
  SELECTED_IRPD_SAVE_BUTTON_TEXT_DS: "Speichern / Als Datenblatt festlegen",
  IRPD_STATUS_1: "Über das Internet angemacht",
  IRPD_STATUS_2: "Manuell eingeschaltet",
  IRPD_STATUS_3: "Ein nach der Pausenzeit",
  IRPD_STATUS_4: "Einschalten nach Stromausfall",
  IRPD_STATUS_5: "Pumpe ist programmiert",
  IRPD_STATUS_6: "Gerät ist eingeschaltet",
  IRPD_STATUS_7: "Pumpe durch einen Sensor eingeschaltet",
  IRPD_STATUS_8: "Pumpe wartet nach Stromausfall",
  IRPD_STATUS_9: "Pumpe per Funkfernbedienung eingeschaltet",
  IRPD_STATUS_192: "Betriebsstundenzähler beschädigt",
  IRPD_STATUS_193: "Pumpe durch unbekannte Fernsteuerung eingeschaltet",
  IRPD_STATUS_245: "Pump off - No communication to IMeter",
  IRPD_STATUS_246: "Pumpe aus durch IMeter",
  IRPD_STATUS_247: "Zeitplan abgelaufen",
  IRPD_STATUS_248: "Falscher Zeitplan",
  IRPD_STATUS_249: "Pumpe aus wegen Ende des Zeitplans",
  IRPD_STATUS_250: "Pumpe durch Sensor ausgeschaltet",
  IRPD_STATUS_251: "Aus",
  IRPD_STATUS_252: "Ausschalten nach Stromausfall",
  IRPD_STATUS_253: "Aus nach Pausenzeit",
  IRPD_STATUS_254: "Manuell ausgeschaltet",
  IRPD_STATUS_255: "Gestoppt über das Internet",
  IRPD_CONFIG_FIELD_START_NOW: "Jetzt anfangen",
  IRPD_CONFIG_FIELD_STOP_NOW: "Jetzt aufhören",
  IRPD_FIELD_START_INTERVAL: "Anfangen",
  IRPD_CONFIG_FIELD_DONT_STOP: "Nicht aufhören",
  IRPD_FIELD_END_INTERVAL: "Hereinspaziert",
  IRPD_FIELD_OFF: "Aus",
  EDIT_IRPD_CANCEL: "Sind Sie sicher?",
  EDIT_IRPD_ERROR_RTCDATE: "Ungültige RTC",
  EDIT_IRPD_ERROR_HOUR_RANGE_MAX: "Ungültige Höchststunde",
  EDIT_IRPD_ERROR_HOUR_RANGE_MIN: "Ungültige Mindeststundenzahl",
  EDIT_IRPD_ERROR_ENERGY_TIME: "Ungültige Energiezeit",
  COUNTRY_AFGHANISTAN: "Afghanistan",
  COUNTRY_SOUTH_AFRICA: "Südafrika",
  COUNTRY_ALBANIA: "Albanien",
  COUNTRY_GERMANY: "Deutschland",
  COUNTRY_ANDORRA: "Andorra",
  COUNTRY_ANGOLA: "Angola",
  COUNTRY_ANGUILLA: "Anguilla",
  COUNTRY_ANTARCTICA: "Antarktis",
  COUNTRY_ANTIGUA_AND_BARBUDA: "Antigua und Barbuda",
  COUNTRY_SAUDI_ARABIA: "Saudi-Arabien",
  COUNTRY_ALGERIA: "Algerien",
  COUNTRY_ARGENTINA: "Argentinien",
  COUNTRY_ARMENIA: "Armenien",
  COUNTRY_ARUBA: "Aruba",
  COUNTRY_AUSTRALIA: "Australien",
  COUNTRY_AUSTRIA: "Österreich",
  COUNTRY_AZERBAIJAN: "Aserbaidschan",
  COUNTRY_BAHAMAS: "Bahamas",
  COUNTRY_BAHRAIN: "Bahrain",
  COUNTRY_BANGLADESH: "Bangladesch",
  COUNTRY_BARBADOS: "Barbados",
  COUNTRY_BELARUS: "Weißrussland",
  COUNTRY_BELGIUM: "Belgien",
  COUNTRY_BELIZE: "Belize",
  COUNTRY_BENIN: "Benin",
  COUNTRY_BERMUDA: "Bermuda",
  COUNTRY_BOLIVIA: "Bolivien",
  COUNTRY_BOSNIA_AND_HERZEGOVINA: "Bosnien und Herzegowina",
  COUNTRY_BOTSWANA: "Botswana",
  COUNTRY_BRAZIL: "Brasilien",
  COUNTRY_BRUNEI: "Brunei",
  COUNTRY_BULGARIA: "Bulgarien",
  COUNTRY_BURKINA_FASO: "Burkina Faso",
  COUNTRY_BURUNDI: "Burundi",
  COUNTRY_BHUTAN: "Bhutan",
  COUNTRY_CAPE_VERDE: "Kap Verde",
  COUNTRY_CAMEROON: "Kamerun",
  COUNTRY_CAMBODIA: "Kambodscha",
  COUNTRY_CANADA: "Kanada",
  COUNTRY_CANARY_ISLANDS: "Kanarische Inseln",
  COUNTRY_KAZAKHSTAN: "Kasachstan",
  COUNTRY_CEUTA_AND_MELILLA: "Ceuta und Melilla",
  COUNTRY_CHAD: "Tschad",
  COUNTRY_CHILE: "Chile",
  COUNTRY_CHINA: "China",
  COUNTRY_CYPRUS: "Zypern",
  COUNTRY_SINGAPORE: "Singapur",
  COUNTRY_COLOMBIA: "Kolumbien",
  COUNTRY_COMOROS: "Komoren",
  COUNTRY_CONGO_REPUBLIC: "Kongo (Republik)",
  COUNTRY_NORTH_KOREA: "Nordkorea",
  COUNTRY_SOUTH_KOREA: "Südkorea",
  COUNTRY_CTE_DIVOIRE: "Côte d¿Ivoire",
  COUNTRY_COSTA_RICA: "Costa Rica",
  COUNTRY_CROATIA: "Kroatien",
  COUNTRY_CUBA: "Kuba",
  COUNTRY_CURAAO: "Curaçao",
  COUNTRY_DIEGO_GARCIA: "Diego Garcia",
  COUNTRY_DENMARK: "Dänemark",
  COUNTRY_DJIBOUTI: "Dschibuti",
  COUNTRY_DOMINICA: "Dominica",
  COUNTRY_EGYPT: "Ägypten",
  COUNTRY_EL_SALVADOR: "El Salvador",
  COUNTRY_UNITED_ARAB_EMIRATES: "Vereinigte Arabische Emirate",
  COUNTRY_ECUADOR: "Ecuador",
  COUNTRY_ERITREA: "Eritrea",
  COUNTRY_SLOVAKIA: "Slowakei",
  COUNTRY_SLOVENIA: "Slowenien",
  COUNTRY_SPAIN: "Spanien",
  COUNTRY_UNITED_STATES: "Vereinigte Staaten",
  COUNTRY_ESTONIA: "Estland",
  COUNTRY_ETHIOPIA: "Äthiopien",
  COUNTRY_FIJI: "Fidschi",
  COUNTRY_PHILIPPINES: "Philippinen",
  COUNTRY_FINLAND: "Finnland",
  COUNTRY_FRANCE: "Frankreich",
  COUNTRY_GABON: "Gabun",
  COUNTRY_GAMBIA: "Gambia",
  COUNTRY_GHANA: "Ghana",
  COUNTRY_GEORGIA: "Georgien",
  COUNTRY_GIBRALTAR: "Gibraltar",
  COUNTRY_UNITED_KINGDOM: "Vereinigtes Königreich",
  COUNTRY_GRENADA: "Grenada",
  COUNTRY_GREECE: "Griechenland",
  COUNTRY_GREENLAND: "Grönland",
  COUNTRY_GUADELOUPE: "Guadeloupe",
  COUNTRY_GUAM: "Guam",
  COUNTRY_GUATEMALA: "Guatemala",
  COUNTRY_GUERNSEY: "Guernsey",
  COUNTRY_GUYANA: "Guyana",
  COUNTRY_FRENCH_GUIANA: "Französisch-Guayana",
  COUNTRY_GUINEA: "Guinea",
  COUNTRY_EQUATORIAL_GUINEA: "Äquatorialguinea",
  COUNTRY_GUINEA_BISSAU: "Guinea-Bissau",
  COUNTRY_HAITI: "Haiti",
  COUNTRY_NETHERLANDS: "Niederlande",
  COUNTRY_HONDURAS: "Honduras",
  COUNTRY_HONG_KONG: "Hongkong",
  COUNTRY_HUNGARY: "Ungarn",
  COUNTRY_YEMEN: "Jemen",
  COUNTRY_BOUVET_ISLAND: "Bouvetinsel",
  COUNTRY_ASCENSION_ISLAND: "Die Insel Ascension",
  COUNTRY_CLIPPERTON_ISLAND: "Clipperton Island",
  COUNTRY_ISLE_OF_MAN: "Insel Man",
  COUNTRY_CHRISTMAS_ISLAND: "Weihnachtsinsel",
  COUNTRY_PITCAIRN_ISLANDS: "Pitcairn-Inseln",
  COUNTRY_RUNION: "Réunion",
  COUNTRY_LAND_ISLANDS: "Åland-Inseln",
  COUNTRY_CAYMAN_ISLANDS: "Kaimaninseln",
  COUNTRY_COCOS_KEELING_ISLANDS: "Kokosinseln (Keeling)",
  COUNTRY_COOK_ISLANDS: "Cook-Inseln",
  COUNTRY_FAROE_ISLANDS: "Färöer Inseln",
  COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS:
    "Südgeorgien und Südliche Sandwichinseln",
  COUNTRY_HEARD_AND_MCDONALD_ISLANDS: "Heard & McDonald Islands",
  COUNTRY_FALKLAND_ISLANDS_ISLAS_MALVINAS: "Falklandinseln (Islas Malvinas)",
  COUNTRY_NORTHERN_MARIANA_ISLANDS: "Nördliche Marianen",
  COUNTRY_MARSHALL_ISLANDS: "Marshallinseln",
  COUNTRY_US_OUTLYING_ISLANDS: "U.S. Entlegene Inseln",
  COUNTRY_NORFOLK_ISLAND: "Norfolkinsel",
  COUNTRY_SOLOMON_ISLANDS: "Salomonen",
  COUNTRY_SEYCHELLES: "Seychellen",
  COUNTRY_TOKELAU: "Tokelau",
  COUNTRY_TURKS_AND_CAICOS_ISLANDS: "Turks- und Caicosinseln",
  COUNTRY_US_VIRGIN_ISLANDS: "U.S. Jungferninseln",
  COUNTRY_BRITISH_VIRGIN_ISLANDS: "Britische Jungferninseln",
  COUNTRY_INDIA: "Indien",
  COUNTRY_INDONESIA: "Indonesien",
  COUNTRY_IRAN: "Iran",
  COUNTRY_IRAQ: "Irak",
  COUNTRY_IRELAND: "Irland",
  COUNTRY_ICELAND: "Island",
  COUNTRY_ISRAEL: "Israel",
  COUNTRY_ITALY: "Italien",
  COUNTRY_JAMAICA: "Jamaika",
  COUNTRY_JAPAN: "Japan",
  COUNTRY_JERSEY: "Jersey",
  COUNTRY_JORDAN: "Jordanien",
  COUNTRY_KIRIBATI: "Kiribati",
  COUNTRY_KOSOVO: "Kosovo",
  COUNTRY_KUWAIT: "Kuwait",
  COUNTRY_LAOS: "Laos",
  COUNTRY_LESOTHO: "Lesotho",
  COUNTRY_LATVIA: "Lettland",
  COUNTRY_LEBANON: "Libanon",
  COUNTRY_LIBERIA: "Liberia",
  COUNTRY_LIBYA: "Libyen",
  COUNTRY_LIECHTENSTEIN: "Liechtenstein",
  COUNTRY_LITHUANIA: "Litauen",
  COUNTRY_LUXEMBOURG: "Luxemburg",
  COUNTRY_MACAU: "Macau",
  COUNTRY_MACEDONIA_FYROM: "Mazedonien (FYROM)",
  COUNTRY_MADAGASCAR: "Madagaskar",
  COUNTRY_MALAYSIA: "Malaysia",
  COUNTRY_MALAWI: "Malawi",
  COUNTRY_MALDIVES: "Malediven",
  COUNTRY_MALI: "Mali",
  COUNTRY_MALTA: "Malta",
  COUNTRY_MOROCCO: "Marokko",
  COUNTRY_MARTINIQUE: "Martinique",
  COUNTRY_MAURITIUS: "Mauritius",
  COUNTRY_MAURITANIA: "Mauretanien",
  COUNTRY_MAYOTTE: "Mayotte",
  COUNTRY_MEXICO: "Mexiko",
  COUNTRY_MICRONESIA: "Mikronesien",
  COUNTRY_MOZAMBIQUE: "Mosambik",
  COUNTRY_MOLDOVA: "Moldawien",
  COUNTRY_MONACO: "Monaco",
  COUNTRY_MONGOLIA: "Mongolei",
  COUNTRY_MONTENEGRO: "Montenegro",
  COUNTRY_MONTSERRAT: "Montserrat",
  COUNTRY_MYANMAR_BURMA: "Myanmar (Burma)",
  COUNTRY_NAMIBIA: "Namibia",
  COUNTRY_NAURU: "Nauru",
  COUNTRY_NEPAL: "Nepal",
  COUNTRY_NICARAGUA: "Nicaragua",
  COUNTRY_NIGER: "Niger",
  COUNTRY_NIGERIA: "Nigeria",
  COUNTRY_NIUE: "Niue",
  COUNTRY_NORWAY: "Norwegen",
  COUNTRY_NEW_CALEDONIA: "Neukaledonien",
  COUNTRY_NEW_ZEALAND: "Neuseeland",
  COUNTRY_OMAN: "Oman",
  COUNTRY_CARIBBEAN_NETHERLANDS: "Karibische Niederlande",
  COUNTRY_PALAU: "Palau",
  COUNTRY_PALESTINE: "Palästina",
  COUNTRY_PANAMA: "Panama",
  COUNTRY_PAPUA_NEW_GUINEA: "Papua-Neuguinea",
  COUNTRY_PAKISTAN: "Pakistan",
  COUNTRY_PARAGUAY: "Paraguay",
  COUNTRY_PERU: "Peru",
  COUNTRY_FRENCH_POLYNESIA: "Französisch-Polynesien",
  COUNTRY_POLAND: "Polen",
  COUNTRY_PUERTO_RICO: "Puerto Rico",
  COUNTRY_PORTUGAL: "Portugal",
  COUNTRY_QATAR: "Katar",
  COUNTRY_KENYA: "Kenia",
  COUNTRY_KYRGYZSTAN: "Kirgisistan",
  COUNTRY_CENTRAL_AFRICAN_REPUBLIC: "Zentralafrikanische Republik",
  COUNTRY_CONGO_DRC: "Congo (DRC)",
  COUNTRY_DOMINICAN_REPUBLIC: "Dominikanische Republik",
  COUNTRY_CZECH_REPUBLIC: "Tschechische Republik",
  COUNTRY_ROMANIA: "Rumänien",
  COUNTRY_RWANDA: "Rwanda",
  COUNTRY_RUSSIA: "Russland",
  COUNTRY_WESTERN_SAHARA: "Westsahara",
  COUNTRY_ST_PIERRE_AND_MIQUELON: "St. Pierre und Miquelon",
  COUNTRY_AMERICAN_SAMOA: "Amerikanisch-Samoa",
  COUNTRY_SAMOA: "Samoa",
  COUNTRY_SAN_MARINO: "San Marino",
  COUNTRY_ST_HELENA: "St. Helena",
  COUNTRY_ST_LUCIA: "St. Lucia",
  COUNTRY_ST_BARTHLEMY: "St. Barthélemy",
  COUNTRY_ST_KITTS_AND_NEVIS: "St. Kitts & Nevis",
  COUNTRY_ST_MARTIN: "St. Martin",
  COUNTRY_SINT_MAARTEN: "Sint Maarten",
  COUNTRY_SO_TOM_AND_PRNCIPE: "São Tomé & Príncipe",
  COUNTRY_ST_VINCENT_AND_GRENADINES: "St. Vincent und die Grenadinen",
  COUNTRY_SENEGAL: "Senegal",
  COUNTRY_SIERRA_LEONE: "Sierra Leone",
  COUNTRY_SERBIA: "Serbien",
  COUNTRY_SYRIA: "Syrien",
  COUNTRY_SOMALIA: "Somalia",
  COUNTRY_SRI_LANKA: "Sri Lanka",
  COUNTRY_SWAZILAND: "Swasiland",
  COUNTRY_SUDAN: "Sudan",
  COUNTRY_SOUTH_SUDAN: "Südsudan",
  COUNTRY_SWEDEN: "Schweden",
  COUNTRY_SWITZERLAND: "Schweiz",
  COUNTRY_SURINAME: "Surinam",
  COUNTRY_SVALBARD_AND_JAN_MAYEN: "Spitzbergen & Jan Mayen",
  COUNTRY_TAJIKISTAN: "Tajikistan",
  COUNTRY_THAILAND: "Thailand",
  COUNTRY_TAIWAN: "Taiwan",
  COUNTRY_TANZANIA: "Tanzania",
  COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY:
    "Britisches Territorium im Indischen Ozean",
  COUNTRY_FRENCH_SOUTHERN_TERRITORIES: "Französische Südgebiete",
  COUNTRY_TIMOR_LESTE: "Timor-Leste",
  COUNTRY_TOGO: "Togo",
  COUNTRY_TONGA: "Tonga",
  COUNTRY_TRINIDAD_AND_TOBAGO: "Trinidad und Tobago",
  COUNTRY_TRISTAN_DA_CUNHA: "Tristan da Cunha",
  COUNTRY_TUNISIA: "Tunisia",
  COUNTRY_TURKMENISTAN: "Turkmenistan",
  COUNTRY_TURKEY: "Türkei",
  COUNTRY_TUVALU: "Tuvalu",
  COUNTRY_UKRAINE: "Ukraine",
  COUNTRY_UGANDA: "Uganda",
  COUNTRY_URUGUAY: "Uruguay",
  COUNTRY_UZBEKISTAN: "Usbekistan",
  COUNTRY_VANUATU: "Vanuatu",
  COUNTRY_VATICAN_CITY: "Vatikanstadt",
  COUNTRY_VENEZUELA: "Venezuela",
  COUNTRY_VIETNAM: "Vietnam",
  COUNTRY_WALLIS_AND_FUTUNA: "Wallis & Futuna",
  COUNTRY_ZAMBIA: "Sambia",
  COUNTRY_ZIMBABWE: "Zimbabwe",
  IRRIGATION_BOX_CONVENTIONAL_BUTTON: "Befehl senden",
  IRRIGATION_BOX_CONVENTIONAL_SUCCESS_MSG:
    "Einfacher Bewässerungsauftrag gesendet.",
  IRRIGATION_BOX_BY_ANGLE_SUCCESS_MSG: "Befehl erfolgreich gesendet.",
  IRRIGATION_BOX_BY_ANGLE_ERROR_MSG: "Fehler beim Senden des Befehls.",
  IRRIGATION_BOX_GENERIC_ERROR_MSG: "Fehler beim Senden des Befehls.",
  IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG:
    "Fehler beim Senden des Befehls. Prüfen Sie, ob alle Felder ausgefüllt sind.",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE: "Bestätigen Sie Ihre Aktion",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_DESCRIPTION:
    "Bitte bestätigen Sie die Stopp-Aktion für ${}",
  IRRIGATION_BOX_FORWARD_PIVOT_DIALOG_DESCRIPTION:
    "Bitte bestätigen Sie die Vorwärtsbewässerungsaktion für ${}",
  IRRIGATION_BOX_REVERSE_PIVOT_DIALOG_DESCRIPTION:
    "Bitte bestätigen Sie die umgekehrte Bewässerungsaktion für ${}",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES: "Senden",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO: "Abbrechen",
  MOBILE_MODAL_TITLE_SIMPLE_IRRIGATION: "Einfache Bewässerung",
  MOBILE_MODAL_TITLE_SCHEDULE_IRRIGATION: "Bewässerung einplanen",
  MOBILE_MODAL_TITLE_CONVENTIONAL_IRRIGATION: "Einfache Bewässerung",
  MOBILE_MODAL_TITLE_LANGUAGES: "Wählen Sie eine Sprache",
  MOBILE_MODAL_TITLE_BY_ANGLE_IRRIGATION: "Bewässerung nach Winkeln",
  MOBILE_MODAL_TITLE_SEGMENTED_IRRIGATION: "Segment Bewässerung",
  PIVOT_ACTION_FIELD_DIRECTION: "Richtung",
  PIVOT_ACTION_FIELD_MODE: "Betriebsmodus",
  PIVOT_ACTION_FIELD_ENDGUN: "Endgun",
  PIVOT_ACTION_FIELD_PRECIPITATION: "Wasser-Klinge",
  PIVOT_ACTION_FIELD_START_DATE: "Start bei",
  PIVOT_ACTION_FIELD_START_TIME: "Startzeit",
  PIVOT_ACTION_FIELD_END_DATE: "Ende bei",
  PIVOT_ACTION_FIELD_END_TIME: "Endzeit",
  PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN: "Unbekannt",
  PIVOT_ACTION_VALUE_DIRECTION_STOPPED: "Angehalten",
  PIVOT_ACTION_VALUE_DIRECTION_PROGRESS: "Vorwärts",
  PIVOT_ACTION_VALUE_DIRECTION_REVERSE: "Umgekehrt",
  PIVOT_START_ACTION_VALUE_DIRECTION_PROGRESS: "Start Vorwärts",
  PIVOT_START_ACTION_VALUE_DIRECTION_REVERSE: "Start Umgekehrt",
  PIVOT_ACTION_VALUE_DRY: "Trocken",
  PIVOT_ACTION_VALUE_MODE_WET: "Nass",
  PIVOT_ACTION_FIELD_PERCENT: "Prozent",
  PIVOT_ACTION_FIELD_START_NOW: "Jetzt anfangen",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME: "Nach der Pausenzeit",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1: "Nach Pausenzeit 1",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2: "Nach Pausenzeit 2",
  PIVOT_ACTION_FIELD_WITHOUT_STOP: "Ohne Halt",
  PIVOT_ACTION_INVALID_DATE: "Ungültiges Datum",
  NAV_ITEM_LOGOUT: "Abmelden",
  NAV_ITEM_LANGUAGE: "Sprache",
  NAV_ITEM_PROFILE: "Profil bearbeiten",
  NAV_ITEM_FIRST_STEPS: "Erste Schritte",
  NAV_ITEMS_NEW_FARM: "Neuer Bauernhof",
  SELECTED_FARM_NO_PIVOTS: "Diese Farm hat keine Zapfwellen.",
  SELECTED_FARM_CENTRAL_ONLINE: "Zentral online",
  SELECTED_FARM_PIVOT_ONLINE: "Pivot online",
  SELECTED_FARM_CENTRAL_FILTER_BY: "Geräte filtern nach",
  SELECTED_FARM_CENTRAL_OFFLINE: "Zentrale offline",
  SELECTED_FARM_PIVOT_OFFLINE: "Pivot offline",

  SELECTED_FARM_WATER_LIMIT_EXCEEDED: "Wasserverbrauchsgrenze überschritten",
  LOADING: "Laden",
  NOT_FOUND_TITLE: "Ops!",
  NOT_FOUND_ERROR_TEXT: "Seite nicht gefunden.",
  NOT_FOUND_BACK_BUTTON_TEXT: "STARTSEITE",
  NOT_FOUND_TOKEN: "Die Sitzung ist abgelaufen. Melden Sie sich erneut an.",
  EDIT_PIVOT_V5_RELEVANT_INFO: "Relevante Informationen",
  EDIT_PIVOT_V5_PIVOT_TITLE_SEGMENTS: "Segmente",
  EDIT_PIVOT_V5_PUMP_PRESSURE_CONFIG: "Druckeinstellungen",
  EDIT_PIVOT_V5_AUTOREVERSION: "Autoreversion",
  EDIT_PIVOT_V5_AUTOREVERSION_30S: "30 Sekunden",
  EDIT_PIVOT_V5_AUTOREVERSION_1MIN: "1 Minute",
  EDIT_PIVOT_V5_AUTOREVERSION_2MIN: "2 Minuten",
  EDIT_PIVOT_V5_REFERENCE_NORTH: "Norden als Referenz verwenden",
  EDIT_PIVOT_V5_REFERENCE_NORTH_SELECT: "Pivot-Referenz setzen",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_END_ADORNMENT: "s",
  EDIT_PIVOT_V5_AUTOREVERSION_DISABLED: "Deaktiviert",
  EDIT_PIVOT_ERROR_NAME: "Ungültige Namensgröße",
  EDIT_PIVOT_V5_ERROR_POTENCY: "Ungültiger Leistungswert",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LAT: "Ungültiger mittlerer Breitengrad",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LNG: "Ungültiger mittlerer Längengrad",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LAT: "Ungültiger Startreferenz-Breitengrad",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LNG: "Ungültiger Startreferenz-Längengrad",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LAT:
    "Ungültiger Wert für den Breitengrad am Ende des Referenzzeitraums",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LNG:
    "Ungültiger Wert für den Referenzlängengrad am Ende",
  EDIT_PIVOT_V5_ERROR_SENDING_CONFIGURATION:
    "Fehler beim Senden der Konfiguration.",
  EDIT_PIVOT_V5_ERROR_RADIUS: "Ungültiger Radiuswert",
  EDIT_PIVOT_V5_ERROR_FLOW: "Ungültiger Flusswert",
  EDIT_PIVOT_V5_ERROR_SPEED: "Ungültiger Radiuswert",
  EDIT_PIVOT_V5_ERROR_AREA: "Ungültiger Bereichswert",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_STABLE_TIME:
    "Ungültiger Zeitwert für stabile Spannung",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_REF: "Ungültiger Spannungsreferenzwert",
  EDIT_PIVOT_V5_ERROR_PUMP_TIMEOUT:
    "Ungültiger Wert für die Pumpenzeitüberschreitung",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_DELAY:
    "Ungültiger Wert für die Pumpendruckverzögerung",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_SWITCH:
    "Ungültiger Wert für den Pumpendruckschalter",
  EDIT_PIVOT_V5_ERROR_PUMP_SOFT_START_TIME:
    "Ungültiger Wert für die Softstartzeit der Pumpe",
  EDIT_PIVOT_V5_ERROR_ANGLE_START: "Ungültiger Startwinkelwert",
  EDIT_PIVOT_V5_ERROR_ANGLE_END: "Ungültiger Endwinkelwert",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START: "Ungültiger Startzeitwert",
  EDIT_PIVOT_V5_ERROR_PIVOT_AVERAGE_POWER:
    "Ungültiger Pivot-Durchschnittsleistungswert",
  EDIT_PIVOT_V5_ERROR_CANAL_AVERAGE_POWER:
    "Ungültiger Wert für die durchschnittliche Leistung des Kanals",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END: "Ungültiger Zeitendwert",
  EDIT_PIVOT_V5_ERROR_POWER_DELAY:
    "Ungültiger Wert für die Leistungsverzögerung",
  EDIT_PIVOT_V5_ERROR_SENSOR_SCALE_END: "Ungültiger Endwert der Sensorskala",
  EDIT_PIVOT_V5_ERROR_PIVOT_SEGMENT: "Ungültige Pivot-Segmente",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS: "Segmente",
  EDIT_PIVOT_V5_ENDGUN: "Endgun",
  EDIT_PIVOT_V5_SAVE_SUCCESS: "Pivot-Konfiguration gesendet",
  EDIT_PIVOT_V5_SEGMENT_WARNING:
    "Füllen Sie alle Segmente aus und überschreiten Sie nicht den Arbeitswinkel",
  EDIT_PIVOT_V5_SAVE_ERROR:
    "Fehler beim Speichern des Pivots. Alle Felder müssen ausgefüllt werden.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_CHECK: "Spannungssteuerung",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_DELETE_ALL: "Alle löschen",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_START: "Start: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_END: "Ende: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_FORWARD: "VORWÄRTS",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_REVERSE: "Umgekehrt",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_MODE: "${}, ${}%",
  PIVOT_CONFIG_V5_CONFIG_HISTORY: "Konfigurationsverlauf",
  PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM: "Tisch abräumen",
  PIVOT_CONFIG_V5_SEGMENT_ERROR: "Sie können bis zu ${} Segmente erstellen",
  EDIT_PIVOT_V5_WEEKDAY_MONDAY: "MON",
  EDIT_PIVOT_V5_WEEKDAY_TUESDAY: "TUE",
  EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY: "WED",
  EDIT_PIVOT_V5_WEEKDAY_THURSDAY: "THU",
  EDIT_PIVOT_V5_WEEKDAY_FRIDAY: "FRI",
  EDIT_PIVOT_V5_WEEKDAY_SATURDAY: "SAT",
  EDIT_PIVOT_V5_WEEKDAY_SUNDAY: "SUN",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK: "Tage der Woche",
  EDIT_PIVOT_V5_CONFIGURATIONS: "Konfigurationen",
  COMMAND_SENT_WAITING_CONFIRMATION:
    "Befehl gesendet. Warten Sie auf die Gerätebestätigung.",
  COMMAND_CONFIRMED: "Vom Gerät empfangener Befehl.",
  COMMAND_ERROR: "Fehler beim Ausführen des Befehls",
  NAV_NO_NOTIFICATIONS: "Sie haben keine Benachrichtigungen.",
  CURRENT_ANGLE: "Aktueller Winkel: ",
  POWER_RANGE_POINT: "Spitze",
  POWER_RANGE_OUT_OF_POINT: "Aus der Spitze heraus",
  POWER_RANGE_REDUCED: "Ermäßigt",
  POWER_RANGE_TIME_START: "Start",
  POWER_RANGE_TIME_END: "Ende",
  POWER_RANGE_TYPE: "Typus",
  POWER_RANGE_DELETE_CONFIRMATION:
    "Sind Sie sicher, dass Sie diese Meldung löschen wollen?",
  HISTORIC_SEGMENT_IRRIGATION_START_MODE: "Startmodus: ",
  HISTORIC_SEGMENT_IRRIGATION_START_AT: "Anfangen ",
  HISTORIC_SEGMENT_IRRIGATION_END_MODE: "Endmodus: ",
  HISTORIC_SEGMENT_IRRIGATION_SEGMENT: "Segment",
  HISTORIC_SEGMENT_IRRIGATION_START_ANGLE: "Startwinkel",
  HISTORIC_SEGMENT_IRRIGATION_END_ANGLE: "Endwinkel: ${}",
  HISTORIC_SEGMENT_IRRIGATION_FORWARD_INFO: "VORWÄRTS",
  HISTORIC_SEGMENT_IRRIGATION_REVERSE_INFO: "Umgekehrt",
  HISTORIC_SEGMENT_IRRIGATION_END_AT: "Ende bei: ",
  HISTORIC_SEGMENT_IRRIGATION_ROUNDS: "Rundungen: ${}",
  HISTORIC_SEGMENT_IRRIGATION_START_DATE: "Start: ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_DATE: "Ende: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_MODE: "Autoreversionsmodus: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_QUANTITY: "Rundungen: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NEVER_ENDS: "Don't Stop",
  HISTORIC_SEGMENT_IRRIGATION_TOP_BY_ANGLE: "Halt im Winkel",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_DATE: "Stop by Date",
  HISTORIC_SEGMENT_IRRIGATION_STOP_END_SECTOR: "Halt bei Sector End",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_ROUNDS:
    "Schauen Sie bei der Visite vorbei",
  HISTORIC_SEGMENT_IRRIGATION_AUTO_REVERSIONS: "Automatische Umkehrungen: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NOW: "Sofort",
  HISTORIC_SEGMENT_IRRIGATION_ON_A_DATE: "Bei einem Date",
  SEGMENT_IRRIGATION_FIELD_START_NOW: "Jetzt anfangen",
  SEGMENT_IRRIGATION_FIELD_END_BY_TIME: "Ende durch Zeit?",
  SEGMENT_IRRIGATION_FIELD_END_BY_LAPS: "Rundenweise beenden?",
  SEGMENT_IRRIGATION_FIELD_END_BY_SEGMENT: "Ende nach Segmenten?",
  SEGMENT_IRRIGATION_FIELD_NEVER_ENDS: "Es hört nie auf.",
  SEGMENT_IRRIGATION_FIELD_NUMBER_OF_LAPS: "Anzahl der Runden",
  SEGMENT_IRRIGATION_FIELD_LAPS: "Runde(n)",
  SEGMENT_IRRIGATION_BUTTON_FORWARD: "START VORWÄRTS",
  SEGMENT_IRRIGATION_BUTTON_REVERSE: "RÜCKWÄRTSGANG EINLEGEN",
  DRAWER_HOME: "Zuhause",
  DRAWER_FARMS: "Bauernhöfe",
  DRAWER_REPORTS: "Berichte",
  NOTIFICATIONS_DRAWER_TITLE: "Benachrichtigungen",
  GENERAL_BOX_PRESSURE_LABEL: "${} bar",
  LOGIN_WELCOME: "Willkommen",
  LOGIN_EMAIL: "E-Mail",
  LOGIN_NEW_USER: "Neuer Kunde?",
  LOGIN_SIGN_IN: "Anmelden",
  LOGIN_SIGN_UP: "Unterschreiben",
  LOGIN_USERNAME_OR_EMAIL: "Benutzername oder E-Mail",
  LOGIN_PASSWORD: "Passwort",
  LOGIN_OR: "oder",
  LOGIN_FORGET_PASSWORD: "Haben Sie den Benutzer oder das Passwort vergessen?",
  LOGIN_GOOGLE_SIGN_IN: "Mit Google anmelden",
  LOGIN_RECOVER_PASSWORD: "Stellen Sie Ihr Passwort wieder her",
  LOGIN_GO_BACK: "Geh zurück",
  LOGIN_SUCCESSFUL: "Benutzer erfolgreich eingeloggt.",
  SIGN_UP_FOOTER: "Füllen Sie alle Felder des Formulars aus, um fortzufahren.",
  SIGN_UP_WELCOME: "Willkommen bei der Plattform von Irricontrol.",
  SIGN_UP_WELCOME_AUX:
    "Füllen Sie das Formular aus, um Ihre Anmeldung abzuschließen:",
  SIGN_UP_EMAIL: "E-Mail",
  SIGN_UP_REPEAT_EMAIL: "Wiederholen Sie Ihre E-Mail",
  SIGN_UP_NAME: "Name",
  SIGN_UP_LAST_NAME: "Nachname",
  SIGN_UP_USERNAME: "Benutzername",
  SIGN_UP_REPEAT_PASSWORD: "Wiederholen Sie Ihr Passwort",
  SIGN_UP_BACK: "Zurück",
  SIGN_UP_NEXT: "Weiter",
  SIGN_UP_WARNING_SUCCESS: "Registrierung erfolgreich abgeschlossen",
  LOGIN_ERROR_CREDENTIALS_NOT_PROVIDED:
    "Benutzername und/oder Passwort nicht angegeben",
  LOGIN_ERROR_GOOGLE_USER_NOT_REGISTERED: "Google-Nutzer ist nicht registriert",
  LOGIN_ERROR_DEFAULT: "Authentifizierung fehlgeschlagen",
  LOGIN_ERROR_SERVER_OFFLINE: "Server offline",
  LOGIN_ERROR_INVALID_CREDENTIALS: "Benutzername oder Passwort ist falsch",
  SIGN_UP_CONFIRMATION_RETURN:
    "Sind Sie sicher, dass Sie die Seite verlassen wollen, ohne die Registrierung abzuschließen?",
  SIGN_UP_CONFIRMATION_SIGN_UP: "Bestätigen Sie alle Ihre Daten?",
  SIGN_UP_BUTTON_CONFIRM: "Bestätigen",
  CREATE_DEVICE_MODAL_TITLE: "Ausrüstung registrieren",
  CREATE_DEVICE_MODAL_CENTRAL: "Zentral",
  CREATE_DEVICE_MODAL_EQUIPMENT: "Gerätetyp",
  CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER: "Gerätebezeichnung",

  CREATE_DEVICE_MODAL_HARDWARE_ID_PLACEHOLDER: "Hardware Id",

  CREATE_DEVICE_MODAL_CREATE_DEVICE: "Geräte registrieren",
  CREATE_DEVICE_MODAL_CONTROLLER_CODE: "Controller-Funk",
  CREATE_DEVICE_MODAL_GPS_CODE: "GPS-Funk",
  CREATE_DEVICE_MODAL_PIVOT: "Drehpunkt",
  CREATE_DEVICE_MODAL_GPS: "GPS",
  CREATE_DEVICE_MODAL_REPEATER: "Wiederholer",
  CREATE_DEVICE_MODAL_REPEATER_CODE: "Wiederholer-Funk",
  CREATE_DEVICE_MODAL_CODE_READ_SUCCESS: "Code erfolgreich gelesen",
  CREATE_DEVICE_MODAL_CODE_READ_FAIL: "Code-Lesefehler",
  CREATE_DEVICE_MODAL_CREATE: "Erstellen",
  CREATE_DEVICE_MODAL_ERROR_NOT_VALID: "Ungültiges Radio",
  CREATE_DEVICE_MODAL_ERROR_TAKEN: "Dieses Gerät ist bereits vergeben",
  CREATE_DEVICE_MODAL_ERROR_NOT_MONITOR: "Dieses Gerät ist kein GPS",
  CREATE_DEVICE_MODAL_ERROR_NOT_CONTROL: "Dieses Gerät ist kein Pivot",
  CREATE_DEVICE_MODAL_ERROR_NOT_REPEATER: "Dieses Gerät ist kein Repeater",
  CREATE_DEVICE_MODAL_ERROR_SERVER: "Serverfehler",
  CREATE_DEVICE_MODAL_SUCCESS: "Gerät wurde erfolgreich erstellt",
  EDIT_PROFILE_TITLE: "Benutzerprofil",
  EDIT_PROFILE_ERROR_NAME: "Ungültige Namensgröße",
  EDIT_PROFILE_ERROR_LAST_NAME: "Ungültige Namensgröße",
  EDIT_PROFILE_ERROR_USERNAME: "Ungültige Größe des Benutzernamens",
  EDIT_PROFILE_ERROR_EMAIL: "Ungültige E-Mail Adresse",
  EDIT_PROFILE_ERROR_CONFIRM_EMAIL: "Ungültige E-Mail Adresse",
  EDIT_PROFILE_ERROR_BIRTHDAY: "Ungültiges Datum",
  EDIT_PROFILE_ERROR_COUNTRY_CODE: "Ungültiger Ländercode",
  EDIT_PROFILE_ERROR_CELLPHONE: "Ungültige Handynummer",
  EDIT_PROFILE_ERROR_LANDLINE: "Ungültige Festnetznummer",
  EDIT_PROFILE_ERROR_ADDRESS: "Ungültige Adresse",
  EDIT_PROFILE_ERROR_NUMBER_HOUSE: "Ungültige Hausnummer",
  EDIT_PROFILE_ERROR_NEIGHBORHOOD: "Ungültige Nachbarschaft",
  EDIT_PROFILE_ERROR_CITY: "Ungültige Stadt",
  EDIT_PROFILE_ERROR_STATE: "Ungültiger Zustand",
  EDIT_PROFILE_ERROR_COUNTRY: "Ungültiges Land",
  EDIT_PROFILE_ERROR_ZIPCODE: "Ungültige Postleitzahl",
  INVALID_CPF: "Ungültiger Dokumentencode",
  EDIT_PROFILE_ERROR_PASSWORD: "Ungültiges Passwort",
  EDIT_PROFILE_ERROR_PASSWORD2: "Ungültiges Passwort",
  EDIT_PROFILE_NAME: "Name",
  EDIT_PROFILE_LAST_NAME: "Nachname",
  EDIT_PROFILE_EMAIL: "E-Mail",
  EDIT_PROFILE_BIRTHDAY: "Geburtstag",
  EDIT_PROFILE_CELLPHONE: "Handynummer",
  EDIT_PROFILE_LANDLINE: "Festnetznummer",
  EDIT_PROFILE_COUNTRY_CODE: "Ländercode",
  EDIT_PROFILE_ADDRESS: "Adresse",
  EDIT_PROFILE_NUMBER_HOUSE: "Nummer",
  EDIT_PROFILE_NEIGHBORHOOD: "Nachbarschaft",
  EDIT_PROFILE_CITY: "Stadt",
  EDIT_PROFILE_STATE: "Staat",
  EDIT_PROFILE_COUNTRY: "Land",
  EDIT_PROFILE_ZIPCODE: "Postleitzahl",
  EDIT_PROFILE_OFFICIAL_DOCUMENT_CODE: "Offizieller Dokumentencode",
  PROFILE_BOX_CONVENTIONAL_ERROR_MSG: "Fehler beim Bearbeiten des Profils.",
  PROFILE_BOX_CONVENTIONAL_SUCCESS_MSG: "Benutzerprofil gesendet.",
  EDIT_PROFILE_CONFIRM_PASSWORD: "Passwort",
  EDIT_PROFILE_PASSWORD: "Passwort bestätigen",
  EDIT_PROFILE_CONFIRM_EMAIL: "E-Mail bestätigen",
  EDIT_PROFILE_CURRENT_PASSWORD: "Aktuelles Passwort",
  EDIT_PROFILE_NEW_PASSWORD: "Neues Passwort",
  EDIT_PROFILE_CONFIRM_NEW_PASSWORD: "Neues Passwort bestätigen",
  EDIT_PROFILE_PERSONAL_INFO: "Persönliche Informationen:",
  EDIT_PROFILE_CONTACT_ADDRESS: "Kontaktadresse:",
  EDIT_PROFILE_PASSWORD_CHANGE: "Passwort ändern:",
  SIMPLE_IRRIGATION_FIELD_DIRECTION: "Richtung",
  SIMPLE_IRRIGATION_FIELD_MODE: "Betriebsmodus",
  SIMPLE_IRRIGATION_FIELD_PERCENT: "Prozent",
  SIMPLE_IRRIGATION_FIELD_MM: "Prozentmesser",
  SIMPLE_IRRIGATION_FIELD_BY_ANGLE: "Stoppen Sie im Winkel?",
  SIMPLE_IRRIGATION_FIELD_ANGLE_TO_STOP: "Letzter Winkel",
  SIMPLE_IRRIGATION_VALUE_INJECTION_ON: "On",
  SIMPLE_IRRIGATION_VALUE_INJECTION_OFF: "Aus",
  SIMPLE_IRRIGATION_VALUE_FORWARD: "Vorwärts",
  SIMPLE_IRRIGATION_VALUE_REVERSE: "Umgekehrt",
  SIMPLE_IRRIGATION_VALUE_DRY: "Trocken",
  SIMPLE_IRRIGATION_VALUE_WET: "Nass",
  SIMPLE_IRRIGATION_FIELD_STOP_MODE: "Stoppmodus",
  SIMPLE_IRRIGATION_NEVER_ENDS: "Don't Stop",
  SIMPLE_IRRIGATION_END_SECTOR: "Endsektor",
  SIMPLE_IRRIGATION_BY_ROUNDS: "Nach Runden",
  SIMPLE_IRRIGATION_FIELD_ROUNDS_TO_STOP: "Rundungen",
  FARM: "Bauernhof",
  FARMS: "Bauernhöfe",
  FARM_FIELD_SEARCH_BAR: "Suche",
  FARM_FIELD_NAME: "Name des Betriebs",
  FARM_FIELD_BILLING_DATE: "Power Invoice Day",
  FARM_FIELD_LAT: "Breitengrad",
  FARM_FIELD_LNG: "Längengrad",
  FARM_FIELD_PHONE: "Telefon",
  FARM_FIELD_ADDRESS: "Straße und Hausnummer",
  FARM_FIELD_POSTAL_CODE: "Postleitzahl",
  FARM_FIELD_CITY: "Stadt",
  FARM_FIELD_STATE: "Staat",
  FARM_FIELD_COUNTRY: "Land",
  FARM_FIELD_START_INTERVAL: "Startzeit",
  FARM_FIELD_END_INTERVAL: "Endstunde",
  FARM_FIELD_TYPE_INTERVAL: "Typus",
  FARM_VALUE_RUSH_HOUR: "Zeit anhalten",
  FARM_VALUE_OFF_PEAK_TIME: "Pausenzeit",
  FARM_VALUE_REDUCED_TIME: "Reduzierte Zeit",
  HISTORIC_SIMPLE_IRRIGATION_CREATED_AT: "Erstellt am: ${}",
  HISTORIC_CREATED_BY: "Gesendet von: ",
  HISTORIC_CREATED_ON_HARDWARE: "Erstellt am Gerät",
  HISTORIC_PAINEL_STREAM_REASON: "Grund:",
  HISTORIC_PAINEL_STREAM_REASON_VALUE: "${}",
  HISTORIC_PAINEL_STREAM_WITH_ENERGY: "Energiegeladen",
  HISTORIC_PAINEL_STREAM_WITHOUT_ENERGY: "Nicht erregt",
  HISTORIC_PAINEL_STREAM_ALIGNED: "Ausgerichtet",
  HISTORIC_PAINEL_STREAM_NOT_ALIGNED: "Nicht angeglichen",
  HISTORIC_PAINEL_STREAM_HOURMETER: "Betriebsstundenzähler: ",
  HISTORIC_PAINEL_STREAM_POWER: "Spannung: ",
  HISTORIC_PAINEL_STREAM_WITH_PRESSURE: "Mit Druck",
  HISTORIC_PAINEL_STREAM_WITHOUT_PRESSURE:
    "Zeitüberschreitung des Pumpendrucks",
  HISTORIC_PAINEL_STREAM_REVERSIONS: "Durchgeführte Umkehrungen: ${} mal",
  HISTORIC_GPS_STREAM_CREATED_HUMANIZED: "${} ago",
  HISTORIC_GPS_STREAM_CREATED: "${}",
  HISTORIC_GPS_STREAM_POSITION: "Stellung: ${},${}.",
  HISTORIC_GPS_STREAM_REASON: "Grund:",
  HISTORIC_GPS_STREAM_REASON_VALUE: "${}",
  HISTORIC_GPS_STREAM_SPEED: "Prozentsatz: ",
  HISTORIC_GPS_STREAM_HOURMETER: "Betriebsstundenzähler: ",
  HISTORIC_GPS_STREAM_WATER_PRESSURE: "Letzter Turmdruck: ",
  HISTORIC_GPS_STREAM_CENTER_PRESSURE: "Mitteldruck: ${} bar",
  HISTORIC_GPS_STREAM_DIRECTION: "Richtung: ${}",
  HISTORIC_PIVOT_ACTION_STOP: "Stop Pivot",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW_OLD: "${} | ${}",
  HISTORIC_PIVOT_ACTION_CREATED_HUMANIZED: "${} ago",
  HISTORIC_PIVOT_ACTION_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_START: "Start: ",
  HISTORIC_PIVOT_ACTION_START_NOW: "Unverzüglich",
  HISTORIC_PIVOT_ACTION_END: "Programmierter Halt: ",
  HISTORIC_PIVOT_ACTION_NEVER_END: "Nicht aufhören",
  HISTORIC_PIVOT_ACTION_MANUAL: "Manuell erstellt",
  HISTORIC_PIVOT_ACTION_USER: "Nach Benutzer: ",
  HISTORIC_PIVOT_ACTION_DIRECTION: "Richtung: ",
  HISTORIC_PIVOT_ACTION_ENDGUN: "Prozentsatz: ${} %, ${} mm",
  HISTORIC_PIVOT_ACTION_MODE: "Modus: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_OVERVIEW: "Nach Winkel",
  HISTORIC_PIVOT_ACTION_VRI_START: "Start: ",
  HISTORIC_PIVOT_ACTION_VRI_START_NOW: "Jetzt",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED_HUMANIZED: "${} ago",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MANUAL: "Manuell erstellt",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_USER: "Benutzer: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MODE: "Modus: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ACTION: "${} | ${} , ${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_ON: "Endgun an",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_OFF: "Endgun aus",
  HISTORIC_PANEL_STREAM_V5_SIMPLE_IRRIGATION: "Einfache Bewässerung",
  HISTORIC_PANEL_STREAM_V5_: "Stop Pivot",
  HISTORIC_PANEL_STREAM_V5_SCHEDULE_IRRIGATION: "Bewässerung einplanen",
  HISTORIC_PANEL_STREAM_V5_SEGMENT_IRRIGATION: "Segment Bewässerung",
  HISTORIC_PANEL_STREAM_V5_STOPPED: "Angehalten",
  HISTORIC_PANEL_STREAM_V5_FORWARD: "Vorwärts",
  HISTORIC_PANEL_STREAM_V5_REVERSE: "Umgekehrt",
  HISTORIC_PANEL_STREAM_V5_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PANEL_STREAM_V5_DIRECTION: "Richtung: ",
  HISTORIC_PANEL_STREAM_V5_STATUS: "Status",
  HISTORIC_PANEL_STREAM_V5_ACTIVE_IRRIGATION: "Aktive Bewässerung: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_TOTAL: "Gesamtstundenzähler: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_WET: "Nasser stundenzähler: ",
  HISTORIC_PANEL_STREAM_V5_MODE: "Modus: ",
  HISTORIC_PANEL_STREAM_V5_PERCENTAGE: "Niederschlag: ",
  HISTORIC_PANEL_STREAM_V5_POSITION: "Stellung",
  HISTORIC_PANEL_STREAM_V5_ANGLE: "Winkel: ",
  HISTORIC_PANEL_STREAM_V5_VOLTAGE: "Spannung: ${} V",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_CENTER: "Druck im zentralen turm: ",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_END_TOWER: "Turmenddruck: ",
  HISTORIC_GPS_STREAM_V5_NO_LABEL: "${}",
  HISTORIC_GPS_STREAM_V5_REMAINING_TIME: "Voraussichtlich verbleibende Zeit: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SCHEDULE: "Aktueller Zeitplan: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SEGMENT: "Aktuelles Segment: ",
  HISTORIC_PANEL_LABEL_DATE: "Datum",
  HISTORIC_PANEL_LABEL_DATASHEET: "Datenblatt",
  HISTORIC_PANEL_LABEL_TYPE: "Typus",
  HISTORIC_PANEL_LABEL_SUMMARY: "Zusammenfassung",
  HISTORIC_MAINTENANCE_ON: "Wartungsmodus aktiviert.",
  HISTORIC_MAINTENANCE_OFF: "Wartungsmodus deaktiviert.",
  IRRIGATION_TYPE_SIMPLE: "Einfache Bewässerung",
  IRRIGATION_TYPE_SCHEDULE: "Bewässerung einplanen",
  IRRIGATION_TYPE_SEGMENT: "Segment Bewässerung",
  IRRIGATION_MODE_NONE: "Keine",
  IRRIGATION_MODE_WET: "Nass",
  IRRIGATION_MODE_DRY: "Trocken",
  IRRIGATION_DIRECTION_STOPPED: "Angehalten",
  IRRIGATION_DIRECTION_FORWARD: "Vorwärts",
  IRRIGATION_DIRECTION_REVERSE: "Umgekehrt",
  EDIT_PIVOT_LAST_SETTINGS: "Letzte Konfiguration: ${}",
  EDIT_PIVOT_MANUAL: "- Handbuch",
  EDIT_PIVOT_BY_WEB: "- Internet",
  EDIT_PIVOT_ERROR: "Formular hat ungültige Werte.",
  EDIT_PIVOT_ERROR_CENTER_LAT: "Ungültiger mittlerer Breitengrad",
  EDIT_PIVOT_ERROR_CENTER_LNG: "Ungültiger mittlerer Längengrad",
  EDIT_PIVOT_ERROR_START_REF_LAT: "Ungültiger Startreferenz-Breitengrad",
  EDIT_PIVOT_ERROR_START_REF_LNG: "Ungültiger Startreferenz-Längengrad",
  EDIT_PIVOT_ERROR_END_REF_LAT: "Ungültiger Breitengrad der Endreferenz",
  EDIT_PIVOT_ERROR_END_REF_LNG: "Ungültiger Längengrad der Endreferenz",
  EDIT_PIVOT_ERROR_RADIUS: "Ungültiger Radiuswert",
  EDIT_PIVOT_ERROR_TOTAL_RADIUS: "Ungültiger Gesamtradiuswert",
  EDIT_PIVOT_ERROR_AREA: "Ungültiger Bereichswert",
  EDIT_PIVOT_ERROR_SPEED: "Ungültiger Wert für die letzte Turmgeschwindigkeit",
  EDIT_PIVOT_ERROR_FLOW: "Ungültiger Wert für die Durchflussrate",
  EDIT_PIVOT_ERROR_POTENCY: "Ungültiger Wert für die Pumpenleistung",
  EDIT_PIVOT_ERROR_PUMP_TIME: "Ungültiger Wert für die Pumpzeit",
  EDIT_PIVOT_ERROR_POWER_TIME: "Ungültiger Wert für Power Time",
  EDIT_PIVOT_ERROR_POWER_RANGE_MIN: "Ungültige Namensgröße",
  EDIT_PIVOT_ERROR_POWER_RANGE_MAX: "Ungültige Namensgröße",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MIN: "Ungültige Namensgröße",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MAX: "Ungültige Namensgröße",
  EDIT_PIVOT_ERROR_CLOCK_DATE: "Ungültiges Datum",
  EDIT_PIVOT_ERROR_CLOCK_TIME: "Invalidenzeit",
  EDIT_PIVOT_CLOCK_LABEL: "Geräteuhr einstellen",
  EDIT_PIVOT_SAVE_AWAIT: "Konfiguration gesendet. Warten auf Gerätebestätigung",
  EDIT_PIVOT_SAVE_AWAIT_ERROR: "Ein Fehler ist aufgetreten",
  EDIT_PIVOT_SAVE_CONTROLLER_SUCCESS: "Controller erfolgreich gespeichert",
  EDIT_PIVOT_SAVE_GPS_SUCCESS: "GPS erfolgreich gespeichert",
  EDIT_PIVOT_SAVE_PUMP_SUCCESS: "Pumpe erfolgreich gespeichert",
  EDIT_PIVOT_GPS_LABEL: "GPS Funk",
  EDIT_PIVOT_PUMP_LABEL: "Pump Funk",
  EDIT_PIVOT_DELIVERED_LABEL: "Controller Funk",
  EDIT_PIVOT_CENTRAL_LABEL: "Zentraler Funk",
  HISTORIC_GPS_STREAM_COMUNICATION_ERROR: "Pivot ohne Kommunikation",
  HISTORIC_STREAM_LOST_ERROR:
    "Möglicher Verlust von Informationen von GPS oder Controller",
  TERRAIN_MAP: "Gelände",
  GENERIC_REGISTER_DATA_ERROR: "Fehler bei der Registrierung von Daten",
  GENERIC_REGISTER_DATA_SUCCESS: "Daten erfolgreich registriert!",
  CREATE_DEVICE_MODAL_ERROR_FARM_BASE_EMPTY: "Konnte Pivot nicht erstellen",
  METERSYSTEM_WATER_LEVEL_BY_TIME: "Wasserstand nach Zeit",
  METERSYSTEM_CURRENT_FLOW: "Fluss:",
  METERSYSTEM_NO_READINGS: "Keine Messwerte",
  METERSYSTEM_TOTAL_FLOW: "Total Fluss",
  METERSYSTEM_FLOW_BY_TIME: "Fluss durch die Zeit",
  METER_SYSTEM_EDIT: "Messgerät bearbeiten",
  METER_SYSTEM_EDIT_GENERAL: "Allgemeine Konfigurationen",
  METERSYSTEM_STATUS_1: "Gerät ist eingeschaltet",
  METERSYSTEM_STATUS_2: "Einschalten nach Stromausfall",
  METERSYSTEM_STATUS_3: "Überwachung eines Sensors",
  METERSYSTEM_STATUS_176: "Pumpe aus",
  METERSYSTEM_STATUS_177: "Pumpe an",
  METERSYSTEM_STATUS_178: "Pumpe des Geräts durch anderen IMeter ausgeschaltet",
  METERSYSTEM_STATUS_179: "Pumpe des Geräts durch anderen IMeter eingeschaltet",
  METERSYSTEM_STATUS_180: "Pumpe des Geräts durch Sensor ausgeschaltet",
  METERSYSTEM_STATUS_181: "Pumpe des Geräts durch Sensor eingeschaltet",
  METERSYSTEM_STATUS_192: "Betriebsstundenzähler beschädigt",
  METERSYSTEM_STATUS_208: "Pumpe verriegelt Schütz nicht",
  METERSYSTEM_STATUS_209: "Zeit für eine Pause",
  METERSYSTEM_STATUS_210: "Gerät ist ausgeschaltet",
  METERSYSTEM_STATUS_211: "Aus wegen Stromausfall",
  METERSYSTEM_STATUS_212: "Batteriestand unter 10 V",
  METERSYSTEM_STATUS_213: "Gerät hat Kontakt zu anderem IMeter verloren",
  METER_SYSTEMS: "Zählersysteme:",
  DEVICES_CONTROLLER_NOT_FOUND: "Controller nicht gefunden",
  DEVICES_ANALYSIS: "Analyse:",
  DEVICES_STATUS_COLOR_NOT_FOUND: "Statusfarbe nicht gefunden",
  DEVICE: "Gerät:",
  DEVICE_FOUND: "Gefunden",
  DEVICE_NOT_FOUND: "Nicht gefunden",
  DEVICE_CENTRAL: "Zentral",
  DEVICE_LINE_VERY_STRONG: "Sehr stark",
  DEVICE_LINE_STRONG: "Stark",
  DEVICE_LINE_MODERATE: "Mäßig",
  DEVICE_LINE_WEAK: "Schwach",
  DEVICE_LINE_ERROR: "Error",
  DEVICE_LINE: "Zeile:",

  ICROP_FORM_NO_ACCESS:
    "Es sieht so aus, als hätten Sie noch keinen Zugang zu dieser Funktion.",
  ICROP_FORM_SELECT_A_FARM: "Wählen Sie einen Betrieb:",
  EDIT_FARM_IMPORT_NATIONAL_HOLIDAYS: "Nationale Feiertage importieren",
  PAGE_TRY_AGAIN: "Noch einmal versuchen",
  EDIT_PIVOT_LAT_AND_LONG: "Breitengrad und Längengrad",
  PAUSE_TIME_WITHOUT_CONFIG: "Pausenzeit nicht aktiviert",
  WEEK_OF_THE_DAY: "Woche des Tages",
  BASIC_INFO_BOX_FILTERED_CHARTS_DATES_INFO:
    "Diese Tabelle enthält keine Daten für den Datumsbereich.",

  BASIC_INFO_BOX_INFORMATION_CALCULATED:
    "Die Informationen werden für die bereits im System vorhandenen Daten berechnet, was sehr lange dauern kann.",
  CROP_COTTOM: "Cottom",
  CROP_RICE: "Reis",
  CROP_OAT: "Hafer",
  CROP_POTATO: "Kartoffel",
  CROP_SWEET_POTATO: "Süßkartoffel",
  CROP_SUGAR_CANE: "Zuckerrohr",
  CROP_BARLEY: "Barley",
  CROP_PEA: "Erbse",
  CROP_BEAN: "Bohne",
  CROP_MILLET: "Hirse",
  CROP_CORN: "Hirse",
  CROP_SWEET_CORN: "Zuckermais",
  CROP_POPCORN: "Popcorn",
  CROP_CORN_SEEDS: "Maissaatgut",
  CROP_SILAGE_CORN: "Silomais",
  CROP_PASTURE: "Weide",
  CROP_SOY: "Soja",
  CROP_SOY_SEEDS: "Sojasamen",
  CROP_SORGHUM: "Sorghum",
  CROP_SORGHUM_SEEDS: "Sorghum-Saatgut",
  CROP_TOMATO: "Tomate",
  CROP_WHEAT: "Weizen",

  ORBITAL_SENSOR: "Orbitaler Sensor",
  ANNOUNCEMENT_SYSTEM_TITLE: "Ankündigung",

  PRESSURE_DELAY_INPUT:
    "Die Verzögerungszeit bezieht sich auf die Zeitspanne, nachdem der Zapfen unter Druck gesetzt wurde. Es gibt eine Wartezeit, bis der Druck den letzten Turm erreicht hat, so dass sich der Drehpunkt in Bewegung setzen kann.",

  PRESSURE_SENSOR_RANGE_MIN:
    "Der Mindestwert gibt den Druckbereich an, den das Gerät einhalten muss. Unterhalb des Minimums wird der Drehpunkt gestoppt.",

  PRESSURE_SENSOR_RANGE_MAX:
    "Der Höchstwert gibt den Druckbereich an, den das Gerät einhalten muss. Oberhalb des Maximums wird der Pivot gestoppt.",

  SENSOR_SCALE_END:
    "Die Skala des Sensors bezieht sich auf den maximalen Druck, den er messen kann.",

  GET_NEW_STREAM: "Suche nach letzter Aktualisierung",

  ADD_SENSOR: "Sensor hinzufügen",

  WATER_LEVEL_SENSOR: "Füllstandsensor",

  TRIGGER_RULE: "Trigger-Regel",

  CHART: "Tabelle",

  PIVOT_UNDER_MAINTENANCE: "Pivot unter Wartung",

  GET_NEW_STREAM_ERROR: "Fehler bei der Suche nach der letzten Aktualisierung",
  PIVOT_MANAGEMENT: "Pivot Management",

  CHOOSE_ACCUMULATED_VOL_PERIOD: "Wählen Sie den Informationszeitraum:",

  LATEST_INSTANT_FLOW: "Neueste sofortige Durchflussrate",

  APPROXIMATE_USAGE_TIME: "Geschätzte Nutzungsdauer",

  APPROXIMATE_USAGE_TIME_ABBREVIATED: "Geschätzte Nutzungsdauer",

  ACCUMULATED_VOLUME: "Kumuliertes Gesamtvolumen",

  HOURS_24: "Stunden",

  HOURS_48: "48 STUNDEN",

  HOURS_72: "72 STUNDEN",

  WEEK_1: "1 WOCHE",

  MONTH_1: "1 MONTH",

  FLOW_BY_TIME_CHART: "Flussdiagramm nach Zeit",

  MONTHLY_WATER_ACTION_CONFIRMATION:
    "Das monatliche Wasserlimit wurde überschritten, sind Sie sicher, dass Sie weitermachen wollen?",

  ATENTION: "Achtung",
  CONTINUE: "Weiter",
  SEGMENT_FINAL_ANGLE: "Segment-End-Winkel",
  WORKING_ANGLE: "Schwenkwinkelbereich",
  HARDWARE: "Hardware",
  DAILY_RAINING: "Regen heute",
  PLUVIOMETER_HISTOGRAM: "Histogramm",
  IRRIGATION_INCREMENTED_DUE_TO_RAIN:
    "Bewässerungsgeschwindigkeit auf ${}% erhöht, da es regnet.",
  RAINING_NOW: "Es regnet jetzt",
  ICROP_DOC_URL:
    "https://irricontroldev.s3.us-east-2.amazonaws.com/icrop_en.pdf",
  ICROP_WELCOME_PANEL_TITLE: "Willkommen im Pivot Management Panel!",
  ICROP_WELCOME_PANEL_P1:
    "Hier haben Sie Zugang zu Empfehlungen für die Pivot-Bewässerung sowie zu Informationen über die Pflanzen und den Boden.",
  ICROP_WELCOME_PANEL_P2:
    "Laden Sie das unten stehende Dokument herunter oder rufen Sie das Panel auf.",
  ICROP_DOC_DOWNLOAD: "Dokument herunterladen",
  ICROP_ACCESS_PANEL: "Zugriff auf das Panel",
  WEEK_ACCUMULATED_RAIN: "Angestauter Regen der Woche",
  ACCUMULATED_MILLIMETERS: "Kumulierte Millimeter",
  BASE_CHANGE_CLOCK_ALERT:
    "Die Uhr der folgenden Geräte wird auf die aktuelle Zeit umgestellt",
  EQUIPMENT_ID_MISSING:
    "Ausrüstung nicht in der Anfrage gefunden. Bitte wenden Sie sich an das Support-Team.",
  RADIO_ID_NOT_FOUND_IN_DATABASE: "Radio nicht gefunden",
  RADIO_ID_ALREADY_USED: "Das Radio ist bereits im Einsatz",
  SEND_RADIO_CHANGE_TO: "Funk-Update senden an",
  NO_EQUIPMENT: "Keine",
  ALL_EQUIPMENTS: "Alle Ausrüstungen",
  SEND: "Senden",
  CHANGE_BASE_RADIO_MODAL_TITLE: "Zentralradio ändern",
  USER_PERMISSION: "Keine Erlaubnis",
  USER_PERMISSION_READ: "Betrachter",
  USER_PERMISSION_OPERATOR: "Betreiber",
  USER_PERMISSION_CONFIGURATOR: "Konfigurator",
  SETORIAL_ENDGUN_ERROR:
    "Dieser Schwenk ist sektoral. Konfigurationen, die über den roten Bereich hinausgehen, haben keine Auswirkungen.",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION: "Beschreibung",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION_TEXT:
    "Pausenzeiten sind Zeitbereiche, in denen der Pivot nicht im Nassbetrieb arbeiten sollte, um den Einsatz zu kritischen Zeiten zu vermeiden, z.B. wenn die Kosten für die elektrische Energie höher sind.",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS_TEXT:
    "Es ist üblich, einige Pivots in zwei oder mehr Teile aufzuteilen, sei es wegen der Pflanzensorte, der Kulturpflanze oder auch, um das Betriebsmanagement zu erleichtern. Mit dieser Funktion können Sie die Pivots segmentieren und mehr Kontrolle über ihren Betrieb erlangen.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_TEXT:
    "Bei Zapfstellen mit einer Endpistole am letzten Turm ist es möglich, die Aktivierung zu segmentieren, um zu verhindern, dass sie sich in Bereichen einschaltet, die nicht bewässert werden sollen, wie Straßen, Häuser usw.",
  PIVOT_CONFIG_V5_AUTORREVERSION_TEXT:
    "Autoreversion ist eine Funktion des Geräts, die es ermöglicht, dass der Drehpunkt das Ende seines Kurses erreicht und automatisch zurückkehrt und arbeitet. Die Rückkehr zum Drehpunkt erfolgt, wenn ein physisches Hindernis erreicht wird, das als Endpunkt bezeichnet wird - verfügbar für SmartConnect-Panels - oder wenn der endgültige Arbeitswinkel erreicht ist.",
  WORKING_ANGLE_LABEL:
    "Wie groß ist der maximale Winkel, den dieser Drehpunkt erreicht?",
  PASSWORD_RECOVERY_INSERT_EMAIL:
    "Geben Sie Ihre E-Mail-Adresse ein, damit wir Ihnen eine E-Mail zur Wiederherstellung Ihres Kontos schicken können.",
  VALID_UNTILL: "Gültig bis",
  NO_EQUIPMENTS: "Es gibt keine Ausrüstungen",
  UPDATE_USER_TO_ADMINISTRATOR_SUCCESS:
    "Administrator-Benutzer erfolgreich hinzugefügt.",
  CENTRAL_FOUND: "Zentrale gefunden",
  CONTROLLER_FOUND: "Controller gefunden",
  PUMP_FOUND: "Pumpe gefunden",
  REPEATER_FOUND: "Wiederholer gefunden",
  FIELD_CAPACITY: "Feldkapazität",
  FULL_MOISTURE: "Volle Feuchtigkeit",
  MODERATE_MOISTURE: "Mäßig feucht",
  LIMIT_POINT: "Grenzpunkt",
  MODERATE_WATER_STRESS: "Mäßiger Wasserstress",
  WATER_STRESS: "Wasserstress",
  APPROPRIATE: "Angemessen",
  INNAPPROPRIATE: "Unangemessen",
  SCALE: "Skala",
  BASIC_INFO_BOX_RAINFALL: "Regen",
  PERIOD_TOTAL_WATER_CONSUMPTION: "Gesamtverbrauch der Periode",
  SECTOR_PIVOT_REASON_FALSE: "Nein",
  HISTORIC_CONFIG_PIVOT_OLD_DATE_TIME: "Datum und Uhrzeit: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MAX: "Letzter Stundenbereich: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MIN: "Start Stundenbereich: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MAX: "Maximaler Spannungsbereich: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MIN: "Minimaler Spannungsbereich: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FLOW_RATE: "Durchflussmenge: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_STABILIZATION_TIME:
    "Rückkehrzeit nach Stromausfall: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_PRESSURIZATION_TIME: "Pumpzeit: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_VELOCITY_TOWER: "Letzte Turmgeschwindigkeit: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_AREA: "Bewässerte Fläche: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_RADIUS: "Bewässerungsradius: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_RADIUS_TOWER: "Radius zum letzten Turm: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FINAL_REFERENCE: "Endgültige Referenz:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_INITIAL_REFERENCE:
    "Ursprüngliche Referenz:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_CENTER: "Zentrum:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_SECTOR_PIVOT: "Sektoraler Pivot: ${}",
  SECTOR_PIVOT_REASON_TRUE: "Ja",
  HISTORIC_CONFIG_PIVOT_OLD_END_GUN: "Endgun: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_NAME: "Name: ${}",
  HISTORIC_CONFIG_PIVOT_DRIVE: "${}",
  HISTORIC_CONFIG_PIVOT_PINNED: "Bevorzugte Konfigurationen",
  HISTORIC_IRPD_ACTION_USER: "Benutzer: ",
  MEASURE_SCALE_UNIT_METER: "m³",
  MEASURE_SCALE_UNIT_LITER: "L",
  MEASURE_SCALE_100: "100",
  MEASURE_SCALE_10: "10",
  MEASURE_SCALE_1: "1",
  HISTORIC_IRPD_STREAM_HOURMETER: "Betriebsstundenzähler: ",
  HISTORIC_IRPD_ACTION_CREATED: "${} ago | ${}",
  NUMBER_OF_BARS_12: "12",
  NUMBER_OF_BARS_24: "24",
  NUMBER_OF_BARS_30: "30",
  EDIT_FARM_WEEK: "Woche",
  CREATE_DEVICE_MODAL_PROTOCOL_5: "G2",
  CREATE_DEVICE_MODAL_PROTOCOL_4_1: "G1",
  EDIT_FARM_IRPD_INFOS_RECALCULATE_TEXT:
    "Drücken Sie den Knopf, um die Berichte für die Pumpen der Farm neu zu berechnen.",
  EDIT_FARM_IRPD_INFOS_ENABLE_TEXT:
    "Drücken Sie den Knopf, um die Meldungen an die Pumpen der Farm zu aktivieren.",
  SELECTED_FARM_LIST: "Liste",
  SWAP_RADIO: "Schwenkbare Radios",
  EDIT_RADIO: "Radio bearbeiten",
  HISTORIC_PIVOT_ACTION_BLADE: "Prozentsatz: ",
  HISTORIC_PAINEL_TYPE_CONTROLLER_UPDATE: "Aktualisieren",
  HISTORIC_PAINEL_TYPE_COMMAND: "Befehl",
  PEAK_TIME_DISABLED: "Seien Sie sich bewusst: Pause Zeit deaktiviert",
  TABLE_OF: "von",
  FORGET_PASSWORD_OPTION_PASSWORD: "Ich habe mein Passwort vergessen",
  SIGN_UP_FIELD_USERNAME_CHECK:
    "Verwenden Sie nur Buchstaben und Zahlen. 8 bis 20 Zeichen.",
  SIGN_UP_FIELD_USERNAME_ALREADY_EXIST: "Benutzername nicht verfügbar",
  SIGN_UP_FIELD_REQUIRED: "Dieses Feld ist erforderlich.",
  CREATE_FARM_LABEL: "Bauernhof anlegen",
  SELECTED_FARM_MAP: "Karte",
  RADIO: "Funk",
  SWAP: "Swap",
  SIGN_UP_FIELD_PASSWORD_CHECK:
    "Achten Sie darauf, dass Ihr Passwort mehr als 8 Zeichen hat und Zahlen enthält.",
  NO_COMPATIBLE_RADIO_FOUND: "Kein kompatibles Funkgerät gefunden.",
  SWAP_RADIO_OF: "Tausche Radio von ",
  SELECT_TO_SWAP_RADIO_OF: "Wählen Sie den Drehpunkt, um die Radios von ",
  HISTORIC_CONFIG_PIVOT_DRIVE_MANUAL: "Manual",
  NO_NOTIFICATIONS: "Sie haben keine Benachrichtigungen.",
  SAVE_RADIO: "Radio speichern",
  CANCEL: "Abbrechen",
  CONTROL: "Controller",
  PUMP_CABLE: "Pumpenkabel",
  HISTORIC_IRPD_ACTION_NOW: "Unverzüglich",
  HISTORIC_PAINEL_TYPE_GPS_STREAM: "GPS-Update",
  HISTORIC_PAINEL_NO_GPS: "Keine GPS-Informationen",
  HISTORIC_PAINEL_TYPE_UPDATE: "Aktualisieren",
  HISTORIC_PANEL_STREAM_V5_STOP_PIVOT: "Stop Pivot",
  HISTORIC_PAINEL_TYPE_MAINTENANCE: "Wartung",
  HISTORIC_IRPD_ACTION_START_AT: "Start: ",
  HISTORIC_IRPD_ACTION_END_AT: "Ende: ",
  CREATE_DEVICE_MODAL_IRPD_SOON: "Demnächst",
  REDO_CONVENTIONAL_ACTION: "Letzte Bewässerung laden",
  PIVOT_ACTION_VALUE_MODE_DRY: "Trocken",
  UPDATING: "Aktualisieren...",
  SIGN_UP_FIELD_PASSWORD_REPEAT_CHECK: "Die Passwörter sind unterschiedlich.",
  EDIT_FARM_CONTACT: "Kontakt",
  CREATE_DEVICE_MODAL_PIVO_CHOOSER:
    "Pivot Automatisierung (SmartConnect und Nexus)",
  CREATE_DEVICE_MODAL_BAUER_PIVO_CHOOSER:
    "Bauer Pivot (SmartTouch und Universal Pro)",
  CREATE_DEVICE_MODAL_PIVO_MONITOR_CHOOSER: "Monitordrehpunkt",
  CREATE_DEVICE_MODAL_IRPD_CHOOSER: "Fernsteuerung der Pumpe",
  CREATE_DEVICE_MODAL_PUMP_PIVOT_CODE: "Pumpenradio (Spoti)",
  CREATE_DEVICE_MODAL_PUMP_IRPD_CODE: "Pumpenradio (Irripump)",
  HISTORIC_PANEL_LABEL_BY: "Von",
  HISTORIC_PAINEL_TYPE_PUMP_UPDATE: "Pumpen-Update",
  HISTORIC_CONFIG_PIVOT_V5_LANGUAGE: "Gerätesprache",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MIN: "Mindestspannung",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MAX: "Maximale Spannung",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_STABLE: "Stabile Spannung: ${}",
  HISTORIC_CONFIG_PIVOT_V5_WORKING_ANGLE: "Sektor: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_TIMEOUT: "Pump Timeout: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_DELAY: "Pumpendruckverzögerung: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_READ_PRESSURE: "Druck gelesen: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH: "Druckschalter",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_SOFT_START_TIME: "Sanftanlaufzeit: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Drucksensor-Maximalwert: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MIN_RANGE:
    "Drucksensor-Mindestwert: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_START: "Pause Zeit Start",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_END: "Pause Zeit Ende",
  HISTORIC_CONFIG_PIVOT_V5_POTENCY: "Pumpenleistung: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POWER_DELAY: "Automation Start: ${}",
  HISTORIC_CONFIG_PIVOT_V5_CANAL_AVERAGE_POWER:
    "Durchschnittliche Leistung des Kanals: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PILOT_AVERAGE_POWER: "Pivot-Durchschnittsleistung",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_START: "Startwinkel: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_END: "Endwinkel: ${}",
  HISTORIC_CONFIG_PIVOT_V5_FIELD_ENDGUN_ANGLE_MODE: "Modus: ",
  HISTORIC_CREATED_AT: "Gesendet am: ",
  HISTORIC_IRPD_ACTION_NEVER: "Niemals",
  HISTORIC_IRPD_ACTION_START: "Einschalten",
  HISTORIC_IRPD_ACTION_STOP: "Ausschalten",
  PAINEL_STREAM_RESUMED_REASON_242: "Unerwarteter Halt",
  PAINEL_STREAM_RESUMED_REASON_246: "Angehalten - instabile Leistung",
  PAINEL_STREAM_RESUMED_REASON_248: "Gestoppt - Unterdruck",
  PAINEL_STREAM_RESUMED_REASON_250: "Gestoppt - Druckzeitüberschreitung",
  PAINEL_STREAM_RESUMED_REASON_251: "Angehalten - Thermorelais",
  PAINEL_STREAM_RESUMED_REASON_253: "Angehalten - Stromausfall",
  PAINEL_STREAM_RESUMED_REASON_255: "Sicherheit",
  IRPD_ACTION_VALUE_MODE_WET: "Nass",
  IRPD_ACTION_VALUE_MODE_DRY: "Trocken",
  IRPD_STATUS_ON_BY_SENSOR: "Pumpe wird durch Sensor eingeschaltet",
  IRPD_STATUS_WAITING_AFTER_POWER_FAILURE:
    "Einige Zeit nach Stromausfall warten",
  IRPD_STATUS_HOURMETER_CORRUPTED:
    "Betriebsstundenzähler wird nicht berücksichtigt",
  IRPD_STATUS_INCORRECT_SCHEDULE: "Falscher Zeitplan",
  IRPD_STATUS_END_SCHEDULE_REACHED: "Pumpe aus nach Zeitplan beendet",
  CREATE_DEVICE_MODAL_POTENCY_PLACEHOLDER: "Pumpleistung",
  CREATE_DEVICE_MODAL_PROTOCOL: "Fassung",
  FARM_FIELD_WATER_BILLING_DATE: "Tag der Wasserrechnung",
  CREATE_USER_MODAL_USERNAME_OR_EMAIL: "Name, Benutzername oder E-Mail",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_FORWARD:
    "Bestätigen Sie, dass Sie mit diesen Einstellungen den Drehpunkt in VORWÄRTS einschalten wollen?",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_REVERSE:
    "Bestätigen Sie, dass Sie mit diesen Einstellungen den Drehpunkt in UMGEKEHRT einschalten wollen?",
  MEASURE_SCALE: "Skala messen",
  MEASURE_SCALE_UNIT: "Skaleneinheit messen",
  VOLTAGE: "Spannung",
  DATASHEET_ALERT_CONFIRMATION:
    "Sind Sie sicher, dass Sie die Datenblattkonfiguration durch diese ersetzen möchten?",
  HISTORIC_CONFIG_PIVOT_SUBTITLE:
    "Die in diesem Tab angezeigte Konfiguration ist als Datenblatt markiert. Um sie zu ändern, gehen Sie zu den anderen Tabs und legen Sie eine andere Konfiguration als Datenblatt fest.",
  HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY:
    "Konfiguration aus den Favoriten entfernt",
  DATASHEET_PLACEHOLDER: "Datenblattkonfiguration",
  HISTORIC_CONFIG_PIVOT_DATASHEET_ADD_SUCCESSFULLY:
    "Konfiguration erfolgreich als aktuelles Datenblatt festgelegt!",
  HISTORIC_CONFIG_PIVOT_DATASHEET_FAILED:
    "Fehler beim Festlegen der Konfiguration als Datenblatt. Bitte versuchen Sie es später erneut!",
  HISTORIC_CONFIG_PIVOT_DATASHEET_ERROR:
    "Fehler, bitte versuchen Sie es später erneut",
  EDIT_FARM_ERROR_RADIO_ID_NOT_FOUND: "Zentraler Funk nicht gültig",
  EDIT_FARM_ERROR_RADIO_ID_ALREADY_USED: "Zentraler Funk wurde bereits genutzt",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_NOT_VALID:
    "Ungültiges Controller-Funkgerät",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_ALREADY_USED:
    "Controller-Funkgerät bereits verwendet",
  CREATE_DEVICE_MODAL_ERROR_GPS_NOT_VALID: "Ungültiges GPS-Funkgerät",
  CREATE_DEVICE_MODAL_ERROR_GPS_ALREADY_USED: "GPS-Funkgerät bereits verwendet",
  CREATE_DEVICE_MODAL_ERROR_PUMP_NOT_VALID: "Ungültiges Pumpenradio",
  CREATE_DEVICE_MODAL_ERROR_PUMP_ALREADY_USED: "Pumpenradio bereits benutzt",
  CREATE_USER_MODAL_404: "Benutzer existiert nicht",
  CREATE_USER_MODAL_INVALID: "Ungültiger Benutzername oder E-Mail",
  EDIT_PERMISSIONS_ADD_ADM_ERROR: "Fehler beim Hinzufügen als Administrator",
  RADIO_CHANGED_SUCCESS: "Radio erfolgreich gewechselt",
  RADIO_SWAP_SUCCESS: "Funkgeräte erfolgreich getauscht",
  HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY:
    "Konfiguration zu den Favoriten hinzugefügt",
  HISTORIC_CONFIG_PIVOT_PINNED_ERROR:
    "Fehler versuchen Sie es später noch einmal",
  IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG:
    "Es gibt keine vorherige Bewässerungskonfiguration",
  FARM_DISABLED:
    "Bauernhof deaktiviert. Der Zugang wird erst nach Zahlung der Jahresgebühren gewährt.",
  FARM_DISABLED_CONTACT: "Kontaktieren Sie mit ",
  IRPD_STATUS_OPERATING: "Operating",
  IRPD_STATUS_SCHEDULE_EXPIRED: "Schedule Expired",
  CENTRAL_UPDATE: "Central Update",
  SHOW_CENTRAL_STATUS: "Show Central Updates",
  CREATE_USER_MODAL_EMAIL_NOT_FOUND: "Email not found",
  CREATE_USER_MODAL_SEND_INVITE: "Send Invite",
  CREATE_USER_MODAL_KEEP_TYPING: "Keep typing...",
  CREATE_USER_MODAL_FIND_USER_ERROR:
    "An error occurred while searching for user",
  SIGNAL_STRENGTH: "Signal Strength (dbm)",
  ID_RADIO: "ID (Radio)",
  LOGS: "Logs",
  POWER_RANGE_PEAK_ACRONYM: "PH",
  POWER_RANGE_OFF_PEAK_ACRONYM: "OPH",
  POWER_RANGE_REDUCED_ACRONYM: "NT",
  EDIT_FARM_EDIT_USER: "Benutzer bearbeiten",
  EDIT_FARM_NO_ADMIN: "Kein Administrator registriert",
  USER_PERMISSION_CUSTOMIZED: "Brauch",
  PIVOT_SEGMENT_CONFIG_REVERSE: "Reverse Config",
  PIVOT_SEGMENT_CONFIG_FORWARD: "Forward Config",
  PIVOT_SEGMENT_BEGIN_REVERSE: "Start Reverse",
  PIVOT_SEGMENT_BEGIN_FORWARD: "Start Forward",
  SELECTED_PIVOT_ANGLE_MODAL_FAILURE: "Failed to send Irrigation by angle",
  EDIT_FARM_ANGLE_RANGES: "Bereiche",
  FARM_PIVOT_IRRIGATION_ANGLE_ENDGUN_MESSAGE:
    "* Die endgun Aktivierung der Waffe erfolgt automatisch gemäß der Pivot-Konfiguration.",
  CREATE_DEVICE_MODAL_TYPE: "Typ",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_CENTRAL: "überwachter Mitteldrehpunkt",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_LINEAR: "überwachter Lineardrehpunkt",
  CREATE_DEVICE_MODAL_PANEL_TYPE: "Panel-Typ",
  CREATE_DEVICE_MODAL_NEXUS: "Nexus",
  CREATE_DEVICE_MODAL_SMARTCONNECT: "SmartConnect",
  CREATE_DEVICE_MODAL_BRAND_MODEL: "Brand model",
  CREATE_BRAND_MODEL_BAUER: "Bauer",
  CREATE_BRAND_MODEL_IRRIGABRAS: "Irrigabras",
  CREATE_BRAND_MODEL_VALLEY: "Valley",
  CREATE_BRAND_MODEL_LINDSAY: "Lindsay",
  CREATE_BRAND_MODEL_CARBORUNDUM: "Carborundum",
  CREATE_BRAND_MODEL_KREBS: "Krebs",
  CREATE_BRAND_MODEL_FOCKINK: "Fockink",
  CREATE_BRAND_MODEL_REINKE: "Reinke",
  CREATE_BRAND_MODEL_OTHER: "Andere",
  CREATE_DEVICE_MODAL_UNIT: "Potency Unit",
  CREATE_DEVICE_MODAL_KW: "kW",
  CREATE_DEVICE_MODAL_HP: "hp",
  CREATE_DEVICE_POTENCY_PLACEHOLDER: "Potency",
  CREATE_DEVICE_MODAL_PERFORMANCE_PERCENT: "Performance",
  CREATE_DEVICE_MODAL_CONVERTED_POTENCY: "Converted Potency",
  ENABLE_PLUVIOMETER: "Enable pluviometer",
  DISABLE_PLUVIOMETER: "Disable pluviometer",
  PLUVIOMETER_BOX_ENABLE_DIALOG: "Are you sure you want ${}",
  CREATE_BRAND_MODEL_INSERT: "Input Brand Model",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_NOT_VALID: "Invalid Repeater radio",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_ALREADY_USED:
    "Repeater radio already used",
  CREATE_DEVICE_MODAL_METER: "Meter System",
  CREATE_DEVICE_MODAL_SENSOR: "Sensor",
  CREATE_DEVICE_MODAL_SENSOR_5: "Sensor 5mWC",
  CREATE_DEVICE_MODAL_SENSOR_3: "Sensor 10mWC",
  CREATE_DEVICE_MODAL_SENSOR_2: "Sensor 20mWC",
  CREATE_DEVICE_MODAL_SENSOR_7: "Sensor 100mWC",
  CREATE_DEVICE_MODAL_IMETER: "IMeter Radio",
  CREATE_DEVICE_MODAL_ERROR_IMETER_NOT_FOUND: "Invalid Meter System radio",
  CREATE_DEVICE_MODAL_ERROR_IMETER_ALREADY_USED: "IMeter radio already used",
  CREATE_DEVICE_MODAL_ERROR_IRPD_NOT_FOUND: "Invalid Pump radio",
  CREATE_DEVICE_MODAL_ERROR_IRPD_ALREADY_USED: "Pump radio already used",
  CREATE_DEVICE_MODAL_ERROR_INVALID_HARDWARE_ID: "Harware ID is not available",
  SEND_COMMAND_ERROR: "Fehler beim Senden des Befehls.",
  CREATE_DEVICE_MODAL_ENERGY_TYPE: "der Typ",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_SOLAR: "Solar",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_BIVOLT: "Dual voltage",
  PIVOT_STATUS_NO_COMMUNICATION_INFO_POPOVER:
    "Equipment without communication with the platform.",
  REPORT_TABLE_DISTANCE: "Distance",
  PRIVACY_POLICY: "Datenschutz-Bestimmungen",
  TERMS_AND_CONDITIONS: "Geschäftsbedingungen",
  ANNOUNCEMENT_SYSTEM_TITLE_TERMS_AND_CONDITIONS:
    "Irricontrol's Terms and Conditions",
  TERMS_AND_CONDITIONS_CHECKBOX:
    "I declare that I have read and agree to the Irricontrol Terms and Conditions of Use.",
  TERMS_AND_CONDITIONS_LOGIN_1:
    "By using the Irricontrol platform, you declare that you have read and accepted the",
  TERMS_AND_CONDITIONS_LOGIN_2: "and the",
  PAGE_NO_ACCESS_TO_FARMS:
    "Sie haben keine Berechtigung zum Zugriff auf diese Seite.",
  PAGE_NO_INTERNET:
    "Verbindung zum Internet konnte nicht hergestellt werden, bitte überprüfen Sie Ihren Netzwerkzugriff.",
  PAGE_PRIVACY_POLICY_NOT_ACCEPTED:
    "Sie haben die Nutzungsbedingungen von Irricontrol noch nicht akzeptiert.",
  PAGE_CODE_404:
    "Wir konnten die angeforderte Seite nicht finden. Bitte versuchen Sie es erneut.",
  PAGE_DEFAULT: "Ein unerwartetes Problem ist aufgetreten, es tut uns leid.",
  BACK_TO_INICIAL_PAGE: "Zurück zur Startseite",
  BILLING_TAB_REDIRECT: "Technischer Support",
  PIVOT_CONFIG_FIELD_RADIUS_TOOLTIP: "Abstand von der Mitte zum letzten Turm.",
  PIVOT_CONFIG_V5_FIELD_SPEED_TOOLTIP:
    "Geschwindigkeit vom letzten Turm bis 100%.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MIN: "Minimale Betriebsspannung",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MAX: "Maximale Betriebsspannung",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME_TOOLTIP:
    "Wenn der Pivot länger als die eingestellte Zeit außerhalb der Spannungsgrenzen betrieben wird, wird die Pivot-Aktion gestoppt.",
  SELECTED_ALERT_SELECT_PIVOT_VERSION_TOOLTIP: "Wählen Sie die Pivot-Version:",
  IRRIGATION_END_DATE_PREDICTION_TOOLTIP:
    "Die Endvorhersage berücksichtigt Ausfallzeiten aufgrund von Spitzenzeiten.",
  BASIC_BAR_TOOLTIP_HP: "Spitzenstunden.",
  BASIC_BAR_TOOLTIP_HFP: "Außerhalb der Spitzenzeiten.",
  BASIC_BAR_TOOLTIP_HR: "Außerhalb der Spitzenstunden.",
  PIVOT_CONFIG_V5_LANGUAGE_TOOLTIP:
    "Sprache zur Verwendung in der Zentralensteuerung.",
  EDIT_IRPD_FIELD_ENERGY_TIME: "Leistungszeit",
  EDIT_IRPD_FIELD_ENERGY_TIME_TOOLTIP:
    "Rückkehrzeit, um die Ausführung des Befehls nach einem Stromausfall fortzusetzen.",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY: "Stromversorgungszeit",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY_TOOLTIP:
    "Rückgabezeit, um die Ausführung des Befehls nach einem Stromausfall fortzusetzen.",
  MEASUREMENT_MAX_VALUE: "Sensor-Skala",
  MEASUREMENT_PULSES_VALUE: "Volumen",
  MEASUREMENT_MAX_VALUE_TOOLTIP:
    "Bezieht sich auf die Zeit, die der Drehpunkt benötigt, um Druck zu erzeugen. Wird diese Zeit überschritten, ohne dass der Druck erreicht wird, wird die Pumpe aus Sicherheitsgründen abgeschaltet.",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_TOOLTIP:
    "Gerät zur Messung des Drucks in der Mitte des Pivots.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_TOOLTIP:
    "Wenn der Pivot länger als die eingestellte Zeitspanne außerhalb der Druckgrenzen arbeitet, wird die Pivot-Aktion gestoppt.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE: "Stoppbedingung.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE_TOOLTIP:
    '"Nach Wert" wird der Pivot-Befehl nach Erreichen des im Feld "Wert" festgelegten Schwellenwerts gestoppt. Durch "Dekrement" erhöht der Pivot die Geschwindigkeit, um die angewendete Klinge zu kompensieren.',
  EDIT_PLUVIOMETER_MM_TO_STOP: "Wert",
  EDIT_PLUVIOMETER_MM_TO_STOP_TOOLTIP:
    "Niederschlagsschwelle in Millimetern, die in einem bestimmten Gebiet festgelegt wurde.",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END: "Sensor-Skala",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END_TOOLTIP:
    "Wert, der sich auf die Skala des verwendeten Regenmessers bezieht.",
  PIVOT_CONFIG_V5_FIELD_PUMP_TIMEOUT: "Pumpenzeitüberschreitung",
  PRESSURE_TIMEOUT_INPUT:
    "Bezieht sich auf die Zeit, die der Pivot benötigt, um den Druck zu erhöhen. Wird diese Zeit überschritten, ohne dass der Druck erreicht wird, wird die Pumpe aus Sicherheitsgründen abgeschaltet.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MIN_RANGE: "Minimaler Sensorwert",
  PIVOT_CONFIG_FIELD_SETORIAL: "Sektor Drehpunkt",
  PIVOT_CONFIG_FIELD_SETORIAL_TOOLTIP:
    "Pivot, der einen anderen Arbeitswinkel als 360° hat.",
  PIVOT_CONFIG_FIELD_SPEED: "Geschwindigkeit des letzten Turms",
  PIVOT_CONFIG_FIELD_SPEED_TOOLTIP:
    "Geschwindigkeit des letzten Turms bei 100%.",
  PIVOT_CONFIG_FIELD_POWER_TIME: "Rückkehrzeit nach Stromausfall",
  PIVOT_CONFIG_FIELD_POWER_TIME_TOOLTIP:
    "Zeit, die ein Befehl nach einem Stromausfall gültig bleibt.",
  PIVOT_CONFIG_FIELD_PUMP_TIME: "Zeit der Druckbeaufschlagung",
  PIVOT_CONFIG_FIELD_PUMP_TIME_TOOLTIP:
    "Bezieht sich auf die Zeit, die der Pivot benötigt, um den Druck zu erhöhen. Wird diese Zeit überschritten, ohne dass der Druck erreicht wird, wird die Pumpe aus Sicherheitsgründen abgeschaltet.",
  PIVOT_CONFIG_FIELD_POWER_RANGE: "Arbeitsspannung",
  PIVOT_CONFIG_FIELD_POWER_RANGE_TOOLTIP:
    "Funktion, die verhindert, dass das Gerät außerhalb der Mindest- und Höchstspannungen betrieben wird.",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MIN: "Minimale Betriebsspannung",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MAX: "Maximale Betriebsspannung",
  ENERGY_TIME_DESCRIPTION:
    "In dieser Funktion können Sie die Zeit in Minuten einstellen, die die Pumpe nach einem Stromausfall auf einen Neustart warten muss.",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE_TOOLTIP:
    "Standort des letzten Turms am 0°-Punkt.",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER_TOOLTIP:
    "Standort des Mittelpunkts des Drehpunkts.",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER: "Standort des Geräts",
  PIVOT_CONFIG_V5_FIELD_NAME: "Pivot-Name",
  PIVOT_CONFIG_V5_FIELD_LANGUAGE: "Gerätesprache",
  PIVOT_CONFIG_V5_FIELD_AREA: "Bewässerte Fläche",
  PIVOT_CONFIG_FIELD_AREA: "Bewässerte Fläche",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME: "Zeitüberschreitung",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_LIMIT_TIME: "Zeitüberschreitung",
  PIVOT_CONFIG_V5_TENSION_LIMIT_TOOLTIP:
    "Funktion, die verhindert, dass das Gerät länger als die Schwellenwertzeit außerhalb der Mindest- und Höchstspannungen betrieben wird.",
  PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL: "Gerätetakt",
  PIVOT_CONFIG_FIELD_POTENCY: "Pumpenleistung",
  PIVOT_CONFIG_FIELD_RTC: "Geräteuhr einstellen",
  PIVOT_CONFIG_FIELD_HOUR_RANGE: "Spitzenlastzeit",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MIN: "Anfangsspitzenzeit",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MAX: "Endzeitpunkt der Spitze",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_ENABLE: "Regenmesser einschalten",
  PIVOT_CONFIG_V5_FIELD_CLOCK_DATE: "Datum der Ausrüstung",
  PIVOT_CONFIG_V5_FIELD_CLOCK_TIME: "Ausrüstungsdatum",
  EDIT_PIVOT_LABEL_POWER: "Pumpenleistung",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE: "Druckanzeige",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_DELAY: "Verzögerungszeit",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH: "Instabile Druckschaltzeit",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_1: "Freigabe Spitzenzeit 1",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_2: "Spitzenzeit 2 aktivieren",
  PIVOT_CONFIG_V5_FIELD_ANGLE_START: "Startwinkel",
  PIVOT_CONFIG_V5_FIELD_ANGLE_END: "Endwinkel",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_START: "Startwinkel",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END: "Endwinkel",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_START: "Startwinkel",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_END: "Endwinkel",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_START: "Startwinkel",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_END: "Endwinkel",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME: "Zeitüberschreitung bei Autoreversion",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_TOOLTIP:
    "Zeit, die der Pivot braucht, um seine Bewegung zu stoppen und seine Richtung zu ändern.",
  EDIT_PLUVIOMETER_MODE_BY_VALUE: "Nach Wert",
  EDIT_PLUVIOMETER_MODE_DECREMENT: "Durch Dekrement",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_OFF: "Immer aus",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_ON: "Immer an",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_BY_SEGMENTS: "Nach Schalter",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_SWITCH: "Am Ende des Hubs",
  SIMPLE_IRRIGATION_BY_ANGLE: "Nach Winkel",
  PIVOT_CONFIG_VALUE_ENDGUN_BOOSTER: "Druckerhöhungspumpe",
  PIVOT_CONFIG_FIELD_ENDGUN: "Endgun",
  IRPD_FIELD_NAME: "Pumpenname",
  PIVOT_CONFIG_V5_FIELD_POTENCY: "Pumpenleistung",
  PIVOT_CONFIG_V5_IS_DATASHEET: "Datenblatt",
  IRPD_MONTHLY_WATER_LIMIT: "Monatlicher Grenzwert für den Wasserverbrauch",
  EDIT_IRPD_FIELD_RTCDATE: "Geräteuhr",
  EDIT_IRPD_FIELD_HOUR_RANGE_MAX: "Ende der Spitzenstunde",
  EDIT_IRPD_FIELD_HOUR_RANGE_MIN: "Beginn der Spitzenzeit",
  EDIT_PIVOT_LABEL_POWER_SETTINGS: "Energieeinstellungen",
  MEASUREMENT_OFFSET: "Messwertverschiebung",
  MEASUREMENT_OFFSET_TOOLTIP:
    "Einstellen von Werten für Sondenmessungen, die sich den realen Werten annähern.",
  METERSYSTEM_FLOW_CURVE: "Restdurchflussrate",
  METERSYSTEM_FLOW_CURVE_TOOLTIP:
    "Wenn der Pegelmesser in einem Fluss installiert ist, bietet die Irricontrol-Plattform die Möglichkeit, die Konstanten der Fluss-Schlüsselkurve hinzuzufügen, die durch Messungen in diesem Fluss ermittelt wurden. Anhand dieser Gleichung und des an der Pegelsonde gemessenen Wertes berechnet die Irricontrol-Plattform die Restdurchflussmenge, die im Bericht auf der Startseite des analysierten Messsystems angezeigt wird.",
  PERMISSIONS_REGISTER_USER_INFO: "Berechtigungen",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTRAL: "Standort der Anlage",
  PIVOT_CONFIG_FIELD_REFERENCE_START_TOOLTIP:
    "Standort des letzten Turms am Punkt 0°.",
  PIVOT_CONFIG_FIELD_REFERENCE_END_TOOLTIP:
    "Lage des Endwinkels des Drehpunkts.",
  PIVOT_CONFIG_FIELD_CENTER_TOOLTIP: "Lage des Mittelpunkts des Drehpunkts.",
  NO_PERMISSION: "Keine Erlaubnis",
  NO_PERMISSION_DESCRIPTION:
    "Mit dieser Berechtigung hat der Benutzer keinen Zugang zu den ausgewählten Geräten. Daher wird sie nicht auf dem Startbildschirm der Farm angezeigt",
  VISUALIZER: "Visualizer",
  VIZUALIZER_DESCRIPTION:
    "Mit dieser Berechtigung hat der Benutzer Zugriff auf das Gerät und kann alles einsehen, von Berichten bis zu gesendeten Befehlen. Der Benutzer kann jedoch keine Befehle an das Gerät senden, keine Konfigurationsänderungen am Gerät vornehmen und hat keinen Zugriff auf die Seite mit den Farm-Bearbeitungen.",
  OPERATOR: "Operator",
  OPERATOR_DESCRIPTION:
    "Mit dieser Berechtigung hat der Benutzer die gleichen Rechte wie der Betrachter, außerdem kann er Befehle an das Gerät senden. Der Benutzer kann jedoch keine Änderungen an den Einstellungen des Geräts vornehmen und hat keinen Zugriff auf die Seite für die Bearbeitung der Farm.",
  CONFIGURATOR: "Konfigurator",
  CONFIGURATOR_DESCRIPTION:
    "Mit dieser Berechtigung hat der Benutzer die gleichen Rechte wie der Bediener, außerdem kann er die Geräteeinstellungen bearbeiten. Diese können jedoch nicht auf die Bearbeitungsseite der Farm zugreifen",
  ADMINISTRATOR: "Administrator",
  ADMINISTRATOR_DESCRIPTION:
    "Schließlich hat der Benutzer als Administrator alle Berechtigungen des Konfigurators. Er hat Zugang zu Berichten, zur Befehlshistorie, zum Senden neuer Befehle, zur Bearbeitung aller Ausrüstungsinformationen, zur Bearbeitung von Bauernhöfen und kann außerdem Benutzer hinzufügen und verwalten",
  PERMISSION_INFO: "Berechtigungsinformationen",
  HISTORIC_PIVOT_GPS_V5_SENSOR: "${} | ${} | ${} - ${} | ${}",
  HISTORIC_PIVOT_GPS_V5: "${} | ${} | ${} | ${}",
  VALUE: "${}",
  VALUE_HOUR_AND_MINUTE: "${}h ${}min",
  VALUE_AND_UNIT_HOUR: "${}h",
  VALUE_AND_UNIT_BAR: "${} bar",
  VALUE_AND_UNIT_MIN: "${} min",
  VALUE_AND_UNIT_DEGREES: "${}º",
  VALUE_AND_UNIT_PERCENT_MILIMETERS: "${}% - ${} mm",
  VALUE_AND_UNIT_MILIMETERS: "${} mm",
  VALUE_OF_TURNS: "${} Runden",
  VALUE_AND_UNIT_POWER: "${} Volt",
  EDIT_PIVOT_MONITOR_LABEL: "Monitor Radio",
  CREATE_DEVICE_MODAL_MONITOR_CODE: "Monitor Radio",
  GPS_FOUND: "GPS/Monitor found",
  EDIT_FARM_USER_PERMISSION_ERROR: "Error saving permissions",
  MOBILE_ONLY_INFO: "This feature only works on the mobile version.",
  I18N_KEYS: "TAGS I18N",
  IRPD_STATUS_NO_COMMUNICATION: "No Communication",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5: "5.0",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_1: "5.1",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_2: "5.2",
  CREATE_DEVICE_MODAL_METER_PROTOCOL_5_2: "5.2",
  CREATE_DEVICE_MODAL_METER_PROTOCOL_5_3: "5.3",
  METERSYSTEM_WATER_LEVEL: "Messung",
  METERSYSTEM_OFFSET_WATER_LEVEL: "Versatz",
  METERSYSTEMGH_WATER_LEVEL: "Reservoirstand",
  METERSYSTEM_AJUSTED_WATER_LEVEL: "Wasserstand im Reservoir",
  PIVOT_REPORT_TOTAL_COST_HINT:
    "To change the prices of the power ranges, go to pivot settings.",
  EDIT_PIVOT_PAUSE_TIME_PRICES_TITLE: "Prices of Power Ranges",

  ERROR_MAX_16_CHARACTERS: "Das Feld darf maximal 16 Zeichen haben",
  ERROR_MAX_32_CHARACTERS: "Das Feld darf maximal 32 Zeichen haben",
  ERROR_16_CARACTERS_RADIO_CONTROLER:
    "Das Feld Controller Radio darf bis zu 16 Zeichen enthalten",
  ERROR_16_CARACTERS_RADIO_GPS: "Das GPS-Funkfeld muss 16 Zeichen enthalten",
  ERROR_16_CARACTERS_RADIO_PUMP:
    "Das Feld Pump Cable Radio muss 16 Zeichen enthalten",
  ERROR_16_CARACTERS_RADIO_MONITOR:
    "Das Feld Radio des Monitors muss 16 Zeichen enthalten",
  ERROR_16_CARACTERS_RADIO_REPEATER:
    "Das Feld Radio des Repeaters muss 16 Zeichen enthalten",
  ERROR_16_CARACTERS_RADIO_IMETER:
    "Das Feld Radio des IMeters muss 16 Zeichen enthalten",

  ERROR_INVALID_CHARACTERS: "Das Feld enthält ungültige Zeichen",
  ERROR_INVALID_AMOUNT_MIN: "Unterschreitung der Mindestanzahl von Zeichen",
  ERROR_INVALID_AMOUNT_MAX: "Über die maximale Anzahl von Zeichen hinaus",
  ERROR_EMPTY_FIELD: "Dieses Feld kann nicht leer sein",
  ERROR_NEGATIVE_VALUES: "Nur mit positiven Werten ausfüllen",
  ERROR_ZIP_CODE: "CEP nicht gefunden",
  ERROR_MIN_MAX_PRECIPITATION: "Muss ungültigen Klingenwert haben",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MIN:
    "Minimaler Spannungswert, größer als der maximale",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MAX:
    "Minimaler Spannungswert, größer als der maximale",
  ERROR_GENERAL: "Feld hat ungültige Werte",
  ERROR_ZERO: "Feldwert kann nicht 0 sein",
  ERROR_ADD_NAME: "Fügen Sie einen Namen für das Segment hinzu",
  ERROR_PIVOT_LAT_AND_LONG: "Ungültiger Breitengrad und Längengrad",
  ERROR_PIVOT_LAT: "Ungültiger Breitengrad",
  ERROR_PIVOT_LONG: "Ungültiger Längengrad",
  FORGET_PASSWORD_FAILED:
    "Passwörter müssen Folgendes enthalten:\nMindestens 8 Zeichen;\nMindestens eine Zahl;\nMindestens ein Sonderzeichen;",
  SIGN_UP_PASSWORD:
    "Passwort (8+ Zeichen, einschließlich Zahlen und Sonderzeichen)",
  EDIT_PROFILE_ADDRESS_2: "Address details",
  CPF_ALREADY_USED: "CPF already used on the platform",
  SIGN_UP_WARNING_ERROR: "This email or CPF is already registered",
  ERROR_VALUE_UNDER: "Dieser Wert ist kleiner als das akzeptierte Minimum",
  ERROR_VALUE_OVER: "Dieser Wert ist größer als der zulässige Höchstwert",
  ERROR_MIN_MAX: "Muss Werte größer als Null haben",
  CPF_ALREADY_REGISTERED: "Diese CPF wird bereits verwendet",
  CURRENCY: "$",
  PIVOT_CONFIG_V5_BRAND_MODEL: "Brand Model",
  CREATE_DEVICE_MODAL_OTHER: "-",
  IRPD_PRESSURE_CHART_DAY: "Tag",
  IRPD_PRESSURE_CHART_NUMBER_OF_POINTS: "Anzahl der Punkte",
  NUMBER_OF_POINTS_12: "12",
  NUMBER_OF_POINTS_24: "24",
  NUMBER_OF_POINTS_30: "30",
  BASIC_INFO_BOX_CHART_PLUVIOMETER: "Regenmesser",
  BASIC_INFO_BOX_CHART_WATER_CONSUMPTION: "Verbrauchsdiagramm",
  BASIC_INFO_BOX_CHART_WATER_PRESSURE: "Druckdiagramm",
  IRPD_CONFIG_GENERAL_HAS_PRESSURE_SENSOR: "Drucksensor",
  TABLE_HP: "Hauptverkehrszeit",
  CREATE_DEVICE_MODAL_PIVO_LINEAR_MONITOR_CHOOSER: "Linear Pivot Monitor",
  CREATE_DEVICE_MODAL_ERROR_BASE_NOT_VALID: "Invalid base radio",
  CREATE_DEVICE_MODAL_FLOW_RATE: "Flow Rate",
  CREATE_DEVICE_MODAL_PIVOT_LENGTH: "Length",
  CREATE_DEVICE_MODAL_PIVOT_SPEED: "Speed",
  EDIT_MONITOR_LINEAR_LABEL: "Edit Pivot Linear Monitor",
  PIVOT_CONFIG_FIELD_SIZE: "Pivot Size",
  PIVOT_CONFIG_FIELD_SIZE_TOOLTIP: "Total Length of the Linear Pivot",
  PIVOT_CONFIG_V5_LINEAR_SPEED: "Linear Pivot Speed",
  PIVOT_CONFIG_V5_LINEAR_SPEED_TOOLTIP: "Linear pivot speed at 100%",
  BASIC_INFO_BOX_TOTAL_WET_HOURS: "Total hours worked.",
  EDIT_LINEAR_PIVOT_MONITOR_LABEL: "Edit Linear Pivot Monitor",
  LPM_CONFIG_FIELD_LENGTH: "Length",
  LPM_CONFIG_FIELD_LENGTH_TOOLTIP: "Linear pivot total length",
  LPM_CONFIG_V5_FIELD_SPEED: "Speed",
  LPM_CONFIG_V5_FIELD_SPEED_TOOLTIP: "Speed of the linear pivot to 100%",
  MAINTENANCE_REASON_01: "Supply - Generator",
  MAINTENANCE_SPEC_NONE_01: "---",
  MAINTENANCE_SPEC_01A: "injector nozzle",
  MAINTENANCE_SPEC_01B: "auxiliary fuel pump",
  MAINTENANCE_SPEC_01C: "injection pump",
  MAINTENANCE_SPEC_01D: "injector barrel",
  MAINTENANCE_SPEC_01E: "fuel shortage",
  MAINTENANCE_SPEC_01F: "fuel filter",
  MAINTENANCE_SPEC_01G: "water separator filter",
  MAINTENANCE_SPEC_01H: "fuel hose",
  MAINTENANCE_SPEC_01I: "fuel",
  MAINTENANCE_SPEC_01J: "fuel tank leakage",
  MAINTENANCE_REASON_02: "Supply - Water Pump",
  MAINTENANCE_SPEC_NONE_02: "---",
  MAINTENANCE_SPEC_02A: "injector nozzle",
  MAINTENANCE_SPEC_02B: "auxiliary fuel pump",
  MAINTENANCE_SPEC_02C: "injection pump",
  MAINTENANCE_SPEC_02D: "injector barrel",
  MAINTENANCE_SPEC_02E: "fuel shortage",
  MAINTENANCE_SPEC_02F: "fuel filter",
  MAINTENANCE_SPEC_02G: "water separator filter",
  MAINTENANCE_SPEC_02H: "fuel hose",
  MAINTENANCE_SPEC_02I: "fuel",
  MAINTENANCE_SPEC_02J: "fuel tank leakage",
  MAINTENANCE_REASON_03: "Cooling - Water Pump",
  MAINTENANCE_SPEC_NONE_03: "---",
  MAINTENANCE_SPEC_03A: "water pump",
  MAINTENANCE_SPEC_03B: "alternator belt",
  MAINTENANCE_SPEC_03C: "belt tightener",
  MAINTENANCE_SPEC_03D: "primary air filter",
  MAINTENANCE_SPEC_03E: "secondary air filter",
  MAINTENANCE_SPEC_03F: "propeller",
  MAINTENANCE_SPEC_03G: "cracked radiator",
  MAINTENANCE_SPEC_03H: "radiator cap",
  MAINTENANCE_SPEC_03I: "belt tensioner",
  MAINTENANCE_REASON_04: "Cooling - Pivot",
  MAINTENANCE_SPEC_NONE_04: "---",
  MAINTENANCE_SPEC_04A: "water pump",
  MAINTENANCE_SPEC_04B: "alternator belt",
  MAINTENANCE_SPEC_04C: "belt tightener",
  MAINTENANCE_SPEC_04D: "primary air filter",
  MAINTENANCE_SPEC_04E: "secondary air filter",
  MAINTENANCE_SPEC_04F: "propeller",
  MAINTENANCE_SPEC_04G: "cracked radiator",
  MAINTENANCE_SPEC_04H: "radiator cap",
  MAINTENANCE_SPEC_04I: "belt tensioner",
  MAINTENANCE_REASON_05: "Centrifugal Pump",
  MAINTENANCE_SPEC_NONE_05: "---",
  MAINTENANCE_SPEC_05A: "gasket - tighten",
  MAINTENANCE_SPEC_05B: "gasket - change",
  MAINTENANCE_SPEC_05C: "lubricant - low level",
  MAINTENANCE_SPEC_05D: "damaged retainer",
  MAINTENANCE_SPEC_05E: "broken bearing",
  MAINTENANCE_REASON_06: "Vinasse Canal",
  MAINTENANCE_SPEC_NONE_06: "---",
  MAINTENANCE_SPEC_06A: "lack of vinasse",
  MAINTENANCE_SPEC_06B: "gate failure",
  MAINTENANCE_SPEC_06C: "low flow",
  MAINTENANCE_REASON_07: "Electricity - Water Pump",
  MAINTENANCE_SPEC_NONE_07: "---",
  MAINTENANCE_SPEC_07A: "alternator",
  MAINTENANCE_SPEC_07B: "automatic",
  MAINTENANCE_SPEC_07C: "battery",
  MAINTENANCE_SPEC_07D: "battery - cable",
  MAINTENANCE_SPEC_07E: "battery - support",
  MAINTENANCE_SPEC_07F: "wiring",
  MAINTENANCE_SPEC_07G: "fuse",
  MAINTENANCE_SPEC_07H: "starter motor",
  MAINTENANCE_SPEC_07I: "auxiliary start relay",
  MAINTENANCE_SPEC_07J: "solenoid",
  MAINTENANCE_REASON_08: "Electricity - Pivot",
  MAINTENANCE_SPEC_NONE_08: "---",
  MAINTENANCE_SPEC_08A: "alternator",
  MAINTENANCE_SPEC_08B: "battery",
  MAINTENANCE_SPEC_08C: "battery - cabble",
  MAINTENANCE_SPEC_08D: "battery - support",
  MAINTENANCE_SPEC_08E: "drive contactor",
  MAINTENANCE_SPEC_08F: "wiring",
  MAINTENANCE_SPEC_08G: "fuse",
  MAINTENANCE_SPEC_08H: "generator - brush",
  MAINTENANCE_SPEC_08I: "generator - brush holder",
  MAINTENANCE_SPEC_08J: "drive micro",
  MAINTENANCE_SPEC_08K: "security micro",
  MAINTENANCE_SPEC_08L: "starter motor",
  MAINTENANCE_SPEC_08M: "gearmotor",
  MAINTENANCE_SPEC_08N: "percent meter",
  MAINTENANCE_SPEC_08O: "plate",
  MAINTENANCE_REASON_09: "Structure - Water Pump",
  MAINTENANCE_SPEC_NONE_09: "---",
  MAINTENANCE_SPEC_09A: "header",
  MAINTENANCE_SPEC_09B: "support cable ratchet",
  MAINTENANCE_SPEC_09C: "suction hose",
  MAINTENANCE_SPEC_09D: "suction hose - clamp",
  MAINTENANCE_SPEC_09E: "suction hose - support cable",
  MAINTENANCE_SPEC_09F: "flat tire",
  MAINTENANCE_SPEC_09G: "wheel bearing",
  MAINTENANCE_SPEC_09H: "expansion tank",
  MAINTENANCE_SPEC_09I: "expansion tank - handle",
  MAINTENANCE_SPEC_09J: "expansion tank - register",
  MAINTENANCE_REASON_10: "Structure - Pivot",
  MAINTENANCE_SPEC_NONE_10: "---",
  MAINTENANCE_SPEC_10A: "cardan",
  MAINTENANCE_SPEC_10B: "cardan - flexible joint",
  MAINTENANCE_SPEC_10C: "cardan - universal joint",
  MAINTENANCE_SPEC_10D: "misalignment",
  MAINTENANCE_SPEC_10E: "sprinkler cleaning",
  MAINTENANCE_SPEC_10F: "pivot cleaning",
  MAINTENANCE_SPEC_10G: "span junction hose",
  MAINTENANCE_SPEC_10H: "broken hose",
  MAINTENANCE_SPEC_10I: "wheel stud",
  MAINTENANCE_SPEC_10J: "retaining plate",
  MAINTENANCE_SPEC_10K: "flat tire",
  MAINTENANCE_SPEC_10L: "sprinkler replacement",
  MAINTENANCE_SPEC_10M: "hose replacement",
  MAINTENANCE_SPEC_10N: "loose wheel",
  MAINTENANCE_SPEC_10O: "security system inoperative",
  MAINTENANCE_SPEC_10P: "front reducer support",
  MAINTENANCE_SPEC_10Q: "rear reducer support",
  MAINTENANCE_SPEC_10R: "trusses",
  MAINTENANCE_SPEC_10S: "reinforcement tube",
  MAINTENANCE_SPEC_10T: "base beam",
  MAINTENANCE_REASON_11: "Lubrication - Generator",
  MAINTENANCE_SPEC_NONE_11: "---",
  MAINTENANCE_SPEC_11A: "lubricant - filter",
  MAINTENANCE_SPEC_11B: "lubricant - low level",
  MAINTENANCE_SPEC_11C: "leakage - flexible",
  MAINTENANCE_SPEC_11D: "leakage - front flywheel retainer",
  MAINTENANCE_SPEC_11E: "leakage - rear flywheel retainer",
  MAINTENANCE_SPEC_11F: "leakage - crankcase cover",
  MAINTENANCE_SPEC_11G: "leakage - mirror cover",
  MAINTENANCE_SPEC_11H: "leakage - turbine",
  MAINTENANCE_REASON_12: "Lubrication - Water Pump",
  MAINTENANCE_SPEC_NONE_12: "---",
  MAINTENANCE_SPEC_12A: "lubricant - filter",
  MAINTENANCE_SPEC_12B: "lubricant - low level",
  MAINTENANCE_SPEC_12C: "leakage - flexible",
  MAINTENANCE_SPEC_12D: "leakage - front flywheel retainer",
  MAINTENANCE_SPEC_12E: "leakage - rear flywheel retainer",
  MAINTENANCE_SPEC_12F: "leakage - crankcase cover",
  MAINTENANCE_SPEC_12G: "leakage - mirror cover",
  MAINTENANCE_SPEC_12H: "leakage - turbine",
  MAINTENANCE_REASON_13: "Scheduled Downtime",
  MAINTENANCE_SPEC_NONE_13: "---",
  MAINTENANCE_SPEC_13A: "supply",
  MAINTENANCE_SPEC_13B: "change of location",
  MAINTENANCE_SPEC_13C: "pivoting",
  MAINTENANCE_SPEC_13D: "towing",
  MAINTENANCE_SPEC_13E: "hose change",
  MAINTENANCE_SPEC_13F: "wheels turning",
  MAINTENANCE_REASON_NONE: "---",
  MAINTENANCE_SPEC_NONE: "---",
  MAINTENANCE_NOTE_NONE: "-> ",
  MAINTENANCE_BUTTON_TOOLTIP_ENABLE: "Wartungsmodus aktivieren",
  MAINTENANCE_BUTTON_TOOLTIP_DISABLE: "Wartungsmodus deaktivieren",
  MAINTENANCE_MODAL_REASON_QUESTION: "Inform reason for downtime?",
  MAINTENANCE_MODAL_REASON_LABEL: "Select a reason:",
  MAINTENANCE_MODAL_SPEC_QUESTION: "Specify the reason for downtime?",
  MAINTENANCE_MODAL_SPEC_LABEL: "Select a specification:",
  MAINTENANCE_MODAL_NOTE_QUESTION: "Any notes?",
  MAINTENANCE_MODAL_NOTE_LABEL: "Note:",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_ENABLE: "Wartungsmodus aktivieren?",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_DISABLE:
    "Wartungsmodus deaktivieren?",
  MAINTENANCE_MODALS_BUTTON_CANCEL: "Abbrechen",
  MAINTENANCE_MODALS_BUTTON_NEXT: "Weiter",
  MAINTENANCE_MODALS_BUTTON_CONFIRM: "Bestätigen",
  BASIC_INFO_BOX_CHART_GENERATED_BETWEEN:
    "Chart for the period from ${} to ${}",
  BASIC_INFO_BOX_WATER_PRESSURE: "Druck",
  GRAPHIC_SCALE: "Graphic scale",
  GRAPHIC_SCALE_TOOLTIP:
    "The Graphic Scale refers to the maximum meters of water column (MCA) it can measure.",
  LPM_EFFICIENCY: "Wirkungsgrad: ",
  IMETER_FIELD_NAME: "Meter name",
  METERSYSTEM_FIELD_NAME: "System Name",
  MCA_10: "10 MCA",
  MCA_20: "20 MCA",
  MCA_100: "100 MCA",
  IMETER_CONFIG_V5_FIELD_SENSOR: "Sensor",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS: "Befruchtung: ",
  REPORT_TABLE_OPERATION_NOTE: "Überwachung",
  REPORT_TABLE_IRRIGATION_MODE: "Modus",
  PANEL_STREAM_STATUS_26: "Düngen",
  EDIT_PIVOT_V5_INJETCTION_PUMP_CONFIG: "Befruchtung aktivieren",
  SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP: "Befruchtung",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE: "Bemerkungen",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED: "Bemerkungen: ",
  IRRIGATION_MODE_FERTIRRIGATION: "Düngung",
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_TOOL_TIP:
    'Wählen Sie "Fertirrigation aktivieren", wenn Sie die Fertigationsfunktion in den Bewässerungsbefehlen dieses Drehpunkts verwenden möchten.',
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_CHECK: "Befruchtung aktivieren",
  EDIT_IMETER_LEVEL_TAB: "Level",
  EDIT_IMETER_LEVEL_TAB_SENSOR_INFO_TITLE: "Sensor's information",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TITLE: "Chart configuration",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TEXT:
    "These values are for inserting reference lines into the measurement chart.",
  EDIT_IMETER_LEVEL_TAB_MAX_LIMIT_INPUT: "Maximum value",
  EDIT_IMETER_LEVEL_TAB_MIN_LIMIT_INPUT: "Minimum value",
  BASIC_INFO_BOX_CHART_LAKE_LEVEL: "Level Chart",
  BASIC_INFO_BOX_LEVEL: "Level",
  GENERAL_BOX_MAX_LIMIT: "Maximum value:",
  GENERAL_BOX_MIN_LIMIT: "Minimum value:",
  PERIOD_TOTAL_RAINFALL: "Rain total for the period",
  PLUVIOMETER_CHART_NUMBER_OF_BARS: "Anzahl der Balken",
  IRPD_CONSUMPTION_CHART_NUMBER_OF_BARS: "Anzahl der Balken",
  IMETER_LEVEL_CHART_NUMBER_OF_POINTS: "Anzahl der Punkte",
  BASIC_INFO_BOX_CHART_GENERATED_FOR: "Chart for ${}",
  SEGMENTS_PLANT_DATE: "Anpflanzungsdatum",
  SEGMENTS_HARVEST_DATE: "Geschätztes Erntedatum",
  EDIT_PIVOT_LABEL_PIVOT_SEGMENTS: "Segmente und Bepflanzung",
  SEGMENT_NAME: "Name des Segments/der Anpflanzung",
  MOBILE_MODAL_TITLE_SEGMENTS_DISPLAY: "Zuschneiden",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_PLANT_DATE: "Anpflanzung: ${}",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_HARVEST_DATE: "Ernte: ${}",
  NEW_SEGMENT: "Segmente hinzufügen/bearbeiten",
  HOVER_ON_SEGMENT:
    "Bewegen Sie den Mauszeiger unter die Zeichnung, um Informationen über das Segment zu erhalten",
  ANGLE_RANGE: "Winkelbereich",
  ACCUMULATED_BLADE: "Aufgelaufene Klinge",
  SEGMENT_TYPE: "Art der Bepflanzung/Bewirtschaftung",
  CROP_WARNING_CONFIG:
    "Für weitere Informationen, konfigurieren Sie die Pflanz-/Bewirtschaftungsdaten in Edit Pivot",
  CROP_WARNING_INFO: "In diesem Zeitbereich liegen keine Informationen vor.",
  CROP_WARNING_SECTORIAL:
    "Dieser Drehpunkt ist sektoral. Die Drehpunktsegmente bestehen nur aus den farbigen Bereichen der Karte.",
  PLUVIOMETER_WITHOUT_CONFIG: "Pluviometer not enabled",
  ACTIVATED: "Activated",
  DEACTIVATED: "Deactivated",
  EDIT_PIVOT_V5_AUTOREVERSION_MODE: "Stoppbedingung",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_ANGLE: "Nach Winkel",
  ENDGUN_START_ANGLE: "Start",
  ENDGUN_FINAL_ANGLE: "Ende",
  EDIT_IRPD_LOAD_SUCCESS: "Konfiguration erfolgreich geladen!",
  EDIT_IRPD_LOAD_ERROR: "Fehler beim Laden der Konfiguration.",
  EDIT_IRPD_LOAD_BUTTON: "Laden",
  HISTORIC_CONFIG_INF_MONITOR: "Monitor",
  HISTORIC_CONFIG_INF_CONTROLLER: "Controller",
  HISTORIC_CONFIG_INF_HARDWARE: "Hardware",
  HISTORIC_CONFIG_INF_GPS: "GPS",
  HISTORIC_CONFIG_INF_PUMP: "Pump",
  HISTORIC_CONFIG_INF_DATASHEET: "Datenblatt",
  HISTORIC_CONFIG_INF_IMETER: "Meter",
  HISTORIC_PANEL_LABEL_MONITOR: "MO",
  HISTORIC_PANEL_LABEL_CONTROLLER: "C",
  HISTORIC_PANEL_LABEL_HARDWARE: "H",
  HISTORIC_PANEL_LABEL_GPS: "G",
  HISTORIC_PANEL_LABEL_PUMP: "P",
  HISTORIC_PANEL_LABEL_IMETER: "ME",
  LEGEND: "Legend",
  ALL_CONFIGURATIONS: "All Configurations",
  CONFIG_NOT_SENT: "configuration not sent",
  CONFIG_SENT: "configuration sent, but not received",
  CONFIG_RECEIVED: "configuration received",
  TEXT_CONFIG_NOT_SENT: "Configuration was not sent to the device.",
  TEXT_CONFIG_SENT:
    "Configuration was sent to the device, but it was not received.",
  TEXT_CONFIG_RECEIVED: "Configuration was received by the device.",
  IRPD_EDIT_LABEL: "Edit Water Pump",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START: "Beginn der Spitzenzeit",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END: "Ende der Spitzenzeit",

  BAUER_PIVOT_EDIT_ZERO_POSITION: "Nullstellung",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_ALWAYS_ON: "Immer auf",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_AUTOMATIC_MODE: "Automatischer Modus",
  BAUER_PIVOT_EDIT_SECTOR_BEGIN: "Sektor starten",
  BAUER_PIVOT_EDIT_SECTOR_END: "Endsektor",
  BAUER_PIVOT_EDIT_AUTOREVERSE: "Normal",
  BAUER_PIVOT_EDIT_PARKING_FUNCTION: "Parkposition",
  BAUER_PIVOT_EDIT_INTERMEDIATE_FUNCTION: "Zwischenposition",

  BAUER_PIVOT_GENERAL_TOOLTIP_PRESSURE: "Stromdruck",
  BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE: "Stromwinkel",
  BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE: "Bewässerungsrate",

  BAUER_PIVOT_HISTORY_TOOLTIP_USER: "Vom Benutzer gesendet",
  BAUER_PIVOT_HISTORY_TOOLTIP_DEVICE: "Vom Gerät gesendet",

  BAUER_PIVOT_HISTORY_USER: "Überblick",
  BAUER_PIVOT_HISTORY_PROTOCOL: "Protokoll",
  BAUER_PIVOT_HISTORY_PROTOCOL_TABLE: "Protokolltabelle",

  BAUER_PIVOT_STATUS_0: "Maschine nicht im Betrieb",
  BAUER_PIVOT_STATUS_1: "Maschine arbeitet mit Vorschubgeschwindigkeit",
  BAUER_PIVOT_STATUS_2:
    "Warten Sie bis zur Startzeit, um die Bewässerung zu beginnen",
  BAUER_PIVOT_STATUS_3: "Bewässerung machte eine Pause",
  BAUER_PIVOT_STATUS_4: "Sicherheitsschaltung unterbrochen",
  BAUER_PIVOT_STATUS_5: "Maschinenbetrieb im Notfallmodus",
  BAUER_PIVOT_STATUS_6: "Maschine über Fernbedienung gestoppt",
  BAUER_PIVOT_STATUS_7: "Sicherheitsschalter ausgeschaltet",
  BAUER_PIVOT_STATUS_8: "Wasserdruck zu niedrig",
  BAUER_PIVOT_STATUS_9: "Wegen starker Wind angehalten",
  BAUER_PIVOT_STATUS_10: "Spannung zu niedrig",
  BAUER_PIVOT_STATUS_11: "CAN-Bus-Fehler",
  BAUER_PIVOT_STATUS_12: "I2C -Fehler",
  BAUER_PIVOT_STATUS_13: "Start Verzögerungszeit aktiv",
  BAUER_PIVOT_STATUS_14: "Parkposition erreicht",
  BAUER_PIVOT_STATUS_15: "Maschine arbeitet im Rückwärtsgang",
  BAUER_PIVOT_STATUS_16: "GPS -Fehler",
  BAUER_PIVOT_STATUS_17: "Wegen Niederschlags gestoppt",
  BAUER_PIVOT_STATUS_18: "Eckfehler",
  BAUER_PIVOT_STATUS_19: "Span -Sensorfehler",
  BAUER_PIVOT_STATUS_20: "Neigungssensorfehler",
  BAUER_PIVOT_STATUS_21: "Letzter Turmfehler",
  BAUER_PIVOT_STATUS_22: "Entfernung zu vergrabenem Führungskabel zu groß",
  BAUER_PIVOT_STATUS_23: "Begrabenes Leitsignal zu niedrig",
  BAUER_PIVOT_STATUS_24: "Begrabener Leitfehler",
  BAUER_PIVOT_STATUS_25: "Eckfrequenzwandlerfehler",
  BAUER_PIVOT_STATUS_26: "Kompressorfehler",
  BAUER_PIVOT_STATUS_27: "Keine Bewegung erkannt",
  BAUER_PIVOT_STATUS_28: "Zwischenstoppposition erreicht",
  BAUER_PIVOT_STATUS_40: "Wasserdruck zu hoch",
  BAUER_PIVOT_STATUS_41: "Notfallschalter gedrückt",

  BAUER_PIVOT_STATUS_NEW_CON: "Neue Kommunikation",
  BAUER_PIVOT_STATUS_MAN_START: "Manueller Start",
  BAUER_PIVOT_STATUS_MAN_STOP: "Manueller Stopp",
  BAUER_PIVOT_STATUS_ACK_FWD: "Weiterleitung empfangen",
  BAUER_PIVOT_STATUS_ACK_REV: "Rückwärts empfangen",
  BAUER_PIVOT_STATUS_ACK_STOP: "Stopp empfangen",
  BAUER_PIVOT_STATUS_START_FWD: "Vorwärtsbefehl gesendet",
  BAUER_PIVOT_STATUS_START_REV: "Rückwärtsbefehl gesendet",
  BAUER_PIVOT_STATUS_STOP_CMD: "Stoppbefehl gesendet",
  BAUER_PIVOT_STATUS_GET_CONFIG: "Konfiguration holen",
  BAUER_PIVOT_STATUS_SET_CONFIG: "Konfiguration setzen",
  BAUER_PIVOT_STATUS_ACK_CONFIG: "Konfiguration empfangen",
  BAUER_PIVOT_STATUS_ACK_VRI: "VRI empfangen",
  BAUER_PIVOT_STATUS_RFTP_ERROR_1: "Datei: Keine Daten",
  BAUER_PIVOT_STATUS_RFTP_MAX_RETRY: "Datei: Limit überschritten",
  BAUER_PIVOT_STATUS_RFTP_ERR_FILE: "Datei: Prüfsummenfehler",
  BAUER_PIVOT_STATUS_RFTP_OK: "Datei: erfolgreich",
  BAUER_PIVOT_STATUS_ACK_START: "Start empfangen",
  BAUER_PIVOT_STATUS_DEFAULT: "Aktualisierung",

  PUMP: "Pumpe",
  IMETER: "iManage",
  EDIT_REPEATER_LABEL_GENERAL: "Allgemein",
  EDIT_REPEATER_CENTRAL_LABEL: "Zentraler Funk",
  EDIT_REPEATER_RADIO_LABEL: "Repeater-Funk",
  REPEATER_FIELD_NAME: "Name",
  REPEATER_CONFIG_FIELD_ENERGY_TYPE: "Typ",
  REPEATER_CONFIG_FIELD_SOLAR: "Solar",
  REPEATER_CONFIG_FIELD_BIVOLT: "Bivolt",
  REPEATER_CONFIG_DEVICE_FIELD_GP_CENTER: "Gerätestandort",
  REPEATER_EDIT_LABEL: "Repeater",
  RADIO_SWAP_CONTROLLER_TITLE: "Das Funkgerät des Controllers wechseln",
  RADIO_SWAP_GPS_TITLE: "Wechseln Sie das GPS-Funkgerät",
  RADIO_SWAP_PUMP_TITLE: "Wechseln Sie das Funkgerät der Pumpe",
  RADIO_SWAP_IMETER_TITLE: "iManage-Funkgerät austauschen",
  RADIO_SWAP_REPEATER_TITLE: "Repeater-Funkgerät austauschen",
  RADIO_SWAP_CONTROLLER_SUBTITLE:
    "Wählen Sie den Drehpunkt, um das Funkgerät des Controllers zu wechseln.",
  RADIO_SWAP_GPS_SUBTITLE:
    "Wählen Sie den Drehpunkt, um das GPS-Funkgerät zu wechseln.",
  RADIO_SWAP_PIVOT_PUMP_SUBTITLE:
    "Wählen Sie den Drehpunkt, um das Funkgerät der Pumpe zu ändern.",
  RADIO_SWAP_IMETER_SUBTITLE:
    "Wählen Sie einen iManage, um das Radio zu wechseln.",
  RADIO_SWAP_PUMP_SUBTITLE:
    "Wählen Sie eine Pumpe aus, um das Funkgerät zu ändern.",
  RADIO_SWAP_REPEATER_SUBTITLE:
    "Wählen Sie einen Repeater, um das Funkgerät zu wechseln.",
  REPEATER: "Repeater",
  BASIC_INFO_BOX_PRESSURE_FILTERED_CHARTS_INFO:
    "Druckdaten erzeugt zwischen ${} und ${}",
  BASIC_INFO_BOX_COMPARISON_FILTERED_CHARTS_INFO:
    "Zwischen ${} und ${} erzeugte Vergleichsdaten",
  BASIC_INFO_BOX_COMPARE_DATE_START: "Start am:",
  BASIC_INFO_BOX_COMPARE_DATE_END: "Ende um:",
  BASIC_INFO_BOX_PRESSURE_INFO:
    'Die "Druck"-Werte beziehen sich auf das im Bericht definierte Zeitintervall. Die "Vergleichsdruck"-Werte beziehen sich auf das in diesem Diagramm ausgewählte Zeitintervall. Verwenden Sie die "Vergleichsdruck"-Daten, um die Druckmesswerte für jeden bewässerten Winkel zwischen verschiedenen Zeiträumen zu vergleichen.',
  HISTORIC_IRPD_STREAM_CONSUMPTION: "Estimated consumption",
  HISTORIC_IRPD_STREAM_PRESSURE: "Pressure",
  DEVICE_BOX_SHOW_PERIODIC_STREAMS: "Show periodic streams",
  LOGIN_ERROR_ACCEPT_PRIVACY:
    "To continue accessing, accept the privacy policy",
  EDIT_FARM_BILLING: "Billing",
  FARM_BILLING_FIELD_PHONE: "Telephone",
  FARM_BILLING_FIELD_ADDRESS: "Address",
  FARM_BILLING_FIELD_POSTAL_CODE: "CEP (Only numbers)",
  FARM_BILLING_FIELD_CITY: "City",
  FARM_BILLING_FIELD_STATE: "State",
  FARM_BILLING_FIELD_COUNTRY: "Country",
  ALERT_DIALOG_ALERT_DELETE:
    "Are you sure you want to delete this notification?",
  NOTIFICATIONS_TABPANEL_LABEL_PIVOTS: "Pivots",
  NOTIFICATIONS_TABPANEL_LABEL_MONITOR: "Pivot monitors",
  NOTIFICATIONS_TABPANEL_LABEL_PUMP: "Pumps",
  NOTIFICATIONS_TABPANEL_LABEL_METER: "Level meters",
  SHOW_REASONS: "Show reasons",
  HIDE_REASONS: "Hide reasons",
  EDIT_NOTIFICATIONS: "Edit notifications",
  EDIT_NOTIFICATIONS_QUESTION: "Do you wish to save your changes?",
  EDIT_NOTIFICATIONS_SAVE: "Save",
  CREATE_NOTIFICATIONS: "Create notifications",
  FARM_HAS_NO_NOTIFICATIONS: "You have no notifications for ${}",
  CREATE_NOTIFICATIONS_QUESTION:
    "Do you wish to create this group of notifications?",
  CREATE_NOTIFICATIONS_CREATE: "Create",
  DELETE_NOTIFICATIONS_QUESTION:
    "Do you wish to delete this group of notifications?",
  DELETE_NOTIFICATIONS: "Delete notifications",
  DELETE_NOTIFICATIONS_CANCEL: "Cancel",
  DELETE_NOTIFICATIONS_DELETE: "Delete",
  CREATE_NOTIFICATIONS_ALL_DAY: "All day",
  CREATE_NOTIFICATIONS_BACK: "Back",
  CREATE_NOTIFICATIONS_NEXT: "Next",
  CREATE_NOTIFICATIONS_FINISH: "Finish",
  CREATE_NOTIFICATIONS_BASIC_INFO: "Basic information",
  CREATE_NOTIFICATIONS_TIME_PERIOD: "Time",
  CREATE_NOTIFICATIONS_DEVICES: "Devices",
  CREATE_NOTIFICATIONS_REASONS: "Reasons",
  CREATE_NOTIFICATIONS_CHOOSE_NAME:
    "Choose a name for this group of notifications",
  CREATE_NOTIFICATIONS_CHOOSE_NAME_PLACEHOLDER:
    "Type a name for this group of notifications",
  CREATE_NOTIFICATIONS_PICK_TIME: "Pick a time for this group of notifications",
  CREATE_NOTIFICATIONS_STARTS_ON: "Starts at",
  CREATE_NOTIFICATIONS_ENDS_ON: "Ends at",
  CREATE_NOTIFICATIONS_SELECT_THE_DEVICES:
    "Select the devices for this group of notifications",
  CREATE_NOTIFICATIONS_SELECT_ALL_THE_DEVICES: "Select all the devices",
  CREATE_NOTIFICATIONS_SELECT_REASONS:
    "Select the reasons for this group of notifications",
  CREATE_NOTIFICATIONS_CHOOSE_GENERATION:
    "Choose the pivot generation for this group of notifications",
  HISTORY_OF_MEASUREMENTS: "History of Measurements",
  FARM_BILLING_FIELD_COMPANY_NAME: "Company name",
  FARM_BILLING_FIELD_DOCUMENT: "Document",
  FARM_BILLING_FIELD_DOCUMENT_TYPE: "Document type",
  FARM_BILLING_FIELD_EMAIL: "Email",
  EDIT_BILLING_SAVE_SUCCESS: "Billing information saved",
  EDIT_BILLING_ERROR: "Error while saving billing information",
  FARM_GENERAL_INFORMATION_TITLE: "General Information",
  FARM_BILLING_DESCRIPTION:
    "This information will be used to issue invoices for the use of the Irricontrol Platform.",
  FARM_BILLING_INFORMATION_TITLE: "Billing Information",
  FARM_BILLING_ADDRESS_TITLE: "Billing Address",
  FARM_CONTACT_INFORMATION_TITLE: "Contact Information",
  FARM_CONTACT_ADDRESS_TITLE: "Contact Address",
  FARM_CONTACT_ADDRESS_SWITCH: "Same as billing address",
  FARM_LOCATION_INFORMATION_TITLE: "Location Information",
  CREATE_FARM_STEP_LABEL_GENERAL: "General",
  CREATE_FARM_STEP_LABEL_CONTACT: "Contact",
  CREATE_FARM_STEP_LABEL_BILLING: "Billing",
  CREATE_FARM_STEP_LABEL_LOCATION: "Location",
  CENTRAL_RADIO: "Central Radio",
  CREATE_FARM_QUESTION: "Do you wish to create this farm?",
  CREATE_FARM_CREATE: "Create",
  FARM_CONTACT_FIELD_POSTAL_CODE: "CEP (Only numbers)",
  COMMUNICATION_TYPE: "Communication Type",
  CREATE_DEVICE_MODAL_XBEE: "XBee",
  CREATE_DEVICE_MODAL_4G: "4G",
  CREATE_DEVICE_MODAL_GATEWAY_CODE: "Gateway Number",
  ERROR_16_CARACTERS_GATEWAY_CODE:
    "The Gateway Number field must have up to 16 characters",
  ERROR_16_CARACTERS_RADIO_GATEWAY:
    "The Gateway Radio field must have up to 16 characters",
  COMMUNICATION_TYPE_OPTION_XBEE: "XBee",
  COMMUNICATION_TYPE_OPTION_4G_LTE: "4G/LTE",
  SELECTED_PIVOT_ONLINE: "Pivot with internet",
  SELECTED_PIVOT_OFFLINE: "Pivot without internet",
  CENTRAL_LINKED: "Does the farm have a Central?",
  CENTRAL_LINKED_TOOLTIP:
    "Farms that have a Central are those that use radio communication. Farms that do not have a Central are those that use 4G communication, available only for pivots.",
  FARM_LOCATION: "Farm Location",
  EDIT_PIVOT_GATEWAY_NUMBER_LABEL: "Gateway Number",
  EDIT_GATEWAY: "Edit gateway",
  SELECTED_IRPD_ONLINE: "Pump with internet",
  SELECTED_IRPD_OFFLINE: "Pump without internet",
  GATEWAY_CHANGED_SUCCESS: "Gateway changed successfully",
  GATEWAY_ID_ALREADY_USED: "Gateway already used on another device",
  SAVE_GATEWAY: "Save gateway",
  HISTORIC_DATA_UNDEFINDED: "Unbestimmt",
  HOVER_OVER_SEGMENT:
    "Hover the cursor over the drawing for segment information",
  CREATE_NOTIFICATIONS_CRITICAL: "Critical",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS:
    "Do you want this notification to be a critical alert?",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO:
    "Critical alerts play different and louder sounds than conventional notifications. These notifications are disruptive and should be used when immediate action is required, for example in the event of unexpected downtime.",
  CRITICAL_ALERTS_QUESTION_ENABLE: "Do you want to enable critical alerts?",
  CRITICAL_ALERTS_QUESTION_DISABLE: "Do you want to disable critical alerts?",
  CRITICAL_ALERTS_CANCEL: "Cancel",
  CRITICAL_ALERTS_ENABLE: "Enable",
  CRITICAL_ALERTS_DISABLE: "Disable",
  CRITICAL_ALERTS: "Critical alerts",
  FARM_PAYMENT_METHOD_TITLE: "Payment Method",
  FARM_BILLING_HISTORY_TITLE: "Billing History",
  BILLING_HISTORY_NO_INVOICES_FOUND: "No invoices found",
  BILLING_HISTORY_NO_BILLING_DATA_FOUND: "Billing data not found",
  BILLING_INVOICE_DUE_DATE: "Due Date",
  BILLING_INVOICE_STATUS: "Status",
  BILLING_INVOICE_STATUS_OPEN: "Open",
  BILLING_INVOICE_STATUS_PAID: "Paid",
  BILLING_INVOICE_STATUS_PAST_DUE: "Past due",
  BILLING_INVOICE_STATUS_VOID: "Void",
  BILLING_INVOICE_AMOUNT: "Amount",
  BILLING_INVOICE_LINK: "Link",
  BILLING_INVOICE_LINK_PAY: "Pay",
  BILLING_INVOICE_LINK_DOCS: "Documents",
  HISTORIC_PAINEL_TYPE_POSITION_STREAM: "Aktualisierung des Winkels",
  DEVICE_BOX_SHOW_POSITION: "Position anzeigen",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS:
    "Nutzungsbestimmungen und Datenschutz",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS_UPDATE:
    "Aktualisierung der Nutzungsbedingungen und des Datenschutzes",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT: "Unsere",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT2:
    "zielen darauf ab, Bedingungen für die Nutzung unserer Dienste hinzuzufügen, um die Einhaltung der geltenden Gesetze zu gewährleisten, die Ihren Datenschutz garantieren.",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT:
    "Wir haben unsere aktualisiert",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT2:
    "um neue Bedingungen für die Nutzung unserer Dienste hinzuzufügen und die Einhaltung der geltenden Gesetze zum Schutz Ihrer Daten zu gewährleisten.",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN:
    "Indem Sie fortfahren, erklären Sie sich mit unseren aktualisierten Bedingungen und allen darin beschriebenen Richtlinien einverstanden. Zugang und Überprüfung unter",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK:
    "Nutzungsbestimmungen und Datenschutz",
  POLICY_TERMS_AND_CONDITIONS_CHECKBOX:
    "Ich erkläre, dass ich die Nutzungs- und Datenschutzbedingungen der Irricontrol-Plattform gelesen habe und ihnen zustimme.",
  ACCEPT_TEXT: "ICH AKZEPTIERE",
  PAYMENT_METHOD_WARNNING:
    "Rechnungen in manueller Zahlung. Um automatische Zahlungen auszulösen, fügen Sie eine Kreditkarte zu den Zahlungsoptionen hinzu.",
  PAYMENT_METHOD_ADD: "Zahlungsmethode hinzufügen",
  PAYMENT_METHOD_UPDATE: "Zahlungsmethode aktualisieren",
  PAYMENT_METHOD_CREDIT_CARD_NUMBER: "Kreditkartennummer",
  PAYMENT_METHOD_CREDIT_CARD_EXPIRY: "Gültigkeit",
  PAYMENT_METHOD_CREDIT_NAME: "Name auf der Karte",
  DISPLAY_DATE_FORMAT: "DD.MM.YYYY",
  DEBTOR_NOTIFICANTION_USER:
    "Ihr Betrieb hat überfällige Zahlungen. Bitten Sie Ihren Verwalter, die Zahlungen vorzunehmen.",
  DEBTOR_NOTIFICANTION_ADM:
    "Ihr Betrieb ist mit den Zahlungen im Rückstand. Nehmen Sie die Zahlungen vor",
  DEBTOR_NOTIFICANTION_LINK: "<in Einstellungen>",
  DEBTOR_NOTIFICANTION_COUNT:
    "Die Farm wird in ${} Tagen automatisch gesperrt. Für weitere Informationen kontaktieren Sie bitte: +55(19)99843-7356",
  BILLING_DESCRIPTION_TEXT: `Die Registerkarte "Abrechnung" enthält die wichtigsten Finanzinformationen zu den kommerziellen Transaktionen auf der Plattform.`,
  PAYMENT_METHOD_WARNING:
    "Um automatische Zahlungen zu ermöglichen, fügen Sie eine Kreditkarte hinzu.",
  BILLING_WARNING: "Es gibt überfällige Zahlungen!",
  SUCCESS: "Erfolg",
  SUCCESS_PAGE_TEXT: "Zahlungsmethode erfolgreich aktualisiert.",
  CANCEL_PAGE_TEXT: "Der Zahlweg konnte nicht aktualisiert werden.",
  BILLING: "Abrechnung",
  UNDER_CONSTRUCTION: "Im Aufbau",
  PRESSURE_CHART_COMPARISON_END_TOWER: "Vergleichsdruck - Letzter Turm",
  PRESSURE_CHART_END_TOWER: "Druck - Letzter Turm",
  PRESSURE_CHART_COMPARISON_CENTER: "Vergleichsdruck - Turm Mitte",
  PRESSURE_CHART_CENTER: "Druck - Turm Mitte",
  EDIT_PROFILE_TERMS_AND_CONDITIONS:
    "Weitere Informationen über die Bedingungen der Irricontrol-Plattform finden Sie unter ",
  EDIT_FARM_ERROR_FIELDS:
    "Die Registrierung ist fehlgeschlagen, überprüfen Sie die Felder erneut",
  EDIT_FARM_ERROR: "Der Betrieb konnte nicht registriert werden.",
  NO_INFO: "Keine Kommunikation",
  HARDWARE_ID_ALREADY_EXISTS: "Hardware-ID bereits verwendet",
  ENCODER: "Geber",
  ENCODER_DESCRIPTION_TEXT:
    'LTE/4G-Pivot-Systeme verfügen über Präzisionsgeräte, die im Mittelturm installiert sind und die Winkelposition des Pivots in Echtzeit erfassen. Die Registerkarte "Drehgeber" ist für die Konfiguration und Kalibrierung des Drehgebers vorgesehen und ermöglicht es Ihnen, die 0°-Winkelreferenz des Drehpunkts entsprechend seiner aktuellen Position einzustellen.',
  ENCODER_BTN: "Zurücksetzen des 0°-Winkels",
  ENCODER_CONFIRMATION:
    "Möchten Sie den aktuellen Schwenkwinkel auf 0º zurücksetzen?",
  EDIT_ENCODER_LABEL_SETTING_HISTORIC: "Encoder Reset History",
  REDEFINE: "Ja",
  REDEFINE_ENCONDER_LABEL: "Drehgeber- Rückstellungen",
  EDIT_ENCODER_SAVE_AWAIT_ERROR:
    "Fehler beim Zurücksetzen des Encoders auf Winkel 0º",
  EDIT_ENCODER_SAVE_AWAIT_SUCCESS: "Geber auf Winkel 0º zurückgesetzt",
  ENCODER_RESET_INSTRUCTION_TITLE: "Wie wird der Encoder-Reset verwendet",
  ENCODER_RESET_INSTRUCTION_STEP1: "Schritt 1:",
  ENCODER_RESET_INSTRUCTION_STEP1_TEXT:
    "Positionieren Sie den Drehpunkt auf den gewünschten Anfangsreferenzpunkt. Vergewissern Sie sich, dass sich der in der Mitte des Drehpunkts installierte Encoder in der richtigen Position befindet.",
  ENCODER_RESET_INSTRUCTION_STEP2: "Schritt 2:",
  ENCODER_RESET_INSTRUCTION_STEP2_TEXT:
    'Klicken Sie auf die Schaltfläche "RESET ANGLE 0º"',
  ENCODER_RESET_INSTRUCTION_STEP3: "Schritt 3:",
  ENCODER_RESET_INSTRUCTION_STEP3_TEXT:
    'Es wird ein Bestätigungsbildschirm geöffnet. Bestätigen Sie das Zurücksetzen, indem Sie auf "Ja" klicken.',
  ENCODER_RESET_INSTRUCTION_DESCRIPTION:
    "Es ist wichtig zu beachten, dass dieses Gerät den aktuellen Winkel beibehält, auch wenn das Schwenkpanel ausgeschaltet ist. Verwenden Sie daher den Reset nur bei der Erstinstallation, bei einem Geräteaustausch oder bei einer Aktualisierung der ursprünglichen Referenzkonfiguration.",
  PAYMENT_METHOD_CREDIT_CARD: "Kreditkarte",
  PAYMENT_METHOD_CREDIT_BILL: "Banküberweisung",
  EDIT_BILLING_NOT_FOUND_USER:
    "Keine Rechnungsdaten registriert. Bitten Sie Ihren Administrator, sie zu registrieren.",
  EDIT_BILLING_NOT_FOUND_ADM:
    "Es sind keine Rechnungsdaten registriert. Führen Sie die Registrierung durch",
  PAYMENT_METHOD_DELETE_TEXT:
    "Sind Sie sicher, dass Sie automatische Gebühren entfernen möchten?",
  PAYMENT_METHOD_DELETE_TITLE: "Automatische Abrechnung entfernen",
  PAYMENT_METHOD_DELETE_BTN: "Entfernen",
  PAYMENT_METHOD_UPDATE_BTN: "Aktualisieren",
  BILLING_PREFERRED_LANGUAGE: "Bevorzugte Sprache für E-Mails",
  BILLING_PREFERRED_LANGUAGE_TOOLTIP:
    'Die "Bevorzugte Sprache für E-Mails" legt fest, in welcher Sprache Sie E-Mail-Mitteilungen über Zahlungen und Rechnungsdetails auf der Irricontrol-Plattform erhalten. Die Auswahl dieser Sprache ändert nicht die Sprache der Schnittstelle auf der Plattform.',
  FARM_PAYMENT_METHOD_DESCRIPTION:
    'Fügen Sie eine "Kreditkarte" hinzu, um die automatische Zahlung von Rechnungen zu ermöglichen. Diese Option stellt sicher, dass Ihre Rechnungen zum richtigen Zeitpunkt bezahlt werden, ohne dass bei jedem Abrechnungszyklus manuell eingegriffen werden muss, und vermeidet jegliche Art von Blockaden auf der Plattform.',
  REPORT_EMAIL_NOTIFICATION:
    "Der Bericht wird innerhalb von ${} Min. per E-Mail verschickt.",
  REPORT_PROCESSING_NOTIFICATION: "Bericht in Bearbeitung. Schätzung: ${} min.",
  REPORT_OPERATION: "Vorgang melden",
  REPORT_FARM: "Farm melden",
  REPEATER_FIELD_HEIGHT: "Höhe",
  BATTERY_LEVEL_LOW: "Akku schwach",
  BATTERY_LEVEL_CHARGED: "Akkuladestand hoch",
  PERIODIC_WARNING: "Periodisch",
  GH_LEVEL_TITLE: "Reservoirinformationen",
  GH_LEVEL_DESCRIPTION:
    "Erforderliche Werte, damit das Niveau-Messgerät das Reservoir überwachen und steuern kann",
  GH_MAX_LINE_TOOLTIP:
    "Gewünschter maximaler Prozentsatz des maximalen Niveaus im Reservoir. Sobald dieser Wert erreicht ist, schaltet das Niveau-Messgerät die zugehörigen Pumpen ab.",
  GH_MIN_LINE_TOOLTIP:
    "Gewünschter minimaler Prozentsatz des maximalen Niveaus im Reservoir, als visuelle Referenz der Messungen",
  GH_RESERVOIR_TOOLTIP: "Maximales Wasserniveau, das das Reservoir tragen kann",
  GH_OFFSET_TOOLTIP:
    "Anpassungswert für Niveaumessungen, falls die Sonde den Boden des Reservoirs nicht erreicht",
  ERROR_GH_MIN_LINE: "Muss kleiner als der Maximalwert und mindestens 0 sein",
  ERROR_GH_MAX_LINE: "Muss ungleich Null und größer als der Mindestwert sein",
  GH_MODAL_BATTERY_TITLE: "Warnung! Niedriger Batteriestand",
  GH_MODAL_BATTERY_DESC_1: "Der Batteriestand des Geräts ",
  GH_MODAL_BATTERY_DESC_2: " ist niedrig",
  GH_MODAL_BATTERY_ACTION: "Zum Gerät gehen",
  ROUNDS_LIMIT_TITLE: "Grenzwert für Drehungen“, „Pivot Turn Limit",
  ROUNDS_LIMIT_DESCRIPTION:
    "Maximal zulässige Anzahl von Drehungen vor der automatischen Abschaltung",
  LINK_METER_SELECT_LABEL: "Verbundene Füllstandsanzeiger",
};
export default i18n_deAt;
