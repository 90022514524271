import React, { useState, useEffect, useCallback } from "react";
import "./CreateDeviceModal.scss";
import "../SelectedFarm/components/DeviceBox/DeviceBox.scss";
import {
  makeStyles,
  TextField,
  CircularProgress,
  InputLabel,
  Icon,
  Tooltip,
} from "@material-ui/core";
import useFarm from "../../../../hooks/models/useFarm";
import {
  coreHTTPClient,
  getAuthorization,
} from "../../../../services/webclient";
import useNotify from "../../../../hooks/tools/useNotify";
import FormInput from "../../../../components/Form/FormInput/FormInput";
import FormSelect from "../../../../components/Form/FormSelect/FormSelect";
import { isMobile } from "../../../../mobileConfig";
import pivotAutomationDrawLogo from "../../../../assets/images/automacao.svg";
import monitorPivotDrawLogo from "../../../../assets/images/pivot_monitor_icon.svg";
import irpdAutomationDrawLogo from "../../../../assets/images/i_imanage.svg";
import RepeaterDrawLogo from "../../../../assets/images/repeater.svg";
import { useDispatch } from "react-redux";
import { hideModal } from "../../../../redux/modal/actions";
import { i18n } from "../../../../i18n/i18nText";
import FormCheckBox from "../../../../components/Form/FormCheckBox/FormCheckBox";
import QrReader from "modern-react-qr-reader";
import { ValidationErrorMessage } from "../../../../hooks/tools/useFormValidation";
import {
  checkIsOnIntervalNumeric,
  checkLatCoordinates,
  checkLngCoordinates,
  formatFloat,
  formatPositiveFloat,
} from "../../../../utils/checks";
import { PIVOT_COMMUNICATION_TYPE } from "../../../../redux/pivots/types";
import axios from "axios";
import { validadeFloatInput } from "../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";

const {
  REACT_APP_ALLOW_PUMP_5_2_CREATION,
  REACT_APP_ALLOW_METER_5_3_CREATION,
} = process.env;

export const useStyles = makeStyles({
  select: {
    width: "100%",
  },
});

interface Props {
  farmID: number;
}

let globalResolve: (data: string) => void;

let globalReject: (data: string) => void;

const CreateDeviceModal = (props: Props) => {
  const [, farm] = useFarm(props.farmID);

  const [showQrReader, setShowQrReader] = useState(false);

  const [showChooser, setShowChooser] = useState(true);

  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    bauerDeviceName: undefined,
    bauerHardwareId: undefined,
    deviceName: undefined,
    otherBrand: undefined,
    devicePotency: undefined,
    convertedPotency: undefined,
    devicePerformance: undefined,
    pivotData: undefined,
    pivotData4g: undefined,
    monitorPivotData: undefined,
    linearMonitorPivotData: undefined,
    gpsData: undefined,
    pumpData: undefined,
    repeaterData: undefined,
    deviceHeight: undefined,
    meterData: undefined,
    deviceLat: undefined,
    deviceLong: undefined,
    flowRate: undefined,
    pivotLength: undefined,
    pivotSpeed: undefined,
  });

  const [deviceName, setDeviceName] = useState("");

  const setValidDeviceName = useCallback(
    (value) => {
      setDeviceName(value);
      if (value.length === 0) {
        setValidationErrors({
          ...validationErrors,
          deviceName: "ERROR_EMPTY_FIELD",
        });
      } else if (value.length > 16) {
        setValidationErrors({
          ...validationErrors,
          deviceName: "ERROR_MAX_16_CHARACTERS",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          deviceName: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [bauerDeviceName, setBauerDeviceName] = useState("");
  const setValidBauerDeviceName = useCallback(
    (value) => {
      setBauerDeviceName(value);
      if (value.length === 0) {
        setValidationErrors({
          ...validationErrors,
          bauerDeviceName: "ERROR_EMPTY_FIELD",
        });
      } else if (value.length > 32) {
        setValidationErrors({
          ...validationErrors,
          bauerDeviceName: "ERROR_MAX_32_CHARACTERS",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          bauerDeviceName: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [bauerHardwareId, setBauerHardwareId] = useState("");

  const [deviceHeight, setDeviceHeight] = useState("");
  const setValidDeviceHeight = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) {
        setDeviceHeight(value);
        setValidationErrors({
          ...validationErrors,
          deviceHeight: undefined,
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          deviceHeight: "ERROR_INVALID_CHARACTERS",
        });
      }
    },
    [validationErrors]
  );

  const [otherBrand, setOtherBrand] = useState("");

  const setValidOtherBrand = useCallback(
    (value) => {
      setOtherBrand(value);
      if (value.length < 3) {
        setValidationErrors({
          ...validationErrors,
          otherBrand: "ERROR_INVALID_AMOUNT_MIN",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          otherBrand: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [flowRate, setFlowRate] = useState("");

  const setValidFlowRate = useCallback(
    (value) => {
      if (value.length === 0 || parseFloat(value) === 0) {
        setFlowRate(value);
        setValidationErrors({
          ...validationErrors,
          flowRate: "ERROR_EMPTY_FIELD",
        });
      } else if (parseFloat(value) < 0) {
        setFlowRate("0");
        setValidationErrors({
          ...validationErrors,
          flowRate: "ERROR_NEGATIVE_VALUES",
        });
      } else {
        setFlowRate(value);
        setValidationErrors({
          ...validationErrors,
          flowRate: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [pivotLength, setPivotLength] = useState("");

  const setValidPivotLength = useCallback(
    (value) => {
      if (value.length === 0 || parseFloat(value) === 0) {
        setPivotLength(value);
        setValidationErrors({
          ...validationErrors,
          pivotLength: "ERROR_EMPTY_FIELD",
        });
      } else if (parseFloat(value) < 0) {
        setPivotLength("0");
        setValidationErrors({
          ...validationErrors,
          pivotLength: "ERROR_NEGATIVE_VALUES",
        });
      } else {
        setPivotLength(value);
        setValidationErrors({
          ...validationErrors,
          pivotLength: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [pivotSpeed, setPivotSpeed] = useState("");

  const setValidPivotSpeed = useCallback(
    (value) => {
      if (value.length === 0 || parseFloat(value) === 0) {
        setPivotSpeed(value);
        setValidationErrors({
          ...validationErrors,
          pivotSpeed: "ERROR_EMPTY_FIELD",
        });
      } else if (parseFloat(value) < 0) {
        setPivotSpeed("0");
        setValidationErrors({
          ...validationErrors,
          pivotSpeed: "ERROR_NEGATIVE_VALUES",
        });
      } else {
        setPivotSpeed(value);
        setValidationErrors({
          ...validationErrors,
          pivotSpeed: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [devicePotency, setDevicePotency] = useState("");

  const setValidDevicePotency = useCallback(
    (value) => {
      if (value.length === 0) {
        setDevicePotency(value);
        setValidationErrors({
          ...validationErrors,
          devicePotency: "ERROR_EMPTY_FIELD",
        });
      } else if (parseFloat(value) < 0) {
        setDevicePotency("0");
        setValidationErrors({
          ...validationErrors,
          devicePotency: "ERROR_NEGATIVE_VALUES",
        });
      } else {
        setDevicePotency(value);
        setValidationErrors({
          ...validationErrors,
          devicePotency: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [convertedPotency, setConvertedPotency] = useState("");

  const setValidConvertedPotency = useCallback(
    (value) => {
      if (value.length === 0 || parseFloat(value) === 0) {
        setConvertedPotency(value);
        setValidationErrors({
          ...validationErrors,
          convertedPotency: "ERROR_EMPTY_FIELD",
        });
      } else if (parseFloat(value) < 0) {
        setConvertedPotency("0");
        setValidationErrors({
          ...validationErrors,
          convertedPotency: "ERROR_NEGATIVE_VALUES",
        });
      } else {
        setConvertedPotency(value);
        setValidationErrors({
          ...validationErrors,
          convertedPotency: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [devicePotencyHP, setDevicePotencyHP] = useState("");

  const setValidDevicePotencyHP = useCallback(
    (value) => {
      if (value.length === 0 || parseFloat(value) === 0) {
        setDevicePotencyHP(value);
        setValidationErrors({
          ...validationErrors,
          devicePotencyHP: "ERROR_EMPTY_FIELD",
        });
      } else if (parseFloat(value) < 0) {
        setDevicePotencyHP("0");
        setValidationErrors({
          ...validationErrors,
          devicePotencyHP: "ERROR_NEGATIVE_VALUES",
        });
      } else {
        setDevicePotencyHP(value);
        setValidationErrors({
          ...validationErrors,
          devicePotencyHP: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [potencyUnit, setPotencyUnit] = useState<"kW" | "hp">("hp");

  const [devicePerformance, setDevicePerformance] = useState("");

  const setValidDevicePerformance = useCallback(
    (value) => {
      if (value.length === 0 || parseFloat(value) === 0) {
        setDevicePerformance(value);
        setValidationErrors({
          ...validationErrors,
          devicePerformance: "ERROR_EMPTY_FIELD",
        });
      } else if (parseFloat(value) < 0) {
        setDevicePerformance("0");
        setValidationErrors({
          ...validationErrors,
          devicePerformance: "ERROR_NEGATIVE_VALUES",
        });
      } else {
        setDevicePerformance(value);
        setValidationErrors({
          ...validationErrors,
          devicePerformance: checkIsOnIntervalNumeric(value, 0, 100),
        });
      }
    },
    [validationErrors]
  );

  const hasInvalidRadioCharacters = (value) => {
    const regex = RegExp(/[0-9A-Fa-f]{16}/g);
    return !regex.test(value);
  };

  const hasValidSpotiCharacters = (value) => {
    const regex = RegExp(/^(?!([0-9A-Fa-f]{16})$|^$).*/g);
    return regex.test(value);
  };

  // equipament data validations
  const [pivotData, setPivotData] = useState("");

  const setValidPivotData = useCallback(
    (value) => {
      setPivotData(value.toUpperCase());

      if (value.length !== 16 || hasInvalidRadioCharacters(value)) {
        if (value.length !== 16) {
          if (
            validationErrors.pivotData !== "ERROR_16_CARACTERS_RADIO_CONTROLER"
          ) {
            notify("ERROR_16_CARACTERS_RADIO_CONTROLER", "error");
            setValidationErrors({
              ...validationErrors,
              pivotData: "ERROR_16_CARACTERS_RADIO_CONTROLER",
            });
          }
        } else if (hasInvalidRadioCharacters(value)) {
          if (validationErrors.pivotData !== "ERROR_INVALID_CHARACTERS") {
            notify("ERROR_INVALID_CHARACTERS", "error");
            setValidationErrors({
              ...validationErrors,
              pivotData: "ERROR_INVALID_CHARACTERS",
            });
          }
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          pivotData: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [pivotData4g, setPivotData4g] = useState("");

  const setValidPivotData4g = useCallback(
    (value) => {
      setPivotData4g(value.toUpperCase());

      if (value.length !== 16 || hasInvalidRadioCharacters(value)) {
        if (value.length !== 16) {
          if (
            validationErrors.pivotData4g !== "ERROR_16_CARACTERS_GATEWAY_CODE"
          ) {
            notify("ERROR_16_CARACTERS_GATEWAY_CODE", "error");
            setValidationErrors({
              ...validationErrors,
              pivotData4g: "ERROR_16_CARACTERS_GATEWAY_CODE",
            });
          }
        } else if (hasInvalidRadioCharacters(value)) {
          if (validationErrors.pivotData4g !== "ERROR_INVALID_CHARACTERS") {
            notify("ERROR_INVALID_CHARACTERS", "error");
            setValidationErrors({
              ...validationErrors,
              pivotData4g: "ERROR_INVALID_CHARACTERS",
            });
          }
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          pivotData4g: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [monitorPivotData, setMonitorPivotData] = useState("");

  const setValidMonitorPivotData = useCallback(
    (value) => {
      setMonitorPivotData(value.toUpperCase());
      if (value.length !== 16 || hasInvalidRadioCharacters(value)) {
        if (value.length !== 16) {
          if (
            validationErrors.monitorPivotData !==
            "ERROR_16_CARACTERS_RADIO_MONITOR"
          ) {
            notify("ERROR_16_CARACTERS_RADIO_MONITOR", "error");
            setValidationErrors({
              ...validationErrors,
              monitorPivotData: "ERROR_16_CARACTERS_RADIO_MONITOR",
            });
          }
        } else if (hasInvalidRadioCharacters(value)) {
          if (
            validationErrors.monitorPivotData !== "ERROR_INVALID_CHARACTERS"
          ) {
            notify("ERROR_INVALID_CHARACTERS", "error");
            setValidationErrors({
              ...validationErrors,
              monitorPivotData: "ERROR_INVALID_CHARACTERS",
            });
          }
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          monitorPivotData: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [linearMonitorPivotData, setLinearMonitorPivotData] = useState("");

  const setValidLinearMonitorPivotData = useCallback(
    (value) => {
      setLinearMonitorPivotData(value.toUpperCase());
      if (value.length !== 16 || hasInvalidRadioCharacters(value)) {
        if (value.length !== 16) {
          if (
            validationErrors.linearMonitorPivotData !==
            "ERROR_16_CARACTERS_RADIO_MONITOR"
          ) {
            notify("ERROR_16_CARACTERS_RADIO_MONITOR", "error");
            setValidationErrors({
              ...validationErrors,
              linearMonitorPivotData: "ERROR_16_CARACTERS_RADIO_MONITOR",
            });
          }
        } else if (hasInvalidRadioCharacters(value)) {
          if (
            validationErrors.linearMonitorPivotData !==
            "ERROR_INVALID_CHARACTERS"
          ) {
            notify("ERROR_INVALID_CHARACTERS", "error");
            setValidationErrors({
              ...validationErrors,
              linearMonitorPivotData: "ERROR_INVALID_CHARACTERS",
            });
          }
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          linearMonitorPivotData: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [gpsData, setGPSData] = useState("");

  const setValidGpsData = useCallback(
    (value) => {
      setGPSData(value.toUpperCase());
      if (value.length !== 16 || hasInvalidRadioCharacters(value)) {
        if (value.length !== 16) {
          if (validationErrors.gpsData !== "ERROR_16_CARACTERS_RADIO_GPS") {
            notify("ERROR_16_CARACTERS_RADIO_GPS", "error");
            setValidationErrors({
              ...validationErrors,
              gpsData: "ERROR_16_CARACTERS_RADIO_GPS",
            });
          }
        } else if (hasInvalidRadioCharacters(value)) {
          if (validationErrors.gpsData !== "ERROR_INVALID_CHARACTERS") {
            notify("ERROR_INVALID_CHARACTERS", "error");
            setValidationErrors({
              ...validationErrors,
              gpsData: "ERROR_INVALID_CHARACTERS",
            });
          }
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          gpsData: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [pumpData, setPUMPData] = useState("");

  const setValidPumpData = useCallback(
    (value) => {
      setPUMPData(value.toUpperCase());
      if (
        (value.length !== 0 && value.length !== 16) ||
        hasValidSpotiCharacters(value)
      ) {
        if (value.length !== 0 && value.length !== 16) {
          if (validationErrors.pumpData !== "ERROR_16_CARACTERS_RADIO_PUMP") {
            notify("ERROR_16_CARACTERS_RADIO_PUMP", "error");
            setValidationErrors({
              ...validationErrors,
              pumpData: "ERROR_16_CARACTERS_RADIO_PUMP",
            });
          }
        } else if (value.length !== 0 && hasValidSpotiCharacters(value)) {
          if (validationErrors.pumpData !== "ERROR_INVALID_CHARACTERS") {
            notify("ERROR_INVALID_CHARACTERS", "error");
            setValidationErrors({
              ...validationErrors,
              pumpData: "ERROR_INVALID_CHARACTERS",
            });
          }
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          pumpData: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [repeaterData, setRepeaterData] = useState("");

  const setValidRepeaterData = useCallback(
    (value) => {
      setRepeaterData(value.toUpperCase());
      if (value.length !== 16 || hasInvalidRadioCharacters(value)) {
        if (value.length !== 16) {
          if (
            validationErrors.repeaterData !==
            "ERROR_16_CARACTERS_RADIO_REPEATER"
          ) {
            notify("ERROR_16_CARACTERS_RADIO_REPEATER", "error");
            setValidationErrors({
              ...validationErrors,
              repeaterData: "ERROR_16_CARACTERS_RADIO_REPEATER",
            });
          }
        } else if (hasInvalidRadioCharacters(value)) {
          if (validationErrors.repeaterData !== "ERROR_INVALID_CHARACTERS") {
            notify("ERROR_INVALID_CHARACTERS", "error");
            setValidationErrors({
              ...validationErrors,
              repeaterData: "ERROR_INVALID_CHARACTERS",
            });
          }
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          repeaterData: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [meterData, setMeterData] = useState("");

  const setValidMeterData = useCallback(
    (value) => {
      setMeterData(value.toUpperCase());
      if (value.length !== 16 || hasInvalidRadioCharacters(value)) {
        if (value.length !== 16) {
          if (
            validationErrors.meterData !== "ERROR_16_CARACTERS_RADIO_IMETER"
          ) {
            notify("ERROR_16_CARACTERS_RADIO_IMETER", "error");
            setValidationErrors({
              ...validationErrors,
              meterData: "ERROR_16_CARACTERS_RADIO_IMETER",
            });
          }
        } else if (hasInvalidRadioCharacters(value)) {
          if (validationErrors.meterData !== "ERROR_INVALID_CHARACTERS") {
            notify("ERROR_INVALID_CHARACTERS", "error");
            setValidationErrors({
              ...validationErrors,
              meterData: "ERROR_INVALID_CHARACTERS",
            });
          }
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          meterData: undefined,
        });
      }
    },
    [validationErrors]
  );

  // protocols
  const [protocolSelectValue] = useState<"4.1" | "5.0">("5.0");

  const [energyType, setEnergyType] = useState<"Solar" | "Bivolt">("Solar");

  const [panelType, setPanelType] = useState<"1" | "2">("1");

  const [communicationType, setCommunicationType] = useState<"XBEE" | "4G">(
    farm.base?.radio_id ? "XBEE" : "4G"
  );

  const [protocolIRPDSelectValue, setProtocolIRPDSelectValue] = useState<
    "5.0" | "5.1" | "5.2"
  >("5.1");

  const [protocolMeterSelectValue, setProtocolMeterSelectValue] = useState<
    "5.2" | "5.3"
  >("5.2");

  const [brandModel, setBrandModel] = useState<
    | "Bauer"
    | "Carborundum"
    | "Irrigabras"
    | "Fockink"
    | "Krebs"
    | "Lindsay"
    | "Reinke"
    | "Valley"
    | "Other"
  >("Bauer");

  const [sensor, setSensor] = useState<string>("4");

  const irpdProtocolOptions: [string, string][] =
    REACT_APP_ALLOW_PUMP_5_2_CREATION === "true"
      ? [
          ["5.0", "CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5"],
          ["5.1", "CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_1"],
          ["5.2", "CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_2"],
        ]
      : [
          ["5.0", "CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5"],
          ["5.1", "CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_1"],
        ];

  const meterProtocolOptions: [string, string][] =
    REACT_APP_ALLOW_METER_5_3_CREATION === "true"
      ? [
          ["5.2", "CREATE_DEVICE_MODAL_METER_PROTOCOL_5_2"],
          ["5.3", "CREATE_DEVICE_MODAL_METER_PROTOCOL_5_3"],
        ]
      : [["5.2", "CREATE_DEVICE_MODAL_METER_PROTOCOL_5_2"]];

  //List of sensors defined in the backend so that you are always up to date with the list of available sensors, thus avoiding errors in registering and editing meters

  const [sensorOptions, setSensorOptions] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      coreHTTPClient.get(`v3/sensors/?available=true`).then((res) => {
        let sensors: any[] = [];
        res.data.forEach((sensor: { id: number; sensor: { name: string } }) =>
          sensors.push([sensor.id, sensor.sensor.name])
        );
        setSensorOptions(sensors);
      });
    })();
  }, []);

  const [havePluviometer, setHavePluviometer] = useState(false);

  const [localActuation] = useState(false);

  const [deviceSelectValue, setDeviceSelectValue] = useState<
    | "PIVOT"
    | "BAUER-PIVOT"
    | "MONITOR-PIVOT"
    | "LINEAR-MONITOR-PIVOT"
    | "REPEATER"
    | "METER"
    | "IRPD"
  >("PIVOT");

  const notify = useNotify();

  const dispatch = useDispatch();

  const defaultPosition = String(farm.location).split(",", 2);

  const [defaultLat, setDefaultLat] = useState(defaultPosition[0]);

  const [defaultLong, setDefaultLong] = useState(defaultPosition[1]);

  const [deviceLat, setDeviceLat] = useState("0");

  const setValidDeviceLat = useCallback(
    (value) => {
      setDeviceLat(formatFloat(value));
      if (
        !checkLatCoordinates(value) ||
        value.length < 4 ||
        value.length > 16
      ) {
        setValidationErrors({
          ...validationErrors,
          deviceLat: "ERROR_PIVOT_LAT",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          deviceLat: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [deviceLong, setDeviceLong] = useState("0");

  const setValidDeviceLong = useCallback(
    (value) => {
      setDeviceLong(formatFloat(value));
      if (
        !checkLngCoordinates(value) ||
        value.length < 4 ||
        value.length > 16
      ) {
        setValidationErrors({
          ...validationErrors,
          deviceLong: "ERROR_PIVOT_LONG",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          deviceLong: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [creatingDevice, setCreatingDevice] = useState(false);

  const [mouseOnPivot, setMouseOnPivot] = useState(false);

  const [mouseOnBauerPivot, setMouseOnBauerPivot] = useState(false);

  const [mouseOnMonitor, setMouseOnMonitor] = useState(false);

  const [mouseOnLinearMonitor, setMouseOnLinearMonitor] = useState(false);

  const [mouseOnRepeater, setMouseOnRepeater] = useState(false);

  const [mouseOnIRPD, setMouseOnIRPD] = useState(false);

  const [mouseOnMeter, setMouseOnMeter] = useState(false);

  async function showQrReaderWithMode(
    mode:
      | "PIVOT"
      | "MONITOR-PIVOT"
      | "LINEAR-MONITOR-PIVOT"
      | "GPS"
      | "REPEATER"
      | "METER"
      | "IRPD"
  ) {
    setShowQrReader(true);

    const data = await new Promise<string>((resolve) => {
      globalResolve = resolve;
    });

    window.alert(`${i18n("CREATE_DEVICE_MODAL_CODE_READ_SUCCESS")}\n${data}`);

    setShowQrReader(false);

    if (mode == "PIVOT") {
      communicationType === "XBEE"
        ? setValidPivotData(data.trim())
        : setValidPivotData4g(data.trim());
    } else if (mode == "MONITOR-PIVOT") {
      setValidMonitorPivotData(data.trim());
    } else if (mode == "LINEAR-MONITOR-PIVOT") {
      setValidLinearMonitorPivotData(data.trim());
    } else if (mode == "GPS") {
      setValidGpsData(data.trim());
    } else if (mode == "REPEATER") {
      setValidRepeaterData(data.trim());
    } else if (mode == "METER") {
      setValidMeterData(data.trim());
    } else if (mode == "IRPD") {
      setValidPumpData(data.trim());
    }
  }

  function checkCanCreateDevice() {
    if (deviceSelectValue == "PIVOT") {
      if (potencyUnit === "kW") {
        if (
          !!!validationErrors["pivotData"] &&
          !!!validationErrors["pivotData4g"] &&
          !!!validationErrors["gpsData"] &&
          !!!validationErrors["pumpData"] &&
          deviceName.length > 0 &&
          deviceName.length <= 17 &&
          parseFloat(devicePotency) > 0
        ) {
          return true;
        }
      } else {
        if (
          !!!validationErrors["pivotData"] &&
          !!!validationErrors["pivotData4g"] &&
          !!!validationErrors["gpsData"] &&
          !!!validationErrors["pumpData"] &&
          deviceName.length > 0 &&
          deviceName.length <= 17 &&
          parseFloat(devicePotencyHP) > 0 &&
          parseFloat(devicePerformance) > 0 &&
          parseFloat(convertedPotency) > 0
        ) {
          return true;
        }
      }
    }

    if (deviceSelectValue == "BAUER-PIVOT") {
      if (bauerDeviceName.length > 3 && bauerHardwareId.length > 3) {
        return true;
      }
    }

    if (deviceSelectValue == "MONITOR-PIVOT") {
      if (potencyUnit === "kW") {
        if (
          !!!validationErrors["monitorPivotData"] &&
          !!!validationErrors["pumpData"] &&
          deviceName.length > 0 &&
          deviceName.length <= 17 &&
          parseFloat(devicePotency) > 0
        ) {
          return true;
        }
      } else {
        if (
          !!!validationErrors["monitorPivotData"] &&
          !!!validationErrors["pumpData"] &&
          deviceName.length > 0 &&
          deviceName.length <= 17 &&
          parseFloat(devicePotencyHP) > 0 &&
          parseFloat(devicePerformance) > 0 &&
          parseFloat(convertedPotency) > 0
        ) {
          return true;
        }
      }
    }

    if (deviceSelectValue == "LINEAR-MONITOR-PIVOT") {
      if (
        !!!validationErrors["linearMonitorPivotData"] &&
        !!!validationErrors["pumpData"] &&
        deviceName.length > 0 &&
        deviceName.length <= 17 &&
        flowRate.length > 0 &&
        pivotSpeed.length > 0 &&
        pivotLength.length > 0 &&
        !!!validationErrors["flowRate"] &&
        !!!validationErrors["pivotSpeed"] &&
        !!!validationErrors["pivotLength"]
      ) {
        return true;
      }
    }

    if (deviceSelectValue == "REPEATER") {
      if (
        !!!validationErrors["repeaterData"] &&
        !!!validationErrors["deviceHeight"] &&
        deviceLat.length >= 4 &&
        deviceLat.length <= 16 &&
        deviceLong.length >= 4 &&
        deviceLong.length <= 16 &&
        deviceName.length > 1 &&
        deviceName.length <= 16 &&
        repeaterData.length == 16
      ) {
        return true;
      }
    }

    if (deviceSelectValue == "IRPD") {
      if (potencyUnit === "kW") {
        if (
          String(deviceLat).length >= 4 &&
          String(deviceLat).length <= 16 &&
          String(deviceLong).length >= 4 &&
          String(deviceLong).length <= 16 &&
          deviceName.length > 1 &&
          deviceName.length <= 17 &&
          !!!validationErrors["pumpData"] &&
          pumpData.length == 16 &&
          parseFloat(devicePotency) > 0
        ) {
          return true;
        }
      } else {
        if (
          String(deviceLat).length >= 4 &&
          String(deviceLat).length <= 16 &&
          String(deviceLong).length >= 4 &&
          String(deviceLong).length <= 16 &&
          deviceName.length > 1 &&
          deviceName.length <= 17 &&
          !!!validationErrors["pumpData"] &&
          pumpData.length == 16 &&
          parseFloat(devicePotencyHP) > 0 &&
          parseFloat(devicePerformance) > 0 &&
          parseFloat(convertedPotency) > 0
        ) {
          return true;
        }
      }
    }

    if (deviceSelectValue == "METER") {
      if (
        !!!validationErrors["meterData"] &&
        deviceLat?.length >= 4 &&
        deviceLat?.length <= 16 &&
        deviceLong?.length >= 4 &&
        deviceLong?.length <= 16 &&
        deviceName?.length > 1 &&
        deviceName?.length <= 20 &&
        meterData?.length == 16
      ) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    const cvHpRatio = 0.7355;

    const hpToCv = (devicePotencyHP, devicePerformance) =>
      ((devicePotencyHP * cvHpRatio * 100) / devicePerformance).toFixed(2);

    const convertedHPPotency = hpToCv(devicePotencyHP, devicePerformance);

    setValidDevicePotency(convertedHPPotency);

    setValidConvertedPotency(convertedHPPotency);
  }, [devicePotencyHP, devicePerformance]);

  useEffect(() => {
    setDefaultLat(defaultPosition[0]);

    setDefaultLong(defaultPosition[1]);
  }, [defaultPosition]);

  useEffect(() => {
    setDeviceLat(defaultLat);

    setDeviceLong(defaultLong);
  }, [defaultLat, defaultLong]);

  function validateDeviceCreation(responseDeviceCreated) {
    if (responseDeviceCreated?.data?.error) {
      if (responseDeviceCreated.data.error == "BASE_NOT_FROM_FARM") {
        notify("CREATE_DEVICE_MODAL_ERROR_NOT_VALID", "error");
      } else if (responseDeviceCreated.data.error == "CONTROL_NOT_FOUND") {
        notify("CREATE_DEVICE_MODAL_ERROR_CONTROL_NOT_VALID", "error");
      } else if (responseDeviceCreated.data.error == "FARM_BASE_EMPTY") {
        notify("CREATE_DEVICE_MODAL_ERROR_BASE_NOT_VALID", "error");
      } else if (responseDeviceCreated.data.error == "CONTROL_ALREADY_USED") {
        notify("CREATE_DEVICE_MODAL_ERROR_CONTROL_ALREADY_USED", "error");
      } else if (responseDeviceCreated.data.error == "MONITOR_NOT_FOUND") {
        notify("CREATE_DEVICE_MODAL_ERROR_GPS_NOT_VALID", "error");
      } else if (responseDeviceCreated.data.error == "MONITOR_ALREADY_USED") {
        notify("CREATE_DEVICE_MODAL_ERROR_GPS_ALREADY_USED", "error");
      } else if (responseDeviceCreated.data.error == "PUMP_NOT_FOUND") {
        notify("CREATE_DEVICE_MODAL_ERROR_PUMP_NOT_VALID", "error");
      } else if (responseDeviceCreated.data.error == "PUMP_ALREADY_USED") {
        notify("CREATE_DEVICE_MODAL_ERROR_PUMP_ALREADY_USED", "error");
      } else if (responseDeviceCreated.data.error == "FARM_BASE_EMPTY") {
        notify("CREATE_DEVICE_MODAL_ERROR_FARM_BASE_EMPTY", "error");
      } else if (responseDeviceCreated.data.error == "REPEATER_NOT_FOUND") {
        notify("CREATE_DEVICE_MODAL_ERROR_REPEATER_NOT_VALID", "error");
      } else if (responseDeviceCreated.data.error == "REPEATER_ALREADY_USED") {
        notify("CREATE_DEVICE_MODAL_ERROR_REPEATER_ALREADY_USED", "error");
      } else if (responseDeviceCreated.data.error == "IMETER_NOT_FOUND") {
        notify("CREATE_DEVICE_MODAL_ERROR_IMETER_NOT_FOUND", "error");
      } else if (responseDeviceCreated.data.error == "IMETER_ALREADY_USED") {
        notify("CREATE_DEVICE_MODAL_ERROR_IMETER_ALREADY_USED", "error");
      } else if (responseDeviceCreated.data.error == "IRPD_NOT_FOUND") {
        notify("CREATE_DEVICE_MODAL_ERROR_IRPD_NOT_FOUND", "error");
      } else if (responseDeviceCreated.data.error == "IRPD_ALREADY_USED") {
        notify("CREATE_DEVICE_MODAL_ERROR_IRPD_ALREADY_USED", "error");
      }
    } else {
      notify("CREATE_DEVICE_MODAL_SUCCESS", "success");
      dispatch(hideModal());
      window.location.reload();
    }
  }

  async function createDevice() {
    setCreatingDevice(true);

    let pivotToBeCreated: {
      name: string;
      potency: number;
      monitor: string;
      automation_type: number;
      panel_type: number;
      brand_model: string;
      pluviometer: boolean;
      control: string;
      base: string;
      communication_type: number;
      pump: string;
      protocol: string;
    };

    let monitorPivotToBeCreated: {
      name: string;
      potency: number;
      monitor: string;
      automation_type: number;
      base: string;
      brand_model: string;
      pump: string;
      protocol: string;
    };

    let linearMonitorPivotToBeCreated: {
      name: string;
      monitor: string;
      automation_type: number;
      brand_model: string;
      protocol: string;
      base: string;
      flowRate: number;
      pivotLength: number;
      pivotSpeed: number;
    };

    let repeaterToBeCreated: {
      name: string;
      height: string;
      base: any;
      position: string;
      repeater: string;
      energy_type: string;
    };

    let irpdToBeCreated: {
      name: string;
      base: string;
      pump: string;
      potency: number;
      flow: string;
      position: string;
      protocol: number;
    };

    let meterToBeCreated: {
      name: string;
      position: string;
      imeter_device: string;
      base: string;
      local_actuation: boolean;
      protocol: string;
      function: string;
      sensor_id: number;
    };

    try {
      const stringPosition = `${deviceLat.replace(
        ",",
        "."
      )},${deviceLong?.replace(",", ".")}`;

      let responseDeviceCreated;
      switch (deviceSelectValue) {
        case "PIVOT":
          pivotToBeCreated = {
            name: deviceName,
            potency: parseFloat(devicePotency),
            communication_type:
              communicationType === "XBEE"
                ? PIVOT_COMMUNICATION_TYPE.TYPE_XBEE
                : PIVOT_COMMUNICATION_TYPE.TYPE_4G,
            base: communicationType === "XBEE" ? farm.base.radio_id : null,
            control: communicationType === "XBEE" ? pivotData : pivotData4g,
            monitor: gpsData,
            automation_type: 0,
            panel_type: Number(panelType),
            brand_model: brandModel,
            pluviometer: havePluviometer,
            pump: pumpData != "" ? pumpData : null,
            protocol: parseFloat(protocolSelectValue).toFixed(1),
          };

          if (brandModel === "Other") {
            pivotToBeCreated.brand_model = otherBrand;
          }

          responseDeviceCreated = await coreHTTPClient.post(
            `/v3/farms/${props.farmID}/pivots/`,
            pivotToBeCreated
          );

          break;
        case "BAUER-PIVOT":
          await axios.post(
            `/v4/smartrain/pivot/`,
            {
              name: bauerDeviceName,
              farm: props.farmID,
              hardware_id: bauerHardwareId,
            },
            {
              headers: {
                Authorization: `Bearer ${getAuthorization()}`,
              },
              baseURL: process.env.REACTWEB_COREAPI_URL,
            }
          );
          break;
        case "MONITOR-PIVOT":
          monitorPivotToBeCreated = {
            name: deviceName,
            potency: parseFloat(devicePotency),
            monitor: monitorPivotData,
            base: farm.base.radio_id,
            automation_type: 1,
            brand_model: brandModel,
            pump: pumpData != "" ? pumpData : null,
            protocol: "5.0",
          };

          if (brandModel === "Other") {
            monitorPivotToBeCreated.brand_model = otherBrand;
          }
          responseDeviceCreated = await coreHTTPClient.post(
            `/v3/farms/${props.farmID}/pivots/`,
            monitorPivotToBeCreated
          );
          break;
        case "LINEAR-MONITOR-PIVOT":
          linearMonitorPivotToBeCreated = {
            name: deviceName,
            monitor: linearMonitorPivotData,
            base: farm.base.radio_id,
            automation_type: 2,
            brand_model: brandModel,
            protocol: "5.0",
            flowRate: parseFloat(flowRate),
            pivotLength: parseFloat(pivotLength),
            pivotSpeed: parseFloat(pivotSpeed),
          };

          if (brandModel === "Other") {
            linearMonitorPivotToBeCreated.brand_model = otherBrand;
          }

          responseDeviceCreated = await coreHTTPClient.post(
            `/v3/farms/${props.farmID}/pivots/`,
            linearMonitorPivotToBeCreated
          );
          break;
        case "REPEATER":
          repeaterToBeCreated = {
            name: deviceName,
            height: deviceHeight,
            base: farm.base.radio_id,
            position: stringPosition,
            repeater: repeaterData,
            energy_type: energyType,
          };
          responseDeviceCreated = await coreHTTPClient.post(
            `/v3/farms/${props.farmID}/repeaters/`,
            repeaterToBeCreated
          );
          break;
        case "IRPD":
          irpdToBeCreated = {
            name: deviceName,
            base: farm.base.radio_id,
            pump: pumpData,
            potency: parseFloat(devicePotency),
            flow: "100",
            position: stringPosition,
            protocol: Number(protocolIRPDSelectValue),
          };

          responseDeviceCreated = await coreHTTPClient.post(
            `/v3/farms/${props.farmID}/irpds/`,
            irpdToBeCreated
          );
          break;

        default:
          meterToBeCreated = {
            name: deviceName,
            position: stringPosition,
            imeter_device: meterData,
            base: farm.base.radio_id,
            local_actuation: localActuation,
            protocol: protocolMeterSelectValue,
            function: "LEVEL",
            sensor_id: Number(sensor),
          };
          responseDeviceCreated = await coreHTTPClient.post(
            `/v3/farms/${props.farmID}/metersystems/`,
            meterToBeCreated
          );

          break;
      }
      validateDeviceCreation(responseDeviceCreated);
    } catch (err) {
      if (err?.response?.data?.error === "HARDWARE_ID_ALREADY_EXISTS")
        notify("HARDWARE_ID_ALREADY_EXISTS", "error");
      else notify("CREATE_DEVICE_MODAL_ERROR_SERVER", "error");
    } finally {
      setCreatingDevice(false);
    }
  }

  return showChooser ? (
    <div
      className="root"
      style={{
        display: "grid",
        gridTemplateColumns: isMobile() ? "auto auto" : "1fr 1fr",
        width: "100%",
        height: "auto",
        rowGap: isMobile() ? 10 : 20,
        columnGap: isMobile() ? 10 : 20,
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 10,
          padding: 15,
          borderColor: mouseOnPivot ? "#0057ff" : "gray",
          height: 280,
          maxWidth: isMobile() ? "45vw" : 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 20,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setMouseOnPivot(true);
        }}
        onMouseLeave={() => {
          setMouseOnPivot(false);
        }}
        onClick={() => {
          setShowChooser(false);
          setDeviceSelectValue("PIVOT");
        }}
      >
        <img
          src={pivotAutomationDrawLogo}
          alt="Pivot"
          style={{ height: 150, width: 150 }}
        />
        <span
          style={{
            textAlign: "center",
            color: mouseOnPivot ? "#0057ff" : "black",
          }}
        >
          {i18n("CREATE_DEVICE_MODAL_PIVO_CHOOSER")}
        </span>
      </div>

      <div
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 10,
          padding: 15,
          borderColor: mouseOnBauerPivot ? "#0057ff" : "gray",
          height: 280,
          maxWidth: isMobile() ? "45vw" : 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 20,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setMouseOnBauerPivot(true);
        }}
        onMouseLeave={() => {
          setMouseOnBauerPivot(false);
        }}
        onClick={() => {
          setShowChooser(false);
          setDeviceSelectValue("BAUER-PIVOT");
        }}
      >
        <img
          src={pivotAutomationDrawLogo}
          alt="Bauer-Pivot"
          style={{ height: 150, width: 150 }}
        />
        <span
          style={{
            textAlign: "center",
            color: mouseOnBauerPivot ? "#0057ff" : "black",
          }}
        >
          {i18n("CREATE_DEVICE_MODAL_BAUER_PIVO_CHOOSER")}
        </span>
      </div>

      <div
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 10,
          padding: 15,
          borderColor: mouseOnMonitor ? "#0057ff" : "gray",
          background: farm.base?.radio_id ? "white" : "#ddd",
          height: 280,
          maxWidth: isMobile() ? "45vw" : 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 20,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setMouseOnMonitor(true);
        }}
        onMouseLeave={() => {
          setMouseOnMonitor(false);
        }}
        onClick={() => {
          if (farm.base?.radio_id) {
            setShowChooser(false);
            setDeviceSelectValue("MONITOR-PIVOT");
          }
        }}
      >
        <img
          src={monitorPivotDrawLogo}
          alt="Monitor-Pivot"
          style={{ height: 150, width: 150 }}
        />
        <span
          style={{
            textAlign: "center",
            color: mouseOnMonitor ? "#0057ff" : "black",
          }}
        >
          {i18n("CREATE_DEVICE_MODAL_PIVO_MONITOR_CHOOSER")}
        </span>
      </div>

      <div
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 10,
          padding: 15,
          borderColor: mouseOnLinearMonitor ? "#0057ff" : "gray",
          background: farm.base?.radio_id ? "white" : "#ddd",
          height: 280,
          maxWidth: isMobile() ? "45vw" : 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 20,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setMouseOnLinearMonitor(true);
        }}
        onMouseLeave={() => {
          setMouseOnLinearMonitor(false);
        }}
        onClick={() => {
          if (farm.base?.radio_id) {
            setShowChooser(false);
            setDeviceSelectValue("LINEAR-MONITOR-PIVOT");
          }
        }}
      >
        <img
          src={monitorPivotDrawLogo}
          alt="Linear-Monitor-Pivot"
          style={{ height: 150, width: 150 }}
        />
        <span
          style={{
            textAlign: "center",
            color: mouseOnLinearMonitor ? "#0057ff" : "black",
          }}
        >
          {i18n("CREATE_DEVICE_MODAL_PIVO_LINEAR_MONITOR_CHOOSER")}
        </span>
      </div>

      <div
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 10,
          padding: 15,
          borderColor: mouseOnIRPD ? "#0057ff" : "gray",
          background: farm.base?.radio_id ? "white" : "#ddd",
          height: 280,
          maxWidth: isMobile() ? "45vw" : 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 20,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setMouseOnIRPD(true);
        }}
        onMouseLeave={() => {
          setMouseOnIRPD(false);
        }}
        onClick={() => {
          if (farm.base?.radio_id) {
            setShowChooser(false);
            setProtocolIRPDSelectValue("5.1");
            setDeviceSelectValue("IRPD");
          }
        }}
      >
        <img
          src={irpdAutomationDrawLogo}
          alt="IRPD"
          style={{ height: 150, width: 150 }}
        />
        <span
          style={{
            textAlign: "center",
            color: mouseOnIRPD ? "#0057ff" : "black",
          }}
        >
          {i18n("CREATE_DEVICE_MODAL_IRPD_CHOOSER")}
        </span>
      </div>

      <div
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 10,
          padding: 15,
          borderColor: mouseOnRepeater ? "#0057ff" : "gray",
          background: farm.base?.radio_id ? "white" : "#ddd",
          height: 280,
          maxWidth: isMobile() ? "45vw" : 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 20,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setMouseOnRepeater(true);
        }}
        onMouseLeave={() => {
          setMouseOnRepeater(false);
        }}
        onClick={() => {
          if (farm.base?.radio_id) {
            setShowChooser(false);
            setDeviceSelectValue("REPEATER");
          }
        }}
      >
        <img
          src={RepeaterDrawLogo}
          alt="Repeater"
          style={{ height: 150, width: 150 }}
        />
        <span
          style={{
            textAlign: "center",
            color: mouseOnRepeater ? "#0057ff" : "black",
          }}
        >
          {i18n("CREATE_DEVICE_MODAL_REPEATER")}
        </span>
      </div>

      <div
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 10,
          padding: 15,
          borderColor: mouseOnMeter ? "#0057ff" : "gray",
          background: farm.base?.radio_id ? "white" : "#ddd",
          height: 280,
          maxWidth: isMobile() ? "45vw" : 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 20,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setMouseOnMeter(true);
        }}
        onMouseLeave={() => {
          setMouseOnMeter(false);
        }}
        onClick={() => {
          if (farm.base?.radio_id) {
            setShowChooser(false);
            setDeviceSelectValue("METER");
          }
        }}
      >
        <img
          src={RepeaterDrawLogo}
          alt="Meter"
          style={{ height: 150, width: 150 }}
        />
        <span
          style={{
            textAlign: "center",
            color: mouseOnMeter ? "#0057ff" : "black",
          }}
        >
          {i18n("CREATE_DEVICE_MODAL_METER")}
        </span>
      </div>
    </div>
  ) : (
    <div className="root">
      <div className="input-div">
        {deviceSelectValue == "PIVOT" && (
          <>
            <span className="title">
              {i18n("CREATE_DEVICE_MODAL_PIVO_CHOOSER")}
            </span>

            <FormInput
              id="nameCreatePivot"
              label="CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"
              state={[deviceName, setValidDeviceName]}
              helperText={validationErrors["deviceName"]}
            />

            <FormSelect
              id="brandModelSelect"
              label="CREATE_DEVICE_MODAL_BRAND_MODEL"
              newLabel="CREATE_DEVICE_MODAL_BRAND_MODEL"
              state={[brandModel, setBrandModel]}
              values={[
                ["Bauer", "CREATE_BRAND_MODEL_BAUER"],
                ["Carborundum", "CREATE_BRAND_MODEL_CARBORUNDUM"],
                ["Fockink", "CREATE_BRAND_MODEL_FOCKINK"],
                ["Irrigabras", "CREATE_BRAND_MODEL_IRRIGABRAS"],
                ["Krebs", "CREATE_BRAND_MODEL_KREBS"],
                ["Lindsay", "CREATE_BRAND_MODEL_LINDSAY"],
                ["Reinke", "CREATE_BRAND_MODEL_REINKE"],
                ["Valley", "CREATE_BRAND_MODEL_VALLEY"],
                ["Other", "CREATE_BRAND_MODEL_OTHER"],
              ]}
            />

            {brandModel === "Other" ? (
              <div className="inputs">
                <FormInput
                  id="otherBrand"
                  label="CREATE_BRAND_MODEL_INSERT"
                  state={[otherBrand, setValidOtherBrand]}
                  helperText={validationErrors["otherBrand"]}
                />
              </div>
            ) : null}

            {brandModel == "Bauer" ? (
              <FormSelect
                id="panelTypeSelect"
                label="CREATE_DEVICE_MODAL_PANEL_TYPE"
                newLabel="CREATE_DEVICE_MODAL_PANEL_TYPE"
                state={[panelType, setPanelType]}
                values={[
                  ["1", "CREATE_DEVICE_MODAL_NEXUS"],
                  ["2", "CREATE_DEVICE_MODAL_SMARTCONNECT"],
                ]}
              />
            ) : (
              <FormSelect
                id="panelTypeSelect"
                label="CREATE_DEVICE_MODAL_PANEL_TYPE"
                newLabel="CREATE_DEVICE_MODAL_PANEL_TYPE"
                state={[panelType, setPanelType]}
                values={[["1", "CREATE_DEVICE_MODAL_NEXUS"]]}
              />
            )}

            <div className="input-div">
              <FormSelect
                id="unitSelect"
                label="CREATE_DEVICE_MODAL_UNIT"
                newLabel="CREATE_DEVICE_MODAL_UNIT"
                state={[potencyUnit, setPotencyUnit]}
                values={[
                  ["hp", "CREATE_DEVICE_MODAL_HP"],
                  ["kW", "CREATE_DEVICE_MODAL_KW"],
                ]}
              />
            </div>

            <div className="input-div">
              {potencyUnit === "kW" ? (
                <FormInput
                  id="pontecyCreatePivot"
                  label="CREATE_DEVICE_MODAL_POTENCY_PLACEHOLDER"
                  state={[devicePotency, setValidDevicePotency]}
                  inputMode="numeric"
                  endAdornment="kW"
                  type="number"
                  helperText={validationErrors["devicePotency"]}
                />
              ) : (
                <div className="input-div">
                  <div>
                    <div className="inputs">
                      <FormInput
                        id="pontecyHP"
                        label="CREATE_DEVICE_POTENCY_PLACEHOLDER"
                        state={[devicePotencyHP, setValidDevicePotencyHP]}
                        inputMode="numeric"
                        type="number"
                        endAdornment={i18n("CREATE_DEVICE_MODAL_HP")}
                        helperText={validationErrors["devicePotencyHP"]}
                      />
                    </div>

                    <div className="inputs">
                      <FormInput
                        id="performancePercent"
                        label="CREATE_DEVICE_MODAL_PERFORMANCE_PERCENT"
                        state={[devicePerformance, setValidDevicePerformance]}
                        inputMode="numeric"
                        endAdornment="%"
                        max="100"
                        type="number"
                        helperText={validationErrors["devicePerformance"]}
                      />
                    </div>
                  </div>

                  <div className="inputs">
                    <FormInput
                      id="convertedPotency"
                      label="CREATE_DEVICE_MODAL_CONVERTED_POTENCY"
                      state={[convertedPotency, setValidConvertedPotency]}
                      inputMode="numeric"
                      endAdornment="kW"
                      type="number"
                      helperText={validationErrors["convertedPotency"]}
                    />
                  </div>
                </div>
              )}
            </div>

            {protocolSelectValue === "5.0" ? (
              <div className="general-form__row">
                <FormCheckBox
                  state={[havePluviometer, setHavePluviometer]}
                  label="EDIT_PIVOT_LABEL_PLUVIOMETER"
                />
              </div>
            ) : null}

            {farm.base?.radio_id ? (
              <FormSelect
                id="communicationTypeSelect"
                label="COMMUNICATION_TYPE"
                newLabel="COMMUNICATION_TYPE"
                state={[communicationType, setCommunicationType]}
                values={[
                  ["XBEE", "CREATE_DEVICE_MODAL_XBEE"],
                  ["4G", "CREATE_DEVICE_MODAL_4G"],
                ]}
                setPivotData={setPivotData}
                setPivotData4g={setPivotData4g}
                setGPSData={setGPSData}
                setPUMPData={setPUMPData}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
              />
            ) : (
              <FormSelect
                id="communicationTypeSelect"
                label="COMMUNICATION_TYPE"
                newLabel="COMMUNICATION_TYPE"
                state={[communicationType, setCommunicationType]}
                values={[["4G", "CREATE_DEVICE_MODAL_4G"]]}
                setPivotData={setPivotData}
                setPivotData4g={setPivotData4g}
                setGPSData={setGPSData}
                setPUMPData={setPUMPData}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
              />
            )}
            {communicationType === "XBEE" && farm.base?.radio_id ? (
              <div>
                <div className="device-row mb-30">
                  <div className="input">
                    <TextField
                      id="text"
                      type="text"
                      label={<>{i18n("CREATE_DEVICE_MODAL_CONTROLLER_CODE")}</>}
                      onChange={(e) => setValidPivotData(e.target.value)}
                      onFocus={(e) => setValidPivotData(e.target.value)}
                      value={pivotData}
                      error={!!validationErrors["pivotData"]}
                    />
                  </div>

                  {isMobile() ? (
                    <div
                      className="camera-button"
                      onClick={async () => {
                        showQrReaderWithMode("PIVOT");
                      }}
                    >
                      <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                        <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>

                <div className="device-row mb-30">
                  <div className="input">
                    <TextField
                      id="text"
                      type="text"
                      label={<>{i18n("CREATE_DEVICE_MODAL_GPS_CODE")}</>}
                      onChange={(e) => setValidGpsData(e.target.value)}
                      onFocus={(e) => setValidGpsData(e.target.value)}
                      value={gpsData}
                      error={!!validationErrors["gpsData"]}
                    />
                  </div>

                  {isMobile() ? (
                    <div
                      className="camera-button"
                      onClick={async () => {
                        showQrReaderWithMode("GPS");
                      }}
                    >
                      <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                        <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>

                <div className="device-row">
                  <div className="input marginTop">
                    <TextField
                      id="text"
                      type="text"
                      label={<>{i18n("CREATE_DEVICE_MODAL_PUMP_PIVOT_CODE")}</>}
                      onChange={(e) => setValidPumpData(e.target.value)}
                      onFocus={(e) => setValidPumpData(e.target.value)}
                      value={pumpData}
                      error={!!validationErrors["pumpData"]}
                    />
                  </div>

                  {isMobile() ? (
                    <div
                      className="camera-button"
                      onClick={async () => {
                        showQrReaderWithMode("IRPD");
                      }}
                    >
                      <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                        <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div>
                <div className="device-row mb-30">
                  <div className="input">
                    <TextField
                      id="text"
                      type="text"
                      label={<>{i18n("CREATE_DEVICE_MODAL_GATEWAY_CODE")}</>}
                      onChange={(e) => setValidPivotData4g(e.target.value)}
                      onFocus={(e) => setValidPivotData4g(e.target.value)}
                      value={pivotData4g}
                      error={!!validationErrors["pivotData4g"]}
                    />
                  </div>

                  {isMobile() ? (
                    <div
                      className="camera-button"
                      onClick={async () => {
                        showQrReaderWithMode("PIVOT");
                      }}
                    >
                      <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                        <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </>
        )}

        {deviceSelectValue == "BAUER-PIVOT" && (
          <>
            <span className="title">
              {i18n("CREATE_DEVICE_MODAL_BAUER_PIVO_CHOOSER")}
            </span>

            <FormInput
              id="nameCreatePivot"
              label="CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"
              state={[bauerDeviceName, setValidBauerDeviceName]}
              helperText={validationErrors["bauerDeviceName"]}
            />

            <FormInput
              id="nameCreatePivot"
              label="CREATE_DEVICE_MODAL_HARDWARE_ID_PLACEHOLDER"
              state={[bauerHardwareId, setBauerHardwareId]}
              helperText={validationErrors["bauerHardwareId"]}
            />
          </>
        )}

        {deviceSelectValue == "MONITOR-PIVOT" && (
          <>
            <span className="title">
              {i18n("CREATE_DEVICE_MODAL_PIVO_MONITOR_CHOOSER")}
            </span>

            <FormInput
              id="nameCreatePivot"
              label="CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"
              state={[deviceName, setValidDeviceName]}
              helperText={validationErrors["deviceName"]}
            />

            <FormSelect
              id="brandModelSelect"
              label="CREATE_DEVICE_MODAL_BRAND_MODEL"
              newLabel="CREATE_DEVICE_MODAL_BRAND_MODEL"
              state={[brandModel, setBrandModel]}
              values={[
                ["Bauer", "CREATE_BRAND_MODEL_BAUER"],
                ["Carborundum", "CREATE_BRAND_MODEL_CARBORUNDUM"],
                ["Fockink", "CREATE_BRAND_MODEL_FOCKINK"],
                ["Irrigabras", "CREATE_BRAND_MODEL_IRRIGABRAS"],
                ["Krebs", "CREATE_BRAND_MODEL_KREBS"],
                ["Lindsay", "CREATE_BRAND_MODEL_LINDSAY"],
                ["Reinke", "CREATE_BRAND_MODEL_REINKE"],
                ["Valley", "CREATE_BRAND_MODEL_VALLEY"],
                ["Other", "CREATE_BRAND_MODEL_OTHER"],
              ]}
            />

            {brandModel === "Other" ? (
              <div className="inputs">
                <FormInput
                  id="otherBrand"
                  label="CREATE_BRAND_MODEL_INSERT"
                  state={[otherBrand, setValidOtherBrand]}
                  helperText={validationErrors["otherBrand"]}
                />
              </div>
            ) : null}

            <div className="input-div">
              <FormSelect
                id="unitSelect"
                label="CREATE_DEVICE_MODAL_UNIT"
                newLabel="CREATE_DEVICE_MODAL_UNIT"
                state={[potencyUnit, setPotencyUnit]}
                values={[
                  ["hp", "CREATE_DEVICE_MODAL_HP"],
                  ["kW", "CREATE_DEVICE_MODAL_KW"],
                ]}
              />
            </div>

            <div className="input-div">
              {potencyUnit === "kW" ? (
                <FormInput
                  id="pontecyCreatePivot"
                  label="CREATE_DEVICE_MODAL_POTENCY_PLACEHOLDER"
                  state={[devicePotency, setValidDevicePotency]}
                  inputMode="numeric"
                  endAdornment="kW"
                  type="number"
                  helperText={validationErrors["devicePotency"]}
                />
              ) : (
                <div className="input-div">
                  <div>
                    <div className="inputs">
                      <FormInput
                        id="pontecyHP"
                        label="CREATE_DEVICE_POTENCY_PLACEHOLDER"
                        state={[devicePotencyHP, setValidDevicePotencyHP]}
                        inputMode="numeric"
                        type="number"
                        endAdornment={i18n("CREATE_DEVICE_MODAL_HP")}
                        helperText={validationErrors["devicePotencyHP"]}
                      />
                    </div>

                    <div className="inputs">
                      <FormInput
                        id="performancePercent"
                        label="CREATE_DEVICE_MODAL_PERFORMANCE_PERCENT"
                        state={[devicePerformance, setValidDevicePerformance]}
                        inputMode="numeric"
                        endAdornment="%"
                        type="number"
                        helperText={validationErrors["devicePerformance"]}
                      />
                    </div>
                  </div>

                  <div className="inputs">
                    <FormInput
                      id="convertedPotency"
                      label="CREATE_DEVICE_MODAL_CONVERTED_POTENCY"
                      state={[convertedPotency, setValidConvertedPotency]}
                      inputMode="numeric"
                      endAdornment="kW"
                      type="number"
                      helperText={validationErrors["convertedPotency"]}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="device-row mb-30">
              <div className="input">
                <TextField
                  id="text"
                  type="text"
                  label={<>{i18n("CREATE_DEVICE_MODAL_MONITOR_CODE")}</>}
                  onChange={(e) => setValidMonitorPivotData(e.target.value)}
                  onFocus={(e) => setValidMonitorPivotData(e.target.value)}
                  value={monitorPivotData}
                  error={!!validationErrors["monitorPivotData"]}
                />
              </div>

              {isMobile() ? (
                <div
                  className="camera-button"
                  onClick={async () => {
                    showQrReaderWithMode("MONITOR-PIVOT");
                  }}
                >
                  <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                    <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </>
        )}

        {deviceSelectValue == "LINEAR-MONITOR-PIVOT" && (
          <>
            <span className="title">
              {i18n("CREATE_DEVICE_MODAL_PIVO_LINEAR_MONITOR_CHOOSER")}
            </span>

            <FormInput
              id="nameCreatePivot"
              label="CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"
              state={[deviceName, setValidDeviceName]}
              helperText={validationErrors["deviceName"]}
            />

            <FormSelect
              id="brandModelSelect"
              label="CREATE_DEVICE_MODAL_BRAND_MODEL"
              newLabel="CREATE_DEVICE_MODAL_BRAND_MODEL"
              state={[brandModel, setBrandModel]}
              values={[
                ["Bauer", "CREATE_BRAND_MODEL_BAUER"],
                ["Carborundum", "CREATE_BRAND_MODEL_CARBORUNDUM"],
                ["Fockink", "CREATE_BRAND_MODEL_FOCKINK"],
                ["Irrigabras", "CREATE_BRAND_MODEL_IRRIGABRAS"],
                ["Krebs", "CREATE_BRAND_MODEL_KREBS"],
                ["Lindsay", "CREATE_BRAND_MODEL_LINDSAY"],
                ["Reinke", "CREATE_BRAND_MODEL_REINKE"],
                ["Valley", "CREATE_BRAND_MODEL_VALLEY"],
                ["Other", "CREATE_BRAND_MODEL_OTHER"],
              ]}
            />

            {brandModel === "Other" ? (
              <div className="inputs">
                <FormInput
                  id="otherBrand"
                  label="CREATE_BRAND_MODEL_INSERT"
                  state={[otherBrand, setValidOtherBrand]}
                  helperText={validationErrors["otherBrand"]}
                />
              </div>
            ) : null}

            <div className="device-row mb-30">
              <div className="input">
                <TextField
                  id="text"
                  type="text"
                  label={<>{i18n("CREATE_DEVICE_MODAL_MONITOR_CODE")}</>}
                  onChange={(e) =>
                    setValidLinearMonitorPivotData(e.target.value)
                  }
                  onFocus={(e) =>
                    setValidLinearMonitorPivotData(e.target.value)
                  }
                  value={linearMonitorPivotData}
                  error={!!validationErrors["linearMonitorPivotData"]}
                />
              </div>

              {isMobile() ? (
                <div
                  className="camera-button"
                  onClick={async () => {
                    showQrReaderWithMode("LINEAR-MONITOR-PIVOT");
                  }}
                >
                  <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                    <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                  </Tooltip>
                </div>
              ) : null}
            </div>

            <FormInput
              id="flowRate"
              label="CREATE_DEVICE_MODAL_FLOW_RATE"
              state={[flowRate, setValidFlowRate]}
              inputMode="numeric"
              endAdornment="m³/h"
              type="number"
              helperText={validationErrors["flowRate"]}
            />

            <FormInput
              id="pivotLength"
              label="CREATE_DEVICE_MODAL_PIVOT_LENGTH"
              state={[pivotLength, setValidPivotLength]}
              inputMode="numeric"
              endAdornment="m"
              type="number"
              helperText={validationErrors["pivotLength"]}
            />

            <FormInput
              id="pivotSpeed"
              label="CREATE_DEVICE_MODAL_PIVOT_SPEED"
              state={[pivotSpeed, setValidPivotSpeed]}
              inputMode="numeric"
              endAdornment="m/h"
              type="number"
              helperText={validationErrors["pivotSpeed"]}
            />
          </>
        )}

        {deviceSelectValue == "REPEATER" && (
          <>
            <span className="title">
              {i18n("CREATE_DEVICE_MODAL_REPEATER")}
            </span>

            <div className="device-row mb-30">
              <div className="input">
                <FormInput
                  id="nameCreateRepeater"
                  label="CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"
                  state={[deviceName, setValidDeviceName]}
                  helperText={validationErrors["deviceName"]}
                />
                <FormInput
                  id="heightCreateRepeater"
                  label="REPEATER_FIELD_HEIGHT"
                  state={[deviceHeight, setValidDeviceHeight]}
                  helperText={validationErrors["deviceHeight"]}
                  endAdornment={"m"}
                />

                <div className="device-row">
                  <div className="input marginTop">
                    <TextField
                      id="repeater-id"
                      type="text"
                      label={<>{i18n("CREATE_DEVICE_MODAL_REPEATER_CODE")}</>}
                      onChange={(e) => setValidRepeaterData(e.target.value)}
                      onFocus={(e) => setValidRepeaterData(e.target.value)}
                      value={repeaterData}
                      error={!!validationErrors["repeaterData"]}
                    />
                  </div>

                  {isMobile() ? (
                    <div
                      className="camera-button"
                      onClick={async () => {
                        showQrReaderWithMode("REPEATER");
                      }}
                    >
                      <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                        <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>

                <div className="input">
                  <div className="marginTop2">
                    <InputLabel>
                      {i18n("HISTORIC_PANEL_STREAM_V5_POSITION")}
                    </InputLabel>
                  </div>

                  <div className="inputs">
                    <FormInput
                      id="latCreateRepeater"
                      label="EDIT_PIVOT_LATITUDE"
                      state={[deviceLat, setValidDeviceLat]}
                      type="number"
                      helperText={validationErrors["deviceLat"]}
                    />
                  </div>

                  <div className="inputs">
                    <FormInput
                      id="longCreateRepeater"
                      label="EDIT_PIVOT_LONGITUDE"
                      state={[deviceLong, setValidDeviceLong]}
                      type="number"
                      helperText={validationErrors["deviceLong"]}
                    />
                  </div>

                  <FormSelect
                    id="energyType"
                    label="CREATE_DEVICE_MODAL_ENERGY_TYPE"
                    newLabel="CREATE_DEVICE_MODAL_ENERGY_TYPE"
                    state={[energyType, setEnergyType]}
                    values={[
                      ["Solar", "CREATE_DEVICE_MODAL_ENERGY_TYPE_SOLAR"],
                      ["Bivolt", "CREATE_DEVICE_MODAL_ENERGY_TYPE_BIVOLT"],
                    ]}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {deviceSelectValue == "IRPD" && (
          <>
            <span className="title">
              {i18n("CREATE_DEVICE_MODAL_IRPD_CHOOSER")}
            </span>

            <FormInput
              id="nameCreateirpd"
              label="CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"
              state={[deviceName, setValidDeviceName]}
              helperText={validationErrors["deviceName"]}
            />

            <div className="device-row">
              <div className="input marginTop">
                <TextField
                  id="pump-code"
                  type="text"
                  label={<>{i18n("CREATE_DEVICE_MODAL_PUMP_IRPD_CODE")}</>}
                  onChange={(e) => setValidPumpData(e.target.value)}
                  onFocus={(e) => setValidPumpData(e.target.value)}
                  value={pumpData.toUpperCase()}
                  error={!!validationErrors["pumpData"]}
                />
              </div>

              {isMobile() ? (
                <div
                  className="camera-button"
                  onClick={async () => {
                    showQrReaderWithMode("IRPD");
                  }}
                >
                  <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                    <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                  </Tooltip>
                </div>
              ) : null}
            </div>

            <div className="input-div">
              <FormSelect
                id="unitSelect"
                label="CREATE_DEVICE_MODAL_UNIT"
                newLabel="CREATE_DEVICE_MODAL_UNIT"
                state={[potencyUnit, setPotencyUnit]}
                values={[
                  ["hp", "CREATE_DEVICE_MODAL_HP"],
                  ["kW", "CREATE_DEVICE_MODAL_KW"],
                ]}
              />
            </div>

            <div className="input-div">
              {potencyUnit === "kW" ? (
                <FormInput
                  id="pontecyCreatePivot"
                  label="CREATE_DEVICE_MODAL_POTENCY_PLACEHOLDER"
                  state={[devicePotency, setDevicePotency]}
                  inputMode="numeric"
                  endAdornment="kW"
                  type="number"
                  helperText={validationErrors["devicePotency"]}
                />
              ) : (
                <div className="input-div">
                  <div>
                    <div className="inputs">
                      <FormInput
                        id="pontecyHP"
                        label="CREATE_DEVICE_POTENCY_PLACEHOLDER"
                        state={[devicePotencyHP, setValidDevicePotencyHP]}
                        inputMode="numeric"
                        type="number"
                        endAdornment={i18n("CREATE_DEVICE_MODAL_HP")}
                        helperText={validationErrors["devicePotencyHP"]}
                      />
                    </div>

                    <div className="inputs">
                      <FormInput
                        id="performancePercent"
                        label="CREATE_DEVICE_MODAL_PERFORMANCE_PERCENT"
                        state={[devicePerformance, setValidDevicePerformance]}
                        inputMode="numeric"
                        endAdornment="%"
                        max="100"
                        type="number"
                        helperText={validationErrors["devicePerformance"]}
                      />
                    </div>
                  </div>

                  <div className="inputs">
                    <FormInput
                      id="convertedPotency"
                      label="CREATE_DEVICE_MODAL_CONVERTED_POTENCY"
                      state={[convertedPotency, setValidConvertedPotency]}
                      inputMode="numeric"
                      endAdornment="kW"
                      type="number"
                      helperText={validationErrors["convertedPotency"]}
                    />
                  </div>
                </div>
              )}
            </div>

            <FormSelect
              id="protocolIRPDSelect"
              label="CREATE_DEVICE_MODAL_PROTOCOL"
              newLabel="CREATE_DEVICE_MODAL_PROTOCOL"
              state={[protocolIRPDSelectValue, setProtocolIRPDSelectValue]}
              values={irpdProtocolOptions}
            />

            <div className="input">
              <div className="marginTop2">
                <InputLabel>
                  {i18n("HISTORIC_PANEL_STREAM_V5_POSITION")}
                </InputLabel>
              </div>

              <div className="inputs">
                <FormInput
                  id="latCreateRepeater"
                  label="EDIT_PIVOT_LATITUDE"
                  state={[deviceLat, setValidDeviceLat]}
                  type="number"
                  helperText={validationErrors["deviceLat"]}
                />
              </div>

              <div className="inputs">
                <FormInput
                  id="longCreateRepeater"
                  label="EDIT_PIVOT_LONGITUDE"
                  state={[deviceLong, setValidDeviceLong]}
                  type="number"
                  helperText={validationErrors["deviceLong"]}
                />
              </div>
            </div>
          </>
        )}

        {deviceSelectValue == "METER" && (
          <>
            <span className="title">{i18n("CREATE_DEVICE_MODAL_METER")}</span>

            <div className="device-row mb-30">
              <div className="input">
                <FormInput
                  id="nameCreateRepeater"
                  label="CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"
                  state={[deviceName, setValidDeviceName]}
                  helperText={validationErrors["deviceName"]}
                />

                <div className="input">
                  <div className="marginTop2">
                    <InputLabel>
                      {i18n("HISTORIC_PANEL_STREAM_V5_POSITION")}
                    </InputLabel>
                  </div>

                  <div className="inputs">
                    <FormInput
                      id="latCreateRepeater"
                      label="EDIT_PIVOT_LATITUDE"
                      state={[deviceLat, setValidDeviceLat]}
                      type="number"
                      helperText={validationErrors["deviceLat"]}
                    />
                  </div>

                  <div className="inputs">
                    <FormInput
                      id="longCreateRepeater"
                      label="EDIT_PIVOT_LONGITUDE"
                      state={[deviceLong, setValidDeviceLong]}
                      type="number"
                      helperText={validationErrors["deviceLong"]}
                    />
                  </div>
                </div>

                <FormSelect
                  id="protocolMeterSelect"
                  label="CREATE_DEVICE_MODAL_PROTOCOL"
                  newLabel="CREATE_DEVICE_MODAL_PROTOCOL"
                  state={[
                    protocolMeterSelectValue,
                    setProtocolMeterSelectValue,
                  ]}
                  values={meterProtocolOptions}
                />

                <FormSelect
                  id="sensor"
                  label="CREATE_DEVICE_MODAL_SENSOR"
                  state={[sensor, setSensor]}
                  noI18n
                  values={sensorOptions}
                />

                <div className="device-row mb-30">
                  <div className="input">
                    <TextField
                      id="imeter-id"
                      type="text"
                      label={<>{i18n("CREATE_DEVICE_MODAL_IMETER")}</>}
                      onChange={(e) => setValidMeterData(e.target.value)}
                      onFocus={(e) => setValidMeterData(e.target.value)}
                      value={meterData}
                      error={!!validationErrors["meterData"]}
                    />
                  </div>

                  {isMobile() ? (
                    <div
                      className="camera-button"
                      onClick={async () => {
                        showQrReaderWithMode("METER");
                      }}
                    >
                      <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                        <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="create-equipment-button">
        <div
          className="button"
          onClick={() => {
            if (!creatingDevice && checkCanCreateDevice()) createDevice();
          }}
          style={{
            backgroundColor: checkCanCreateDevice() ? "#0057ff" : "#C4C4C4",
          }}
        >
          {creatingDevice ? (
            <CircularProgress size={18} />
          ) : (
            i18n("CREATE_DEVICE_MODAL_CREATE")
          )}
        </div>
      </div>

      {showQrReader && (
        <div className="qr-reader-root">
          <div className="close-button" onClick={() => setShowQrReader(false)}>
            X
          </div>

          <QrReader
            delay={300}
            facingMode={"environment"}
            onError={(data) => globalReject && globalReject(data)}
            onScan={(data) => globalResolve && data && globalResolve(data)}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
};

export default CreateDeviceModal;
