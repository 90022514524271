import React, { useState, useMemo, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Button, Step, StepLabel, Stepper } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isMobile } from "../../../../../mobileConfig";
import useFormValidation from "../../../../../hooks/tools/useFormValidation";
import { Farm } from "../../../../../redux/farms/types";
import { getCountryPhone } from "../../../../../utils/consts/countries";
import ContactStep from "./components/ContactStep";
import "../../../../Dashboard/Farm/EditFarm/EditFarm.scss";
import { i18n } from "../../../../../i18n/i18nText";
import { hideModal } from "../../../../../redux/modal/actions";
import GeneralInfo from "./components/GeneralInfo";
import FarmLocationStep from "./components/FarmLocationStep";
import BillingStep from "./components/BillingStep";
import routes from "../../../../../routes/routes";
import { ApplicationState } from "../../../../../redux";

interface Props {
  createFarm: (patchObject: any) => Promise<Farm>;
  reseller: boolean;
  userID: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      overflow: "hidden",
    },
    container: {
      backgroundColor: theme.palette.background.paper,
      display: "grid",
      gridTemplateRows: `repeat(1, 1fr)`,
      alignItems: "center",
      gridGap: "50px 50px 0px 50px",
      width: isMobile() ? "auto" : "600px",
    },
    confirmation: {
      backgroundColor: theme.palette.background.paper,
      display: "grid",
      gridTemplateRows: `repeat(1, 1fr)`,
      alignItems: "center",
      gridGap: "50px 50px 0px 50px",
      margin: isMobile() ? "15px" : "30px",
      width: isMobile() ? "auto" : "500px",
      overflowY: isMobile() ? "scroll" : "hidden",
    },
    content: {
      margin: isMobile() ? "15px" : "30px",
      maxHeight: isMobile() ? "70vh" : "100%",
      paddingBottom: "20px",
      overflowY: isMobile() ? "scroll" : "hidden",
    },
    buttonsDesktop: {
      display: "flex",
      alignItems: " center",
      justifyContent: "space-evenly",
      margin: "15px",
      fontColor: "white",
    },
    buttonsMobile: {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "15px 15px 20px 15px",
      color: "white",
      position: "absolute",
      bottom: "-10px",
      left: 0,
      right: 0,
    },
    button: {
      width: "100px",
    },
    instructions: {
      display: "flex",
      alignItems: " center",
      justifyContent: "center",
      height: "150px",
    },
  })
);

// Commented code will be reactivated with the billing release

interface CreateFarm {
  name: string;
  base?: string;
  administrators?: any[];
  billing_date: number;
  water_billing_date: number;
  location: string;
  address: string;
  postal_code: string;
  city: string;
  state: string;
  timezone: string;
  country: string;
  phone: number;
  customer: CreateBilling;
}
interface CreateBilling {
  document_type: number;
  document: string;
  company_name: string;
  country: string;
  state: string;
  city: string;
  address: string;
  postal_code: string;
  email: string;
  phone: string;
  language: string;
}

interface CreateNewFarm {
  name: string;
  central_linked: boolean;
  base: string;
  billing_date: number;
  water_billing_date: number;
  location: string;
  address: string;
  postal_code: string;
  city: string;
  state: string;
  timezone: string;
  country: string;
  phone: string;
  customer: CreateBilling;
}

function isInvalidCreateNewFarmFields(createNewFarm: CreateNewFarm) {
  let farmToBeValidated = { ...createNewFarm };
  if (!createNewFarm.central_linked) delete farmToBeValidated.base;
  delete farmToBeValidated.central_linked; // variável de controle, não é parte do objeto a ser criado

  const fields = Object.values(farmToBeValidated);

  for (let i = 0; i < fields.length; i++) {
    if (fields[i] === "" || fields[i] === null) {
      return true;
    }
  }

  return false;
}

function validateStep(fields, createNewFarm, validationErrors) {
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const value = getFieldValue(field, createNewFarm);

    if (typeof value === "string") {
      const valueNoSpaces = value.replace(/\s+/g, "");

      if (valueNoSpaces === "" || value === null || validationErrors[field]) {
        return false;
      }
    } else {
      if (value === "" || value === null || validationErrors[field]) {
        return false;
      }
    }
  }

  return true;
}

function getFieldValue(field, createNewFarm) {
  const fieldKeys = field.split(".");

  let value = createNewFarm;

  for (let i = 0; i < fieldKeys.length; i++) {
    value = value[fieldKeys[i]];

    if (value === undefined) {
      return null;
    }
  }

  return value;
}

const CreateFarmSteps = (props: Props) => {
  const tabValue = parseInt(window.location.href.split("?tab=")[1]);

  const language = useSelector(
    (state: ApplicationState) => state.setting.language,
    shallowEqual
  );

  useEffect(() => {
    document.title = i18n("CREATE_FARM_LABEL");
  }, []);

  let { userID, reseller } = props;

  const classes = useStyles();

  const [validationErrors, formValidationActions] = useFormValidation([
    "name",
    "central_linked",
    "base",
    "billing_date",
    "water_billing_date",
    "location",
    "address",
    "postal_code",
    "city",
    "state",
    "timezone",
    "country",
    "phone",
    "customer.document_type",
    "customer.document",
    "customer.company_name",
    "customer.country",
    "customer.state",
    "customer.city",
    "customer.address",
    "customer.postal_code",
    "customer.email",
    "customer.phone",
  ]);

  useEffect(() => {
    if (!reseller) dispatch(hideModal());
  }, []);

  const dispatch = useDispatch();

  const [createNewFarm, setCreateNewFarm] = useState<CreateNewFarm>({
    name: "",
    central_linked: true,
    billing_date: null,
    water_billing_date: null,
    timezone: "America/Sao_Paulo",
    customer: {
      document_type: 0,
      document: "",
      company_name: "",
      phone: "",
      email: "",
      postal_code: "",
      address: "",
      city: "",
      state: "",
      country: "BR",
      language: language === "pt-br" ? "pt-br" : "en",
    },
    phone: null,
    postal_code: "",
    address: "",
    city: "",
    state: "",
    country: "BR",
    location: `-22.00843896236507, -46.8126823934425`,
    base: "",
  });

  const [activeStep, setActiveStep] = useState<number>(0);

  const IDD = useMemo(() => {
    const ddiPhone = getCountryPhone(createNewFarm.country);
    if (ddiPhone !== "" && ddiPhone !== "0") return `+${parseInt(ddiPhone)}`;
    return "";
  }, [createNewFarm.country]);

  const IDDBILLING = useMemo(() => {
    const ddiPhone = getCountryPhone(createNewFarm.customer.country);
    if (ddiPhone !== "" && ddiPhone !== "0") return `+${parseInt(ddiPhone)}`;
    return "";
  }, [createNewFarm.customer.country]);

  const STEPS = [
    {
      step: 0,
      label: i18n("CREATE_FARM_STEP_LABEL_GENERAL"),
      required: [
        "name",
        "central_linked",
        "billing_date",
        "water_billing_date",
        "timezone",
      ],
      content: (
        <GeneralInfo
          validationErrors={validationErrors}
          formValidationActions={formValidationActions}
          createFarmState={[createNewFarm, setCreateNewFarm]}
        />
      ),
    },
    {
      step: 1,
      label: i18n("CREATE_FARM_STEP_LABEL_BILLING"),
      required: [
        "customer.document_type",
        "customer.document",
        "customer.company_name",
        "customer.country",
        "customer.state",
        "customer.city",
        "customer.address",
        "customer.postal_code",
        "customer.email",
        "customer.phone",
      ],
      content: (
        <BillingStep
          validationErrors={validationErrors}
          formValidationActions={formValidationActions}
          newFarm={[createNewFarm, setCreateNewFarm]}
          IDD={IDDBILLING}
        ></BillingStep>
      ),
    },
    {
      step: 2,
      label: i18n("CREATE_FARM_STEP_LABEL_CONTACT"),
      required: [
        "address",
        "postal_code",
        "city",
        "state",
        "timezone",
        "country",
        "phone",
      ],
      content: (
        <ContactStep
          validationErrors={validationErrors}
          formValidationActions={formValidationActions}
          createFarmState={[createNewFarm, setCreateNewFarm]}
          IDD={IDD}
        ></ContactStep>
      ),
    },
    {
      step: 3,
      label: i18n("CREATE_FARM_STEP_LABEL_LOCATION"),
      required: !createNewFarm.central_linked
        ? ["location"]
        : ["location", "base"],
      content: (
        <FarmLocationStep
          validationErrors={validationErrors}
          formValidationActions={formValidationActions}
          createFarmState={[createNewFarm, setCreateNewFarm]}
          isCreating={true}
        />
      ),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    if (
      !formValidationActions.showErrorMessages() &&
      !isInvalidCreateNewFarmFields(createNewFarm)
    ) {
      // cleaning formating
      let farmToBeCreated = {
        ...createNewFarm,
        phone: createNewFarm.phone.replace(/\D/g, ""),
        customer: {
          ...createNewFarm.customer,
          phone: createNewFarm.customer.phone.replace(/\D/g, ""),
        },
      };

      if (!farmToBeCreated.central_linked) delete farmToBeCreated.base;

      // delete control variable
      delete farmToBeCreated.central_linked;

      const newFarm: CreateFarm = (farmToBeCreated as unknown) as CreateFarm;

      if (userID) newFarm.administrators = [userID];

      let createdFarm = await props.createFarm(newFarm).then((value) => value);

      if (createdFarm) {
        dispatch(hideModal());

        const link = document.createElement("a");
        link.href = routes.DASHBOARD.SELECTED_FARM.replace(
          ":farm",
          `${createdFarm.id}`
        );
        link.click();
      }
    }
  };

  return (
    <div className={classes.root}>
      {activeStep === STEPS.length ? null : (
        <Stepper activeStep={activeStep} alternativeLabel>
          {STEPS.map((step) => (
            <Step key={step.step}>
              <StepLabel>{isMobile() ? null : step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {activeStep === STEPS.length ? (
        <div className={classes.confirmation}>
          <div className={classes.content}>
            <Typography className={classes.instructions}>
              {i18n("CREATE_FARM_QUESTION")}
            </Typography>
          </div>

          <div
            className={
              isMobile() ? classes.buttonsMobile : classes.buttonsDesktop
            }
          >
            <Button
              className={classes.button}
              variant="contained"
              color="inherit"
              onClick={handleBack}
            >
              {i18n("CREATE_NOTIFICATIONS_BACK")}
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isInvalidCreateNewFarmFields(createNewFarm)}
            >
              {i18n("CREATE_FARM_CREATE")}
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.content}>{STEPS[activeStep].content}</div>

          <div
            className={
              isMobile() ? classes.buttonsMobile : classes.buttonsDesktop
            }
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
              color="inherit"
              className={classes.button}
            >
              {i18n("CREATE_NOTIFICATIONS_BACK")}
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={
                !validateStep(
                  STEPS[activeStep].required,
                  createNewFarm,
                  validationErrors
                )
              }
            >
              {activeStep === STEPS.length - 1
                ? i18n("CREATE_NOTIFICATIONS_FINISH")
                : i18n("CREATE_NOTIFICATIONS_NEXT")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateFarmSteps;
