import React, { useState, createContext } from "react";
import { ReactJSX } from "../../../../../../../utils/types";
import { Pivot } from "../../../../../../../redux/pivots/types";
import { Segments, Endgun } from "../NewEditPivotV5";
import moment from "moment";

interface Props {
  pivot: Pivot;
  children: ReactJSX;
}

export const EditPivotV5Context = createContext({
  name: null, // geral
  setName: null,
  language: null,
  setLanguage: null,
  radius: null,
  setRadius: null,
  flow: null,
  setFlow: null,
  speed: null,
  setSpeed: null,
  area: null,
  setArea: null,
  brandModel: null,
  setBrandModel: null,
  otherBrand: null,
  setOtherBrand: null,
  panelType: null,
  setPanelType: null,
  checkVoltage: null,
  setCheckVoltage: null,
  voltageMin: null,
  setVoltageMin: null,
  voltageMax: null,
  setVoltageMax: null,
  voltageStableTime: null,
  setVoltageStableTime: null,
  voltageRef: null,
  setVoltageRef: null,

  centerLat: null, // localização
  setCenterLat: null,
  centerLng: null,
  setCenterLng: null,
  centerLatLabel: null,
  setCenterLatLabel: null,
  centerLngLabel: null,
  setCenterLngLabel: null,
  startRefLat: null,
  setStartRefLat: null,
  startRefLng: null,
  setStartRefLng: null,
  startLatLabel: null,
  setStartLatLabel: null,
  startLngLabel: null,
  setStartLngLabel: null,
  referenceNorth: null,
  setReferenceNorth: null,
  endRefLat: null,
  setEndRefLat: null,
  endRefLng: null,
  setEndRefLng: null,
  endLatLabel: null,
  setEndLatLabel: null,
  endLngLabel: null,
  setEndLngLabel: null,

  potency: null, // bomba
  setPotency: null,
  powerDelay: null,
  setPowerDelay: null,
  readPressureBy: null,
  setReadPressureBy: null,
  pumpTimeout: null,
  setPumpTimeout: null,
  pumpPressDelay: null,
  setPumpPressDelay: null,
  pumpPressSwitch: null,
  setPumpPressSwitch: null,
  sensorMinRange: null,
  setSensorMinRange: null,
  sensorMaxRange: null,
  setSensorMaxRange: null,
  sensorScaleEnd: null,
  setSensorScaleEnd: null,
  injectionPump: null,
  setInjectionPump: null,
  pumpSoftStartTime: null,
  setPumpSoftStartTime: null,
  sensorFactor: null,
  setSensorFactor: null,
  pilotAveragePower: null,
  setPilotAveragePower: null,
  canalAveragePower: null,
  setCanalAveragePower: null,

  pluviometerStopMode: null, // pluviômetro
  setPluviometerStopMode: null,
  mmToStop: null,
  setMMToStop: null,
  pluviometerScale: null,
  setPluviometerScale: null,

  valueKwhPeak: null, // horário de pico
  setValueKwhPeak: null,
  valueKwhOutOfPeak: null,
  setValueKwhOutOfPeak: null,
  valueKwhReduced: null,
  setValueKwhReduced: null,
  weekday: null,
  setWeekday: null,
  pauseTimeStatus1: null,
  setPauseTimeStatus1: null,
  pauseTimeStart1: null,
  setPauseTimeStart1: null,
  pauseTimeEnd1: null,
  setPauseTimeEnd1: null,
  pauseTimeStatus2: null,
  setPauseTimeStatus2: null,
  pauseTimeStart2: null,
  setPauseTimeStart2: null,
  pauseTimeEnd2: null,
  setPauseTimeEnd2: null,

  angleEnd: null, // segmentos e plantio
  setAngleEnd: null,
  arrayAngleStart: null,
  setArrayAngleStart: null,
  arrayAngleEnd: null,
  setArrayAngleEnd: null,
  // O reload foi uma forma de redesenhar  os segmentos configurados pelo  usuário
  // pois ao carregar uma configuração do histórico de configurações os dados eram
  // carregados corretamente, porém não existia um trigger para redesenhar os seg-
  // mentos no componente com o mapa.
  reload: null,
  setReload: null,
  segmentsArray: null,
  setSegmentsArray: null,

  endgunMode: null, // canhão final
  setEndgunMode: null,
  endgunArray: null,
  setEndgunArray: null,
  arrayEndgunAngleStart: null,
  setArrayEndgunAngleStart: null,
  arrayEndgunAngleEnd: null,
  setArrayEndgunAngleEnd: null,
  checkLocation: null,
  setCheckLocation: null,
  eAngleStart: null,
  setEAngleStart: null,
  eAngleEnd: null,
  setEAngleEnd: null,

  autoreversionMode: null, // autoreversão
  setAutoreversionMode: null,
  autoreversionTime: null,
  setAutoreversionTime: null,
});

export const EditPivotV5Provider = (props: Props) => {
  const { pivot, children } = props;
  const config = pivot.controllerconfig;

  // GERAL ------------------------------------------------

  const [name, setName] = useState(config.name_pivot_on_config);

  const [language, setLanguage] = useState(
    String(config.content.language.language)
  );

  const [radius, setRadius] = useState(
    String(config.content.pivot_parameters.radius_last)
  );

  const [flow, setFlow] = useState(
    String(config.content.pivot_parameters.flow_rate)
  );

  const [speed, setSpeed] = useState(
    String(config.content.pivot_parameters.speed)
  );

  const [area, setArea] = useState(
    String(config.content.pivot_parameters.irrigated_area)
  );

  const [brandModel, setBrandModel] = useState(config.brand_model);

  const [otherBrand, setOtherBrand] = useState(config.brand_model);

  const [panelType, setPanelType] = useState(config.panel_type);

  const [checkVoltage, setCheckVoltage] = useState(
    config.content?.voltage_limit_enable?.voltage_limit_enable === 1
  );

  const [voltageMin, setVoltageMin] = useState(
    String(config.content.voltage_configurations.minimum_voltage)
  );

  const [voltageMax, setVoltageMax] = useState(
    String(config.content.voltage_configurations.maximum_voltage)
  );

  const [voltageStableTime, setVoltageStableTime] = useState(
    String(config.content.voltage_configurations.stable_time)
  );

  // not on screen:

  const [voltageRef, setVoltageRef] = useState(
    String(config.content.voltage_configurations.voltage_reference)
  );

  // LOCALIZAÇÃO ------------------------------------------------

  const [centerLat, setCenterLat] = useState<String>(
    String(config.content.pivot_positions.latitude_center).substring(0, 10)
  );

  const [centerLng, setCenterLng] = useState<String>(
    String(config.content.pivot_positions.longitude_center).substring(0, 10)
  );

  const [centerLatLabel, setCenterLatLabel] = useState(
    String(config.content.pivot_positions.latitude_center).substring(0, 10)
  );

  const [centerLngLabel, setCenterLngLabel] = useState(
    String(config.content.pivot_positions.longitude_center).substring(0, 10)
  );

  const [startRefLat, setStartRefLat] = useState<String>(
    String(config.content.pivot_positions.latitude_reference).substring(0, 10)
  );

  const [startRefLng, setStartRefLng] = useState<String>(
    String(config.content.pivot_positions.longitude_reference).substring(0, 10)
  );

  const [startLatLabel, setStartLatLabel] = useState(
    String(config.content.pivot_positions.latitude_reference).substring(0, 10)
  );

  const [startLngLabel, setStartLngLabel] = useState(
    String(config.content.pivot_positions.longitude_reference).substring(0, 10)
  );

  const [referenceNorth, setReferenceNorth] = useState<any>(
    config.content.pivot_positions.north_reference
  );

  // not on screen:

  const [endRefLat, setEndRefLat] = useState<String>(
    String(config.content.pivot_positions.latitude_reference).substring(0, 10)
  );
  const [endRefLng, setEndRefLng] = useState<String>(
    String(config.content.pivot_positions.longitude_reference).substring(0, 10)
  );
  const [endLatLabel, setEndLatLabel] = useState(
    String(config.content.pivot_positions.latitude_reference).substring(0, 10)
  );
  const [endLngLabel, setEndLngLabel] = useState(
    String(config.content.pivot_positions.longitude_reference).substring(0, 10)
  );

  // BOMBA ------------------------------------------------

  const [potency, setPotency] = useState(String(config.potency));

  const [powerDelay, setPowerDelay] = useState(
    String(config.content.power_delay.power_delay)
  );

  const [readPressureBy, setReadPressureBy] = useState(
    String(config.content.pressure_config.read_pressure_by)
  );

  const [pumpTimeout, setPumpTimeout] = useState(
    String(config.content.pressure_config.pump_time_out)
  );

  const [pumpPressDelay, setPumpPressDelay] = useState(
    String(config.content.pressure_config.pump_press_delay)
  );

  const [pumpPressSwitch, setPumpPressSwitch] = useState(
    String(config.content.pressure_config.pump_press_switch)
  );

  const [sensorMaxRange, setSensorMaxRange] = useState(
    String(config.content.pressure_config.press_sensor_max_range)
  );

  const [sensorMinRange, setSensorMinRange] = useState(
    String(config.content.pressure_config.press_sensor_min_range)
  );

  const [sensorScaleEnd, setSensorScaleEnd] = useState(
    String(config.content.pressure_config.sensor_scale_end)
  );

  const [injectionPump, setInjectionPump] = useState(config.injection_pump);

  // not on screen:

  const [pumpSoftStartTime, setPumpSoftStartTime] = useState(
    String(config.content.pressure_config.pump_soft_start_time)
  );

  const [sensorFactor, setSensorFactor] = useState(
    String(config.content.pressure_config.press_sensor_factor)
  );

  const [pilotAveragePower, setPilotAveragePower] = useState("0");

  const [canalAveragePower, setCanalAveragePower] = useState("0");

  // PLUVIÔMETRO ------------------------------------------------

  // Juntamos em um mesmo select o pluviometer_enable e o pluviometer_stop_mode
  const [pluviometerStopMode, setPluviometerStopMode] = useState<
    string | undefined
  >(
    pivot?.pluviometer && pivot?.protocol === 5
      ? config.content?.pluviometer_enable?.enable
        ? String(config.content.pluviometer_stop_mode?.stop_mode)
        : "0"
      : undefined
  );

  const [mmToStop, setMMToStop] = useState<number | undefined>(
    pivot?.pluviometer && pivot?.protocol === 5
      ? config.content?.mm_to_stop?.value
      : undefined
  );

  const [pluviometerScale, setPluviometerScale] = useState<number | undefined>(
    !config.content?.pluviometer_scale?.mm
      ? 0.2
      : config.content?.pluviometer_scale?.mm
  );

  // HORÁRIO DE PICO ------------------------------------------------

  const [valueKwhPeak, setValueKwhPeak] = useState(
    config.kwh_peak ? config.kwh_peak : "1"
  );

  const [valueKwhOutOfPeak, setValueKwhOutOfPeak] = useState(
    config.kwh_out_of_peak ? config.kwh_out_of_peak : "1"
  );

  const [valueKwhReduced, setValueKwhReduced] = useState(
    config.kwh_reduced ? config.kwh_reduced : "1"
  );

  const [weekday, setWeekday] = useState<any>({
    mon: config.content.pause_time.enable_monday,
    tue: config.content.pause_time.enable_tuesday,
    wed: config.content.pause_time.enable_wednesday,
    thu: config.content.pause_time.enable_thursday,
    fri: config.content.pause_time.enable_friday,
    sat: config.content.pause_time.enable_saturday,
    sun: config.content.pause_time.enable_sunday,
  });

  const [pauseTimeStatus1, setPauseTimeStatus1] = useState<any>(
    (config.content?.pause_time.start_pause_time_hour_1 === 0 &&
      config.content?.pause_time.start_pause_time_minute_1 === 0 &&
      config.content?.pause_time.end_pause_time_hour_1 === 0 &&
      config.content?.pause_time.end_pause_time_minute_1 === 0) ||
      config.content?.pause_time_command?.pause_time_command === false
      ? false
      : true
  );

  const [pauseTimeStart1, setPauseTimeStart1] = useState(
    moment().set({
      hour: config.content.pause_time.start_pause_time_hour_1,
      minute: config.content.pause_time.start_pause_time_minute_1,
      second: 0,
      millisecond: 0,
    })
  );

  const [pauseTimeEnd1, setPauseTimeEnd1] = useState(
    moment().set({
      hour: config.content.pause_time.end_pause_time_hour_1,
      minute: config.content.pause_time.end_pause_time_minute_1,
      second: 0,
      millisecond: 0,
    })
  );

  const [pauseTimeStatus2, setPauseTimeStatus2] = useState(
    (config.content?.pause_time.start_pause_time_hour_2 === 0 &&
      config.content?.pause_time.start_pause_time_minute_2 === 0 &&
      config.content?.pause_time.end_pause_time_hour_2 === 0 &&
      config.content?.pause_time.end_pause_time_minute_2 === 0) ||
      pauseTimeStatus1 === false
      ? false
      : true
  );

  const [pauseTimeStart2, setPauseTimeStart2] = useState(
    moment().set({
      hour: config.content.pause_time.start_pause_time_hour_2,
      minute: config.content.pause_time.start_pause_time_minute_2,
      second: 0,
      millisecond: 0,
    })
  );

  const [pauseTimeEnd2, setPauseTimeEnd2] = useState(
    config.content?.pause_time.start_pause_time_hour_2 === 0 &&
      config.content?.pause_time.start_pause_time_minute_2 === 0 &&
      config.content?.pause_time.end_pause_time_hour_2 === 0 &&
      config.content?.pause_time.end_pause_time_minute_2 === 0
      ? moment().set({
          hour: 0,
          minute: 1,
          second: 0,
          millisecond: 0,
        })
      : moment().set({
          hour: config.content.pause_time.end_pause_time_hour_2,
          minute: config.content.pause_time.end_pause_time_minute_2,
          second: 0,
          millisecond: 0,
        })
  );

  // SEGMENTOS E PLANTIO ------------------------------------------------

  const [angleEnd, setAngleEnd] = useState(
    String(config.content.sector?.end_angle)
  );

  // not on screen:

  const [reload, setReload] = useState(false);

  const [arrayAngleStart, setArrayAngleStart] = useState<String[]>(
    config.content.segments
      ? config.content.segments.map((value: any) => String(value.angle_start))
      : [0]
  );

  const [arrayAngleEnd, setArrayAngleEnd] = useState<String[]>(
    config.content.segments
      ? config.content.segments.map((value: any, index: number) => {
          return {
            angle: String(value.angle_end),
            name: config.segments_crop[index]?.name,
            segment_type: config.segments_crop[index]?.segment_type,
            crop_plant_date: config.segments_crop[index]?.crop_plant_date
              ? config.segments_crop[index]?.crop_plant_date
              : null,
            crop_harvest_date: config.segments_crop[index]?.crop_harvest_date
              ? config.segments_crop[index]?.crop_harvest_date
              : null,
          };
        })
      : [
          {
            angle: 360,
            name: "",
            segment_type: "",
            crop_plant_date: null,
            crop_harvest_date: null,
          },
        ]
  );

  const [segmentsArray, setSegmentsArray] = useState<Segments[]>([]);

  // CANHÃO FINAL ------------------------------------------------

  const [endgunMode, setEndgunMode] = useState(
    String(config.content.endgun_mode.endgun_mode)
  );

  const [endgunArray, setEndgunArray] = useState<Endgun[]>(
    config.content.endgun_angles?.length
      ? config.content.endgun_angles
      : [{ start_angle: 0, end_angle: 0, number_editing: 0 }]
  );

  // not on screen:

  const [arrayEndgunAngleStart, setArrayEndgunAngleStart] = useState<String[]>(
    config.content.endgun_angles.map((value: any) => String(value.start_angle))
  );

  const [arrayEndgunAngleEnd, setArrayEndgunAngleEnd] = useState<String[]>(
    config.content.endgun_angles.map((value: any) => String(value.end_angle))
  );

  const [checkLocation, setCheckLocation] = useState(false);

  const [eAngleStart, setEAngleStart] = useState("0");

  const [eAngleEnd, setEAngleEnd] = useState("0");

  // AUTOREVERSÃO ------------------------------------------------

  const [autoreversionMode, setAutoreversionMode] = useState<any>(
    config?.content?.autoreversion_command?.command == 0
      ? -1
      : config?.content?.autoreversion_configurations?.mode
  );

  const [autoreversionTime, setAutoreversionTime] = useState<any>(
    config?.content?.autoreversion_configurations?.time
  );

  return (
    <EditPivotV5Context.Provider
      value={{
        name, // geral
        setName,
        language,
        setLanguage,
        radius,
        setRadius,
        flow,
        setFlow,
        speed,
        setSpeed,
        area,
        setArea,
        brandModel,
        setBrandModel,
        otherBrand,
        setOtherBrand,
        panelType,
        setPanelType,
        checkVoltage,
        setCheckVoltage,
        voltageMin,
        setVoltageMin,
        voltageMax,
        setVoltageMax,
        voltageStableTime,
        setVoltageStableTime,
        voltageRef,
        setVoltageRef,

        centerLat, // localização
        setCenterLat,
        centerLng,
        setCenterLng,
        centerLatLabel,
        setCenterLatLabel,
        centerLngLabel,
        setCenterLngLabel,
        startRefLat,
        setStartRefLat,
        startRefLng,
        setStartRefLng,
        startLatLabel,
        setStartLatLabel,
        startLngLabel,
        setStartLngLabel,
        referenceNorth,
        setReferenceNorth,
        endRefLat,
        setEndRefLat,
        endRefLng,
        setEndRefLng,
        endLatLabel,
        setEndLatLabel,
        endLngLabel,
        setEndLngLabel,

        potency, // bomba
        setPotency,
        powerDelay,
        setPowerDelay,
        readPressureBy,
        setReadPressureBy,
        pumpTimeout,
        setPumpTimeout,
        pumpPressDelay,
        setPumpPressDelay,
        pumpPressSwitch,
        setPumpPressSwitch,
        sensorMinRange,
        setSensorMinRange,
        sensorMaxRange,
        setSensorMaxRange,
        sensorScaleEnd,
        setSensorScaleEnd,
        injectionPump,
        setInjectionPump,
        pumpSoftStartTime,
        setPumpSoftStartTime,
        sensorFactor,
        setSensorFactor,
        pilotAveragePower,
        setPilotAveragePower,
        canalAveragePower,
        setCanalAveragePower,

        pluviometerStopMode, // pluviômetro
        setPluviometerStopMode,
        mmToStop,
        setMMToStop,
        pluviometerScale,
        setPluviometerScale,

        valueKwhPeak, // horário de pico
        setValueKwhPeak,
        valueKwhOutOfPeak,
        setValueKwhOutOfPeak,
        valueKwhReduced,
        setValueKwhReduced,
        weekday,
        setWeekday,
        pauseTimeStatus1,
        setPauseTimeStatus1,
        pauseTimeStart1,
        setPauseTimeStart1,
        pauseTimeEnd1,
        setPauseTimeEnd1,
        pauseTimeStatus2,
        setPauseTimeStatus2,
        pauseTimeStart2,
        setPauseTimeStart2,
        pauseTimeEnd2,
        setPauseTimeEnd2,

        angleEnd, // segmentos e plantio
        setAngleEnd,
        arrayAngleStart,
        setArrayAngleStart,
        arrayAngleEnd,
        setArrayAngleEnd,
        reload,
        setReload,
        segmentsArray,
        setSegmentsArray,

        endgunMode, // canhão final
        setEndgunMode,
        endgunArray,
        setEndgunArray,
        arrayEndgunAngleStart,
        setArrayEndgunAngleStart,
        arrayEndgunAngleEnd,
        setArrayEndgunAngleEnd,
        checkLocation,
        setCheckLocation,
        eAngleStart,
        setEAngleStart,
        eAngleEnd,
        setEAngleEnd,

        autoreversionMode, // autoreversão
        setAutoreversionMode,
        autoreversionTime,
        setAutoreversionTime,
      }}
    >
      {children}
    </EditPivotV5Context.Provider>
  );
};
