import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { MeterSystem } from "../../../../../../recoils/MeterSystemRecoil";
import { getIMeterEventStreamStatus } from "../../../../../../utils/models/imeter";
import IMetersMap from "../IMetersMap/IMetersMap";
import moment from "moment";
import { IrrigationV5 } from "../../../../../../redux/pivots/types";
import { isMobile } from "../../../../../../mobileConfig";
import { i18n } from "../../../../../../i18n/i18nText";
import { Battery20, BatteryFull, Straighten } from "@material-ui/icons";
import QuickSelect from "../../../../../../components/QuickSelect/QuickSelect";
import { formatFloatValue } from "../../../../../../utils/models/format";
// import { useParams } from "react-router";

const General = styled.div`
  display: flex;
  flex-direction: column;
`;

const GeneralInner = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
`;

const GeneralContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px;

  h2 {
    margin: 8px 0px;
    font-weight: 500;
    font-size: 1.8rem;
  }
`;

const Status = styled.div`
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffd700;
  font-weight: 600;
  font-size: 0.7rem;
  margin-left: 5px;
  padding: 4px 8px;
  border-radius: 8px;
  color: white;
`;

const GeneralContentItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: "flex-start";
  justify-content: "space-between";
  margin-top: ${isMobile() ? "15px" : "2px"};
`;

const IManageItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const GeneralBoxItem = styled.div`
  margin-top: 12px;
  font-size: 0.8rem;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #444;
`;

const GeneralBoxItem2 = styled.div`
  margin-top: 12px;
  font-size: 0.8rem;
  margin-right: 12px;
  margin-left: -6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #444;
`;

const GeneralBoxItem3 = styled.div`
  margin-top: 12px;
  font-size: 0.8rem;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: red;
`;

const GeneralBoxItem4 = styled.div`
  margin-top: 2px;
  font-size: 0.8rem;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: orange;
`;

interface Props {
  metersystem: MeterSystem;
  farmID: number;
}

function MeterSystemGeneralBox(props: Props) {
  let imeter = props.metersystem.imeter_set[0];

  const farmID = props.farmID;

  // const farmID: number = parseInt(useParams().farm, 10);

  const [latestStream, setLatestStream] = useState<{
    latest: IrrigationV5;
    periodic: IrrigationV5;
    event: IrrigationV5;
  }>();

  useEffect(() => {
    if (
      imeter?.latest_event_stream &&
      moment(imeter?.latest_event_stream.created).isAfter(
        moment(imeter?.latest_periodic_stream?.created)
      )
    ) {
      setLatestStream({
        latest: imeter?.latest_event_stream,
        periodic: imeter?.latest_periodic_stream,
        event: imeter?.latest_event_stream,
      });
    } else {
      setLatestStream({
        latest: imeter?.latest_periodic_stream,
        periodic: imeter?.latest_periodic_stream,
        event: imeter?.latest_event_stream,
      });
    }
  }, [imeter]);

  return isMobile() ? (
    <GeneralContentItems>
      <IManageItem>
        {props.metersystem.imeter_set[0].name}
        <Status
          style={{
            backgroundColor: getIMeterEventStreamStatus(
              latestStream?.latest?.content?.imanage_master_status?.status
            ).color,
          }}
        >
          {getIMeterEventStreamStatus(
            latestStream?.latest.content?.imanage_master_status?.status
          ).text.toUpperCase()}
        </Status>
      </IManageItem>

      <GeneralBoxItem>
        {i18n("GENERAL_BOX_LAST_UPDATE")}{" "}
        {moment(latestStream?.latest.created).format("DD MMM HH:mm")}
      </GeneralBoxItem>

      {props.metersystem.imeter_set[0].protocol === "5.3" ? (
        <GeneralBoxItem2>
          <Straighten
            style={{
              transform: "rotate(270deg)",
            }}
          />
          {`${imeter.water_level_percentage}% (${imeter.water_level_meters})m)`}
        </GeneralBoxItem2>
      ) : (
        <GeneralBoxItem2>
          <Straighten
            style={{
              transform: "rotate(270deg)",
            }}
          />
          <div>
            {`${imeter.water_level_percentage}% (${imeter.water_level_meters})m)`}
          </div>
        </GeneralBoxItem2>
      )}

      {props.metersystem.imeter_set[0].protocol === "5.3" ? (
        <div className="generalbox__content__item" style={{ marginTop: 8 }}>
          {!isNaN(
            Number(latestStream?.latest?.content?.battery_level?.battery_level)
          ) ? (
            <>
              {latestStream?.latest?.content?.battery_level?.battery_level ===
              1 ? (
                <>
                  <BatteryFull
                    style={{
                      marginRight: "8px",
                      color: "green",
                      opacity: 0.85,
                      height: 20,
                      width: 20,
                    }}
                  />
                  {i18n("BATTERY_LEVEL_CHARGED")}
                </>
              ) : (
                <>
                  <Battery20
                    style={{
                      marginRight: "8px",
                      color: "red",
                      opacity: 0.85,
                      height: 20,
                      width: 20,
                    }}
                  />
                  {i18n("BATTERY_LEVEL_LOW")}
                </>
              )}
            </>
          ) : null}
        </div>
      ) : null}

      {imeter.latest_config.max_limit !== 0 &&
      imeter.latest_config.min_limit !== 0 ? (
        <>
          <GeneralBoxItem3>
            {i18n("GENERAL_BOX_MAX_LIMIT")} {imeter.latest_config.max_limit}
            {"%"}
          </GeneralBoxItem3>
          <GeneralBoxItem4>
            {i18n("GENERAL_BOX_MIN_LIMIT")} {imeter.latest_config.min_limit}
            {"%"}
          </GeneralBoxItem4>{" "}
        </>
      ) : null}
    </GeneralContentItems>
  ) : (
    <General>
      <GeneralInner>
        <MapContainer>
          <IMetersMap
            draggable={false}
            imeters={[props.metersystem.imeter_set[0]]}
          />
        </MapContainer>

        <GeneralContent>
          <QuickSelect farmID={farmID} />

          <GeneralContentItems>
            <IManageItem>
              {props.metersystem.imeter_set[0].name}

              <Status
                style={{
                  backgroundColor: getIMeterEventStreamStatus(
                    latestStream?.latest?.content?.imanage_master_status?.status
                  ).color,
                }}
              >
                {getIMeterEventStreamStatus(
                  latestStream?.latest?.content?.imanage_master_status?.status
                ).text.toUpperCase()}
              </Status>
            </IManageItem>

            <GeneralBoxItem>
              {i18n("GENERAL_BOX_LAST_UPDATE")}{" "}
              {moment(latestStream?.latest.created).format("DD MMM HH:mm")}
            </GeneralBoxItem>

            {props.metersystem.imeter_set[0].protocol === "5.3" ? (
              <GeneralBoxItem2>
                <Straighten
                  style={{
                    transform: "rotate(270deg)",
                  }}
                />
                {`${imeter.water_level_percentage}% (${imeter.water_level_meters}m)`}
              </GeneralBoxItem2>
            ) : (
              <GeneralBoxItem2>
                <Straighten
                  style={{
                    transform: "rotate(270deg)",
                  }}
                />
                {`${imeter.water_level_percentage}% (${imeter.water_level_meters}m)`}
              </GeneralBoxItem2>
            )}

            {props.metersystem.imeter_set[0].protocol === "5.3" ? (
              <div
                className="generalbox__content__item"
                style={{ marginTop: 8 }}
              >
                {!isNaN(
                  Number(
                    latestStream?.latest?.content?.battery_level?.battery_level
                  )
                ) ? (
                  <>
                    {latestStream?.latest?.content?.battery_level
                      ?.battery_level === 1 ? (
                      <>
                        <BatteryFull
                          style={{
                            marginRight: "8px",
                            color: "green",
                            opacity: 0.85,
                            height: 20,
                            width: 20,
                          }}
                        />
                        {i18n("BATTERY_LEVEL_CHARGED")}
                      </>
                    ) : (
                      <>
                        <Battery20
                          style={{
                            marginRight: "8px",
                            color: "red",
                            opacity: 0.85,
                            height: 20,
                            width: 20,
                          }}
                        />
                        {i18n("BATTERY_LEVEL_LOW")}
                      </>
                    )}
                  </>
                ) : null}
              </div>
            ) : null}

            {imeter.latest_config.max_limit !== 0 &&
            imeter.latest_config.min_limit !== 0 ? (
              <>
                <GeneralBoxItem3>
                  {i18n("GENERAL_BOX_MAX_LIMIT")}{" "}
                  {imeter.latest_config.max_limit}
                  {"%"}
                </GeneralBoxItem3>
                <GeneralBoxItem4>
                  {i18n("GENERAL_BOX_MIN_LIMIT")}{" "}
                  {imeter.latest_config.min_limit}
                  {"%"}
                </GeneralBoxItem4>{" "}
              </>
            ) : null}
          </GeneralContentItems>
        </GeneralContent>
      </GeneralInner>
    </General>
  );
}

export default React.memo(MeterSystemGeneralBox);
