import { i18nTextId } from "./i18nText";

const i18n_ptBr: { [id in i18nTextId]: string } = {
  PAGE_REGISTER_TITLE: "Registrar",
  PAGE_REGISTER_LABEL_LANGUAGE: "Idioma",
  PAGE_REGISTER_LABEL_EMAIL: "E-mail",
  PAGE_REGISTER_LABEL_USERNAME: "Usuário",
  PAGE_REGISTER_LABEL_PASSWORD: "Senha",
  PAGE_REGISTER_LABEL_CONFIRM_PASSWORD: "Confirme a Senha",
  PAGE_REGISTER_LABEL_FIRST_NAME: "Nome",
  PAGE_REGISTER_LABEL_LAST_NAME: "Sobrenome",
  PAGE_REGISTER_SUBMIT_BUTTON_TEXT: "Registrar",
  PAGE_REGISTER_USERNAME_IN_USE: "Nome de usuário indisponível",
  PAGE_REGISTER_TOKEN_ERROR: "Token expirou ou é inválido",
  PAGE_REGISTER_PASSWORD_UNMATCH: "Senha e confirma senha não correspondem",
  VALIDATION_FIELD_REQUIRED: "Campo obrigatório",
  VALIDATION_FIELD_INVALID: "Campo inválido",
  VALIDATION_FIELD_MIN: "Mínimo ${} digitos",
  VALIDATION_FIELD_MAX: "Máximo ${} digitos",
  VALIDATION_STRONGER_PASSWORD: "Não é forte suficiente",
  SELECTED_FARM_MAP_LABEL: "Mapa",
  SELECTED_FARM_EDIT_BUTTON_TEXT: "Editar",
  SELECTED_FARM_FILTER_BUTTON_TEXT: "Filtrar",
  SELECTED_FARM_FILTER_NAME: "Nome",
  SELECTED_FARM_FILTER_PROBLEM: "Problema",
  SELECTED_FARM_PIVOTS: "Pivôs",
  SELECTED_FARM_IRPDS: "Bombas",
  SELECTED_FARM_SWEEP_BUTTON_TEXT: "Varredura",
  SELECTED_FARM_SWEEP_EXECUTING_TEXT: "Executando Varredura...",
  SELECTED_FARM_SWEEP_END_TEXT: "Varredura finalizada!",
  SELECTED_FARM_SWEEP_GENERIC_ERROR: "Falha na varredura de equipamentos",
  DEVICE_DRAWER_TITLE: "Dispositivos",
  DEVICE_BOX_VERY_STRONG: "Muito Bom",
  DEVICE_BOX_STRONG: "Bom",
  DEVICE_BOX_MODERATE: "Moderado",
  DEVICE_BOX_WEAK: "Fraco",
  DEVICE_BOX_ERROR: "Erro",
  DEVICE_NO_LOGS: "Inicie uma varredura para ter entradas no log.",
  EDIT_FARM_LABEL: "Editar Fazenda",
  EDIT_FARM_GENERAL_INFORMATION: "Informações",
  EDIT_FARM_GENERAL_CONTACT: "Contato",
  EDIT_FARM_ENERGY_RANGES: "Faixas de Energia",
  EDIT_FARM_ENERGY_RANGES_GENERATED: "Tempo restante gerado automaticamente",
  EDIT_FARM_ENERGY_RANGES_ADD_BUTTON: "Adicionar",
  EDIT_FARM_ENERGY_RANGES_REMOVE_BUTTON: "Remover",
  EDIT_FARM_USERS: "Usuários",
  EDIT_FARM_USER: "Usuário",
  EDIT_FARM_VISITORS: "VISITANTES",
  EDIT_FARM_OPERATORS: "Operadores",
  EDIT_FARM_CODE: "Código da fazenda: ",
  EDIT_FARM_EMPLOYEES: "Usuários",
  EDIT_FARM_RESALE: "Revenda",
  EDIT_FARM_APPROVE: "Aprovar",
  EDIT_FARM_PERMISSIONS: "Permissões",
  EDIT_FARM_VIEW: "Visualizar",
  EDIT_FARM_SEND_INVITE_AGAIN: "Enviar convite novamente",
  EDIT_FARM_RESEND_INVITATION_SUCCESS: "Convite enviado.",
  EDIT_FARM_RESEND_INVITATION_FAILED:
    "Não foi possível reenviar o convite. Tente novamente mais tarde.",
  EDIT_FARM_REMOVE: "Remover",
  EDIT_FARM_REMOVE_INFO: "Deseja remover o usuário ${}?",
  EDIT_FARM_REMOVE_REPEATER_INFO: "Deseja remover o repetidor ${}?",
  EDIT_FARM_BUTTON_CANCEL: "Cancelar",
  EDIT_FARM_BUTTON_DETELE: "Excluir",
  EDIT_FARM_TITLE_PERMISSIONS: "Editar permissões",
  EDIT_FARM_TAG_VIEW: "Visualizar",
  EDIT_FARM_TAG_OPERATE: "Operar",
  EDIT_FARM_BUTTON_SAVE: "Salvar",
  EDIT_FARM_GROUPS: "Grupos",
  EDIT_FARM_GROUPS_INFO:
    "Configure grupos de pivôs para obter estatísticas e relatórios por grupo.",
  EDIT_FARM_BUTTON_CREATE_GROUP: "Criar grupo de pivôs",
  EDIT_FARM_BUTTON_ADD_POWER_RANGE: "Adicionar Faixas de Energia",
  SELECTED_FARM_DELETE_BUTTON_TEXT: "Apagar",
  EDIT_FARM_ADMINISTRATOR: "Administrador",
  EDIT_FARM_TEMPORARY_ADMINISTRATOR: "Administrador temporário:",
  EDIT_FARM_REGISTER_USER:
    "Insira o nome, nome de usuário ou e-mail do usuário a ser convidado.",
  EDIT_FARM_REGISTER_USER_LANG: "Idioma do convite",
  EDIT_FARM_BUTTON_REGISTER_USER: "Cadastrar Usuário",
  EDIT_FARM_BUTTON_EDIT: "Editar",
  EDIT_FARM_NAME_GROUP: "Nome do grupo",
  EDIT_FARM_REPEATERS: "Repetidoras",
  EDIT_FARM_BUTTON_REGISTER_REPEATERS: "Cadastrar repetidores",
  EDIT_FARM_USER_PENDING: "Pendente",
  EDIT_FARM_GROUP_NAME: "Nome do grupo",
  EDIT_FARM_EDIT_REPEATER: "Editar Repetidora",
  EDIT_FARM_USER_DELETE_SUCCESS: "Usuário removido",
  EDIT_FARM_USER_PERMISSION_SUCCESS: "Permissões salvas com sucesso",
  EDIT_FARM_GROUP_SUCCESS: "Grupo criado",
  EDIT_FARM_EDIT_GROUP_SUCCESS: "Grupo editado",
  EDIT_FARM_EDIT_GROUP_REMOVED: "Grupo removido",
  EDIT_FARM_GROUP_NAME_ERROR: "Nome do grupo não preenchido",
  EDIT_FARM_GROUP_PIVOT_ERROR: "Pivôs não selecionados",
  EDIT_FARM_REPEATER_SUCCESS: "Repetidora cadastrada",
  EDIT_FARM_REPEATER_ERROR: "Erro ao cadastrar repetidor",
  EDIT_FARM_REPEATER_DELETE_SUCCESS: "Repetidora deletado",
  EDIT_FARM_EDIT_REPEATER_SUCCESS: "Repetidora editada",
  EDIT_FARM_REPEATER_NAME_ERROR: "Nome da repetidora não preenchida",
  EDIT_FARM_TIMEZONE: "Fuso Horário",
  EDIT_FARM_NO_REGISTERED_USERS: "Nenhum usuário cadastrado.",
  EDIT_FARM_NO_REGISTERED_GROUPS: "Nenhum grupo cadastrado.",
  EDIT_FARM_NO_REGISTERED_REPEATERS: "Nenhum repetidor cadastrado.",
  HISTORIC_SCHEDULE_IRRIGATION_INDEX: "Agendamento ${}:",
  HISTORIC_SCHEDULE_IRRIGATION_MODE: "Modo: ",
  HISTORIC_SCHEDULE_IRRIGATION_PERCENT: "Percentímetro: ",
  HISTORIC_SCHEDULE_IRRIGATION_DIRECTION: "Direção: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_DATE: "Data de Início: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_DATE: "Data Final: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ROUNDS: "Término após: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ANGLE: "Ângulo Final: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_SECTOR: "Término no final do setor",
  HISTORIC_SCHEDULE_IRRIGATION_END_MODE: "Modo de Parada: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_END: "Início - Fim: ${} - ${}",
  FORGET_PASSWORD_OPTION: "Seleciona uma opção",
  FORGET_PASSWORD_OPTION_USER: "Esqueci meu usuário ou e-mail",
  ORGET_PASSWORD_OPTION_PASSWORD: "Esqueci minha senha",
  FORGET_PASSWORD_TYPE_EMAIL: "Digite seu e-mail",
  FORGET_PASSWORD_INFO_USER:
    "Entre em contato com o administrador da fazenda ou com o revendedor local.",
  FORGET_PASSWORD_INFO_EMAIL:
    "Um e-mail foi enviado para o endereço:\n\n${}\n\nSiga as instruções no e-mail e recupere sua senha.\n\n Este e-mail pode demorar até 1 hora para chegar.",
  FORGET_PASSWORD_RECOVEY: "Digite sua nova senha.",
  FORGET_PASSWORD_NEW_PASSWORD: "Nova senha",
  FORGET_PASSWORD_REPEAT_NEW_PASSWORD: "Repita a nova senha",
  FORGET_PASSWORD_SAVE: "Salvar senha",
  FORGET_PASSWORD_OK: "OK",
  FORGET_PASSWORD_MODAL_SUCCESS: "Senha alterada com sucesso!",
  CHANGE_PASSWORD_ERROR:
    "Erro ao alterar senha, talvez essa não seja sua senha atual",
  FORGET_PASSWORD_DIFFERENT:
    "A nova senha e a senha de confirmação são diferentes",
  FORGET_PASSWORD_MODAL_EMAIL_FAILED:
    "Não existe nenhum cadastro com este e-mail",
  EDIT_PIVOT_LABEL: "Editar Pivô",
  EDIT_MONITOR_LABEL: "Editar Monitor de Pivô",
  EDIT_PIVOT_LATITUDE: "Latitude",
  EDIT_PIVOT_LONGITUDE: "Longitude",
  EDIT_PIVOT_SAVE_BUTTON: "Salvar",
  EDIT_PIVOT_LOAD_BUTTON: "Carregar",
  EDIT_PIVOT_LOAD_SUCCESS: "Configuração carregada com sucesso!",
  EDIT_PIVOT_LOAD_ERROR: "Erro ao carregar configuração.",
  EDIT_PIVOT_LABEL_GENERAL: "Geral",
  EDIT_PIVOT_LABEL_LOCATION: "Localização",
  EDIT_PIVOT_IMANAGE_RADIO: "Rádio do iManage",
  EDIT_PIVOT_SENSOR: "Sensores",
  EDIT_PIVOT_LABEL_CLOCK: "Horário",
  EDIT_PIVOT_LABEL_PUMP: "Bomba",
  EDIT_PIVOT_LABEL_PAUSE_TIME: "Horário de Pico",
  EDIT_IRPD_LABEL_LINKED_METER: "Medidor vinculado",
  EDIT_METER_LABEL_LINKED_IRPD: "IRPDs vinculados",
  EDIT_METER_SUBTITLE_LINK_IRPD:
    "Vincule até 10 dispositivos IRPD a este dispositivo Imeter",
  EDIT_PIVOT_LABEL_PLUVIOMETER: "Pluviômetro",
  EDIT_PIVOT_LABEL_END_GUN: "Canhão Final",
  EDIT_PIVOT_LABEL_GENERAL_SETTINGS: "Configurações Gerais",
  EDIT_PIVOT_LABEL_POWER_SETTINGS: "Configurações de Potência",
  EDIT_PIVOT_LABEL_PAUSE_TIME_SETTINGS: "Configurações de Horário de Pico",
  HISTORIC_SIMPLE_IRRIGATION_MODE: "Modo: ",
  HISTORIC_SIMPLE_IRRIGATION_DIRECTION: "Direção: ",
  HISTORIC_SIMPLE_IRRIGATION_PERCENT: "Percentímetro: ",
  HISTORIC_SIMPLE_IRRIGATION_START_MODE: "Modo de Início: ${}",
  HISTORIC_SIMPLE_IRRIGATION_STOP_MODE: "Modo de Parada: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ANGLE: "Ângulo de Parada: ",
  HISTORIC_SIMPLE_IRRIGATION_START_DATE: "Data de Início: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ROUNDS: "Voltas: ",
  HISTORIC_SIMPLE_IRRIGATION_DONT_STOP: "Sem parar",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ANGLE: "Parada por ângulo",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_SECTOR: "Final do setor",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ROUNDS: "Parada por voltas",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_ON: "Ligado",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_OFF: "Desligado",
  GPS_STREAM_TABLE_START: "Criado em",
  GPS_STREAM_TABLE_ANGLE: "Ângulo",
  GPS_STREAM_TABLE_POSITION: "Posição",
  GPS_STREAM_TABLE_DIRECTION: "Direção",
  GPS_STREAM_TABLE_SPEED: "Percentímetro",
  GPS_STREAM_TABLE_STATUS: "Status",
  GPS_STREAM_TABLE_WATER_PRESSURE: "Pressão - Última Torre",
  GPS_STREAM_TABLE_CENTER_PRESSURE: "Pressão - Torre Central",
  GPS_STREAM_TABLE_BATERY: "Bateria",
  GPS_STREAM_TABLE_HOURMETER: "Horímetro",
  GPS_STREAM_TABLE_TITLE: "Lista de Informações de GPS",
  USER_HAS_NO_FARM: "Você não possui acesso a nenhuma fazenda.",
  SELECTED_IRPD_START: "Ligar Bomba",
  SELECTED_IRPD_STOP: "Parar Bomba",
  LANGUAGE_SELECTOR_ENGLISH: "ENGLISH (US)",
  LANGUAGE_SELECTOR_FRANCE: "FRENCH (FR)",
  LANGUAGE_SELECTOR_SPANISH: "ESPAÑOL (ES)",
  LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL: "PORTUGUÊS (BR)",
  LANGUAGE_SELECTOR_RUSSIAN: "РУССКИЙ (RU)",
  LANGUAGE_SELECTOR_GERMAN: "DEUTSCH (AT)",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER: "Centro",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE: "Referência Inicial",
  PIVOT_CONFIG_V5_FIELD_GP_END_REFERENCE: "Referência Final",
  PIVOT_CONFIG_V5_FIELD_RADIUS: "Raio",
  PIVOT_CONFIG_V5_FIELD_FLOW: "Vazão",
  PIVOT_CONFIG_V5_FIELD_DEPTH_WELL: "Escala do sensor",
  PIVOT_CONFIG_V5_FIELD_SPEED: "Veloc. da última torre",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_STABLE_TIME: "Tensão Estável",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_REF: "Tensão de Referência",
  PIVOT_CONFIG_V5_FIELD_CLOCK: "Configurar relógio do equipamento manualmente",
  PIVOT_CONFIG_V5_FIELD_AUTO_SET_CLOCK:
    "Configurar relógio do equipamento com o horário atual",
  PIVOT_CONFIG_V5_FIELD_PUMP_SOFT_START_TIME: "Tempo de Soft Start",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_OFF: "Desligado",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SWITCH: "Por pressostato",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SENSOR: "Por sensor de pressão",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MAX_RANGE: "Valor máximo do sensor",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_FACTOR: "Fator do Sensor",
  PIVOT_CONFIG_V5_FIELD_PERIODIC_STREAM_TIMER:
    "Intervalo de Medição (segundos)",
  PIVOT_CONFIG_V5_FIELD_ANGLE_RANGE: "Alcance do Ângulo",
  PIVOT_CONFIG_V5_PIVOT_SEGMENTS: "Segmentos",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_FORWARD: "Modo Avanço",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_REVERSE: "Modo Reverso",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_FORWARD: "% em Avanço",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_REVERSE: "% em Reverso",
  PIVOT_CONFIG_V5_FIELD_PILOT_AVERAGE_POWER: "Potência Média no Pivô",
  PIVOT_CONFIG_V5_FIELD_CANAL_AVERAGE_POWER: "Potência Média no Canal",
  PIVOT_CONFIG_V5_FIELD_SEGMENT: "Segmento",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_ENGLISH: "Inglês",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_PORTUGUESE: "Português",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_GERMAN: "Alemão",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_SPANISH: "Espanhol",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_RUSSIAN: "Russo",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_NONE: "Nenhum",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_WET: "Molhado",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_DRY: "Seco",
  PIVOT_CONFIG_V5_TITLE_ANGLE: "Ângulo",
  PIVOT_CONFIG_V5_FORWARD: "Avanço",
  PIVOT_CONFIG_V5_REVERSE: "Reverso",
  PIVOT_CONFIG_V5_START: "Início",
  PIVOT_CONFIG_V5_END: "Fim",
  NOTIFICATIONS: "Notificações",
  NO_NOTIFICATION:
    "Você não possui nenhuma notificação.\nClique no botão abaixo para criar.",
  ALERT_ANDROID_ONLY_NOTIFY: "Esta opção só está disponível para Android.",
  ALERT_ITEM_TITLE: "${} Pivô(s)",
  ALERT_ITEM_DO_ENABLE: "Habilitar",
  ALERT_ITEM_DO_DISABLE: "Desabilitar",
  ALERT_ITEM_ENABLED: "Habilitado",
  ALERT_ITEM_DISABLED: "Desabilitado",
  ALERT_ITEM_EDIT: "Editar",
  ALERT_ITEM_DELETE: "Excluir",
  ALERT_BOX_TIP: "**Dica: toque 2x no cartão para editá-lo/excluí-lo",
  SELECTED_ALERT_TIME_RANGE: "Faixa de Horários",
  SELECTED_ALERT_TIME_BETWEEN: "Entre ${} e ${}",
  SELECTED_ALERT_SELECT_PIVOTS: "Selecione os Pivôs",
  SELECTED_ALERT_SELECT_REASONS: "Selecione os Motivos",
  SELECTED_ALERT_REASONS: "Motivos",
  EDIT_ALERT_ERROR_MSG: "Erro. Verifique se todos os campos estão preenchidos.",
  NEW_ALERT_CREATE_NOTIFICATION: "Criar Notificação",
  NEW_ALERT_EDIT_SELECTED: "${} selecionados",
  NEW_ALERT_EDIT_NOTIFICATION: "Editar Notificação",
  NOTIFICATION: "Notificação",
  ALARM: "Alarme",
  BASIC_INFO_BOX_COMMING_SOON: "Em breve",
  BASIC_INFO_BOX_UNEXPECTED_STOPS: "Paradas Inesperadas",
  BASIC_INFO_BOX_LACK_OF_PRESSURE: "Falta de pressão",
  BASIC_INFO_BOX_POWER_OUTAGE: "Queda de energia",
  BASIC_INFO_BOX_MISALIGNMENT: "Desalinhado",
  BASIC_INFO_BOX_POWER_SURGE: "Oscilação de energia",
  BASIC_INFO_BOX_ACTIONS_TITLE: "Acionamentos",
  BASIC_INFO_BOX_ACTIONS_BY_WEB: "Via Internet",
  BASIC_INFO_BOX_ACTIONS_BY_PANEL: "Via Painel",
  BASIC_INFO_BOX_NON_EXECUTED_ACTIONS: "Não Executados",
  BASIC_INFO_BOX_HOURS: "Horas",
  BASIC_INFO_BOX_WET: "Molhado",
  BASIC_INFO_BOX_DRY: "Seco",
  BASIC_INFO_BOX_TOTAL: "Total",
  BASIC_INFO_BOX_WET_ENERGY_CONSUMPTION: "Consumo de Energia Molhado",
  BASIC_INFO_BOX_TYPE: "Tipo",
  BASIC_INFO_BOX_KWH_PRICE: "Tarifa",
  BASIC_INFO_BOX_WATER_CONSUMPTION: "Consumo",
  BASIC_INFO_BOX_PEAK_HOUR: "Ponta",
  BASIC_INFO_BOX_OUT_OF_PEAK_HOUR: "Fora de Ponta",
  BASIC_INFO_BOX_AT_NIGHTTIME: "Noturno",
  BASIC_INFO_BOX_AVERAGE_WATER_BLADE: "Lâmina Média Aplicada",
  BASIC_INFO_BOX_REPORT_GENERATED_BETWEEN: "Relatório gerado entre ${} e ${}",
  BASIC_INFO_BOX_WATER_BLADE_RADAR: "Gráfico de Lâmina",
  BASIC_INFO_BOX_WORK_HOURS: "Horas de Trabalho Molhado",
  BASIC_INFO_BOX_TOTAL_COST: "Custo Total",
  BASIC_INFO_BOX_PONTA: "Ponta",
  BASIC_INFO_BOX_FORA_DE_PONTA: "Fora de Ponta",
  BASIC_INFO_BOX_REDUZIDO: "Reduzido",
  BASIC_INFO_BOX_FLOW: "Vazão:",
  BASIC_INFO_BOX_TOTAL_VALUE: "Total: R$",
  BASIC_INFO_BOX_TIME_RANGES: "Faixas de Horário",
  BASIC_INFO_BOX_WATER_BLADE_MM: "Lâmina (mm)",
  BASIC_INFO_BOX_TOTAL_FEES: "Total:",
  BASIC_INFO_BOX_TOTAL_VOLUME: "Volume total:",
  BASIC_INFO_BOX_TENSION_TITLE: "Tensões do Pivô (V)",
  BASIC_INFO_BOX_LATEST_PRESSURE_BY_ANGLE_TITLE: "Comparativo de Pressão (bar)",
  BASIC_INFO_BOX_TENSION_INFO:
    "Tensões maiores que 700V e menores que 300V foram descartadas.",
  BASIC_INFO_BOX_TENSION_INFO_MIN: "Min",
  BASIC_INFO_BOX_TENSION_INFO_AVERAGE: "Méd",
  BASIC_INFO_BOX_TENSION_INFO_MAX: "Máx",
  BASIC_INFO_BOX_TENSION: "Tensões (V)",
  BASIC_INFO_BOX_TENSION_DATES: "Data",
  BASIC_INFO_BOX_PRESSURE: "Pressão (bar)",
  BASIC_INFO_BOX_LATEST_PRESSURE: "Pressão",
  BASIC_INFO_BOX_CURRENT_PRESSURE: "Pressão de Comparação",
  BASIC_INFO_BOX_CHART: "Gráficos",
  BASIC_INFO_BOX_TABLE: "Tabelas",
  BASIC_INFO_BOX_NOT_AVAILABLE:
    "Não disponível para pivôs com protocolo anterior a 4.1.",
  BASIC_INFO_BOX_ENABLE_OPERATIONS_SUMMARY:
    "Para visualizar os dados, vá até a Edição da Fazenda e clique no botão “habilitar” na aba “Relatórios de Pivô”.",
  BASIC_INFO_BOX_ENABLE_IRPD_INFOS:
    "Para visualizar os dados, vá até a edição da fazenda e clique no botão “habilitar” na aba “Relatórios de Bomba”.",
  BASIC_INFO_BOX_OPERATION_SUMMARY_CALCULATED:
    "O Resumo de Operações está sendo calculado para os dados já existentes no sistema. Isto pode demorar um pouco.",
  BASIC_INFO_BOX_GO_TO_EDIT_FARM: "Ir para Edição da Fazenda",
  BASIC_INFO_BOX_NOTIFY_DESKTOP_ONLY:
    "Funcionalidade disponível apenas na versão desktop.",
  SCHEDULE_IRRIGATION_FIELD_DIRECTION: "Direção",
  SCHEDULE_IRRIGATION_FIELD_MODE: "Modo de Operação",
  SCHEDULE_IRRIGATION_FIELD_PERCENT: "Percentímetro",
  SCHEDULE_IRRIGATION_FIELD_PRECIP: "Lâmina",
  SCHEDULE_IRRIGATION_VALUE_FORWARD: "Avanço",
  SCHEDULE_IRRIGATION_VALUE_REVERSE: "Reverso",
  SCHEDULE_IRRIGATION_VALUE_DRY: "Seco",
  SCHEDULE_IRRIGATION_VALUE_WET: "Molhado",
  SCHEDULE_IRRIGATION_BUTTON_TURN_ON: "Ligar",
  SCHEDULE_IRRIGATION_BUTTON_ADD: "Adicionar",
  SCHEDULE_IRRIGATION_BUTTON_REMOVE: "Remover",
  SCHEDULE_IRRIGATION_DATE_START_LABEL: "Data de Início",
  SCHEDULE_IRRIGATION_START_DATE_INVALID:
    "Você não pode criar datas iniciais entre datas já criadas",
  SCHEDULE_IRRIGATION_END_DATE_INVALID:
    "Você não pode criar datas finais entre datas já criadas",
  GEOLOCATION_GETTER_GET_POSITION: "Obtenha a posição desejada:",
  GEOLOCATION_GETTER_LATITUDE: "Latitude:",
  GEOLOCATION_GETTER_LONGITUDE: "Longitude:",
  GEOLOCATION_GETTER_GET: "OBTER",
  GEOLOCATION_GETTER_WAIT:
    "Aguarde alguns segundos para obter melhor precisão do GPS.",
  GEOLOCATION_GETTER_ACCURACY_LABEL: "Precisão:",
  GEOLOCATION_GETTER_ACCURACY_VALUE: "${} metros",
  COUNTRY_SELECTOR_DDI: "Escolha um país",
  CREATE_USER_MODAL_TITLE: "Cadastrar Usuário",
  CREATE_USER_MODAL_ADMIN: "Administrador",
  CREATE_USER_MODAL_EMAIL: "E-mail",
  CREATE_USER_MODAL_BUTTON_SAVE: "Salvar",
  CREATE_USER_MODAL_SUCCESS: "O convite foi enviado.",
  CREATE_USER_MODAL_ERROR:
    "Ocorreu um erro ao enviar o convite. Por favor tente novamente.",
  CREATE_USER_MODAL_ALREADY_REGISTERED: "Usuário já registrado na fazenda.",
  CREATE_USER_MODAL_INVALID_EMAIL: "E-mail inválido.",
  PIVOT_CONFIG_FIELD_CENTER: "Centro",
  PIVOT_CONFIG_FIELD_REFERENCE_START: "Referência Inicial (até a última torre)",
  PIVOT_CONFIG_FIELD_REFERENCE_END: "Referência Final (até o fim da área)",
  PIVOT_CONFIG_FIELD_RADIUS: "Raio até a última torre",
  PIVOT_CONFIG_FIELD_TOTAL_RADIUS: "Raio irrigado",
  PIVOT_CONFIG_FIELD_FLOW: "Vazão",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_STOP_MODE: "Modo de parada",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_MM_TO_STOP: "Parar em (mm)",
  PIVOT_CONFIG_VALUE_ENDGUN_WITHOUT: "Desligado",
  PIVOT_CONFIG_VALUE_ENDGUN_ON: "Ligado",
  PIVOT_CONFIG_FIELD_DATE_TIME: "Data e hora",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_STOP: "Parada por milímetros de chuva",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_DECREASE: "Decremento do percentímetro",
  SELECTED_PIVOT_SEGMENT_MODAL_LEFT_TITLE:
    "Configurações de segmento selecionadas",
  SELECTED_PIVOT_SEGMENT_TITLE: "Segmento selecionado",
  CLICK_ON_SEGMENT: "Clique no segmento para editar as informações",
  SELECTED_PIVOT_SEGMENT_MODAL_RIGHT_TITLE:
    "Parâmetros da irrigação por segments",
  SELECTED_PIVOT_SEGMENT_MODAL_TITLE: "Enviar Irrigação por Segmentos",
  SELECTED_PIVOT_SEGMENT_MODAL_SUCCESS:
    "Irrigação por Segmentos enviada com sucesso",
  SELECTED_PIVOT_SEGMENT_MODAL_FAILURE:
    "Falha ao enviar Irrigação por Segmentos",
  SEGMENT_IRRIGATION_FIELD_START_MODE: "Modo de Início",
  SEGMENT_IRRIGATION_SHOW_CONFIGURED_SEGMENTS: "Ver Segmentos Configurados",
  SEGMENT_IRRIGATION_FIELD_END_MODE: "Modo de Parada",
  SEGMENT_IRRIGATION_FIELD_START_DATE: "Data de Início",
  SEGMENT_IRRIGATION_FIELD_END_DATE: "Data de Parada",
  SEGMENT_IRRIGATION_FIELD_END_ANGLE: "Ângulo de Parada",
  SEGMENT_IRRIGATION_FIELD_END_ROUNDS: "Voltas",
  SEGMENT_IRRIGATION_MODE_NOW: "Agora",
  SEGMENT_IRRIGATION_ON_DATE: "Em uma data",
  SEGMENT_IRRIGATION_NEVER_ENDS: "Sem Parada",
  SEGMENT_IRRIGATION_BY_ANGLE: "Por Ângulo",
  SEGMENT_IRRIGATION_BY_DATE: "Por Agendamento",
  SEGMENT_IRRIGATION_END_SECTOR: "Final do Setor",
  SEGMENT_IRRIGATION_BY_ROUNDS: "Por Voltas",
  SEGMENT_IRRIGATION_ROUND_FIELD_ERROR:
    "Erro. O número de voltas deve ser maior que zero",
  SEGMENT_IRRIGATION_ERROR_END_ANGLE_OUT_OF_RANGE:
    "Ângulo final maior que o ângulo máximo para este pivô",
  SEGMENT_IRRIGATION_ERROR_LOADING_LATEST:
    "Não foi possível carregar a última Irrigação por Segmentos",
  SEGMENT_IRRIGATION_INCOMPATIBLE_IRRIGATIONS:
    "Não foi possível carregar a última irrigação. Os segmentos são diferentes.",
  SEGMENT_IRRIGATION_MISSING_SEGMENTS:
    "Não há dados de segmentos salvos no pivô. Adicione segmentos ao editar o pivô e tente novamente.",
  SELECTED_PIVOT_GENERAL: "Geral",
  SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES: "Editar Histórico",
  SELECTED_PIVOT_HARVEST: "Safra",
  SELECTED_PIVOT_BASIC_INFO: "Resumo de Operações",
  SELECTED_PIVOT_PUMP_BOX: "Bombas",
  SELECTED_PIVOT_HISTORIC: "Histórico",
  SELECTED_PIVOT_RECOMMENDATION: "Recomendação",
  SELECTED_PIVOT_SETUP_IRRIGATION: "Configurar Irrigação",
  SELECTED_PIVOT_CHOOSE_IRRIGATION_MODE: "Escolha o Modo de Irrigação:",
  SELECTED_PIVOT_STANDARD_IRRIGATION: "Simples",
  SELECTED_PIVOT_BY_ANGLE_IRRIGATION: "Por Ângulo",
  SELECTED_PIVOT_VRI_IRRIGATION: "Segmentos",
  SELECTED_PIVOT_SCHEDULE_IRRIGATION: "Agendamento",
  SELECTED_PIVOT_STOP_ACTION: "Parar Pivô",
  SELECTED_PIVOT_EDIT_BUTTON_TEXT: "Editar",
  SELECTED_PIVOT_BACK_BUTTON_TEXT: "Voltar",
  SELECTED_PIVOT_SEGMENT_IRRIGATION_BUTTON: "Segmentos",
  SELECTED_PIVOT_HISTORIC_SWITCH: "Avançado",
  PIVOT_CONFIG_DASHBOARD_NAME: "${}",
  PIVOT_CONFIG_REPORT_PREFERENCES: "Editar Resumo",
  PIVOT_REPORT_EXCEL: "Excel",
  IRPD_REPORT_CENTRAL: "Resumo de Operação",
  IRPD_CENTRAL_GH_REPORT:
    "Relatório de operações de central gerado. Em breve estará disponível em seu E-mail.",
  IRPD_CENTRAL_GH_REPORT_ERROR:
    "Erro ao requisitar o relatório de operações de central. Tente novamente mais tarde.",
  PIVOT_REPORT_EXCEL_ERROR: "Não há dados para gerar o excel.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL:
    "Fazendo download do arquivo Excel. Por favor aguarde.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_ERROR:
    "Algo deu errado. Por favor, tente novamente.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_SUCCESS:
    "Download concluído. Você pode fechar esta janela.",
  REPORT_TABLE_START_DATE: "Início",
  REPORT_TABLE_END_DATE: "Fim",
  REPORT_TABLE_START_TOTAL: "Total",
  REPORT_TABLE_START_WATER_BLADE: "Lâmina",
  REPORT_TABLE_START_DETAILS: "Detalhes",
  HYDRIC_MANAGEMENT_CREATE_LABEL: "Bomba de gestão hídrica",
  HYDRIC_REPORT_TABLE_START_DATE: "Inicio da operação",
  HYDRIC_REPORT_TABLE_END_DATE: "Término da operação",
  HYDRIC_REPORT_TABLE_DURATION: "Duração da operação",
  HYDRIC_REPORT_TABLE_CALC_VOLUME: "Volume calculado",
  HYDRIC_REPORT_TABLE_TOTAL_VOLUME: "Volume hidrômetro",
  SELECTED_PIVOT_IRRIFAST: "Alinhamento de torres",
  IRRIFAST_TABLE_COL_SPEC: "Especificação",
  IRRIFAST_TABLE_COL_VALUE: "Valor",
  IRRIFAST_TABLE_PROPS_TENSION: "Tensão (DC)",
  IRRIFAST_TABLE_PROPS_FREQ: "Frequência de saída",
  IRRIFAST_TABLE_PROPS_CURRENT: "Corrente de saída",
  IRRIFAST_TABLE_PROPS_TENSION_SUFFIX: "V",
  IRRIFAST_TABLE_PROPS_FREQ_SUFFIX: "Hz",
  IRRIFAST_TABLE_PROPS_CURRENT_SUFFIX: "A",
  IRRIFAST_TABLE_ACTION_COLLAPSE: "Informações detalhadas",
  IRRIFAST_TABLE_LABEL_NONE:
    "Por favor, selecione uma torre para inspecionar os dados detalhados.",
  IRRIFAST_TABLE_STATUS_OFF: "Desligado",
  IRRIFAST_TABLE_STATUS_ALIGNED: "Alinhado",
  IRRIFAST_TABLE_STATUS_NOTALIGNED: "Desalinhado",
  DEVICE_BOX_ANALYZE: "Analisar:",
  DEVICE_BOX_CONTROL: "Controlador",
  DEVICE_BOX_GPS: "GPS",
  DEVICE_BOX_PIVOT: "Pivô",
  DEVICE_BOX_SHOW_GPS: "Mostrar GPS",
  DEVICE_BOX_SEARCH_RADIO: "Buscar Rádios",
  DEVICE_BOX_KEEP_LINES: "Manter Linhas",
  SELECTED_PIVOT_SCHEDULE_MODAL_SUCCESS:
    "Irrigação por Agendamento enviada com sucesso",
  SELECTED_PIVOT_SCHEDULE_MODAL_FAILURE:
    "Falha ao enviar Irrigação por Agendamento",
  SELECTED_PIVOT_SCHEDULE_MODAL_ROW_LIMIT:
    "Você só pode adicionar 7 agendamentos.",
  BY_ANGLE_IRRIGATION_BUTTON_FORWARD: "AVANÇO",
  BY_ANGLE_IRRIGATION_BUTTON_BACKWARD: "REVERSO",
  BY_ANGLE_IRRIGATION_FIELD_PERCENTAGE: "Percentímetro",
  BY_ANGLE_IRRIGATION_FIELD_PERCENT: "Porcentagem",
  BY_ANGLE_IRRIGATION_FIELD_MILILITER: "Milimetro",
  BY_ANGLE_IRRIGATION_ERROR:
    "Erro. O valor infomado é menor do que o mínimo esperado",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_MODE: "Modo do Canhão Final",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE: "Modo de Operação",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION: "Sentido",
  BY_ANGLE_IRRIGATION_FIELD_DEFAULT_ERROR:
    "Erro. Verifique se todos os campos estão preenchidos.",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_FORWARD: "Avanço",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_REVERSE: "Reverso",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_ON: "Ligado",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_OFF: "Desligado",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_DRY: "Seco",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_WET: "Molhado",
  BY_ANGLE_IRRIGATION_BUTTON_SEND: "Enviar Comando",
  IRRIGATION_END_DURATION: "Duração prevista da operação: ",
  IRRIGATION_END_DATE_PREDICTION: "Previsão de término: ",
  BY_ANGLE_IRRIGATION_BUTTON_CANCEL: "Cancelar",
  ALERT_DIALOG_YES: "Sim",
  ALERT_DIALOG_NO: "Não",
  ALERT_DIALOG_CONFIRMATION: "Confirmação",
  FIRST_STEPS: "Primeiros Passos",
  FIRST_STEPS_SELECT_OPTION: "Selecione uma opção",
  FIRST_STEPS_OPTION_ADM:
    "Sou administrador da fazenda e preciso cadastrar meus equipamentos.",
  FIRST_STEPS_OPTION_EMPLOYEE:
    "Sou funcionário da fazenda e preciso me cadastrar.",
  FIRST_STEPS_OPTION_ADM_INFO:
    "Use sua câmera para escanear o QR Code dentro da Central, ou insira abaixo a sequência de 16 dígitos que corresponde ao ID da central.",
  FIRST_STEPS_OPTION_EMPLOYEE_INFO:
    "Pergunte ao seu administrador o código da fazenda para continuar o cadastro.",
  FIRST_STEPS_FARM_CODE: "Código da Fazenda",
  FIRST_STEPS_CENTRAL_ID: "ID da Central",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE: "Criar perfil",
  FIRST_STEPS_CREATE_PROFILE_ADM: "Criar administrador",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE_INFO:
    " Insira os dados de seu perfil. Seu cadastro estará completo após confirmar o e-mail de verificação.",
  FIRST_STEPS_CREATE_PROFILE_ADM_INFO:
    "Insira os dados do usuário administrador da fazenda. Seu cadastro estará completo após confirmar o e-mail de verificação.",
  GENERAL_BOX_NO_PANEL_STREAM: "Nenhuma atualização ainda",
  GENERAL_BOX_LAST_UPDATE: "Última comunicação: ",
  GENERAL_BOX_STOP_PIVOT_SUCCESS: "Comando de parada enviado.",
  GENERAL_BOX_STOP_PIVOT_FAILURE: "Falha ao parar pivô",
  GENERAL_BOX_PIVOT_IS_ONLINE_FAILURE:
    "Falha ao verificar se pivô está online.",
  EDIT_IRPD_SEND_SUCCESS: "Enviar",
  EDIT_IRPD_SEND_CANCEL: "Cancelar",
  GENERAL_BOX_IRPD_IS_ONLINE_FAILURE:
    "Falha ao verificar se o irpd está online",
  IRPD_BOX_STOP_COMMAND_SENT: "Comando de parada enviado com sucesso.",
  IRPD_BOX_COMMAND_ERROR: "Erro ao enviar comando.",
  IRPD_BOX_COMMAND_SENT: "Comando enviado com sucesso.",
  IRPD_BOX_CONFIGURATION_SAVED: "Configuração enviada com sucesso.",
  IRPD_BOX_CONFIGURATION_ERROR: "Erro ao enviar configuração.",
  EDIT_FARM_CENTER_MAP_BUTTON: "Centralizar no mapa",
  EDIT_FARM_ERROR_INCOMPLETE_POWER_RANGE:
    "Há dias faltando nas faixas de energia. Preencha todos os dias da semana.",
  EDIT_FARM_REPEATED_DAYS: "Esse dia já foi adicionado à lista.",
  EDIT_FARM_POWER_RANGES: "Faixas de Energia",
  EDIT_FARM__POWER_RANGES_DESCRIPTION:
    "As Faixas de Energia são utilizadas para o cálculo de custos nos relatórios.",
  EDIT_FARM_ADD_POWER_RANGES: "Adicionar faixas de energia",
  EDIT_FARM_HOLLIDAYS: "FERIADOS",
  EDIT_FARM_ADD_HOLLIDAYS: " ADICIONAR FERIADOS",
  DATE_PICKER_HOLIDAYS: "Escolha uma data",
  EDIT_FARM_DAY: "Dia",
  EDIT_FARM_MONTH: "Mês",
  EDIT_FARM_START_TIME: "Hora de Início",
  EDIT_FARM_END_TIME: "Hora de Fim",
  EDIT_FARM_INTERVAL: "Intervalo",
  EDIT_FARM_DURATION: "Duração",
  EDIT_FARM_TYPE: "Type",
  EDIT_FARM_OPERATION_SUMMARY_POWER_RANGES_CHANGED_WARNING:
    "Você modificou as faixas de energia, salve a fazenda para habilitar os botões abaixo.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_LABEL: "Habilitar Relatórios",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_TEXT:
    "Pressione o botão para habilitar o cálculo dos relatórios para os pivôs da fazenda.",
  EDIT_FARM_OPERATION_SUMMARY_INVALID_POWER_RANGES:
    "Cadastre Faixas de Energia válidas para habilitar os relatórios.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATING:
    "Seus relatórios estão sendo gerados.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_ENABLED:
    "A geração de relatórios já está habilitada.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATED:
    "Seus relatórios estão habilitados.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_LABEL: "Recalcular Relatórios",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION:
    "Você tem certeza que deseja recalcular seus relatórios?",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_TEXT:
    "Pressione o botão para recalcular os relatórios para os pivôs da fazenda.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE: "Recalcular",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_SUCESS: "Relatórios habilitados.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_ERROR: "Erro ao habilitar relatórios.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_SUCCESS:
    "Relatórios sendo recalculados",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_ERROR:
    "Erro ao tentar recalcular os relatórios.",
  EDIT_FARM_PIVOT_REPORTS: "Relatórios de Pivô",
  EDIT_FARM_IRPD_REPORTS: "Relatórios de Bomba",
  PIVOT: "Pivô",
  PIVOT_PLURAL: "Pivôs",
  PIVOT_STATUS_STOPPED: "Parado",
  PIVOT_STATUS_IN_MOVING: "Movendo a seco",
  PIVOT_STATUS_IRRIGATING: "Irrigando",
  PIVOT_STATUS_PROBLEM: "Problema",
  PIVOT_STATUS_PROGRAMMED: "Programado",
  PIVOT_STATUS_PANEL_ON: "Painel energizado",
  PIVOT_STATUS_PANEL_OFF: "Painel desligado",
  PIVOT_STATUS_PRESSURIZING: "Pressurizando",
  PIVOT_FIELD_NAME: "Nome",
  PAINEL_STREAM_REASON_0: "Painel energizado",
  PAINEL_STREAM_REASON_1: "Ligado a seco",
  PAINEL_STREAM_REASON_2: "Ligado molhado após pressurizado",
  PAINEL_STREAM_REASON_4: "Ligado molhado após queda de energia",
  PAINEL_STREAM_REASON_5: "Ligado a seco após queda de energia",
  PAINEL_STREAM_REASON_6: "Ligado após queda de energia e pressurizado",
  PAINEL_STREAM_REASON_8: "Ligado molhado após o horário de pico",
  PAINEL_STREAM_REASON_9: "Ligado a seco após o horário de pico",
  PAINEL_STREAM_REASON_10: "Ligado após o horário de pico e pressurizado",
  PAINEL_STREAM_REASON_11:
    "Ligado a seco após o início das irrigações programadas",
  PAINEL_STREAM_REASON_16: "Ligado após programação",
  PAINEL_STREAM_REASON_18: "Ligado molhado após pressurizado",
  PAINEL_STREAM_REASON_21: "Pivô programado a seco",
  PAINEL_STREAM_REASON_22: "Ligado e programado após pressurização",
  PAINEL_STREAM_REASON_32: "Pivô programado molhado",
  PAINEL_STREAM_REASON_33: "Ligado a seco após programado",
  PAINEL_STREAM_REASON_34: "Ligado molhado após programado",
  PAINEL_STREAM_REASON_38: "Ligado molhado após queda de energia",
  PAINEL_STREAM_REASON_39: "Fertirrigação ligada",
  PAINEL_STREAM_REASON_65: "Continuando programação a seco",
  PAINEL_STREAM_REASON_66: "Continuando programação molhado",
  PAINEL_STREAM_REASON_98: "Irrigando",
  PAINEL_STREAM_REASON_100: "Painel energizado",
  PAINEL_STREAM_REASON_101: "Painel desligado",
  PAINEL_STREAM_REASON_102: "Aguardando pressurização",
  PAINEL_STREAM_REASON_103: "Pivô parado com bomba ligada",
  PAINEL_STREAM_REASON_104: "Fertirrigação desligada",
  PAINEL_STREAM_REASON_240: "Parado por pluviômetro",
  PAINEL_STREAM_REASON_241: "Parado para executar outra programação",
  PAINEL_STREAM_REASON_242: "Pivô parado por fator desconhecido",
  PAINEL_STREAM_REASON_243: "Pivô parado no fim de curso",
  PAINEL_STREAM_REASON_244: "Irrigações programadas concluídas",
  PAINEL_STREAM_REASON_245: "Executando auto-reversão",
  PAINEL_STREAM_REASON_246: "Parado após oscilação de energia",
  PAINEL_STREAM_REASON_247: "Parado em horário de pico",
  PAINEL_STREAM_REASON_248: "Parado por falta de pressão",
  PAINEL_STREAM_REASON_249: "Parado através da web",
  PAINEL_STREAM_REASON_250: "Parou após tempo de espera de pressão excedido",
  PAINEL_STREAM_REASON_251: "Parou após relé térmico acionar",
  PAINEL_STREAM_REASON_252: "Parou após fim da irrigação",
  PAINEL_STREAM_REASON_253: "Parou após falta de energia",
  PAINEL_STREAM_REASON_254: "Botão PARAR pressionado",
  PAINEL_STREAM_REASON_255: "Parou após desalinhar",
  PAINEL_STREAM_REASON_256: "Sem comunicação",
  PANEL_STREAM_STATUS_0: "Parado via internet",
  PANEL_STREAM_STATUS_1: "Ligado em avanço",
  PANEL_STREAM_STATUS_2: "Ligado em reverso",
  PANEL_STREAM_STATUS_3: "Esperando pressurização",
  PANEL_STREAM_STATUS_4: "Irrigando",
  PANEL_STREAM_STATUS_5: "Esperando tempo de retardo",
  PANEL_STREAM_STATUS_6: "Movendo a seco",
  PANEL_STREAM_STATUS_7: "Programado",
  PANEL_STREAM_STATUS_8: "Preparando para religar",
  PANEL_STREAM_STATUS_9: "Parado por horário de pico",
  PANEL_STREAM_STATUS_10: "Irrigação finalizada",
  PANEL_STREAM_STATUS_11: "Parado em autorreversão",
  PANEL_STREAM_STATUS_12: "Parado via internet",
  PANEL_STREAM_STATUS_13: "Parado via painel",
  PANEL_STREAM_STATUS_14: "Painel energizado",
  PANEL_STREAM_STATUS_14_2: "Pivô ligado",
  PANEL_STREAM_STATUS_15: "Desalinhado",
  PANEL_STREAM_STATUS_16: "Falha de pressão",
  PANEL_STREAM_STATUS_17: "Direção de irrigação inválida",
  PANEL_STREAM_STATUS_18: "Queda de energia",
  PANEL_STREAM_STATUS_19: "Tensão fora da faixa permitida",
  PANEL_STREAM_STATUS_20: "Erro de parâmetro",
  PANEL_STREAM_STATUS_21: "Erro desconhecido",
  PANEL_STREAM_STATUS_22: "Painel desligado",
  PANEL_STREAM_STATUS_23: "Tempo de bomba excedido",
  PANEL_STREAM_STATUS_24: "Irrigação começou",
  PANEL_STREAM_STATUS_25: "Botão parar pressionado (bomba ligada)",
  PANEL_STREAM_STATUS_32: "Erro na bomba",
  PANEL_STREAM_STATUS_33: "Movendo a seco pelo pluviômetro",
  PANEL_STREAM_STATUS_34: "Parado pelo pluviômetro",
  PANEL_STREAM_STATUS_35: "Parado por erro no relógio interno",
  PANEL_STREAM_STATUS_36: "Fertirrigação ligada",
  PANEL_STREAM_STATUS_37: "Fertirrigação desligada",
  PANEL_STREAM_STATUS_38: "Sem comunicação",
  PANEL_STREAM_STATUS_POWER_OFF: "Desligado",
  PANEL_STREAM_STATUS_NONE: "Status desconhecido",
  IRPD_STATUS_STOPPED: "Parada",
  IRPD_STATUS_PUMPING: "Bombeando",
  IRPD_STATUS_PROBLEM: "Problema",
  IRPD_STATUS_PROGRAMMED: "Programada",
  IRPD_STATUS_ENERGIZED: "Energizada",
  IRPD_STATUS_WITHOUT_ENERGY: "Sem energia",
  IRPD_EDIT_LABEL: "Editar Bomba",
  SELECTED_IRPD_BACK_BUTTON_TEXT: "Voltar",
  SELECTED_IRPD_SAVE_BUTTON_TEXT: "Salvar",
  SELECTED_IRPD_SAVE_BUTTON_TEXT_DS: "Save e definir ficha técnica",
  IRPD_STATUS_1: "Ligada via internet",
  IRPD_STATUS_2: "Ligada manualmente",
  IRPD_STATUS_3: "Ligada após Horário de Pico",
  IRPD_STATUS_4: "Ligada após queda de energia",
  IRPD_STATUS_5: "A bomba está programada",
  IRPD_STATUS_6: "O dispositivo está ligado",
  IRPD_STATUS_7: "A bomba está ligada por um sensor",
  IRPD_STATUS_8: "A bomba está aguardando um tempo após a falha de energia",
  IRPD_STATUS_9: "A bomba está ligada por rádio remoto",
  IRPD_STATUS_192: "Horímetro corrompido",
  IRPD_STATUS_193: "A bomba está controlada por um rádio desconhecido",
  IRPD_STATUS_245: "Desligada - sem comunicação com Imeter",
  IRPD_STATUS_246: "A bomba está desligada pelo IMeter",
  IRPD_STATUS_247: "Agendamento expirado",
  IRPD_STATUS_248: "Agendamento incorreto",
  IRPD_STATUS_249: "A bomba está desligada devido ao fim do agendamento",
  IRPD_STATUS_250: "A bomba está desligada por um sensor",
  IRPD_STATUS_251: "Desligada",
  IRPD_STATUS_252: "Desligada por queda de energia",
  IRPD_STATUS_253: "Desligada por horário de pico",
  IRPD_STATUS_254: "Desligada manualmente",
  IRPD_STATUS_255: "Parada via internet",
  IRPD_CONFIG_FIELD_START_NOW: "Começar agora",
  IRPD_CONFIG_FIELD_STOP_NOW: "Parar agora",
  IRPD_FIELD_START_INTERVAL: "Começar em",
  IRPD_CONFIG_FIELD_DONT_STOP: "Não parar",
  IRPD_FIELD_END_INTERVAL: "Parar em",
  IRPD_FIELD_OFF: "Desligado",
  EDIT_IRPD_CANCEL: "Tem certeza?",
  EDIT_IRPD_ERROR_RTCDATE: "RTC inválido",
  EDIT_IRPD_ERROR_HOUR_RANGE_MAX: "Horário inválido",
  EDIT_IRPD_ERROR_HOUR_RANGE_MIN: "Horário inválido",
  EDIT_IRPD_ERROR_ENERGY_TIME: "Tempo de energia inválido",
  COUNTRY_AFGHANISTAN: "Afeganistão",
  COUNTRY_SOUTH_AFRICA: "África do Sul",
  COUNTRY_ALBANIA: "Albânia",
  COUNTRY_GERMANY: "Alemanha",
  COUNTRY_ANDORRA: "Andorra",
  COUNTRY_ANGOLA: "Angola",
  COUNTRY_ANGUILLA: "Anguilla",
  COUNTRY_ANTARCTICA: "Antártida",
  COUNTRY_ANTIGUA_AND_BARBUDA: "Antígua e Barbuda",
  COUNTRY_SAUDI_ARABIA: "Arábia Saudita",
  COUNTRY_ALGERIA: "Argélia",
  COUNTRY_ARGENTINA: "Argentina",
  COUNTRY_ARMENIA: "Armênia",
  COUNTRY_ARUBA: "Aruba",
  COUNTRY_AUSTRALIA: "Austrália",
  COUNTRY_AUSTRIA: "Áustria",
  COUNTRY_AZERBAIJAN: "Azerbaijão",
  COUNTRY_BAHAMAS: "Bahamas",
  COUNTRY_BAHRAIN: "Bahrein",
  COUNTRY_BANGLADESH: "Bangladesh",
  COUNTRY_BARBADOS: "Barbados",
  COUNTRY_BELARUS: "Belarus",
  COUNTRY_BELGIUM: "Bélgica",
  COUNTRY_BELIZE: "Belize",
  COUNTRY_BENIN: "Benin",
  COUNTRY_BERMUDA: "Bermudas",
  COUNTRY_BOLIVIA: "Bolívia",
  COUNTRY_BOSNIA_AND_HERZEGOVINA: "Bósnia-Herzegóvina",
  COUNTRY_BOTSWANA: "Botsuana",
  COUNTRY_BRAZIL: "Brasil",
  COUNTRY_BRUNEI: "Brunei",
  COUNTRY_BULGARIA: "Bulgária",
  COUNTRY_BURKINA_FASO: "Burkina Fasso",
  COUNTRY_BURUNDI: "Burundi",
  COUNTRY_BHUTAN: "Butão",
  COUNTRY_CAPE_VERDE: "Cabo Verde",
  COUNTRY_CAMEROON: "Camarões",
  COUNTRY_CAMBODIA: "Camboja",
  COUNTRY_CANADA: "Canadá",
  COUNTRY_CANARY_ISLANDS: "Canárias",
  COUNTRY_KAZAKHSTAN: "Cazaquistão",
  COUNTRY_CEUTA_AND_MELILLA: "Ceuta e Melilla",
  COUNTRY_CHAD: "Chade",
  COUNTRY_CHILE: "Chile",
  COUNTRY_CHINA: "China",
  COUNTRY_CYPRUS: "Chipre",
  COUNTRY_SINGAPORE: "Cingapura",
  COUNTRY_COLOMBIA: "Colômbia",
  COUNTRY_COMOROS: "Comores",
  COUNTRY_CONGO_REPUBLIC: "Congo",
  COUNTRY_NORTH_KOREA: "Coréia do Norte",
  COUNTRY_SOUTH_KOREA: "Coréia do Sul",
  COUNTRY_CTE_DIVOIRE: "Costa do Marfim",
  COUNTRY_COSTA_RICA: "Costa Rica",
  COUNTRY_CROATIA: "Croácia",
  COUNTRY_CUBA: "Cuba",
  COUNTRY_CURAAO: "Curaçao",
  COUNTRY_DIEGO_GARCIA: "Diego Garcia",
  COUNTRY_DENMARK: "Dinamarca",
  COUNTRY_DJIBOUTI: "Djibuti",
  COUNTRY_DOMINICA: "Dominica",
  COUNTRY_EGYPT: "Egito",
  COUNTRY_EL_SALVADOR: "El Salvador",
  COUNTRY_UNITED_ARAB_EMIRATES: "Emirados Árabes Unidos",
  COUNTRY_ECUADOR: "Equador",
  COUNTRY_ERITREA: "Eritréia",
  COUNTRY_SLOVAKIA: "Eslováquia",
  COUNTRY_SLOVENIA: "Eslovênia",
  COUNTRY_SPAIN: "Espanha",
  COUNTRY_UNITED_STATES: "Estados Unidos",
  COUNTRY_ESTONIA: "Estônia",
  COUNTRY_ETHIOPIA: "Etiópia",
  COUNTRY_FIJI: "Fiji",
  COUNTRY_PHILIPPINES: "Filipinas",
  COUNTRY_FINLAND: "Finlândia",
  COUNTRY_FRANCE: "França",
  COUNTRY_GABON: "Gabão",
  COUNTRY_GAMBIA: "Gâmbia",
  COUNTRY_GHANA: "Gana",
  COUNTRY_GEORGIA: "Geórgia",
  COUNTRY_GIBRALTAR: "Gibraltar",
  COUNTRY_UNITED_KINGDOM: "Grã-Bretanha (Reino Unido - UK)",
  COUNTRY_GRENADA: "Granada",
  COUNTRY_GREECE: "Grécia",
  COUNTRY_GREENLAND: "Groelândia",
  COUNTRY_GUADELOUPE: "Guadalupe",
  COUNTRY_GUAM: "Guam (Território dos Estados Unidos)",
  COUNTRY_GUATEMALA: "Guatemala",
  COUNTRY_GUERNSEY: "Guernsey",
  COUNTRY_GUYANA: "Guiana",
  COUNTRY_FRENCH_GUIANA: "Guiana Francesa",
  COUNTRY_GUINEA: "Guiné",
  COUNTRY_EQUATORIAL_GUINEA: "Guiné Equatorial",
  COUNTRY_GUINEA_BISSAU: "Guiné-Bissau",
  COUNTRY_HAITI: "Haiti",
  COUNTRY_NETHERLANDS: "Holanda",
  COUNTRY_HONDURAS: "Honduras",
  COUNTRY_HONG_KONG: "Hong Kong",
  COUNTRY_HUNGARY: "Hungria",
  COUNTRY_YEMEN: "Iêmen",
  COUNTRY_BOUVET_ISLAND: "Ilha Bouvet",
  COUNTRY_ASCENSION_ISLAND: "Ilha de Ascensão",
  COUNTRY_CLIPPERTON_ISLAND: "Ilha de Clipperton",
  COUNTRY_ISLE_OF_MAN: "Ilha de Man",
  COUNTRY_CHRISTMAS_ISLAND: "Ilha Natal",
  COUNTRY_PITCAIRN_ISLANDS: "Ilha Pitcairn",
  COUNTRY_RUNION: "Ilha Reunião",
  COUNTRY_LAND_ISLANDS: "Ilhas Aland",
  COUNTRY_CAYMAN_ISLANDS: "Ilhas Cayman",
  COUNTRY_COCOS_KEELING_ISLANDS: "Ilhas Cocos",
  COUNTRY_COOK_ISLANDS: "Ilhas Cook",
  COUNTRY_FAROE_ISLANDS: "Ilhas Faroes",
  COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS:
    "Ilhas Geórgia do Sul e Sandwich do Sul",
  COUNTRY_HEARD_AND_MCDONALD_ISLANDS:
    "Ilhas Heard e McDonald (Território da Austrália)",
  COUNTRY_FALKLAND_ISLANDS_ISLAS_MALVINAS: "Ilhas Malvinas",
  COUNTRY_NORTHERN_MARIANA_ISLANDS: "Ilhas Marianas do Norte",
  COUNTRY_MARSHALL_ISLANDS: "Ilhas Marshall",
  COUNTRY_US_OUTLYING_ISLANDS: "Ilhas Menores dos Estados Unidos",
  COUNTRY_NORFOLK_ISLAND: "Ilhas Norfolk",
  COUNTRY_SOLOMON_ISLANDS: "Ilhas Salomão",
  COUNTRY_SEYCHELLES: "Ilhas Seychelles",
  COUNTRY_TOKELAU: "Ilhas Tokelau",
  COUNTRY_TURKS_AND_CAICOS_ISLANDS: "Ilhas Turks e Caicos",
  COUNTRY_US_VIRGIN_ISLANDS: "Ilhas Virgens (Estados Unidos)",
  COUNTRY_BRITISH_VIRGIN_ISLANDS: "Ilhas Virgens (Inglaterra)",
  COUNTRY_INDIA: "Índia",
  COUNTRY_INDONESIA: "Indonésia",
  COUNTRY_IRAN: "Irã",
  COUNTRY_IRAQ: "Iraque",
  COUNTRY_IRELAND: "Irlanda",
  COUNTRY_ICELAND: "Islândia",
  COUNTRY_ISRAEL: "Israel",
  COUNTRY_ITALY: "Itália",
  COUNTRY_JAMAICA: "Jamaica",
  COUNTRY_JAPAN: "Japão",
  COUNTRY_JERSEY: "Jersey",
  COUNTRY_JORDAN: "Jordânia",
  COUNTRY_KIRIBATI: "Kiribati",
  COUNTRY_KOSOVO: "Kosovo",
  COUNTRY_KUWAIT: "Kuait",
  COUNTRY_LAOS: "Laos",
  COUNTRY_LESOTHO: "Lesoto",
  COUNTRY_LATVIA: "Letônia",
  COUNTRY_LEBANON: "Líbano",
  COUNTRY_LIBERIA: "Libéria",
  COUNTRY_LIBYA: "Líbia",
  COUNTRY_LIECHTENSTEIN: "Liechtenstein",
  COUNTRY_LITHUANIA: "Lituânia",
  COUNTRY_LUXEMBOURG: "Luxemburgo",
  COUNTRY_MACAU: "Macau",
  COUNTRY_MACEDONIA_FYROM: "Macedônia (República Yugoslava)",
  COUNTRY_MADAGASCAR: "Madagascar",
  COUNTRY_MALAYSIA: "Malásia",
  COUNTRY_MALAWI: "Malaui",
  COUNTRY_MALDIVES: "Maldivas",
  COUNTRY_MALI: "Mali",
  COUNTRY_MALTA: "Malta",
  COUNTRY_MOROCCO: "Marrocos",
  COUNTRY_MARTINIQUE: "Martinica",
  COUNTRY_MAURITIUS: "Maurício",
  COUNTRY_MAURITANIA: "Mauritânia",
  COUNTRY_MAYOTTE: "Mayotte",
  COUNTRY_MEXICO: "México",
  COUNTRY_MICRONESIA: "Micronésia",
  COUNTRY_MOZAMBIQUE: "Moçambique",
  COUNTRY_MOLDOVA: "Moldova",
  COUNTRY_MONACO: "Mônaco",
  COUNTRY_MONGOLIA: "Mongólia",
  COUNTRY_MONTENEGRO: "Montenegro",
  COUNTRY_MONTSERRAT: "Montserrat",
  COUNTRY_MYANMAR_BURMA: "Myanma",
  COUNTRY_NAMIBIA: "Namíbia",
  COUNTRY_NAURU: "Nauru",
  COUNTRY_NEPAL: "Nepal",
  COUNTRY_NICARAGUA: "Nicarágua",
  COUNTRY_NIGER: "Níger",
  COUNTRY_NIGERIA: "Nigéria",
  COUNTRY_NIUE: "Niue",
  COUNTRY_NORWAY: "Noruega",
  COUNTRY_NEW_CALEDONIA: "Nova Caledônia",
  COUNTRY_NEW_ZEALAND: "Nova Zelândia",
  COUNTRY_OMAN: "Omã",
  COUNTRY_CARIBBEAN_NETHERLANDS: "Países Baixos Caribenhos",
  COUNTRY_PALAU: "Palau",
  COUNTRY_PALESTINE: "Palestina",
  COUNTRY_PANAMA: "Panamá",
  COUNTRY_PAPUA_NEW_GUINEA: "Papua-Nova Guiné",
  COUNTRY_PAKISTAN: "Paquistão",
  COUNTRY_PARAGUAY: "Paraguai",
  COUNTRY_PERU: "Peru",
  COUNTRY_FRENCH_POLYNESIA: "Polinésia Francesa",
  COUNTRY_POLAND: "Polônia",
  COUNTRY_PUERTO_RICO: "Porto Rico",
  COUNTRY_PORTUGAL: "Portugal",
  COUNTRY_QATAR: "Qatar",
  COUNTRY_KENYA: "Quênia",
  COUNTRY_KYRGYZSTAN: "Quirguistão",
  COUNTRY_CENTRAL_AFRICAN_REPUBLIC: "República Centro-Africana",
  COUNTRY_CONGO_DRC: "República Democrática do Congo",
  COUNTRY_DOMINICAN_REPUBLIC: "República Dominicana",
  COUNTRY_CZECH_REPUBLIC: "República Tcheca",
  COUNTRY_ROMANIA: "Romênia",
  COUNTRY_RWANDA: "Ruanda",
  COUNTRY_RUSSIA: "Rússia",
  COUNTRY_WESTERN_SAHARA: "Saara Ocidental",
  COUNTRY_ST_PIERRE_AND_MIQUELON: "Saint-Pierre e Miquelon",
  COUNTRY_AMERICAN_SAMOA: "Samoa Americana",
  COUNTRY_SAMOA: "Samoa Ocidental",
  COUNTRY_SAN_MARINO: "San Marino",
  COUNTRY_ST_HELENA: "Santa Helena",
  COUNTRY_ST_LUCIA: "Santa Lúcia",
  COUNTRY_ST_BARTHLEMY: "São Bartolomeu",
  COUNTRY_ST_KITTS_AND_NEVIS: "São Cristóvão e Névis",
  COUNTRY_ST_MARTIN: "São Martim",
  COUNTRY_SINT_MAARTEN: "São Martinho",
  COUNTRY_SO_TOM_AND_PRNCIPE: "São Tomé e Príncipe",
  COUNTRY_ST_VINCENT_AND_GRENADINES: "São Vicente e Granadinas",
  COUNTRY_SENEGAL: "Senegal",
  COUNTRY_SIERRA_LEONE: "Serra Leoa",
  COUNTRY_SERBIA: "Sérvia",
  COUNTRY_SYRIA: "Síria",
  COUNTRY_SOMALIA: "Somália",
  COUNTRY_SRI_LANKA: "Sri Lanka",
  COUNTRY_SWAZILAND: "Suazilândia",
  COUNTRY_SUDAN: "Sudão",
  COUNTRY_SOUTH_SUDAN: "Sudão do Sul",
  COUNTRY_SWEDEN: "Suécia",
  COUNTRY_SWITZERLAND: "Suíça",
  COUNTRY_SURINAME: "Suriname",
  COUNTRY_SVALBARD_AND_JAN_MAYEN: "Svalbard",
  COUNTRY_TAJIKISTAN: "Tadjiquistão",
  COUNTRY_THAILAND: "Tailândia",
  COUNTRY_TAIWAN: "Taiwan",
  COUNTRY_TANZANIA: "Tanzânia",
  COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY:
    "Território Britânico do Oceano índico",
  COUNTRY_FRENCH_SOUTHERN_TERRITORIES: "Territórios do Sul da França",
  COUNTRY_TIMOR_LESTE: "Timor-Leste",
  COUNTRY_TOGO: "Togo",
  COUNTRY_TONGA: "Tonga",
  COUNTRY_TRINIDAD_AND_TOBAGO: "Trinidad e Tobago",
  COUNTRY_TRISTAN_DA_CUNHA: "Tristão da Cunha",
  COUNTRY_TUNISIA: "Tunísia",
  COUNTRY_TURKMENISTAN: "Turcomenistão",
  COUNTRY_TURKEY: "Turquia",
  COUNTRY_TUVALU: "Tuvalu",
  COUNTRY_UKRAINE: "Ucrânia",
  COUNTRY_UGANDA: "Uganda",
  COUNTRY_URUGUAY: "Uruguai",
  COUNTRY_UZBEKISTAN: "Uzbequistão",
  COUNTRY_VANUATU: "Vanuatu",
  COUNTRY_VATICAN_CITY: "Vaticano",
  COUNTRY_VENEZUELA: "Venezuela",
  COUNTRY_VIETNAM: "Vietnã",
  COUNTRY_WALLIS_AND_FUTUNA: "Wallis e Futuna",
  COUNTRY_ZAMBIA: "Zâmbia",
  COUNTRY_ZIMBABWE: "Zimbábue",
  IRRIGATION_BOX_CONVENTIONAL_BUTTON: "Enviar Comando",
  IRRIGATION_BOX_CONVENTIONAL_SUCCESS_MSG:
    "Comando de irrigação simples enviado.",
  IRRIGATION_BOX_BY_ANGLE_SUCCESS_MSG: "Comando enviado com sucesso.",
  IRRIGATION_BOX_BY_ANGLE_ERROR_MSG: "Erro ao enviar comando.",
  IRRIGATION_BOX_GENERIC_ERROR_MSG: "Erro ao enviar comando.",
  IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG:
    "Erro ao enviar comando. Verifique se todos os campos estão preenchidos.",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE: "Confirme sua ação",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_DESCRIPTION:
    "Por favor confirme a ação de parada para ${}",
  IRRIGATION_BOX_FORWARD_PIVOT_DIALOG_DESCRIPTION:
    "Por favor confirme a ação de irrigação avanço para ${}",
  IRRIGATION_BOX_REVERSE_PIVOT_DIALOG_DESCRIPTION:
    "Por favor confirme a ação de irrigação reversa para ${}",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES: "Enviar",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO: "Cancelar",
  MOBILE_MODAL_TITLE_SIMPLE_IRRIGATION: "Irrigação Simples",
  MOBILE_MODAL_TITLE_SCHEDULE_IRRIGATION: "Irrigação Por Agendamento",
  MOBILE_MODAL_TITLE_CONVENTIONAL_IRRIGATION: "Irrigação Simples",
  MOBILE_MODAL_TITLE_LANGUAGES: "Escolha um Idioma",
  MOBILE_MODAL_TITLE_BY_ANGLE_IRRIGATION: "Irrigação Por Ângulo",
  MOBILE_MODAL_TITLE_SEGMENTED_IRRIGATION: "Irrigação Por Segmentos",
  PIVOT_ACTION_FIELD_DIRECTION: "Direção",
  PIVOT_ACTION_FIELD_MODE: "Modo de Operação",
  PIVOT_ACTION_FIELD_ENDGUN: "Lâmina",
  PIVOT_ACTION_FIELD_PRECIPITATION: "Lâmina",
  PIVOT_ACTION_FIELD_START_DATE: "Começar em",
  PIVOT_ACTION_FIELD_START_TIME: "Hora de Início",
  PIVOT_ACTION_FIELD_END_DATE: "Parar em",
  PIVOT_ACTION_FIELD_END_TIME: "Hora de Término",
  PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN: "Desconhecido",
  PIVOT_ACTION_VALUE_DIRECTION_STOPPED: "Parado",
  PIVOT_ACTION_VALUE_DIRECTION_PROGRESS: "Avanço",
  PIVOT_ACTION_VALUE_DIRECTION_REVERSE: "Reverso",
  PIVOT_START_ACTION_VALUE_DIRECTION_PROGRESS: "Iniciar Avanço",
  PIVOT_START_ACTION_VALUE_DIRECTION_REVERSE: "Iniciar Reverso",
  PIVOT_ACTION_VALUE_DRY: "Seco",
  PIVOT_ACTION_VALUE_MODE_WET: "Molhado",
  PIVOT_ACTION_FIELD_PERCENT: "Percentímetro",
  PIVOT_ACTION_FIELD_START_NOW: "Iniciar Agora",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME: "Após Horário de Pico",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1: "Após Horário de Pico 1",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2: "Após Horário de Pico 2",
  PIVOT_ACTION_FIELD_WITHOUT_STOP: "Sem parada",
  PIVOT_ACTION_INVALID_DATE: "Data inválida",
  NAV_ITEM_LOGOUT: "Sair",
  NAV_ITEM_LANGUAGE: "Idioma",
  NAV_ITEM_PROFILE: "Editar Perfil",
  NAV_ITEM_FIRST_STEPS: "Primeiros Passos",
  NAV_ITEMS_NEW_FARM: "Nova Fazenda",
  SELECTED_FARM_NO_PIVOTS: "Esta fazenda não possui nenhum pivô.",
  SELECTED_FARM_CENTRAL_ONLINE: "Central com internet",
  SELECTED_FARM_PIVOT_ONLINE: "Pivô online",
  SELECTED_FARM_CENTRAL_FILTER_BY: "Filtrar equipamento por",
  SELECTED_FARM_CENTRAL_OFFLINE: "Central sem internet",
  SELECTED_FARM_PIVOT_OFFLINE: "Pivô offline",
  SELECTED_FARM_WATER_LIMIT_EXCEEDED: "Consumo de água excedeu o limite",
  LOADING: "Carregando",
  NOT_FOUND_TITLE: "Opa!",
  NOT_FOUND_ERROR_TEXT: "Página não encontrada.",
  NOT_FOUND_BACK_BUTTON_TEXT: "PÁGINA INICIAL",
  NOT_FOUND_TOKEN: "Sessão expirada. Faça o login novamente.",
  EDIT_PIVOT_V5_RELEVANT_INFO: "Informações Relevantes",
  EDIT_PIVOT_V5_PIVOT_TITLE_SEGMENTS: "Segmentos",
  EDIT_PIVOT_V5_PUMP_PRESSURE_CONFIG: "Configuração de Pressão",
  EDIT_PIVOT_V5_AUTOREVERSION: "Autoreversão",
  EDIT_PIVOT_V5_AUTOREVERSION_30S: "30 segundos",
  EDIT_PIVOT_V5_AUTOREVERSION_1MIN: "1 minuto",
  EDIT_PIVOT_V5_AUTOREVERSION_2MIN: "2 minutos",
  EDIT_PIVOT_V5_REFERENCE_NORTH: "Utilizar Norte como Referência",
  EDIT_PIVOT_V5_REFERENCE_NORTH_SELECT: "Definir Referência do Pivô",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_END_ADORNMENT: "s",
  EDIT_PIVOT_V5_AUTOREVERSION_DISABLED: "Desabilitado",
  EDIT_PIVOT_ERROR_NAME: "Tamanho do nome inválido",
  EDIT_PIVOT_V5_ERROR_POTENCY: "Valor de potência inválido",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LAT: "Valor de latitude do centro inválido",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LNG: "Valor de longitude do centro inválido",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LAT:
    "Valor de latitude da referência inicial inválido",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LNG:
    "Valor de longitude da referência inicial inválido",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LAT:
    "Valor de latitude da referência final inválido",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LNG:
    "Valor de longitude da referência final inválido",
  EDIT_PIVOT_V5_ERROR_SENDING_CONFIGURATION: "Erro ao enviar configuração:",
  EDIT_PIVOT_V5_ERROR_RADIUS: "Valor de raio inválido",
  EDIT_PIVOT_V5_ERROR_FLOW: "Valor de raio inválido",
  EDIT_PIVOT_V5_ERROR_SPEED: "Valor de vazão inválido",
  EDIT_PIVOT_V5_ERROR_AREA: "Valor de área inválido",

  EDIT_PIVOT_V5_ERROR_VOLTAGE_STABLE_TIME:
    "Valor de tempo de estabilização da tensão inválido",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_REF: "Valor de tensão referencial inválido",
  EDIT_PIVOT_V5_ERROR_PUMP_TIMEOUT: "Valor de tempo da bomba inválido",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_DELAY:
    "Valor de tempo de retardo da bomba inválido",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_SWITCH:
    "Valor de pressostato da bomba inválido",
  EDIT_PIVOT_V5_ERROR_PUMP_SOFT_START_TIME:
    "Valor de soft start time da bomba inválido",
  EDIT_PIVOT_V5_ERROR_ANGLE_START: "Valor de ângulo inicial inválido",
  EDIT_PIVOT_V5_ERROR_ANGLE_END: "Valor de ângulo final inválido",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START: "Valor do horário inicial inválido",
  EDIT_PIVOT_V5_ERROR_PIVOT_AVERAGE_POWER:
    "Valor da potência média no pivô inválido",
  EDIT_PIVOT_V5_ERROR_CANAL_AVERAGE_POWER:
    "Valor da potência média no canal inválido",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END: "Valor do horário final inválido",
  EDIT_PIVOT_V5_ERROR_POWER_DELAY: "Valor de power delay inválido",
  EDIT_PIVOT_V5_ERROR_SENSOR_SCALE_END: "Valor de escala do sensor inválido",
  EDIT_PIVOT_V5_ERROR_PIVOT_SEGMENT: "Valores dos segmentos do pivô inválido",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS: "Segmentos",
  EDIT_PIVOT_V5_ENDGUN: "Canhão Final",
  EDIT_PIVOT_V5_SAVE_SUCCESS: "Configuração de pivô enviada",
  EDIT_PIVOT_V5_SEGMENT_WARNING:
    "Preencha todos os segmentos e não ultrapasse o ângulo de trabalho",
  EDIT_PIVOT_V5_SAVE_ERROR:
    "Erro ao salvar pivô. Todos os campos devem estar preenchidos.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_CHECK: "Controlador de tensão",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_DELETE_ALL: "Excluir tudo",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_START: "Início: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_END: "Fim: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_FORWARD: "Avanço: ",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_REVERSE: "Reverso: ",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_MODE: "${}, ${}%",
  PIVOT_CONFIG_V5_CONFIG_HISTORY: "Histórico de configurações",
  PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM: "Limpar Tabela",
  PIVOT_CONFIG_V5_SEGMENT_ERROR: "Você pode criar até ${} segmentos",
  EDIT_PIVOT_V5_WEEKDAY_MONDAY: "SEG",
  EDIT_PIVOT_V5_WEEKDAY_TUESDAY: "TER",
  EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY: "QUA",
  EDIT_PIVOT_V5_WEEKDAY_THURSDAY: "QUI",
  EDIT_PIVOT_V5_WEEKDAY_FRIDAY: "SEX",
  EDIT_PIVOT_V5_WEEKDAY_SATURDAY: "SÁB",
  EDIT_PIVOT_V5_WEEKDAY_SUNDAY: "DOM",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK: "Dias da Semana",
  EDIT_PIVOT_V5_CONFIGURATIONS: "Configurações",
  COMMAND_SENT_WAITING_CONFIRMATION:
    "Comando enviado. Aguarde a confirmação do dispositivo.",
  COMMAND_CONFIRMED: "Comando recebido pelo dispositivo.",
  COMMAND_ERROR: "Erro ao executar comando",
  NAV_NO_NOTIFICATIONS: "Você não possui nenhuma notificação.",
  CURRENT_ANGLE: "Ângulo atual: ",
  POWER_RANGE_POINT: "Ponta",
  POWER_RANGE_OUT_OF_POINT: "Fora de Ponta",
  POWER_RANGE_REDUCED: "Reduzido",
  POWER_RANGE_TIME_START: "Início",
  POWER_RANGE_TIME_END: "Fim",
  POWER_RANGE_TYPE: "Tipo",
  POWER_RANGE_DELETE_CONFIRMATION:
    "Tem certeza que deseja apagar essa faixa de energia?",
  HISTORIC_SEGMENT_IRRIGATION_START_MODE: "Modo de Início: ",
  HISTORIC_SEGMENT_IRRIGATION_START_AT: "Início em: ",
  HISTORIC_SEGMENT_IRRIGATION_END_MODE: "Modo de Parada: ",
  HISTORIC_SEGMENT_IRRIGATION_SEGMENT: "Segmento ${}:",
  HISTORIC_SEGMENT_IRRIGATION_START_ANGLE: "Ângulo de Início: ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_ANGLE: "Ângulo de Parada: ${}",
  HISTORIC_SEGMENT_IRRIGATION_FORWARD_INFO: "Avanço - ${} - ${}",
  HISTORIC_SEGMENT_IRRIGATION_REVERSE_INFO: "Reverso - ${} - ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_AT: "Fim em: ",
  HISTORIC_SEGMENT_IRRIGATION_ROUNDS: "Voltas: ${}",
  HISTORIC_SEGMENT_IRRIGATION_START_DATE: "Enviado em: ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_DATE: "Enviado em: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_MODE: "Modo de Autoreversão: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_QUANTITY: "Enviado em: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NEVER_ENDS: "Sem Parada",
  HISTORIC_SEGMENT_IRRIGATION_TOP_BY_ANGLE: "Parada por Ângulo",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_DATE: "Parada por Data",
  HISTORIC_SEGMENT_IRRIGATION_STOP_END_SECTOR: "Parada por Fim do Setor",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_ROUNDS: "Parada por Voltas",
  HISTORIC_SEGMENT_IRRIGATION_AUTO_REVERSIONS: "Auto Reversões: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NOW: "Imediato",
  HISTORIC_SEGMENT_IRRIGATION_ON_A_DATE: "Agendado",
  SEGMENT_IRRIGATION_FIELD_START_NOW: "Começar agora?",
  SEGMENT_IRRIGATION_FIELD_END_BY_TIME: "Terminar por tempo?",
  SEGMENT_IRRIGATION_FIELD_END_BY_LAPS: "Terminar por voltas?",
  SEGMENT_IRRIGATION_FIELD_END_BY_SEGMENT: "Terminar por ângulo?",
  SEGMENT_IRRIGATION_FIELD_NEVER_ENDS: "Nunca termina.",
  SEGMENT_IRRIGATION_FIELD_NUMBER_OF_LAPS: "Número de voltas",
  SEGMENT_IRRIGATION_FIELD_LAPS: "Volta(s)",
  SEGMENT_IRRIGATION_BUTTON_FORWARD: "LIGAR AVANÇO",
  SEGMENT_IRRIGATION_BUTTON_REVERSE: "LIGAR REVERSO",
  DRAWER_HOME: "Início",
  DRAWER_FARMS: "Fazendas",
  DRAWER_REPORTS: "Relatórios",
  NOTIFICATIONS_DRAWER_TITLE: "Notificações",
  GENERAL_BOX_PRESSURE_LABEL: "${} bar",
  LOGIN_WELCOME: "Bem-vindo",
  LOGIN_EMAIL: "E-mail",
  LOGIN_NEW_USER: "Cliente novo?",
  LOGIN_SIGN_IN: "Entrar",
  LOGIN_SIGN_UP: "Cadastrar-se",
  LOGIN_USERNAME_OR_EMAIL: "Nome de usuário ou e-mail",
  LOGIN_PASSWORD: "Senha",
  LOGIN_OR: "ou",
  LOGIN_FORGET_PASSWORD: "Esqueceu o usuário ou senha?",
  LOGIN_GOOGLE_SIGN_IN: "Entrar com o Google",
  LOGIN_RECOVER_PASSWORD: "Recupere a sua senha",
  LOGIN_GO_BACK: "Voltar",
  LOGIN_SUCCESSFUL: "Usuário logado com sucesso.",
  SIGN_UP_FOOTER: "Prencha todos os campos do formulário para avançar.",
  SIGN_UP_WELCOME: "Bem vindo à plataforma da Irricontrol.",
  SIGN_UP_WELCOME_AUX: "Preencha o formulário para finalizar seu cadastro:",
  SIGN_UP_EMAIL: "E-mail",
  SIGN_UP_REPEAT_EMAIL: "Repita seu e-mail",
  SIGN_UP_NAME: "Nome",
  SIGN_UP_LAST_NAME: "Último nome",
  SIGN_UP_USERNAME: "Nome de Usuário (Exemplo: joaosilva)",
  SIGN_UP_REPEAT_PASSWORD: "Repita a senha",
  SIGN_UP_BACK: "Voltar",
  SIGN_UP_NEXT: "Avançar",
  SIGN_UP_WARNING_SUCCESS: "Cadastro finalizado com sucesso",
  LOGIN_ERROR_CREDENTIALS_NOT_PROVIDED: "Usuário e/ou senha não preenchido",
  LOGIN_ERROR_GOOGLE_USER_NOT_REGISTERED:
    "Sua conta Google não está registrada",
  LOGIN_ERROR_DEFAULT: "Falha na autenticação",
  LOGIN_ERROR_SERVER_OFFLINE: "Servidor offline",
  LOGIN_ERROR_INVALID_CREDENTIALS: "Usuário ou senha estão incorretos",
  SIGN_UP_CONFIRMATION_RETURN:
    "Você tem certeza que deseja sair da página sem finalizar o cadastro?",
  SIGN_UP_CONFIRMATION_SIGN_UP: "Você confirma todos os seus dados?",
  SIGN_UP_BUTTON_CONFIRM: "Confirmar",
  CREATE_DEVICE_MODAL_TITLE: "Cadastrar Equipamento",
  CREATE_DEVICE_MODAL_CENTRAL: "Central",
  CREATE_DEVICE_MODAL_EQUIPMENT: "Tipo de Equipamento",
  CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER: "Nome do Equipamento",

  CREATE_DEVICE_MODAL_HARDWARE_ID_PLACEHOLDER: "Hardware Id",

  CREATE_DEVICE_MODAL_CREATE_DEVICE: "Cadastrar Dispositivos",
  CREATE_DEVICE_MODAL_CONTROLLER_CODE: "Rádio do Controlador",
  CREATE_DEVICE_MODAL_GPS_CODE: "Rádio do GPS",
  CREATE_DEVICE_MODAL_PIVOT: "Pivô",
  CREATE_DEVICE_MODAL_GPS: "GPS",
  CREATE_DEVICE_MODAL_REPEATER: "Repetidora",
  CREATE_DEVICE_MODAL_REPEATER_CODE: "Rádio da Repetidora",
  CREATE_DEVICE_MODAL_CODE_READ_SUCCESS: "Código lido com sucesso",
  CREATE_DEVICE_MODAL_CODE_READ_FAIL: "Erro na leitura do código",
  CREATE_DEVICE_MODAL_CREATE: "Criar",
  CREATE_DEVICE_MODAL_ERROR_NOT_VALID: "Rádio inválido",
  CREATE_DEVICE_MODAL_ERROR_TAKEN: "Esse dispositivo já possui um vínculo",
  CREATE_DEVICE_MODAL_ERROR_NOT_MONITOR: "Este dispositivo não é um GPS",
  CREATE_DEVICE_MODAL_ERROR_NOT_CONTROL: "Este dispositivo não é um Pivô",
  CREATE_DEVICE_MODAL_ERROR_NOT_REPEATER:
    "Este dispositivo não é uma Repetidora",
  CREATE_DEVICE_MODAL_ERROR_SERVER: "Erro no servidor",
  CREATE_DEVICE_MODAL_SUCCESS: "Dispositivo criado com sucesso",
  EDIT_PROFILE_TITLE: "Perfil do usuário",
  EDIT_PROFILE_ERROR_NAME: "Tamanho do nome inválido",
  EDIT_PROFILE_ERROR_LAST_NAME: "Tamanho do nome inválido",
  EDIT_PROFILE_ERROR_USERNAME: "Tamanho do nome de usuário inválido",
  EDIT_PROFILE_ERROR_EMAIL: "E-mail inválido",
  EDIT_PROFILE_ERROR_CONFIRM_EMAIL: "E-mail inválido",
  EDIT_PROFILE_ERROR_BIRTHDAY: "Data inválida",
  EDIT_PROFILE_ERROR_COUNTRY_CODE: "DDI inválido",
  EDIT_PROFILE_ERROR_CELLPHONE: "Celular inválido",
  EDIT_PROFILE_ERROR_LANDLINE: "Telefone fixo inválido",
  EDIT_PROFILE_ERROR_ADDRESS: "Endereço inválido",
  EDIT_PROFILE_ERROR_NUMBER_HOUSE: "Número inválido",
  EDIT_PROFILE_ERROR_NEIGHBORHOOD: "Bairro inválido",
  EDIT_PROFILE_ERROR_CITY: "Cidade inválida",
  EDIT_PROFILE_ERROR_STATE: "Estado inválido",
  EDIT_PROFILE_ERROR_COUNTRY: "País inválido",
  EDIT_PROFILE_ERROR_ZIPCODE: "CEP inválido",
  INVALID_CPF: "CPF inválido",
  EDIT_PROFILE_ERROR_PASSWORD: "Senha inválida",
  EDIT_PROFILE_ERROR_PASSWORD2: "Senha inválida",
  EDIT_PROFILE_NAME: "Nome",
  EDIT_PROFILE_LAST_NAME: "Sobrenome",
  EDIT_PROFILE_EMAIL: "E-mail",
  EDIT_PROFILE_BIRTHDAY: "Data de nascimento",
  EDIT_PROFILE_CELLPHONE: "Telefone celular",
  EDIT_PROFILE_LANDLINE: "Telefone fixo",
  EDIT_PROFILE_COUNTRY_CODE: "DDI",
  EDIT_PROFILE_ADDRESS: "Endereço",
  EDIT_PROFILE_NUMBER_HOUSE: "Número",
  EDIT_PROFILE_NEIGHBORHOOD: "Bairro",
  EDIT_PROFILE_CITY: "Cidade",
  EDIT_PROFILE_STATE: "Estado",
  EDIT_PROFILE_COUNTRY: "País",
  EDIT_PROFILE_ZIPCODE: "CEP",
  EDIT_PROFILE_OFFICIAL_DOCUMENT_CODE: "CPF",
  PROFILE_BOX_CONVENTIONAL_ERROR_MSG: "Erro ao editar perfil.",
  PROFILE_BOX_CONVENTIONAL_SUCCESS_MSG: "Perfil do usuário enviado.",
  EDIT_PROFILE_CONFIRM_PASSWORD: "Senha",
  EDIT_PROFILE_PASSWORD: "Confirmar Senha",
  EDIT_PROFILE_CONFIRM_EMAIL: "Confirmar E-mail",
  EDIT_PROFILE_CURRENT_PASSWORD: "Senha Atual",
  EDIT_PROFILE_NEW_PASSWORD: "Nova Senha",
  EDIT_PROFILE_CONFIRM_NEW_PASSWORD: "Confirmar Nova Senha",
  EDIT_PROFILE_PERSONAL_INFO: "Informações Pessoais:",
  EDIT_PROFILE_CONTACT_ADDRESS: "Endereço de Contato",
  EDIT_PROFILE_PASSWORD_CHANGE: "Alteração de Senha",
  SIMPLE_IRRIGATION_FIELD_DIRECTION: "Direção",
  SIMPLE_IRRIGATION_FIELD_MODE: "Modo de Operação",
  SIMPLE_IRRIGATION_FIELD_PERCENT: "Percentímetro",
  SIMPLE_IRRIGATION_FIELD_MM: "Milímetros",
  SIMPLE_IRRIGATION_FIELD_BY_ANGLE: "Parar em um ângulo?",
  SIMPLE_IRRIGATION_FIELD_ANGLE_TO_STOP: "Ângulo Final",
  SIMPLE_IRRIGATION_VALUE_INJECTION_ON: "Ligado",
  SIMPLE_IRRIGATION_VALUE_INJECTION_OFF: "Desligado",
  SIMPLE_IRRIGATION_VALUE_FORWARD: "Avanço",
  SIMPLE_IRRIGATION_VALUE_REVERSE: "Reverso",
  SIMPLE_IRRIGATION_VALUE_DRY: "Seco",
  SIMPLE_IRRIGATION_VALUE_WET: "Molhado",
  SIMPLE_IRRIGATION_FIELD_STOP_MODE: "Modo de Parada",
  SIMPLE_IRRIGATION_NEVER_ENDS: "Sem Parar",
  SIMPLE_IRRIGATION_END_SECTOR: "Final do Setor",
  SIMPLE_IRRIGATION_BY_ROUNDS: "Por Voltas",
  SIMPLE_IRRIGATION_FIELD_ROUNDS_TO_STOP: "Voltas",
  FARM: "Fazenda",
  FARMS: "Fazendas",
  FARM_FIELD_SEARCH_BAR: "Procurar",
  FARM_FIELD_NAME: "Nome da Fazenda",
  FARM_FIELD_BILLING_DATE: "Dia da Fatura de Energia",
  FARM_FIELD_LAT: "Latitude",
  FARM_FIELD_LNG: "Longitude",
  FARM_FIELD_PHONE: "Telefone",
  FARM_FIELD_ADDRESS: "Endereço",
  FARM_FIELD_POSTAL_CODE: "CEP",
  FARM_FIELD_CITY: "Cidade",
  FARM_FIELD_STATE: "Estado",
  FARM_FIELD_COUNTRY: "País",
  FARM_FIELD_START_INTERVAL: "Horário Inicial",
  FARM_FIELD_END_INTERVAL: "Horário Final",
  FARM_FIELD_TYPE_INTERVAL: "Tipo",
  FARM_VALUE_RUSH_HOUR: "Horário de Ponta",
  FARM_VALUE_OFF_PEAK_TIME: "Horário Fora de Ponta",
  FARM_VALUE_REDUCED_TIME: "Horário Reduzido",
  HISTORIC_SIMPLE_IRRIGATION_CREATED_AT: "Enviado em: ${}",
  HISTORIC_CREATED_BY: "Enviado por: ",
  HISTORIC_CREATED_ON_HARDWARE: "Criado no dispositivo",
  HISTORIC_PAINEL_STREAM_REASON: "Razão:",
  HISTORIC_PAINEL_STREAM_REASON_VALUE: "${}",
  HISTORIC_PAINEL_STREAM_WITH_ENERGY: "Com energia",
  HISTORIC_PAINEL_STREAM_WITHOUT_ENERGY: "Sem energia",
  HISTORIC_PAINEL_STREAM_ALIGNED: "Alinhado",
  HISTORIC_PAINEL_STREAM_NOT_ALIGNED: "Desalinhado",
  HISTORIC_PAINEL_STREAM_HOURMETER: "Horímetro: ",
  HISTORIC_PAINEL_STREAM_POWER: "Tensão: ",
  HISTORIC_PAINEL_STREAM_WITH_PRESSURE: "Com pressão",
  HISTORIC_PAINEL_STREAM_WITHOUT_PRESSURE: "Tempo de bomba excedido",
  HISTORIC_PAINEL_STREAM_REVERSIONS: "Reversões executadas: ${} vezes",
  HISTORIC_GPS_STREAM_CREATED_HUMANIZED: "Há ${}",
  HISTORIC_GPS_STREAM_CREATED: "${}",
  HISTORIC_GPS_STREAM_POSITION: "Posição: ${},${}.",
  HISTORIC_GPS_STREAM_REASON: "Razão:",
  HISTORIC_GPS_STREAM_REASON_VALUE: "${}",
  HISTORIC_GPS_STREAM_SPEED: "Lâmina: ",
  HISTORIC_GPS_STREAM_HOURMETER: "Horímetro: ",
  HISTORIC_GPS_STREAM_WATER_PRESSURE: "Pressão da última torre: ",
  HISTORIC_GPS_STREAM_CENTER_PRESSURE: "Pressão no centro: ${} bar",
  HISTORIC_GPS_STREAM_DIRECTION: "Direção: ",
  HISTORIC_PIVOT_ACTION_STOP: "Parar Pivô",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW_OLD: "${} | ${}",
  HISTORIC_PIVOT_ACTION_CREATED_HUMANIZED: "Há ${}",
  HISTORIC_PIVOT_ACTION_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_START: "Início: ",
  HISTORIC_PIVOT_ACTION_START_NOW: "Imediato",
  HISTORIC_PIVOT_ACTION_END: "Parada programada: ",
  HISTORIC_PIVOT_ACTION_NEVER_END: "Desligado",
  HISTORIC_PIVOT_ACTION_MANUAL: "Ação criada manualmente",
  HISTORIC_PIVOT_ACTION_USER: "Pelo usuário: ",
  HISTORIC_PIVOT_ACTION_DIRECTION: "Direção: ",
  HISTORIC_PIVOT_ACTION_ENDGUN: "Lâmina: ${} %, ${} mm",
  HISTORIC_PIVOT_ACTION_MODE: "Modo: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_OVERVIEW: "Por Ângulo",
  HISTORIC_PIVOT_ACTION_VRI_START: "Início: ",
  HISTORIC_PIVOT_ACTION_VRI_START_NOW: "Imediato",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED_HUMANIZED: "Há ${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MANUAL: "Ação criada manualmente",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_USER: "Usuário: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MODE: "Modo: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ACTION: "${} | ${} , ${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_ON: "Canhão ligado",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_OFF: "Canhão desligado",
  HISTORIC_PANEL_STREAM_V5_SIMPLE_IRRIGATION: "Irrigação simples",
  HISTORIC_PANEL_STREAM_V5_: "Parar Pivô",
  HISTORIC_PANEL_STREAM_V5_SCHEDULE_IRRIGATION: "Irrigação Por Agendamento",
  HISTORIC_PANEL_STREAM_V5_SEGMENT_IRRIGATION: "Irrigação Por Segmentos",
  HISTORIC_PANEL_STREAM_V5_STOPPED: "Parado",
  HISTORIC_PANEL_STREAM_V5_FORWARD: "Avanço",
  HISTORIC_PANEL_STREAM_V5_REVERSE: "Reverso",
  HISTORIC_PANEL_STREAM_V5_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PANEL_STREAM_V5_DIRECTION: "Direção: ",
  HISTORIC_PANEL_STREAM_V5_STATUS: "Status: ${}",
  HISTORIC_PANEL_STREAM_V5_ACTIVE_IRRIGATION: "Irrigação Ativa: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_TOTAL: "Horímetro total: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_WET: "Horímetro molhado: ",
  HISTORIC_PANEL_STREAM_V5_MODE: "Modo: ",
  HISTORIC_PANEL_STREAM_V5_PERCENTAGE: "Percentímetro: ",
  HISTORIC_PANEL_STREAM_V5_POSITION: "Posição",
  HISTORIC_PANEL_STREAM_V5_ANGLE: "Ângulo: ",
  HISTORIC_PANEL_STREAM_V5_VOLTAGE: "Tensão: ${} V",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_CENTER: "Pressão na torre central: ",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_END_TOWER: "Pressão na última torre: ",
  HISTORIC_GPS_STREAM_V5_NO_LABEL: "${}",
  HISTORIC_GPS_STREAM_V5_REMAINING_TIME: "Tempo restante estimado: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SCHEDULE: "Agendamento atual: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SEGMENT: "Segmento atual: ",
  HISTORIC_PANEL_LABEL_DATE: "Data",
  HISTORIC_PANEL_LABEL_DATASHEET: "Ficha Técnica",
  HISTORIC_PANEL_LABEL_TYPE: "Tipo",
  HISTORIC_PANEL_LABEL_SUMMARY: "Resumo",
  HISTORIC_MAINTENANCE_ON: "Modo manutenção ativado.",
  HISTORIC_MAINTENANCE_OFF: "Modo manutenção desativado.",
  IRRIGATION_TYPE_SIMPLE: "Irrigação Simples",
  IRRIGATION_TYPE_SCHEDULE: "Irrigação Por Agendamento",
  IRRIGATION_TYPE_SEGMENT: "Irrigação Por Segmentos",
  IRRIGATION_MODE_NONE: "Nenhum",
  IRRIGATION_MODE_WET: "Molhado",
  IRRIGATION_MODE_DRY: "Seco",
  IRRIGATION_DIRECTION_STOPPED: "Parado",
  IRRIGATION_DIRECTION_FORWARD: "Avanço",
  IRRIGATION_DIRECTION_REVERSE: "Reverso",
  EDIT_PIVOT_LAST_SETTINGS: "Última configuração: ${}",
  EDIT_PIVOT_MANUAL: "- Manual",
  EDIT_PIVOT_BY_WEB: "- Internet",
  EDIT_PIVOT_ERROR: "O formulário possui valores inválidos.",
  EDIT_PIVOT_ERROR_CENTER_LAT: "Valor de latitude do centro inválido",
  EDIT_PIVOT_ERROR_CENTER_LNG: "Valor de longitude do centro inválido",
  EDIT_PIVOT_ERROR_START_REF_LAT:
    "Valor da latitude de Referência Inicial inválido",
  EDIT_PIVOT_ERROR_START_REF_LNG:
    "Valor de longitude de Referência Inicial inválido",
  EDIT_PIVOT_ERROR_END_REF_LAT:
    "Valor de latitude de Referência Final inválido",
  EDIT_PIVOT_ERROR_END_REF_LNG:
    "Valor de longitude de Referência Final inválido",
  EDIT_PIVOT_ERROR_RADIUS: "Valor de raio até a última torre inválido",
  EDIT_PIVOT_ERROR_TOTAL_RADIUS: "Valor do raio irrigado inválido",
  EDIT_PIVOT_ERROR_AREA: "Valor de Área Irrigada inválido",
  EDIT_PIVOT_ERROR_SPEED: "Valor de Velocidade da Última Torre inválido",
  EDIT_PIVOT_ERROR_FLOW: "Valor de Vazão inválido",
  EDIT_PIVOT_ERROR_POTENCY: "Valor de Potência da Bomba inválido",
  EDIT_PIVOT_ERROR_PUMP_TIME: "Valor de Tempo de Pressurização inválido",
  EDIT_PIVOT_ERROR_POWER_TIME:
    "Valor de Tempo de Retorno Após Queda de Energia inválido",
  EDIT_PIVOT_ERROR_POWER_RANGE_MIN: "Tamanho do nome inválido",
  EDIT_PIVOT_ERROR_POWER_RANGE_MAX: "Tamanho do nome inválido",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MIN: "Tamanho do nome inválido",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MAX: "Tamanho do nome inválido",
  EDIT_PIVOT_ERROR_CLOCK_DATE: "Data inválida",
  EDIT_PIVOT_ERROR_CLOCK_TIME: "Horário inválido",
  EDIT_PIVOT_CLOCK_LABEL: "Configurar Relógio do Equipamento",
  EDIT_PIVOT_SAVE_AWAIT:
    "Configuração enviada. Aguarde confirmação dos dispositivos",
  EDIT_PIVOT_SAVE_AWAIT_ERROR: "Ocorreu um erro",
  EDIT_PIVOT_SAVE_CONTROLLER_SUCCESS: "Controlador salvo com sucesso",
  EDIT_PIVOT_SAVE_GPS_SUCCESS: "GPS salvo com sucesso",
  EDIT_PIVOT_SAVE_PUMP_SUCCESS: "Bomba salva com sucesso",
  EDIT_PIVOT_GPS_LABEL: "Rádio do GPS",
  EDIT_PIVOT_PUMP_LABEL: "Rádio da Bomba",
  EDIT_PIVOT_DELIVERED_LABEL: "Rádio do Controlador",
  EDIT_PIVOT_CENTRAL_LABEL: "Rádio da Central",
  HISTORIC_GPS_STREAM_COMUNICATION_ERROR: "Pivô sem comunicação",
  HISTORIC_STREAM_LOST_ERROR:
    "Possível perda de informação do GPS ou do controlador",
  TERRAIN_MAP: "Relevo",
  GENERIC_REGISTER_DATA_ERROR: "Erro ao cadastrar dados",
  GENERIC_REGISTER_DATA_SUCCESS: "Dados cadastrados com sucesso!",
  CREATE_DEVICE_MODAL_ERROR_FARM_BASE_EMPTY: "Não foi possivel criar o Pivô",
  METERSYSTEM_WATER_LEVEL_BY_TIME: "Nível do Reservatório No Tempo",
  METERSYSTEM_CURRENT_FLOW: "Vazão: ",
  METERSYSTEM_NO_READINGS: "Sem leituras",
  METERSYSTEM_TOTAL_FLOW: "Vazão Totalizada",
  METERSYSTEM_FLOW_BY_TIME: "Vazão no Tempo",
  METER_SYSTEM_EDIT: "Editar Medidor",
  METER_SYSTEM_EDIT_GENERAL: "Configurações Gerais",
  METERSYSTEM_STATUS_1: "O dispositivo está ligado",
  METERSYSTEM_STATUS_2: "Ligado após queda de energia",
  METERSYSTEM_STATUS_3: "Monitorando um sensor",
  METERSYSTEM_STATUS_176: "Bomba desligada",
  METERSYSTEM_STATUS_177: "Bomba ligada",
  METERSYSTEM_STATUS_178:
    "A bomba do dispositivo está desligada por outro IMeter",
  METERSYSTEM_STATUS_179: "A bomba do dispositivo está ligada por outro IMeter",
  METERSYSTEM_STATUS_180: "A bomba do dispositivo está desligada por um sensor",
  METERSYSTEM_STATUS_181: "A bomba do dispositivo está ligada por um sensor",
  METERSYSTEM_STATUS_192: "Horímetro corrompido",
  METERSYSTEM_STATUS_208: "Bomba não atracou",
  METERSYSTEM_STATUS_209: "Em horário de pico",
  METERSYSTEM_STATUS_210: "O dispositivo está desligado",
  METERSYSTEM_STATUS_211: "Desligado após queda de energia",
  METERSYSTEM_STATUS_212: "Nível de bateria abaixo de 10V",
  METERSYSTEM_STATUS_213: "O dispositivo perdeu o contato com outro Imeter",
  METER_SYSTEMS: "Sistemas de Medição:",
  DEVICES_CONTROLLER_NOT_FOUND: "Controlador não encontrado",
  DEVICES_ANALYSIS: "Análise:",
  DEVICES_STATUS_COLOR_NOT_FOUND: "Cor de status não encontrado",
  DEVICE: "Dispositivo:",
  DEVICE_FOUND: "Encontrado",
  DEVICE_NOT_FOUND: "Não encontrado",
  DEVICE_CENTRAL: "Central",
  DEVICE_LINE_VERY_STRONG: "Muito forte",
  DEVICE_LINE_STRONG: "Forte",
  DEVICE_LINE_MODERATE: "Moderado",
  DEVICE_LINE_WEAK: "Fraco",
  DEVICE_LINE_ERROR: "Erro",
  DEVICE_LINE: "Linha:",
  ICROP_FORM_NO_ACCESS:
    "Parece que você ainda não possui acesso a esta funcionalidade.",
  ICROP_FORM_SELECT_A_FARM: "Selecione uma fazenda:",
  EDIT_FARM_IMPORT_NATIONAL_HOLIDAYS: "Importar Feriados Nacionais",
  PAGE_TRY_AGAIN: "Tentar Novamente",
  PAUSE_TIME_WITHOUT_CONFIG: "Horário de Pico não habilitado",
  WEEK_OF_THE_DAY: "Semana do dia",
  BASIC_INFO_BOX_FILTERED_CHARTS_DATES_INFO:
    "A tabela não possui dados entre os dias selecionados.",
  BASIC_INFO_BOX_INFORMATION_CALCULATED:
    "As informações estão sendo calculadas para os dados já existentes no sistema. Isto pode demorar um pouco.",
  CROP_COTTOM: "Algodão",
  CROP_RICE: "Arroz",
  CROP_OAT: "Aveia",
  CROP_POTATO: "Batata",
  CROP_SWEET_POTATO: "Batata Doce",
  CROP_SUGAR_CANE: "Cana-de-açúcar",
  CROP_BARLEY: "Cevada",
  CROP_PEA: "Ervilha",
  CROP_BEAN: "Feijão",
  CROP_MILLET: "Milheto",
  CROP_CORN: "Milho Comercial",
  CROP_SWEET_CORN: "Milho Doce",
  CROP_POPCORN: "Milho Pipoca",
  CROP_CORN_SEEDS: "Milho Sementes",
  CROP_SILAGE_CORN: "Milho Silagem",
  CROP_PASTURE: "Pastagem",
  CROP_SOY: "Soja",
  CROP_SOY_SEEDS: "Soja Sementes",
  CROP_SORGHUM: "Sorgo",
  CROP_SORGHUM_SEEDS: "Sorgo Sementes",
  CROP_TOMATO: "Tomate Mesa",
  CROP_WHEAT: "Trigo",

  ORBITAL_SENSOR: "Sensor Orbital",

  ANNOUNCEMENT_SYSTEM_TITLE: "Comunicado",

  PRESSURE_DELAY_INPUT:
    "O Tempo de Retardo se refere ao tempo posterior que o pivô pressurizou. Há uma espera para que chegue pressão até a última torre, para então o pivô começar a se movimentar.",

  PRESSURE_SENSOR_RANGE_MIN:
    "O valor mínimo indica a faixa de pressão na qual o dispositivo deve se manter. Abaixo do valor mínimo, o funcionamento do pivô é parado.",

  PRESSURE_SENSOR_RANGE_MAX:
    "O valor máximo indica a faixa de pressão que o dispositivo deve se manter. Acima do valor máximo, o funcionamento do pivô é parado.",

  SENSOR_SCALE_END:
    "A Escala do Sensor se refere ao máximo de pressão que ele consegue medir.",

  GET_NEW_STREAM: "Buscando última atualização",

  ADD_SENSOR: "Adicionar Sensor",

  WATER_LEVEL_SENSOR: "Sensor de Nível",

  TRIGGER_RULE: "Regra de Acionamento",

  CHART: "Gráfico",

  PIVOT_UNDER_MAINTENANCE: "Pivô em manutenção",

  GET_NEW_STREAM_ERROR: "Erro ao buscar última atualização",
  PIVOT_MANAGEMENT: "Manejo de Pivôs",

  CHOOSE_ACCUMULATED_VOL_PERIOD: "Escolha o período:",

  LATEST_INSTANT_FLOW: "Última vazão instantânea",

  APPROXIMATE_USAGE_TIME: "Tempo aproximado de uso",

  APPROXIMATE_USAGE_TIME_ABBREVIATED: "Tempo aproximado de uso",

  ACCUMULATED_VOLUME: "Volume total acumulado",

  HOURS_24: "24 HORAS",

  HOURS_48: "48 HORAS",

  HOURS_72: "72 HORAS",

  WEEK_1: "1 SEMANA",

  MONTH_1: "1 MÊS",

  FLOW_BY_TIME_CHART: "Gráfico de vazão no tempo",

  MONTHLY_WATER_ACTION_CONFIRMATION:
    "O Limite de Consumo de Água Mensal foi excedido, tem certeza que deseja continuar?",

  ATENTION: "Atenção",
  CONTINUE: "Continuar",
  SEGMENT_FINAL_ANGLE: "Ângulo Final do Segmento",
  WORKING_ANGLE: "Ângulo de Trabalho do Pivô",
  HARDWARE: "Hardware",
  DAILY_RAINING: "de chuva hoje",
  PLUVIOMETER_HISTOGRAM: "Histograma de Chuvas",
  IRRIGATION_INCREMENTED_DUE_TO_RAIN:
    "Percentímetro incrementado para ${}% devido à chuva.",
  RAINING_NOW: "Chovendo agora",
  WEEK_ACCUMULATED_RAIN: "Chuva Acumulada da Semana",
  ACCUMULATED_MILLIMETERS: "Milímetros Acumulados",
  ICROP_DOC_URL:
    "https://irricontroldev.s3.us-east-2.amazonaws.com/icrop_pt.pdf",
  ICROP_WELCOME_PANEL_TITLE: "Bem vindo ao Painel de Manejo iCrop!",
  ICROP_WELCOME_PANEL_P1:
    "Aqui você pode ver as recomendações de irrigação do seu pivô, analisar o solo e informações sobre a sua cultura.",
  ICROP_WELCOME_PANEL_P2:
    "Baixe o documento abaixo para entender como analisar o dados ou acesse o painel.",
  ICROP_DOC_DOWNLOAD: "Baixar documento",
  ICROP_ACCESS_PANEL: "Acessar o painel",
  BASE_CHANGE_CLOCK_ALERT:
    "O relógio dos equipamentos abaixo serão alterados para o horário atual",
  EQUIPMENT_ID_MISSING:
    "Equipamento não encontrado na requisição. Por favor, entre em contato com o suporte",
  RADIO_ID_NOT_FOUND_IN_DATABASE: "Rádio não encontrado",
  RADIO_ID_ALREADY_USED: "Rádio já utilizado em outro equipamento",
  SEND_RADIO_CHANGE_TO: "Enviar atualização de rádio para",
  NO_EQUIPMENT: "Nenhum Equipamento",
  ALL_EQUIPMENTS: "Todos os Equipamentos",
  SEND: "Enviar",
  CHANGE_BASE_RADIO_MODAL_TITLE: "Alteração de Rádio da Central",
  USER_PERMISSION: "Sem permissão",
  USER_PERMISSION_READ: "Visualizador",
  USER_PERMISSION_OPERATOR: "Operador",
  USER_PERMISSION_CONFIGURATOR: "Configurador",
  SETORIAL_ENDGUN_ERROR:
    "Este pivô é setorial. Configurações sobre a área vermelha não surtirão efeito",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION: "Descrição",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION_TEXT:
    "Horários de Pico são faixas de tempo no qual o pivô não deve operar com água para evitar o uso em horários críticos, por exemplo, quando o custo da energia elétrica é alto.",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS_TEXT:
    "Em alguns pivôs, é comum segmentar os pivôs em duas ou mais partes devido à variedade plantada, época de plantio ou até mesmo para facilitar o gerenciamento da operação. Com esta funcionalidade, você pode segmentar os pivôs e ter maior controle da sua operação.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_TEXT:
    "Para pivôs que possuem canhão final instalado na última torre, é possível segmentar o seu acionamento evitando que ele ligue em locais onde não se deve irrigar, como estradas, casas, etc.",
  PIVOT_CONFIG_V5_AUTORREVERSION_TEXT:
    "A autoreversão é um recurso que possibilita que o pivô chegue ao final do seu percurso e retorne automaticamente, realizando uma operação. O retorno do pivô acontece quando ele alcança um obstáculo físico, chamado de fim de curso - disponível em painéis SmartConnect - ou quando chega ao ângulo final de trabalho.",
  WORKING_ANGLE_LABEL: "Qual é o ângulo máximo que esse pivô atinge?",
  PASSWORD_RECOVERY_INSERT_EMAIL:
    "Insira o seu endereço de e-mail para enviarmos um link de recuperação da sua conta.",
  VALID_UNTILL: "Válido até",
  NO_EQUIPMENTS: "Não há equipamentos",
  UPDATE_USER_TO_ADMINISTRATOR_SUCCESS:
    "Usuário administrador adicionado com sucesso.",
  CENTRAL_FOUND: "Central encontrada",
  CONTROLLER_FOUND: "Controlador encontrado",
  PUMP_FOUND: "Bomba encontrada",
  REPEATER_FOUND: "Repetidora encontrada",
  FIELD_CAPACITY: "Capacidade de Campo",
  FULL_MOISTURE: "Umidade Plena",
  MODERATE_MOISTURE: "Umidade Moderada",
  LIMIT_POINT: "Faixa de Segurança",
  MODERATE_WATER_STRESS: "Déficit Moderado",
  WATER_STRESS: "Déficit",
  APPROPRIATE: "Adequado",
  INNAPPROPRIATE: "Inadequado",
  EDIT_PIVOT_LAT_AND_LONG: "Latitude e Longitude",
  SCALE: "Escala",
  BASIC_INFO_BOX_RAINFALL: "Chuva",
  SECTOR_PIVOT_REASON_FALSE: "Não",
  HISTORIC_CONFIG_PIVOT_OLD_DATE_TIME: "Data e hora : ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MAX: "Horário de Pico Final: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MIN: "Horário de Pico Inicial: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MAX: "Tensão Final: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MIN: "Tensão Inicial: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FLOW_RATE: "Vazão: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_STABILIZATION_TIME:
    "Tempo de retorno após queda de energia: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_PRESSURIZATION_TIME: "Tempo de pressurização: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_VELOCITY_TOWER: "Velocidade da última torre: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_AREA: "Área irrigada: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_RADIUS: "Raio irrigado: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_RADIUS_TOWER: "Raio até a última torre: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FINAL_REFERENCE: "Referência Final:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_INITIAL_REFERENCE: "Referência Inicial:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_CENTER: "Centro:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_SECTOR_PIVOT: "Pivô Setorial: ${}",
  SECTOR_PIVOT_REASON_TRUE: "Sim",
  HISTORIC_CONFIG_PIVOT_OLD_END_GUN: "Canhão Final: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_NAME: "Nome: ${}",
  HISTORIC_CONFIG_PIVOT_DRIVE: "${}",
  HISTORIC_CONFIG_PIVOT_PINNED: "Configurações Favoritas",
  HISTORIC_IRPD_ACTION_USER: "Usuário: ",
  MEASURE_SCALE_UNIT_METER: "m³",
  MEASURE_SCALE_UNIT_LITER: "L",
  MEASURE_SCALE_100: "100",
  MEASURE_SCALE_10: "10",
  MEASURE_SCALE_1: "1",
  HISTORIC_IRPD_STREAM_HOURMETER: "Horímetro: ",
  HISTORIC_IRPD_ACTION_CREATED: "Há ${} | ${}",
  NUMBER_OF_BARS_12: "12",
  NUMBER_OF_BARS_24: "24",
  NUMBER_OF_BARS_30: "30",
  EDIT_FARM_WEEK: "Semana",
  CREATE_DEVICE_MODAL_PROTOCOL_5: "G2",
  CREATE_DEVICE_MODAL_PROTOCOL_4_1: "G1",
  EDIT_FARM_IRPD_INFOS_RECALCULATE_TEXT:
    "Pressione o botão para recalcular os relatórios para as bombas da fazenda.",
  EDIT_FARM_IRPD_INFOS_ENABLE_TEXT:
    "Pressione o botão para habilitar o cálculo dos relatórios para as bombas da fazenda.",
  SELECTED_FARM_LIST: "Lista",
  SWAP_RADIO: "Trocar Rádio entre Pivôs",
  EDIT_RADIO: "Editar rádio",
  HISTORIC_PIVOT_ACTION_BLADE: "Lâmina: ",
  HISTORIC_PAINEL_TYPE_CONTROLLER_UPDATE: "Atualização",
  HISTORIC_PAINEL_TYPE_COMMAND: "Comando",
  PEAK_TIME_DISABLED: "Atenção: Horário de Pico desabilitado",
  TABLE_OF: "de",
  FORGET_PASSWORD_OPTION_PASSWORD: "Esqueci minha senha",
  SIGN_UP_FIELD_USERNAME_CHECK:
    "Utilize somente letras ou números. 8 a 20 caracteres.",
  SIGN_UP_FIELD_USERNAME_ALREADY_EXIST: "Nome de usuário indisponível",
  CREATE_FARM_LABEL: "Criação de Fazenda",
  SELECTED_FARM_MAP: "Mapa",
  RADIO: "Rádio",
  SWAP: "Trocar",
  SIGN_UP_FIELD_PASSWORD_CHECK:
    "A senha deve possuir 8+ caracteres incluindo números.",
  NO_COMPATIBLE_RADIO_FOUND: "Nenhum rádio compatível encontrado.",
  SWAP_RADIO_OF: "Trocar o rádio de ",
  SELECT_TO_SWAP_RADIO_OF: "Selecione o pivô para trocar o rádio de ",
  HISTORIC_CONFIG_PIVOT_DRIVE_MANUAL: "Manual",
  NO_NOTIFICATIONS: "Você não possui nenhuma notificação.",
  SAVE_RADIO: "Salvar rádio",
  CANCEL: "Cancelar",
  CONTROL: "Controlador",
  PUMP_CABLE: "Cabo de Bomba",
  HISTORIC_IRPD_ACTION_NOW: "Imediatamente",
  HISTORIC_PAINEL_TYPE_GPS_STREAM: "Atualização do GPS",
  HISTORIC_PAINEL_NO_GPS: "Sem GPS",
  HISTORIC_PAINEL_TYPE_UPDATE: "Atualização",
  HISTORIC_PANEL_STREAM_V5_STOP_PIVOT: "Parar Pivô",
  HISTORIC_PAINEL_TYPE_MAINTENANCE: "Manutenção",
  HISTORIC_IRPD_ACTION_START_AT: "Início: ",
  HISTORIC_IRPD_ACTION_END_AT: "Término: ",
  CREATE_DEVICE_MODAL_IRPD_SOON: "Em breve",
  REDO_CONVENTIONAL_ACTION: "Carregar última irrigação",
  PIVOT_ACTION_VALUE_MODE_DRY: "Seco",
  UPDATING: "Atualizando...",
  SIGN_UP_FIELD_PASSWORD_REPEAT_CHECK: "As senhas são diferentes.",
  EDIT_FARM_CONTACT: "Contato",
  CREATE_DEVICE_MODAL_PIVO_CHOOSER: "SmartConnect ou Nexus",
  CREATE_DEVICE_MODAL_BAUER_PIVO_CHOOSER: "SmartTouch",
  CREATE_DEVICE_MODAL_IRPD_CHOOSER: "Irripump",
  CREATE_DEVICE_MODAL_PUMP_PIVOT_CODE: "Rádio do Cabo de Bomba (Spoti)",
  CREATE_DEVICE_MODAL_PUMP_IRPD_CODE: "Rádio do Cabo de Bomba (Irripump)",
  HISTORIC_PANEL_LABEL_BY: "Por",
  HISTORIC_PAINEL_TYPE_PUMP_UPDATE: "Atualização da Bomba",
  HISTORIC_CONFIG_PIVOT_V5_LANGUAGE: "Idioma do Dispositivo: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MIN: "Tensão Mínima: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MAX: "Tensão Máxima: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_STABLE: "Tensão Estável: ${}",
  HISTORIC_CONFIG_PIVOT_V5_WORKING_ANGLE: "Ângulo de trabalho: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_TIMEOUT: "Tempo da Bomba: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_DELAY: "Tempo de Retardo: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_READ_PRESSURE: "Leitura de Pressão: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH:
    "Tempo Instável de Pressostato: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_SOFT_START_TIME: "Tempo de Soft Start: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Valor Máximo do Sensor: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MIN_RANGE:
    "Valor Mínimo do Sensor: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_START: "Início do Horário de Pico: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_END: "Fim do Horário de Pico: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POTENCY: "Potência da Bomba: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POWER_DELAY: "Tempo de Energia: ${}",
  HISTORIC_CONFIG_PIVOT_V5_CANAL_AVERAGE_POWER: "Potência Média no Canal: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PILOT_AVERAGE_POWER: "Potência Média no Pivô: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_START: "Ângulo Inicial: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_END: "Ângulo Final: ${}",
  HISTORIC_CONFIG_PIVOT_V5_FIELD_ENDGUN_ANGLE_MODE: "Modo: ",
  HISTORIC_CREATED_AT: "Enviado em: ",
  HISTORIC_IRPD_ACTION_NEVER: "Nunca",
  HISTORIC_IRPD_ACTION_START: "Ligar bomba",
  HISTORIC_IRPD_ACTION_STOP: "Desligar bomba",
  PAINEL_STREAM_RESUMED_REASON_242: "Parada inesperada",
  PAINEL_STREAM_RESUMED_REASON_246: "Parado - energia instável",
  PAINEL_STREAM_RESUMED_REASON_248: "Parado - falta de pressão",
  PAINEL_STREAM_RESUMED_REASON_250: "Parado - tempo de bomba",
  PAINEL_STREAM_RESUMED_REASON_251: "Parado - relé térmico",
  PAINEL_STREAM_RESUMED_REASON_253: "Parado - falta de energia",
  PAINEL_STREAM_RESUMED_REASON_255: "Desalinhado",
  IRPD_ACTION_VALUE_MODE_WET: "Molhado",
  IRPD_ACTION_VALUE_MODE_DRY: "Seco",
  IRPD_STATUS_ON_BY_SENSOR: "Bomba ligada via sensor",
  IRPD_STATUS_WAITING_AFTER_POWER_FAILURE:
    "Esperando tempo após queda de energia",
  IRPD_STATUS_HOURMETER_CORRUPTED: "Horímetro não contabilizado",
  IRPD_STATUS_INCORRECT_SCHEDULE: "Agendamento incorreto",
  IRPD_STATUS_END_SCHEDULE_REACHED: "Desligada após fim do agendamento",
  CREATE_DEVICE_MODAL_POTENCY_PLACEHOLDER: "Potência da Bomba",
  CREATE_DEVICE_MODAL_PROTOCOL: "Versão",
  FARM_FIELD_WATER_BILLING_DATE: "Dia da Fatura de Água",
  CREATE_USER_MODAL_USERNAME_OR_EMAIL: "Nome, Usuário ou E-mail",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_FORWARD:
    "Você tem certeza que deseja ligar o pivo em AVANÇO com as configurações realizadas?",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_REVERSE:
    "Você tem certeza que deseja ligar o pivo em REVERSO com as configurações realizadas?",
  MEASURE_SCALE: "Escala de Medição",
  MEASURE_SCALE_UNIT: "Unidade da Escala de Medição",
  VOLTAGE: "Tensão",
  DATASHEET_ALERT_CONFIRMATION:
    "Tem certeza que deseja substituir a ficha técnica atual por essa configuração?",
  HISTORIC_CONFIG_PIVOT_SUBTITLE:
    "A configuração exibida nessa aba está marcada como ficha técnica. Para alterá-la acesse as outras abas e defina outra configuração como ficha técnica.",
  HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY:
    "Configuração removida dos favoritos",
  DATASHEET_PLACEHOLDER: "Ficha técnica",
  HISTORIC_CONFIG_PIVOT_DATASHEET_ADD_SUCCESSFULLY:
    "Configuração definida como ficha técnica!",
  HISTORIC_CONFIG_PIVOT_DATASHEET_FAILED:
    "Ocorreu um erro ao definir uma nova ficha técnica! Tente novamente mais tarde.",
  HISTORIC_CONFIG_PIVOT_DATASHEET_ERROR: "Erro, tente novamente mais tarde.",
  EDIT_FARM_ERROR_RADIO_ID_NOT_FOUND: "Rádio da central não é valido",
  EDIT_FARM_ERROR_RADIO_ID_ALREADY_USED: "Rádio da central já utilizado",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_NOT_VALID: "Rádio do controlador inválido",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_ALREADY_USED:
    "Rádio do controlador já utilizado",
  CREATE_DEVICE_MODAL_ERROR_GPS_NOT_VALID: "Rádio do GPS inválido",
  CREATE_DEVICE_MODAL_ERROR_GPS_ALREADY_USED: "Rádio do GPS já utilizado",
  CREATE_DEVICE_MODAL_ERROR_PUMP_NOT_VALID: "Rádio do cabo de bomba inválido",
  CREATE_DEVICE_MODAL_ERROR_PUMP_ALREADY_USED:
    "Rádio do cabo de bomba já utilizado",
  CREATE_USER_MODAL_404: "Usuário não existe",
  CREATE_USER_MODAL_INVALID: "Usuário ou e-mail inválido",
  EDIT_PERMISSIONS_ADD_ADM_ERROR: "Erro ao adicionar como administrador",
  RADIO_CHANGED_SUCCESS: "Rádio alterado com sucesso",
  RADIO_SWAP_SUCCESS: "Rádios trocados com sucesso",
  HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY:
    "Configuração adicionada aos favoritos",
  HISTORIC_CONFIG_PIVOT_PINNED_ERROR: "Erro ao adicionar aos favoritos",
  IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG:
    "Não existe configuração de irrigação anterior",
  FARM_DISABLED:
    "Fazenda desativada. O acesso apenas é liberado após a identificação do pagamento das anuidades.",
  FARM_DISABLED_CONTACT: "Entre em contato com ",
  IRPD_STATUS_OPERATING: "Operando",
  IRPD_STATUS_SCHEDULE_EXPIRED: "Programação Expirada",
  CENTRAL_UPDATE: "Atualização da Central",
  SHOW_CENTRAL_STATUS: "Mostrar status da Central",
  CREATE_USER_MODAL_EMAIL_NOT_FOUND: "E-mail não encontrado",
  CREATE_USER_MODAL_SEND_INVITE: "Enviar Convite",
  CREATE_USER_MODAL_KEEP_TYPING: "Continue digitando...",
  CREATE_USER_MODAL_FIND_USER_ERROR: "Ocorreu um erro ao buscar usuário",
  SIGNAL_STRENGTH: "Sinal de Força (dbm)",
  ID_RADIO: "ID (Rádio)",
  LOGS: "Logs",
  POWER_RANGE_PEAK_ACRONYM: "HP",
  POWER_RANGE_OFF_PEAK_ACRONYM: "HFP",
  POWER_RANGE_REDUCED_ACRONYM: "HR",
  EDIT_FARM_EDIT_USER: "Editar Usuário",
  EDIT_FARM_NO_ADMIN: "Nenhum administrador registrado",
  USER_PERMISSION_CUSTOMIZED: "Personalizado",
  PIVOT_SEGMENT_CONFIG_REVERSE: "Configurar Reverso",
  PIVOT_SEGMENT_CONFIG_FORWARD: "Configurar Avanço",
  PIVOT_SEGMENT_BEGIN_REVERSE: "Início em Reverso",
  PIVOT_SEGMENT_BEGIN_FORWARD: "Início em Avanço",
  SELECTED_PIVOT_ANGLE_MODAL_FAILURE: "Falha ao enviar Irrigação por Ângulo",
  EDIT_FARM_ANGLE_RANGES: "Faixas",
  FARM_PIVOT_IRRIGATION_ANGLE_ENDGUN_MESSAGE:
    "* A ativação do Canhão Final é feita automaticamente segundo a configuração do pivô.",
  CREATE_DEVICE_MODAL_PIVO_MONITOR_CHOOSER: "Monitor de Pivô Central",
  CREATE_DEVICE_MODAL_TYPE: "Tipo",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_CENTRAL: "pivô central monitorada",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_LINEAR: "pivô monitor linear",
  CREATE_DEVICE_MODAL_PANEL_TYPE: "Tipo de Painel",
  CREATE_DEVICE_MODAL_NEXUS: "Nexus",
  CREATE_DEVICE_MODAL_SMARTCONNECT: "SmartConnect",
  CREATE_DEVICE_MODAL_BRAND_MODEL: "Fabricante",
  CREATE_BRAND_MODEL_BAUER: "Bauer",
  CREATE_BRAND_MODEL_IRRIGABRAS: "Irrigabras",
  CREATE_BRAND_MODEL_VALLEY: "Valley",
  CREATE_BRAND_MODEL_LINDSAY: "Lindsay",
  CREATE_BRAND_MODEL_CARBORUNDUM: "Carborundum",
  CREATE_BRAND_MODEL_KREBS: "Krebs",
  CREATE_BRAND_MODEL_FOCKINK: "Fockink",
  CREATE_BRAND_MODEL_REINKE: "Reinke",
  CREATE_BRAND_MODEL_OTHER: "Outro",
  CREATE_DEVICE_MODAL_UNIT: "Unidade de Potência",
  CREATE_DEVICE_MODAL_KW: "kW",
  CREATE_DEVICE_MODAL_HP: "cv",
  CREATE_DEVICE_POTENCY_PLACEHOLDER: "Potência",
  CREATE_DEVICE_MODAL_PERFORMANCE_PERCENT: "Rendimento",
  CREATE_DEVICE_MODAL_CONVERTED_POTENCY: "Potência Convertida",
  ENABLE_PLUVIOMETER: "Habilitar pluviômetro",
  DISABLE_PLUVIOMETER: "Desabilitar pluviômetro",
  PLUVIOMETER_BOX_ENABLE_DIALOG: "Tem certeza que deseja ${}",
  CREATE_BRAND_MODEL_INSERT: "Insira o Fabricante",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_NOT_VALID: "Rádio da Repetidora inválido",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_ALREADY_USED:
    "Rádio da Repetidora já utilizado",
  CREATE_DEVICE_MODAL_METER: "Medidor de Nível (iMeter)",
  CREATE_DEVICE_MODAL_SENSOR: "Sensor",
  CREATE_DEVICE_MODAL_SENSOR_5: "Sensor 5MCA",
  CREATE_DEVICE_MODAL_SENSOR_3: "Sensor 10MCA",
  CREATE_DEVICE_MODAL_SENSOR_2: "Sensor 20MCA",
  CREATE_DEVICE_MODAL_SENSOR_7: "Sensor 100MCA",
  CREATE_DEVICE_MODAL_IMETER: "Rádio do IMeter",
  CREATE_DEVICE_MODAL_ERROR_IMETER_NOT_FOUND: "Rádio do IMeter inválido",
  CREATE_DEVICE_MODAL_ERROR_IMETER_ALREADY_USED: "Rádio do IMeter já utilizado",
  CREATE_DEVICE_MODAL_ERROR_IRPD_NOT_FOUND: "Rádio do cabo de bomba inválido",
  CREATE_DEVICE_MODAL_ERROR_IRPD_ALREADY_USED:
    "Rádio do cabo de bomba já utilizado",
  CREATE_DEVICE_MODAL_ERROR_INVALID_HARDWARE_ID: "Harware ID is not available",
  SEND_COMMAND_ERROR: "Erro ao enviar comando.",
  CREATE_DEVICE_MODAL_ENERGY_TYPE: "Tipo",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_SOLAR: "Solar",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_BIVOLT: "Bivolt",
  PIVOT_STATUS_NO_COMMUNICATION_INFO_POPOVER:
    "Equipamento sem comunicação com a plataforma.",
  REPORT_TABLE_DISTANCE: "Distância percorrida",
  PRIVACY_POLICY: "Política de Privacidade",
  TERMS_AND_CONDITIONS: "Termos e Condições",
  ANNOUNCEMENT_SYSTEM_TITLE_TERMS_AND_CONDITIONS:
    "Termos e Condições de Uso da Irricontrol",
  TERMS_AND_CONDITIONS_CHECKBOX:
    "Declaro que li e concordo com os Termos e Condições de Uso da Irricontrol.",
  TERMS_AND_CONDITIONS_LOGIN_1:
    "Ao utilizar a plataforma Irricontrol, você declara ter lido e aceitado os",
  TERMS_AND_CONDITIONS_LOGIN_2: "e a",
  PAGE_NO_ACCESS_TO_FARMS: "Você não possui permissão de acesso a esta página.",
  PAGE_NO_INTERNET:
    "Não foi possível conectar a internet, verifique seu acesso a rede.",
  PAGE_PRIVACY_POLICY_NOT_ACCEPTED:
    "Você ainda não aceitou os Termos e Condições de Uso da Irricontrol.",
  PAGE_CODE_404: "Não encontramos a página solicitada. Tente novamente.",
  PAGE_DEFAULT: "Um problema inesperado ocorreu, lamentamos.",
  BACK_TO_INICIAL_PAGE: "Voltar à página inicial",

  PIVOT_CONFIG_FIELD_RADIUS_TOOLTIP: "Distância do centro até a última torre.",
  PIVOT_CONFIG_V5_FIELD_SPEED_TOOLTIP: "Velocidade da última torre à 100%.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MIN: "Tensão mínima de operação",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MAX: "Tensão máxima de operação",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME_TOOLTIP:
    "Caso o pivô esteja operando fora dos limites de tensão por um período maior que o estabelecido, a ação do pivô é interrompida.",
  SELECTED_ALERT_SELECT_PIVOT_VERSION_TOOLTIP: "Escolha a versão do pivô:",
  IRRIGATION_END_DATE_PREDICTION_TOOLTIP:
    "A previsão de término considera paradas devido ao horário de pico.",
  BASIC_BAR_TOOLTIP_HP: "Horas em Ponta",
  BASIC_BAR_TOOLTIP_HFP: "Horas em Fora de Ponta",
  BASIC_BAR_TOOLTIP_HR: "Horas em Reduzido",
  PIVOT_CONFIG_V5_LANGUAGE_TOOLTIP: "Idioma para uso no controlador do painel.",
  EDIT_IRPD_FIELD_ENERGY_TIME: "Tempo de energia",
  EDIT_IRPD_FIELD_ENERGY_TIME_TOOLTIP:
    "Tempo de retorno para continuar a executar o comando após uma queda de energia.",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY: "Tempo de energia",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY_TOOLTIP:
    "Tempo de retorno para continuar a executar o comando após uma queda de energia.",
  MEASUREMENT_MAX_VALUE: "Escala do sensor",
  MEASUREMENT_PULSES_VALUE: "Volume",
  MEASUREMENT_MAX_VALUE_TOOLTIP:
    "Se refere ao tempo limite para que o pivô pressurize. Caso este tempo seja ultrapassado sem atingir a pressão, a bomba é desligada por segurança.",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_TOOLTIP:
    "Equipamento para aferir a pressão no centro do pivô.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_TOOLTIP:
    "Caso o pivô esteja operando fora dos limites de pressão por um período maior que o estabelecido, a ação do pivô é interrompida.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE: "Condição de parada",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE_TOOLTIP:
    '"Por valor" o comando do pivô é interrompido após atingir o limite definido no campo "Valor". "Por decremento" o pivô aumenta a velocidade para compensar a lâmina aplicada.',
  EDIT_PLUVIOMETER_MM_TO_STOP: "Valor",
  EDIT_PLUVIOMETER_MM_TO_STOP_TOOLTIP:
    "Limite de chuva, em milímetros, estabelecido em uma determinada área.",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END: "Escala do sensor",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END_TOOLTIP:
    "Valor referente a escala do pluviômetro utilizado.",
  PIVOT_CONFIG_V5_FIELD_PUMP_TIMEOUT: "Tempo de bomba",
  PRESSURE_TIMEOUT_INPUT:
    "Se refere ao tempo limite para que o pivô pressurize. Caso este tempo seja ultrapassado sem atingir a pressão, a bomba é desligada por segurança.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MIN_RANGE: "Valor mínimo do sensor",
  PIVOT_CONFIG_FIELD_SETORIAL: "Pivô setorial",
  PIVOT_CONFIG_FIELD_SETORIAL_TOOLTIP:
    "Pivô que possui um ângulo de trabalho diferente de 360°.",
  PIVOT_CONFIG_FIELD_SPEED: "Velocidade da última torre",
  PIVOT_CONFIG_FIELD_SPEED_TOOLTIP: "Velocidade da última torre à 100%.",
  PIVOT_CONFIG_FIELD_POWER_TIME: "Tempo de retorno após queda de energia",
  PIVOT_CONFIG_FIELD_POWER_TIME_TOOLTIP:
    "Tempo que um comando se mantém válido após uma queda de energia.",
  PIVOT_CONFIG_FIELD_PUMP_TIME: "Tempo de pressurização",
  PIVOT_CONFIG_FIELD_PUMP_TIME_TOOLTIP:
    "Se refere ao tempo limite para que o pivô pressurize. Caso este tempo seja ultrapassado sem atingir a pressão, a bomba é desligada por segurança.",
  PIVOT_CONFIG_FIELD_POWER_RANGE: "Tensão de trabalho",
  PIVOT_CONFIG_FIELD_POWER_RANGE_TOOLTIP:
    "Funcionalidade que impede o equipamento de operar fora das tensões mínimas e máximas.",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MIN: "Tensão mínima de operação",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MAX: "Tensão máxima de operação",
  ENERGY_TIME_DESCRIPTION:
    "Nesta função é possível configurar o tempo em minutos que o bombeamento deve esperar para ligar novamente após uma queda de energia.",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE_TOOLTIP:
    "Localização da última torre no ponto 0°.",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER_TOOLTIP: "Localização do centro do pivô.",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER: "Localização do Dispositivo",
  PIVOT_CONFIG_V5_FIELD_NAME: "Nome do pivô",
  PIVOT_CONFIG_V5_FIELD_LANGUAGE: "Linguagem do dispositivo",
  PIVOT_CONFIG_V5_FIELD_AREA: "Área irrigada",
  PIVOT_CONFIG_FIELD_AREA: "Área irrigada",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME: "Tempo limite",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_LIMIT_TIME: "Tempo limite",
  PIVOT_CONFIG_V5_TENSION_LIMIT_TOOLTIP:
    "Funcionalidade que impede o equipamento de operar fora das tensões mínima e máxima por tempo maior do que o tempo limite.",
  PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL: "Relógio do equipamento",
  PIVOT_CONFIG_FIELD_POTENCY: "Potência da bomba",
  PIVOT_CONFIG_FIELD_RTC: "Configurar relógio do equipamento",
  PIVOT_CONFIG_FIELD_HOUR_RANGE: "Horário de pico",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MIN: "Horário de pico inicial",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MAX: "Horário de pico final",

  PIVOT_CONFIG_FIELD_PLUVIOMETER_ENABLE: "Habilitar pluviômetro",
  PIVOT_CONFIG_V5_FIELD_CLOCK_DATE: "Data do equipamento",
  PIVOT_CONFIG_V5_FIELD_CLOCK_TIME: "Data do equipamento",
  EDIT_PIVOT_LABEL_POWER: "Potência da bomba",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE: "Leitura de pressão",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_DELAY: "Tempo de retardo",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH: "Tempo instável de pressostato",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_1: "Ativar horário de pico 1",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_2: "Ativar horário de pico 2",
  PIVOT_CONFIG_V5_FIELD_ANGLE_START: "Ângulo inicial",
  PIVOT_CONFIG_V5_FIELD_ANGLE_END: "Ângulo final",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_START: "Ângulo inicial",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END: "Ângulo final",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_START: "Ângulo inicial",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_END: "Ângulo final",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_START: "Ângulo inicial",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_END: "Ângulo final",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME: "Tempo de espera para autoreversão",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_TOOLTIP:
    "Tempo que o pivô demora para interromper seu movimento e alterar seu sentido.",
  EDIT_PLUVIOMETER_MODE_BY_VALUE: "Por valor",
  EDIT_PLUVIOMETER_MODE_DECREMENT: "Por decremento",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_OFF: "Sempre desligado",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_ON: "Sempre ligado",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_BY_SEGMENTS: "Ligado por segmento",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_SWITCH: "Por fim de curso",
  SIMPLE_IRRIGATION_BY_ANGLE: "Por ângulo",
  PIVOT_CONFIG_VALUE_ENDGUN_BOOSTER: "Bomba booster",
  PIVOT_CONFIG_FIELD_ENDGUN: "Canhão final",
  IRPD_FIELD_NAME: "Nome da bomba",
  PIVOT_CONFIG_V5_FIELD_POTENCY: "Potência da bomba",
  PIVOT_CONFIG_V5_IS_DATASHEET: "Ficha técnica",
  IRPD_MONTHLY_WATER_LIMIT: "Limite de consumo de água mensal",
  EDIT_IRPD_DATASHEET_WARNING:
    "Há campos nessa configuração que divergem da ficha tecnica definida atualmente.",
  EDIT_IRPD_FIELD_RTCDATE: "Relógio do equipamento",
  EDIT_IRPD_FIELD_HOUR_RANGE_MAX: "Fim do horário de pico",
  EDIT_IRPD_FIELD_HOUR_RANGE_MIN: "Início do horário de pico",
  MEASUREMENT_OFFSET: "Diferença de Profundidade do Medidor de Nível",
  MEASUREMENT_OFFSET_TOOLTIP:
    "Valor de ajuste para medições da sonda, aproximando-o aos reais.",
  METERSYSTEM_FLOW_CURVE: "Vazão Residual",
  METERSYSTEM_FLOW_CURVE_TOOLTIP:
    "Quando o medidor de nível é instalado em um rio, a Plataforma Irricontrol disponibiliza a opção de adicionar as constantes da Curva Chave do Rio obtida por meio de medições no mesmo. Por meio desta equação e do valor medido na sonda de nível, a Plataforma Irricontrol calcula a vazão residual mostrada no Relatório da página inicial do sistema de medição analisado.",
  PERMISSIONS_REGISTER_USER_INFO: "Permissões",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTRAL: "Localização da Central",
  PIVOT_CONFIG_FIELD_REFERENCE_START_TOOLTIP:
    "Localização da última torre no ponto 0°.",
  PIVOT_CONFIG_FIELD_REFERENCE_END_TOOLTIP:
    "Localização do ângulo final do pivô.",
  PIVOT_CONFIG_FIELD_CENTER_TOOLTIP: "Localização do centro do pivô.",
  NO_PERMISSION: "Sem Permissão",
  NO_PERMISSION_DESCRIPTION:
    "Nesta permissão, o usuário não terá acesso ao equipamento selecionado. Dessa forma, o mesmo não aparecerá na Tela inicial da Fazenda.",
  VISUALIZER: "Visualizador",
  VIZUALIZER_DESCRIPTION:
    "Nesta permissão, o usuário tem acesso ao equipamento e consegue visualizar desde relatórios a comandos enviados. Porém, o usuário não poderá enviar comandos para o equipamento, fazer edições de configurações do mesmo e não poderá acessar a página de edições da Fazenda.",
  OPERATOR: "Operador",
  OPERATOR_DESCRIPTION:
    "Nesta permissão, o usuário tem as mesmas permissões do Visualizador, além disso, ele pode enviar comandos para o equipamento. Porém, o usuário não poderá fazer edições das configurações do equipamento e não poderá acessar a página de edições da Fazenda.",
  CONFIGURATOR: "Configurador",
  CONFIGURATOR_DESCRIPTION:
    "Nesta permissão, o usuário tem as mesmas permissões do Operador, além disso, ele poderá fazer edições das configurações do equipamento. Porém, o mesmo não conseguirá acessar a página de edições da Fazenda.",
  ADMINISTRATOR: "Administrador",
  ADMINISTRATOR_DESCRIPTION:
    "Por fim, como Administrador, o usuário terá todas as permissões do Configurador. Ele terá acesso a relatórios, histórico de comandos, envio de novos comandos, edições de todas as informações do equipamento, edições da Fazenda e, ainda, poderá adicionar e gerenciar usuários.",
  PERMISSION_INFO: "Informações sobre as permissões",
  HISTORIC_PIVOT_GPS_V5_SENSOR: "${} | ${} | ${} - ${} | ${}",
  HISTORIC_PIVOT_GPS_V5: "${} | ${} | ${} | ${}",
  VALUE: "${}",
  VALUE_HOUR_AND_MINUTE: "${}h ${}min",
  VALUE_AND_UNIT_BAR: "${} bar",
  VALUE_AND_UNIT_MIN: "${} min",
  VALUE_AND_UNIT_HOUR: "${}h",
  VALUE_AND_UNIT_DEGREES: "${}°",
  VALUE_AND_UNIT_PERCENT_MILIMETERS: "${}% - ${} mm",
  VALUE_AND_UNIT_MILIMETERS: "${} mm",
  VALUE_OF_TURNS: "${} voltas",
  VALUE_AND_UNIT_POWER: " ${} volts",
  EDIT_PIVOT_MONITOR_LABEL: "Rádio do Monitor",
  CREATE_DEVICE_MODAL_MONITOR_CODE: "Rádio do Monitor",
  GPS_FOUND: "GPS/Monitor encontrado",
  EDIT_FARM_USER_PERMISSION_ERROR: "Erro ao salvar permissões",
  MOBILE_ONLY_INFO: "Funcionalidade disponível apenas na versão mobile.",
  I18N_KEYS: "TAGS I18N",

  EDIT_FARM_SAVE_SUCCESS: "Fazenda salva com sucesso",
  EDIT_FARM_ERROR_NAME: "Tamanho do nome inválido",
  EDIT_FARM_ERROR_BILLING_DATE: "Valor da data de pagamento inválido",
  EDIT_FARM_ERROR_INITIAL_LAT: "Tamanho da latitude inválido",
  EDIT_FARM_ERROR_INITIAL_LNG: "Tamanho da longitude inválido",
  EDIT_FARM_ERROR_LAT: "Coordenada de latitude inválida",
  EDIT_FARM_ERROR_LNG: "Coordenada de longitude inválida",
  EDIT_FARM_ERROR_ADDRESS: "Endereço inválido",
  EDIT_FARM_ERROR_POSTAL_CODE: "Código postal inválido",
  EDIT_FARM_ERROR_CITY: "Cidade inválida",
  EDIT_FARM_ERROR_STATE: "Estado inválido",
  EDIT_FARM_ERROR_COUNTRY: "País inválido",

  ERROR_MAX_16_CHARACTERS: "O campo deve possuir até 16 caracteres",
  ERROR_MAX_32_CHARACTERS: "O campo deve possuir até 32 caracteres",
  ERROR_16_CARACTERS_RADIO_CONTROLER:
    "O campo Rádio do Controlador deve possuir 16 caracteres",
  ERROR_16_CARACTERS_RADIO_GPS:
    "O campo Rádio do GPS deve possuir 16 caracteres",
  ERROR_16_CARACTERS_RADIO_PUMP:
    "O campo Rádio do Cabo da Bomba deve possuir 16 caracteres",
  ERROR_16_CARACTERS_RADIO_MONITOR:
    "O campo Rádio do Monitor deve possuir 16 caracteres",
  ERROR_16_CARACTERS_RADIO_REPEATER:
    "O campo Rádio da Repetidora deve possuir 16 caracteres",
  ERROR_16_CARACTERS_RADIO_IMETER:
    "O campo Rádio do IMeter deve possuir 16 caracteres",

  ERROR_EMPTY_FIELD: "Este campo não pode estar vazio",
  ERROR_VALUE_UNDER: "Este valor é menor do que o mínimo aceito",
  ERROR_VALUE_OVER: "Este valor é maior do que o máximo aceito",
  ERROR_NEGATIVE_VALUES: "Preencha somente com valores positivos",
  ERROR_MIN_MAX: "Deve possuir valores maiores que zero",

  EDIT_FARM_ERROR_PHONE: "Número de telefone inválido",
  ERROR_ZIP_CODE: "CEP não encontrado",
  ERROR_MIN_MAX_PRECIPITATION: "Valor da lâmina inválido",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MIN: "Valor de tensão mínima, maior que a máxima",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MAX: "Valor de tensão mínima, maior que a máxima",
  ERROR_GENERAL: "O campo possúi valores inválidos",
  ERROR_ZERO: "O valor do campo não pode ser 0",
  ERROR_ADD_NAME: "Adicione um nome para o segmento",
  ERROR_PIVOT_LAT_AND_LONG: "Latitude e Longitude inválidas",
  ERROR_PIVOT_LAT: "Latitude inválida",
  ERROR_PIVOT_LONG: "Longitude inválida",
  FORGET_PASSWORD_FAILED:
    "As senhas devem possuir:\n\nPelo menos 8 caracteres;\n\nPelo menos um número;\n\nPelo menos um caracter especial;",
  SIGN_UP_PASSWORD:
    "Senha (8+ caracteres incluindo números e caracteres especiais)",
  EDIT_PROFILE_ADDRESS_2: "Complemento",
  CPF_ALREADY_USED: "CPF já utilizado na plataforma",
  SIGN_UP_WARNING_ERROR: "Este e-mail ou CPF já está cadastrado",
  SIGN_UP_FIELD_REQUIRED: "Este campo é obrigatório.",
  CPF_ALREADY_REGISTERED: "Este CPF já está registrado",
  ERROR_INVALID_CHARACTERS: "O campo possúi caracteres inválidos",
  ERROR_INVALID_AMOUNT_MIN:
    "Este campo não possuí quantidade mínima de caracteres",
  ERROR_INVALID_AMOUNT_MAX:
    "Este campo utrapassa a quantidade máxima de caracteres",
  IRPD_STATUS_NO_COMMUNICATION: "Sem Comunicação",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5: "5.0",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_1: "5.1",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_2: "5.2",
  CREATE_DEVICE_MODAL_METER_PROTOCOL_5_2: "5.2",
  CREATE_DEVICE_MODAL_METER_PROTOCOL_5_3: "5.3",
  METERSYSTEM_WATER_LEVEL: "Medição",
  METERSYSTEM_OFFSET_WATER_LEVEL: "Offset",
  METERSYSTEMGH_WATER_LEVEL: "Nível de reservatório",
  METERSYSTEM_AJUSTED_WATER_LEVEL: "Nível do Reservatório",
  PIVOT_REPORT_TOTAL_COST_HINT:
    "Para alterar os preços das faixas de energia, acesse as configurações do pivô.",
  EDIT_PIVOT_PAUSE_TIME_PRICES_TITLE: "Preços das Faixas de Energia",
  CURRENCY: "R$",
  PIVOT_CONFIG_V5_BRAND_MODEL: "Fabricante",
  CREATE_DEVICE_MODAL_OTHER: "-",
  IRPD_PRESSURE_CHART_DAY: "Dia",
  IRPD_PRESSURE_CHART_NUMBER_OF_POINTS: "Número de Pontos",
  IMETER_LEVEL_CHART_NUMBER_OF_POINTS: "Número de Pontos",
  NUMBER_OF_POINTS_12: "12",
  NUMBER_OF_POINTS_24: "24",
  NUMBER_OF_POINTS_30: "30",
  BASIC_INFO_BOX_CHART_PLUVIOMETER: "Pluviômetro",
  BASIC_INFO_BOX_CHART_WATER_CONSUMPTION: "Gráfico de Consumo",
  BASIC_INFO_BOX_CHART_WATER_PRESSURE: "Gráfico de Pressão",
  IRPD_CONFIG_GENERAL_HAS_PRESSURE_SENSOR: "Sensor de pressão",
  TABLE_HP: "Horas \n\nem Ponta",
  BASIC_INFO_BOX_CHART_GENERATED_FOR: "Gráfico para ${}",
  MAINTENANCE_REASON_01: "Alimentação - Gerador",
  MAINTENANCE_SPEC_NONE_01: "---",
  MAINTENANCE_SPEC_01A: "bico injetor",
  MAINTENANCE_SPEC_01B: "bomba de combustível auxiliar",
  MAINTENANCE_SPEC_01C: "bomba injetora",
  MAINTENANCE_SPEC_01D: "cano injetor",
  MAINTENANCE_SPEC_01E: "falta de combustível",
  MAINTENANCE_SPEC_01F: "filtro de combustível",
  MAINTENANCE_SPEC_01G: "filtro separador de água",
  MAINTENANCE_SPEC_01H: "mangueira de combustível",
  MAINTENANCE_SPEC_01I: "pescador de combustível",
  MAINTENANCE_SPEC_01J: "vazamento no tanque de combustível",
  MAINTENANCE_REASON_02: "Alimentação - Motobomba",
  MAINTENANCE_SPEC_NONE_02: "---",
  MAINTENANCE_SPEC_02A: "bico injetor",
  MAINTENANCE_SPEC_02B: "bomba de combustível auxiliar",
  MAINTENANCE_SPEC_02C: "bomba injetora",
  MAINTENANCE_SPEC_02D: "cano injetor",
  MAINTENANCE_SPEC_02E: "falta de combustível",
  MAINTENANCE_SPEC_02F: "filtro de combustível",
  MAINTENANCE_SPEC_02G: "filtro separador de água",
  MAINTENANCE_SPEC_02H: "mangueira de combustível",
  MAINTENANCE_SPEC_02I: "pescador de combustível",
  MAINTENANCE_SPEC_02J: "vazamento no tanque de combustível",
  MAINTENANCE_REASON_03: "Arrefecimento - Motobomba",
  MAINTENANCE_SPEC_NONE_03: "---",
  MAINTENANCE_SPEC_03A: "bomba d'água",
  MAINTENANCE_SPEC_03B: "correia do alternador",
  MAINTENANCE_SPEC_03C: "esticador de correia",
  MAINTENANCE_SPEC_03D: "filtro de ar primário",
  MAINTENANCE_SPEC_03E: "filtro de ar secundário",
  MAINTENANCE_SPEC_03F: "hélice",
  MAINTENANCE_SPEC_03G: "radiador furado",
  MAINTENANCE_SPEC_03H: "tampa do radiador",
  MAINTENANCE_SPEC_03I: "tensor de correia",
  MAINTENANCE_REASON_04: "Arrefecimento - Pivô",
  MAINTENANCE_SPEC_NONE_04: "---",
  MAINTENANCE_SPEC_04A: "bomba d'água",
  MAINTENANCE_SPEC_04B: "correia do alternador",
  MAINTENANCE_SPEC_04C: "esticador de correia",
  MAINTENANCE_SPEC_04D: "filtro de ar primário",
  MAINTENANCE_SPEC_04E: "filtro de ar secundário",
  MAINTENANCE_SPEC_04F: "hélice",
  MAINTENANCE_SPEC_04G: "radiador furado",
  MAINTENANCE_SPEC_04H: "tampa do radiador",
  MAINTENANCE_SPEC_04I: "tensor de correia",
  MAINTENANCE_REASON_05: "Bomba Centrífuga",
  MAINTENANCE_SPEC_NONE_05: "---",
  MAINTENANCE_SPEC_05A: "gaxeta - apertar",
  MAINTENANCE_SPEC_05B: "gaxeta - trocar",
  MAINTENANCE_SPEC_05C: "lubrificante - nível baixo",
  MAINTENANCE_SPEC_05D: "retentor danificado",
  MAINTENANCE_SPEC_05E: "rolamento quebrado",
  MAINTENANCE_REASON_06: "Canal de Vinhaça",
  MAINTENANCE_SPEC_NONE_06: "---",
  MAINTENANCE_SPEC_06A: "falta de vinhaça",
  MAINTENANCE_SPEC_06B: "problema na comporta",
  MAINTENANCE_SPEC_06C: "vazão baixa",
  MAINTENANCE_REASON_07: "Elétrico - Motobomba",
  MAINTENANCE_SPEC_NONE_07: "---",
  MAINTENANCE_SPEC_07A: "alternador",
  MAINTENANCE_SPEC_07B: "automático",
  MAINTENANCE_SPEC_07C: "bateria",
  MAINTENANCE_SPEC_07D: "bateria - cabo",
  MAINTENANCE_SPEC_07E: "bateria - suporte",
  MAINTENANCE_SPEC_07F: "fiação",
  MAINTENANCE_SPEC_07G: "fusível",
  MAINTENANCE_SPEC_07H: "motor de partida",
  MAINTENANCE_SPEC_07I: "relé auxiliar de partida",
  MAINTENANCE_SPEC_07J: "solenoide",
  MAINTENANCE_REASON_08: "Elétrico - Pivô",
  MAINTENANCE_SPEC_NONE_08: "---",
  MAINTENANCE_SPEC_08A: "alternador",
  MAINTENANCE_SPEC_08B: "bateria",
  MAINTENANCE_SPEC_08C: "bateria - cabo",
  MAINTENANCE_SPEC_08D: "bateria - suporte",
  MAINTENANCE_SPEC_08E: "contatora de acionamento",
  MAINTENANCE_SPEC_08F: "fiação",
  MAINTENANCE_SPEC_08G: "fusível",
  MAINTENANCE_SPEC_08H: "gerador - escova",
  MAINTENANCE_SPEC_08I: "gerador - porta escova",
  MAINTENANCE_SPEC_08J: "micro de acionamento",
  MAINTENANCE_SPEC_08K: "micro de segurança",
  MAINTENANCE_SPEC_08L: "motor de partida",
  MAINTENANCE_SPEC_08M: "motorredutor",
  MAINTENANCE_SPEC_08N: "percentímetro",
  MAINTENANCE_SPEC_08O: "placa",
  MAINTENANCE_REASON_09: "Estrutura - Motobomba",
  MAINTENANCE_SPEC_NONE_09: "---",
  MAINTENANCE_SPEC_09A: "cabeçalho",
  MAINTENANCE_SPEC_09B: "catraca do cabo de sustentação",
  MAINTENANCE_SPEC_09C: "mangote de sucção",
  MAINTENANCE_SPEC_09D: "mangote de sucção - abraçadeira",
  MAINTENANCE_SPEC_09E: "mangote de sucção - cabo de sustentação",
  MAINTENANCE_SPEC_09F: "pneu furado",
  MAINTENANCE_SPEC_09G: "rolamento de roda",
  MAINTENANCE_SPEC_09H: "tanque de expansão",
  MAINTENANCE_SPEC_09I: "tanque de expansão - alça",
  MAINTENANCE_SPEC_09J: "tanque de expansão - registro",
  MAINTENANCE_REASON_10: "Estrutura - Pivô",
  MAINTENANCE_SPEC_NONE_10: "---",
  MAINTENANCE_SPEC_10A: "cardan",
  MAINTENANCE_SPEC_10B: "cardan - junta flexível",
  MAINTENANCE_SPEC_10C: "cardan - rótula",
  MAINTENANCE_SPEC_10D: "desalinhamento",
  MAINTENANCE_SPEC_10E: "limpeza de aspersores",
  MAINTENANCE_SPEC_10F: "limpeza do pivô",
  MAINTENANCE_SPEC_10G: "mangote de junção dos vãos",
  MAINTENANCE_SPEC_10H: "mangueirão quebrado",
  MAINTENANCE_SPEC_10I: "pino de roda",
  MAINTENANCE_SPEC_10J: "placa de retenção",
  MAINTENANCE_SPEC_10K: "pneu furado",
  MAINTENANCE_SPEC_10L: "reposição de aspersores",
  MAINTENANCE_SPEC_10M: "reposição de mangueiras",
  MAINTENANCE_SPEC_10N: "roda folgada",
  MAINTENANCE_SPEC_10O: "sistema de segurança inoperante",
  MAINTENANCE_SPEC_10P: "suporte do redutor dianteiro",
  MAINTENANCE_SPEC_10Q: "suporte do redutor traseiro",
  MAINTENANCE_SPEC_10R: "treliças",
  MAINTENANCE_SPEC_10S: "tubo de reforço",
  MAINTENANCE_SPEC_10T: "viga base",
  MAINTENANCE_REASON_11: "Lubrificação - Gerador",
  MAINTENANCE_SPEC_NONE_11: "---",
  MAINTENANCE_SPEC_11A: "lubrificante - filtro",
  MAINTENANCE_SPEC_11B: "lubrificante - nível baixo",
  MAINTENANCE_SPEC_11C: "vazamento - flexível",
  MAINTENANCE_SPEC_11D: "vazamento - retentor dianteiro do volante",
  MAINTENANCE_SPEC_11E: "vazamento - retentor traseiro do volante",
  MAINTENANCE_SPEC_11F: "vazamento - tampa do cárter",
  MAINTENANCE_SPEC_11G: "vazamento - tampa do espelho",
  MAINTENANCE_SPEC_11H: "vazamento - turbina",
  MAINTENANCE_REASON_12: "Lubrificação - Motobomba",
  MAINTENANCE_SPEC_NONE_12: "---",
  MAINTENANCE_SPEC_12A: "lubrificante - filtro",
  MAINTENANCE_SPEC_12B: "lubrificante - nível baixo",
  MAINTENANCE_SPEC_12C: "vazamento - flexível",
  MAINTENANCE_SPEC_12D: "vazamento - retentor dianteiro do volante",
  MAINTENANCE_SPEC_12E: "vazamento - retentor traseiro do volante",
  MAINTENANCE_SPEC_12F: "vazamento - tampa do cárter",
  MAINTENANCE_SPEC_12G: "vazamento - tampa do espelho",
  MAINTENANCE_SPEC_12H: "vazamento - turbina",
  MAINTENANCE_REASON_13: "Parada Programada",
  MAINTENANCE_SPEC_NONE_13: "---",
  MAINTENANCE_SPEC_13A: "abastecimento",
  MAINTENANCE_SPEC_13B: "mudança de área",
  MAINTENANCE_SPEC_13C: "pivotando",
  MAINTENANCE_SPEC_13D: "rebocando",
  MAINTENANCE_SPEC_13E: "troca de mangueirão",
  MAINTENANCE_SPEC_13F: "virando rodas",
  MAINTENANCE_REASON_NONE: "---",
  MAINTENANCE_SPEC_NONE: "---",
  MAINTENANCE_NOTE_NONE: "-> ",
  MAINTENANCE_BUTTON_TOOLTIP_ENABLE: "Habilitar Modo Manutenção",
  MAINTENANCE_BUTTON_TOOLTIP_DISABLE: "Desabilitar Modo Manutenção",
  MAINTENANCE_MODAL_REASON_QUESTION: "Informar motivo de parada?",
  MAINTENANCE_MODAL_REASON_LABEL: "Selecione um motivo:",
  MAINTENANCE_MODAL_SPEC_QUESTION: "Especificar o motivo da parada?",
  MAINTENANCE_MODAL_SPEC_LABEL: "Selecione uma especificação:",
  MAINTENANCE_MODAL_NOTE_QUESTION: "Alguma observação?",
  MAINTENANCE_MODAL_NOTE_LABEL: "Observação:",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_ENABLE: "Habilitar Modo Manutenção?",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_DISABLE:
    "Desabilitar Modo Manutenção?",
  MAINTENANCE_MODALS_BUTTON_CANCEL: "Cancelar",
  MAINTENANCE_MODALS_BUTTON_NEXT: "Próximo",
  MAINTENANCE_MODALS_BUTTON_CONFIRM: "Confirmar",
  CREATE_DEVICE_MODAL_PIVO_LINEAR_MONITOR_CHOOSER: "Monitor de Pivô Linear",
  CREATE_DEVICE_MODAL_ERROR_BASE_NOT_VALID: "Rádio da central inválido",
  CREATE_DEVICE_MODAL_FLOW_RATE: "Vazão",
  CREATE_DEVICE_MODAL_PIVOT_LENGTH: "Comprimento",
  CREATE_DEVICE_MODAL_PIVOT_SPEED: "Velocidade",
  EDIT_MONITOR_LINEAR_LABEL: "Editar Monitor de Pivô Linear",
  PIVOT_CONFIG_FIELD_SIZE: "Tamanho do pivô",
  PIVOT_CONFIG_FIELD_SIZE_TOOLTIP: "Comprimento total do pivô linear",
  PIVOT_CONFIG_V5_LINEAR_SPEED: "Veloc. do pivô linear",
  PIVOT_CONFIG_V5_LINEAR_SPEED_TOOLTIP: "Velocidade do pivô linear à 100%.",
  BASIC_INFO_BOX_TOTAL_WET_HOURS: "Total de horas trabalhadas",
  EDIT_LINEAR_PIVOT_MONITOR_LABEL: "Editar Monitor de Pivô Linear",
  LPM_CONFIG_FIELD_LENGTH: "Comprimento",
  LPM_CONFIG_FIELD_LENGTH_TOOLTIP: "Comprimento total do pivô linear",
  LPM_CONFIG_V5_FIELD_SPEED: "Velocidade",
  LPM_CONFIG_V5_FIELD_SPEED_TOOLTIP: "Velocidade do pivô linear a 100%",
  BASIC_INFO_BOX_CHART_GENERATED_BETWEEN: "Gráfico para o período de ${} a ${}",
  BASIC_INFO_BOX_WATER_PRESSURE: "Pressão",
  GRAPHIC_SCALE: "Nível máximo do reservatório",
  GRAPHIC_SCALE_TOOLTIP:
    "Se refere ao máximo de metros de coluna d'água (MCA) que ela consegue medir.",
  LPM_EFFICIENCY: "Eficiência: ",
  IMETER_FIELD_NAME: "Nome do Medidor",
  METERSYSTEM_FIELD_NAME: "Nome do Sistema",
  MCA_10: "10 MCA",
  MCA_20: "20 MCA",
  MCA_100: "100 MCA",
  IMETER_CONFIG_V5_FIELD_SENSOR: "Sensor",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS: "Fertirrigação: ",
  REPORT_TABLE_OPERATION_NOTE: "Observação",
  REPORT_TABLE_IRRIGATION_MODE: "Modo",
  PANEL_STREAM_STATUS_26: "Fertirrigando",
  EDIT_PIVOT_V5_INJETCTION_PUMP_CONFIG: "Bomba de Fertirrigação",
  SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP: "Fertirrigação",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE: "Observações",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED: "Observações: ",
  IRRIGATION_MODE_FERTIRRIGATION: "Fertirrigação",
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_TOOL_TIP:
    'Selecione "Ativar Fertirrigação" caso deseje utilizar a funcionalidade de fertirrigação nos comandos de irrigação deste pivô.',
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_CHECK: "Ativar Fertirrigação",
  EDIT_IMETER_LEVEL_TAB: "Nível",
  EDIT_IMETER_LEVEL_TAB_SENSOR_INFO_TITLE: "Informações do sensor",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TITLE: "Configurações do gráfico",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TEXT:
    "Estes valores são para a inserção de linhas de referência no gráfico de medição.",
  EDIT_IMETER_LEVEL_TAB_MAX_LIMIT_INPUT: "Valor máximo",
  EDIT_IMETER_LEVEL_TAB_MIN_LIMIT_INPUT: "Valor mínimo",
  BASIC_INFO_BOX_CHART_LAKE_LEVEL: "Gráfico de Nível",
  BASIC_INFO_BOX_LEVEL: "Nível",
  GENERAL_BOX_MAX_LIMIT: "Valor máximo:",
  GENERAL_BOX_MIN_LIMIT: "Valor mínimo:",
  PERIOD_TOTAL_WATER_CONSUMPTION: "Consumo total para o período",
  PERIOD_TOTAL_RAINFALL: "Chuva total para o período",
  PLUVIOMETER_CHART_NUMBER_OF_BARS: "Número de Barras",
  IRPD_CONSUMPTION_CHART_NUMBER_OF_BARS: "Número de Barras",
  SEGMENTS_PLANT_DATE: "Data do plantio",
  SEGMENTS_HARVEST_DATE: "Data estimada de colheita",
  EDIT_PIVOT_LABEL_PIVOT_SEGMENTS: "Segmentos e Plantio",
  SEGMENT_NAME: "Nome do segmento/plantio",
  MOBILE_MODAL_TITLE_SEGMENTS_DISPLAY: "Crop",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_PLANT_DATE: "Plantio: ${}",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_HARVEST_DATE: "Colheita: ${}",
  NEW_SEGMENT: "Adicionar/Editar Segmentos",
  HOVER_ON_SEGMENT:
    "Passe o cursor sob o desenho para informações dos segmentos",
  ANGLE_RANGE: "Faixa de ângulo",
  ACCUMULATED_BLADE: "Lâmina acumulada",
  SEGMENT_TYPE: "Tipo de plantio/manejo",
  CROP_WARNING_CONFIG:
    "Para mais informações, configure as datas de plantio/manejo em editar pivô.",
  CROP_WARNING_INFO: "Não existem informações nesse intervalo de tempo.",
  CROP_WARNING_SECTORIAL:
    "Este pivô é setorial. Os segmentos do pivô consistem apenas nas áreas coloridas do mapa.",
  PLUVIOMETER_WITHOUT_CONFIG: "Pluviômetro desabilitado",
  ACTIVATED: "Ativado",
  DEACTIVATED: "Desativado",
  EDIT_PIVOT_V5_AUTOREVERSION_MODE: "Condição de parada",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_ANGLE: "Por ângulo",
  ENDGUN_START_ANGLE: "Início",
  ENDGUN_FINAL_ANGLE: "Fim",
  EDIT_IRPD_LOAD_SUCCESS: "Configuração carregada com sucesso!",
  EDIT_IRPD_LOAD_ERROR: "Erro ao carregar configuração.",
  EDIT_IRPD_LOAD_BUTTON: "Carregar",
  HISTORIC_CONFIG_INF_MONITOR: "Monitor",
  HISTORIC_CONFIG_INF_CONTROLLER: "Controlador",
  HISTORIC_CONFIG_INF_HARDWARE: "Hardware",
  HISTORIC_CONFIG_INF_GPS: "GPS",
  HISTORIC_CONFIG_INF_PUMP: "Bomba",
  HISTORIC_CONFIG_INF_DATASHEET: "Ficha técnica",
  HISTORIC_CONFIG_INF_IMETER: "Medidor",
  HISTORIC_PANEL_LABEL_MONITOR: "MO",
  HISTORIC_PANEL_LABEL_CONTROLLER: "C",
  HISTORIC_PANEL_LABEL_HARDWARE: "H",
  HISTORIC_PANEL_LABEL_GPS: "G",
  HISTORIC_PANEL_LABEL_PUMP: "B",
  HISTORIC_PANEL_LABEL_IMETER: "ME",
  LEGEND: "Legenda",
  ALL_CONFIGURATIONS: "Todas as Configurações",
  CONFIG_NOT_SENT: "configuração não enviada",
  CONFIG_SENT: "configuração enviada, mas não recebida",
  CONFIG_RECEIVED: "configuração recebida",
  TEXT_CONFIG_NOT_SENT: "A configuração não foi enviada para o equipamento.",
  TEXT_CONFIG_SENT:
    "A configuração foi enviada para o equipamento, mas não foi recebida.",
  TEXT_CONFIG_RECEIVED: "A configuração foi recebida pelo equipamento.",
  EDIT_PIVOT_LABEL_SETTING_HISTORIC: "Histórico de Configurações",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START: "Início do horário de pico",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END: "Final do horário de pico",
  BAUER_PIVOT_EDIT_ZERO_POSITION: "Posição Zero",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_ALWAYS_ON: "Sempre Ligado",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_AUTOMATIC_MODE: "Modo Automático",
  BAUER_PIVOT_EDIT_SECTOR_BEGIN: "Setor Inicial",
  BAUER_PIVOT_EDIT_SECTOR_END: "Setor Final",
  BAUER_PIVOT_EDIT_AUTOREVERSE: "Normal",
  BAUER_PIVOT_EDIT_PARKING_FUNCTION: "Posição para estacionar",
  BAUER_PIVOT_EDIT_INTERMEDIATE_FUNCTION: "Posição intermediaria",
  BAUER_PIVOT_GENERAL_TOOLTIP_PRESSURE: "Pressão atual",
  BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE: "Ângulo atual",
  BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE: "Taxa de irrigação",
  BAUER_PIVOT_HISTORY_TOOLTIP_USER: "Enviado pelo usuário",
  BAUER_PIVOT_HISTORY_TOOLTIP_DEVICE: "Enviado pelo equipamento",
  BAUER_PIVOT_HISTORY_USER: "Geral",
  BAUER_PIVOT_HISTORY_PROTOCOL: "Protocolo",
  BAUER_PIVOT_HISTORY_PROTOCOL_TABLE: "Tabela de Protocolo",
  BAUER_PIVOT_STATUS_0: "Maquina não está em operação",
  BAUER_PIVOT_STATUS_1: "Máquina operando em avanço",
  BAUER_PIVOT_STATUS_2:
    "Esperando até o início da hora para começar a irrigação",
  BAUER_PIVOT_STATUS_3: "A irrigação parou",
  BAUER_PIVOT_STATUS_4: "Circuito de segurança interrompido",
  BAUER_PIVOT_STATUS_5: "Máquina operando no modo de emergência",
  BAUER_PIVOT_STATUS_6: "A máquina parou por controle remoto",
  BAUER_PIVOT_STATUS_7: "Interruptor de segurança desligado",
  BAUER_PIVOT_STATUS_8: "Pressão da água muito baixa",
  BAUER_PIVOT_STATUS_9: "Parou por causa do vento forte",
  BAUER_PIVOT_STATUS_10: "Tensão muito baixa",
  BAUER_PIVOT_STATUS_11: "Erro de barramento CAN",
  BAUER_PIVOT_STATUS_12: "ERRO I2C",
  BAUER_PIVOT_STATUS_13: "Iniciar o tempo de atraso ativo",
  BAUER_PIVOT_STATUS_14: "Posição de estacionamento alcançada",
  BAUER_PIVOT_STATUS_15: "Máquina operando em reverso",
  BAUER_PIVOT_STATUS_16: "Erro GPS",
  BAUER_PIVOT_STATUS_17: "Parou por causa das chuvas",
  BAUER_PIVOT_STATUS_18: "Erro de canto",
  BAUER_PIVOT_STATUS_19: "Erro do sensor de span",
  BAUER_PIVOT_STATUS_20: "Erro do sensor de inclinação",
  BAUER_PIVOT_STATUS_21: "Erro da última torre",
  BAUER_PIVOT_STATUS_22:
    "Distância para o cabo de orientação enterrado muito grande",
  BAUER_PIVOT_STATUS_23: "Sinal de orientação enterrado muito baixo",
  BAUER_PIVOT_STATUS_24: "Erro de orientação enterrada",
  BAUER_PIVOT_STATUS_25: "Erro ao conversor de frequência de canto",
  BAUER_PIVOT_STATUS_26: "Erro do compressor",
  BAUER_PIVOT_STATUS_27: "Nenhum movimento detectado",
  BAUER_PIVOT_STATUS_28: "Posição intermediária de parada alcançada",
  BAUER_PIVOT_STATUS_40: "Pressão da água muito alta",
  BAUER_PIVOT_STATUS_41: "Interruptor de off-off pressionado",
  BAUER_PIVOT_STATUS_NEW_CON: "Nova comunicação",
  BAUER_PIVOT_STATUS_MAN_START: "Início manual",
  BAUER_PIVOT_STATUS_MAN_STOP: "Parada manual",
  BAUER_PIVOT_STATUS_ACK_FWD: "Adiante recebido",
  BAUER_PIVOT_STATUS_ACK_REV: "Reverso recebido",
  BAUER_PIVOT_STATUS_ACK_STOP: "Pare recebido",
  BAUER_PIVOT_STATUS_START_FWD: "Comando de avanço enviado",
  BAUER_PIVOT_STATUS_START_REV: "Comando de reverso enviado",
  BAUER_PIVOT_STATUS_STOP_CMD: "Comando de parada enviado",
  BAUER_PIVOT_STATUS_GET_CONFIG: "Obtenha configuração",
  BAUER_PIVOT_STATUS_SET_CONFIG: "Defina a configuração",
  BAUER_PIVOT_STATUS_ACK_CONFIG: "Configuração recebida",
  BAUER_PIVOT_STATUS_ACK_VRI: "VRI recebido",
  BAUER_PIVOT_STATUS_RFTP_ERROR_1: "Arquivo: Sem dados",
  BAUER_PIVOT_STATUS_RFTP_MAX_RETRY: "Arquivo: Limite excedido",
  BAUER_PIVOT_STATUS_RFTP_ERR_FILE: "Arquivo: erro de soma de verificação",
  BAUER_PIVOT_STATUS_RFTP_OK: "Arquivo: bem-sucedido",
  BAUER_PIVOT_STATUS_ACK_START: "Start recebido",
  BAUER_PIVOT_STATUS_DEFAULT: "Atualização",
  PUMP: "Bomba",
  IMETER: "iManage",
  EDIT_REPEATER_LABEL_GENERAL: "Geral",
  EDIT_REPEATER_CENTRAL_LABEL: "Rádio da Central",
  EDIT_REPEATER_RADIO_LABEL: "Rádio da Repetidora",
  REPEATER_FIELD_NAME: "Nome",
  REPEATER_CONFIG_FIELD_ENERGY_TYPE: "Tipo",
  REPEATER_CONFIG_FIELD_SOLAR: "Solar",
  REPEATER_CONFIG_FIELD_BIVOLT: "Bivolt",
  REPEATER_CONFIG_DEVICE_FIELD_GP_CENTER: "Localização do Dispositivo",
  REPEATER_EDIT_LABEL: "Repetidora",
  RADIO_SWAP_CONTROLLER_TITLE: "Trocar o rádio do Controlador",
  RADIO_SWAP_GPS_TITLE: "Trocar o rádio do GPS",
  RADIO_SWAP_PUMP_TITLE: "Trocar o rádio da Bomba",
  RADIO_SWAP_IMETER_TITLE: "Trocar o rádio do iManage",
  RADIO_SWAP_REPEATER_TITLE: "Trocar o rádio da Repetidora",
  RADIO_SWAP_CONTROLLER_SUBTITLE:
    "Selecione o pivô para trocar o rádio do Controlador.",
  RADIO_SWAP_GPS_SUBTITLE: "Selecione o pivô para trocar o rádio do GPS.",
  RADIO_SWAP_PIVOT_PUMP_SUBTITLE:
    "Selecione o pivô para trocar o rádio da Bomba",
  RADIO_SWAP_IMETER_SUBTITLE: "Selecione um iManage para trocar de rádio.",
  RADIO_SWAP_PUMP_SUBTITLE: "Selecione uma Bomba para trocar de rádio.",
  RADIO_SWAP_REPEATER_SUBTITLE:
    "Selecione uma Repetidora para trocar de rádio.",
  REPEATER: "Repetidora",
  BASIC_INFO_BOX_PRESSURE_FILTERED_CHARTS_INFO:
    "Dados de pressão gerado entre ${} e ${}",
  BASIC_INFO_BOX_COMPARISON_FILTERED_CHARTS_INFO:
    "Dados de comparação gerado entre ${} e ${}",
  BASIC_INFO_BOX_COMPARE_DATE_START: "Começar em:",
  BASIC_INFO_BOX_COMPARE_DATE_END: "Parar em:",
  BASIC_INFO_BOX_PRESSURE_INFO:
    "Os valores de “Pressão” são relativos ao intervalo de tempo definido pelo relatório. Os valores de “Pressão de Comparação” são relativos ao intervalo de tempo selecionado neste gráfico. Use os dados de  “Pressão de Comparação“ para comparar as leituras de pressão para cada ângulo irrigado entre diferentes períodos.",
  HISTORIC_IRPD_STREAM_CONSUMPTION: "Consumo estimado",
  HISTORIC_IRPD_STREAM_PRESSURE: "Pressão",
  DEVICE_BOX_SHOW_PERIODIC_STREAMS: "Mostrar periódicas",
  LOGIN_ERROR_ACCEPT_PRIVACY:
    "Para continuar acessando, aceite as políticas de privacidade",
  EDIT_FARM_BILLING: "Faturamento",
  FARM_BILLING_FIELD_PHONE: "Telefone",
  FARM_BILLING_FIELD_ADDRESS: "Endereço",
  FARM_BILLING_FIELD_POSTAL_CODE: "CEP (Apenas números)",
  FARM_BILLING_FIELD_CITY: "Cidade",
  FARM_BILLING_FIELD_STATE: "Estado",
  FARM_BILLING_FIELD_COUNTRY: "País",
  ALERT_DIALOG_ALERT_DELETE:
    "Você tem certeza que deseja deletar esta notificação?",
  NOTIFICATIONS_TABPANEL_LABEL_PIVOTS: "Pivôs",
  NOTIFICATIONS_TABPANEL_LABEL_MONITOR: "Monitores de pivô",
  NOTIFICATIONS_TABPANEL_LABEL_PUMP: "Bombas",
  NOTIFICATIONS_TABPANEL_LABEL_METER: "Medidores de nível",
  SHOW_REASONS: "Mostrar razões",
  HIDE_REASONS: "Esconder razões",
  EDIT_NOTIFICATIONS: "Editar notificações",
  EDIT_NOTIFICATIONS_QUESTION: "Deseja salvar suas alterações?",
  EDIT_NOTIFICATIONS_SAVE: "Salvar",
  CREATE_NOTIFICATIONS: "Cadastrar notificações",
  FARM_HAS_NO_NOTIFICATIONS:
    "Você não possui notificações cadastradas para ${}",
  CREATE_NOTIFICATIONS_QUESTION: "Deseja cadastrar este grupo de notificações?",
  CREATE_NOTIFICATIONS_CREATE: "Cadastrar",
  DELETE_NOTIFICATIONS_QUESTION: "Deseja excluir este grupo de notificações?",
  DELETE_NOTIFICATIONS: "Excluir notificações",
  DELETE_NOTIFICATIONS_CANCEL: "Cancelar",
  DELETE_NOTIFICATIONS_DELETE: "Excluir",
  CREATE_NOTIFICATIONS_ALL_DAY: "Dia todo",
  CREATE_NOTIFICATIONS_BACK: "Voltar",
  CREATE_NOTIFICATIONS_NEXT: "Próximo",
  CREATE_NOTIFICATIONS_FINISH: "Terminar",
  CREATE_NOTIFICATIONS_BASIC_INFO: "Informações básicas",
  CREATE_NOTIFICATIONS_TIME_PERIOD: "Horário",
  CREATE_NOTIFICATIONS_DEVICES: "Dispositivos",
  CREATE_NOTIFICATIONS_REASONS: "Razões",
  CREATE_NOTIFICATIONS_CHOOSE_NAME:
    "Escolha um nome para este grupo de notificações",
  CREATE_NOTIFICATIONS_CHOOSE_NAME_PLACEHOLDER:
    "Digite um nome para este grupo de notificações",
  CREATE_NOTIFICATIONS_PICK_TIME:
    "Escolha um horário para este grupo de notificações",
  CREATE_NOTIFICATIONS_STARTS_ON: "Começa às",
  CREATE_NOTIFICATIONS_ENDS_ON: "Termina às",
  CREATE_NOTIFICATIONS_SELECT_THE_DEVICES:
    "Selecione os dispositivos para este grupo de notificações",
  CREATE_NOTIFICATIONS_SELECT_ALL_THE_DEVICES:
    "Selecionar todos os dispositivos",
  CREATE_NOTIFICATIONS_SELECT_REASONS:
    "Selecione as razões para este grupo de notificações",
  CREATE_NOTIFICATIONS_CHOOSE_GENERATION:
    "Escolha a geração dos pivôs para este grupo de notificações",
  HISTORY_OF_MEASUREMENTS: "Histórico de Leituras",
  FARM_BILLING_FIELD_COMPANY_NAME: "Nome da empresa",
  FARM_BILLING_FIELD_DOCUMENT: "Documento",
  FARM_BILLING_FIELD_DOCUMENT_TYPE: "Tipo de documento",
  FARM_BILLING_FIELD_EMAIL: "E-mail",
  EDIT_BILLING_SAVE_SUCCESS: "Informações de faturamento salvas",
  EDIT_BILLING_ERROR: "Erro ao salvar informações de faturamento",
  FARM_GENERAL_INFORMATION_TITLE: "Informações Gerais",
  FARM_BILLING_DESCRIPTION:
    "Estas informações serão utilizadas na emissão de notas fiscais sobre o uso da Plataforma Irricontrol",
  FARM_BILLING_INFORMATION_TITLE: "Informações de Faturamento",
  FARM_BILLING_ADDRESS_TITLE: "Endereço de Faturamento",
  FARM_CONTACT_INFORMATION_TITLE: "Informações de Contato Técnico",
  FARM_CONTACT_ADDRESS_TITLE: "Endereço de Contato Técnico",
  FARM_CONTACT_ADDRESS_SWITCH: "Mesmo endereço de faturamento",
  FARM_LOCATION_INFORMATION_TITLE: "Informações de Localização",
  CREATE_FARM_STEP_LABEL_GENERAL: "Geral",
  CREATE_FARM_STEP_LABEL_CONTACT: "Contato",
  CREATE_FARM_STEP_LABEL_BILLING: "Faturamento",
  CREATE_FARM_STEP_LABEL_LOCATION: "Localização",
  CENTRAL_RADIO: "Rádio da Central",
  CREATE_FARM_QUESTION: "Deseja cadastrar esta fazenda?",
  CREATE_FARM_CREATE: "Cadastrar",
  FARM_CONTACT_FIELD_POSTAL_CODE: "CEP (Apenas números)",
  COMMUNICATION_TYPE: "Tipo de Comunicação",
  CREATE_DEVICE_MODAL_XBEE: "XBee",
  CREATE_DEVICE_MODAL_4G: "4G",
  CREATE_DEVICE_MODAL_GATEWAY_CODE: "Número do Gateway",
  ERROR_16_CARACTERS_GATEWAY_CODE:
    "O campo Número do Gateway deve possuir 16 caracteres",
  ERROR_16_CARACTERS_RADIO_GATEWAY:
    "O campo Rádio do Gateway deve possuir 16 caracteres",
  COMMUNICATION_TYPE_OPTION_XBEE: "XBee",
  COMMUNICATION_TYPE_OPTION_4G_LTE: "4G/LTE",
  SELECTED_PIVOT_ONLINE: "Pivô com internet",
  SELECTED_PIVOT_OFFLINE: "Pivô sem internet",
  CENTRAL_LINKED: "A fazenda possui Central?",
  CENTRAL_LINKED_TOOLTIP:
    "Fazendas que possuem Central são aquelas que utilizam comunicação via rádio. Fazendas que não possuem Central são aquelas que utilizam comunicação 4G, disponível apenas para pivôs.",
  FARM_LOCATION: "Localização da Fazenda",
  EDIT_PIVOT_GATEWAY_NUMBER_LABEL: "Número do Gateway",
  EDIT_GATEWAY: "Editar gateway",
  SELECTED_IRPD_ONLINE: "Bomba com internet",
  SELECTED_IRPD_OFFLINE: "Bomba sem internet",
  GATEWAY_CHANGED_SUCCESS: "Gateway alterado com sucesso",
  GATEWAY_ID_ALREADY_USED: "Gateway já utilizado em outro equipamento",
  SAVE_GATEWAY: "Salvar gateway",
  HISTORIC_DATA_UNDEFINDED: "Indefinido",
  HOVER_OVER_SEGMENT:
    "Passe o cursor sobre o desenho para visualizar as informações dos segmentos",
  CREATE_NOTIFICATIONS_CRITICAL: "Alertas",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS:
    "Deseja que essa notificação seja um alerta crítico?",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO:
    "Os alertas críticos reproduzem sons diferentes e mais altos do que as notificações convencionais. Essas notificações são disruptivas e devem ser utilizadas quando há necessidade de uma tomada de ação imediata, por exemplo, em caso de paradas inesperadas.",
  CRITICAL_ALERTS_QUESTION_ENABLE: "Deseja habilitar os alertas críticos?",
  CRITICAL_ALERTS_QUESTION_DISABLE: "Deseja desabilitar os alertas críticos?",
  CRITICAL_ALERTS_CANCEL: "Cancelar",
  CRITICAL_ALERTS_ENABLE: "Habilitar",
  CRITICAL_ALERTS_DISABLE: "Desabilitar",
  CRITICAL_ALERTS: "Alertas críticos",
  FARM_PAYMENT_METHOD_TITLE: "Método de Pagamento",
  FARM_BILLING_HISTORY_TITLE: "Histórico de Faturamento",
  BILLING_HISTORY_NO_INVOICES_FOUND: "Nenhuma fatura encontrada",
  BILLING_HISTORY_NO_BILLING_DATA_FOUND: "Dados de faturamento não encontrados",
  BILLING_INVOICE_DUE_DATE: "Vencimento",
  BILLING_INVOICE_STATUS: "Situação",
  BILLING_INVOICE_STATUS_OPEN: "Aberta",
  BILLING_INVOICE_STATUS_PAID: "Paga",
  BILLING_INVOICE_STATUS_PAST_DUE: "Vencida",
  BILLING_INVOICE_STATUS_VOID: "Anulada",
  BILLING_INVOICE_AMOUNT: "Quantia",
  BILLING_INVOICE_LINK: "Link",
  BILLING_INVOICE_LINK_PAY: "Pagar",
  BILLING_INVOICE_LINK_DOCS: "Documentos",
  HISTORIC_PAINEL_TYPE_POSITION_STREAM: "Atualização de ângulo",
  DEVICE_BOX_SHOW_POSITION: "Mostrar posição",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS: "Termos de Uso e Proteção de Dados",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS_UPDATE:
    "Atualização de Termos de Uso e Proteção de Dados",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT: "Nossos",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT2:
    "visam adicionar condições ao uso dos nossos serviços para manter a conformidade com as leis vigentes que garantem a sua privacidade de dados.",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT: "Atualizamos os nossos",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT2:
    "para adicionar novas condições ao uso dos nossos serviços e para manter a conformidade com as leis vigentes que garantem a sua privacidade de dados.",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN:
    "Ao continuar, você concorda com os nossos Termos atualizados e com todas as políticas nele descritas. Acesse e revise em",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK: "Termos de Uso e Proteção de Dados",
  POLICY_TERMS_AND_CONDITIONS_CHECKBOX:
    "Declaro que li e concordo com os Termos de Uso e Proteção de Dados da Plataforma Irricontrol.",
  ACCEPT_TEXT: "EU ACEITO",
  PAYMENT_METHOD_WARNNING:
    "Faturas em pagamento manual. Para acionar os pagamentos automáticos adicione um cartão de crédito às opções de pagamento.",
  PAYMENT_METHOD_ADD: "Adicione método de pagamento",
  PAYMENT_METHOD_UPDATE: "Atualize o método de pagamento",
  PAYMENT_METHOD_CREDIT_CARD_NUMBER: "Número do cartão de crédito",
  PAYMENT_METHOD_CREDIT_CARD_EXPIRY: "Validade",
  PAYMENT_METHOD_CREDIT_NAME: "Nome no cartão",
  DISPLAY_DATE_FORMAT: "DD/MM/YYYY",
  DEBTOR_NOTIFICANTION_USER:
    "Sua fazenda possui pagamentos em atraso. Solicite ao seu administrador para realizar os pagamentos.",
  DEBTOR_NOTIFICANTION_ADM:
    "Sua fazenda possui pagamentos em atraso. Realize os pagamentos",
  DEBTOR_NOTIFICANTION_LINK: "nas configurações",
  DEBTOR_NOTIFICANTION_COUNT:
    "O bloqueio automático da fazenda acontece em ${} dias. Para mais informações, entre em contato: (19) 99951-9890",
  BILLING_DESCRIPTION_TEXT:
    "A aba de faturamentos concentra informações financeiras essenciais relacionadas a transações comerciais da plataforma.",
  PAYMENT_METHOD_WARNING:
    "Para habilitar pagamentos automáticos, adicione um cartão de crédito.",
  BILLING_WARNING: "Existem pagamentos em atraso!",
  SUCCESS: "Success",
  SUCCESS_PAGE_TEXT: "Método de pagamento atualizado com sucesso.",
  CANCEL_PAGE_TEXT: "Não foi possível atualizar o método de pagamento.",
  BILLING: "Faturamento",
  UNDER_CONSTRUCTION: "Em construção",
  PRESSURE_CHART_COMPARISON_END_TOWER: "Pressão de comparação - Última torre",
  PRESSURE_CHART_END_TOWER: "Pressão - Última torre",
  PRESSURE_CHART_COMPARISON_CENTER: "Pressão de comparação - Torre cental",
  PRESSURE_CHART_CENTER: "Pressão - Torre cental",
  EDIT_PROFILE_TERMS_AND_CONDITIONS:
    "Para obter mais informações sobre os termos que regem a plataforma Irricontrol, acesse ",
  EDIT_FARM_ERROR_FIELDS: "Falha no cadastro, confira os campos novamente.",
  EDIT_FARM_ERROR: "Não foi possível cadastrar a fazenda.",
  NO_INFO: "Sem Comunicação",
  HARDWARE_ID_ALREADY_EXISTS: "Hardware id já utilizado",
  ENCODER: "Encoder",
  ENCODER_DESCRIPTION_TEXT:
    "Os sistemas de pivô LTE/4G empregam um equipamento de precisão instalado na torre central, que é responsável por registrar a posição angular do pivô em tempo real. A aba de encoder é dedicada a configurar e calibrar o encoder, permitindo definir a referência de ângulo 0° do pivô de acordo com sua posição atual.",
  ENCODER_BTN: "Redefinir o ângulo 0º",
  ENCODER_CONFIRMATION: "Deseja redefinir o ângulo atual do pivô para 0º?",
  EDIT_ENCODER_LABEL_SETTING_HISTORIC: "Histórico de Redefinição do Encoder",
  REDEFINE: "Sim",
  REDEFINE_ENCONDER_LABEL: "Redefinições do Encoder",
  EDIT_ENCODER_SAVE_AWAIT_ERROR: "Erro ao redefinir o encoder para o ângulo 0º",
  EDIT_ENCODER_SAVE_AWAIT_SUCCESS: "Encoder redefindo para o ângulo 0º",
  ENCODER_RESET_INSTRUCTION_TITLE: "Como usar a redefinição do Encoder",
  ENCODER_RESET_INSTRUCTION_STEP1: "Passo 1:",
  ENCODER_RESET_INSTRUCTION_STEP1_TEXT:
    "Posicione o pivô no ponto de referência inicial desejado. Certifique-se que o Encoder instalado no centro do pivô está na posição correta.",
  ENCODER_RESET_INSTRUCTION_STEP2: "Passo 2:",
  ENCODER_RESET_INSTRUCTION_STEP2_TEXT:
    'Clique no botão "REDEFINIR O ÂNGULO 0º"',
  ENCODER_RESET_INSTRUCTION_STEP3: "Passo 3:",
  ENCODER_RESET_INSTRUCTION_STEP3_TEXT:
    'Uma tela de confirmação será aberta. Confirme a redefinição clicando em "Sim".',
  ENCODER_RESET_INSTRUCTION_DESCRIPTION:
    "É importante destacar que este equipamento preserva o ângulo atual, mesmo quando o painel do pivô estiver desligado. Portanto, utilize a redefinição apenas durante a primeira instalação, em casos de substituição do equipamento, ou quando a configuração de referência inicial for atualizada.",
  BILLING_TAB_REDIRECT: "Ir para aba de faturamento",
  PAYMENT_METHOD_CREDIT_CARD: "Cartão de crédito",
  PAYMENT_METHOD_CREDIT_BILL: "Boleto bancário",
  EDIT_BILLING_NOT_FOUND_USER:
    "Sem informações de faturamento cadastradas. Solicite ao seu administrador para realizar o cadastro.",
  EDIT_BILLING_NOT_FOUND_ADM:
    "Sem informações de faturamento cadastradas. Realize o cadastro",
  PAYMENT_METHOD_DELETE_TEXT:
    "Tem certeza que deseja remover as cobrança automática?",
  PAYMENT_METHOD_DELETE_TITLE: "Remover cobraça automática",
  PAYMENT_METHOD_DELETE_BTN: "Remover",
  PAYMENT_METHOD_UPDATE_BTN: "Atualizar",
  BILLING_PREFERRED_LANGUAGE: "Idioma preferencial para e-mails",
  BILLING_PREFERRED_LANGUAGE_TOOLTIP:
    'O "Idioma preferencial para e-mails" determina em qual idioma você receberá as comunicações por e-mail sobre pagamentos e detalhes de faturas na Plataforma Irricontrol. A seleção dessa linguagem não altera a linguagem da interface na Plataforma.',
  FARM_PAYMENT_METHOD_DESCRIPTION:
    'Adicione um "Cartão de Crédito" para habilitar o pagamento automático das faturas. Esta opção assegura que suas faturas sejam pagas na data correta, sem a necessidade de intervenção manual a cada ciclo de faturamento, evitando quaisquer tipos de bloqueios na plataforma.',
  REPORT_EMAIL_NOTIFICATION: "Relatório será enviado por e-mail em ${} min.",
  REPORT_PROCESSING_NOTIFICATION:
    "Relatório em processamento. Estimativa: ${} min.",
  REPORT_OPERATION: "Relatorio de Operação",
  REPORT_FARM: "Relatar Fazenda",
  REPEATER_FIELD_HEIGHT: "Altura",
  BATTERY_LEVEL_LOW: "Bateria fraca",
  BATTERY_LEVEL_CHARGED: "Bateria carregada",
  PERIODIC_WARNING: "Periódica",
  GH_LEVEL_TITLE: "Informações do reservatório",
  GH_LEVEL_DESCRIPTION:
    "Dados mandatórios para o correto funcionamento do Medidor de Nível e seu monitoramento",
  GH_MAX_LINE_TOOLTIP:
    "Valor máximo percentual desejado do nível máximo do reservatório. Uma vez atingido esse valor, o Medidor de Nível desligará as bombas associadas.",
  GH_MIN_LINE_TOOLTIP:
    "Valor mínimo percentual desejado do nível máximo do reservatório, para referência visual das medições",
  GH_RESERVOIR_TOOLTIP:
    "Nível máximo de água que o reservatório foi projetado para suportar",
  GH_OFFSET_TOOLTIP:
    "Valor de ajuste para medições do nível, caso a sonda não chegue até o fundo do reservatório",
  ERROR_GH_MIN_LINE: "Deve ser menor que o valor máximo e no mínimo 0",
  ERROR_GH_MAX_LINE: "Diferente de zero e maior que o valor mínimo",
  GH_MODAL_BATTERY_TITLE: "Alerta! Bateria fraca",
  GH_MODAL_BATTERY_DESC_1: "O nível de bateria do dispositivo ",
  GH_MODAL_BATTERY_DESC_2: " está baixo",
  GH_MODAL_BATTERY_ACTION: "Ir ao dispositivo",
  ROUNDS_LIMIT_TITLE: "Limite de Voltas do Pivô",
  ROUNDS_LIMIT_DESCRIPTION:
    "Quantidade máxima de voltas permitidas ao pivô antes de ocorrer o desligamento automático.",
  LINK_METER_SELECT_LABEL: "Medidor de nível vinculado",
};

export default i18n_ptBr;
