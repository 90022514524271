import moment from "moment";
import { MutableRefObject } from "react";

import whiteMarker from "../../../../../../assets/images/markers/white.svg";
import { MeterSystem } from "../../../../../../recoils/MeterSystemRecoil";
import { getIMeterEventStreamStatus } from "../../../../../../utils/models/imeter";
import { MapShape } from "../../../../../../utils/types";

// Assets

export async function addMeterSystemsOnMap(
  googleMapRef: MutableRefObject<any>,
  meterSystems: MeterSystem[],
  farmID: number
): Promise<MapShape[]> {
  if (googleMapRef.current === undefined) return;

  if (meterSystems[0]?.imeter_set === undefined) return;

  return meterSystems.map<MapShape>((m, index) => {
    var meterSystem = m.imeter_set[0];
    let centerPosition: string[] = meterSystem?.position.split(",");
    let referenceRadius: number = 60;

    let centerPositionGMaps = new google.maps.LatLng(
      parseFloat(centerPosition[0]),
      parseFloat(centerPosition[1])
    );

    const eventStreamCreated = meterSystem.latest_event_stream?.created;
    const periodicStreamCreated = meterSystem.latest_periodic_stream.created;

    const statusColor = meterSystem.latest_event_stream
      ? getIMeterEventStreamStatus(
          moment(eventStreamCreated).isSameOrAfter(
            moment(periodicStreamCreated)
          )
            ? meterSystem.latest_event_stream.content.imanage_master_status
                .status
            : meterSystem.latest_periodic_stream.content.imanage_master_status
                .status
        ).color
      : getIMeterEventStreamStatus(
          meterSystem.latest_periodic_stream.content.imanage_master_status
            .status
        ).color;

    const statusText = meterSystem.latest_event_stream
      ? getIMeterEventStreamStatus(
          moment(eventStreamCreated).isSameOrAfter(
            moment(periodicStreamCreated)
          )
            ? meterSystem.latest_event_stream.content.imanage_master_status
                .status
            : meterSystem.latest_periodic_stream.content.imanage_master_status
                .status
        ).text
      : getIMeterEventStreamStatus(
          meterSystem.latest_periodic_stream.content.imanage_master_status
            .status
        ).text;

    const infowindow = new google.maps.InfoWindow({
      content: `<span 
    style="cursor: pointer;"
    onclick="(function (){
      let event = new CustomEvent('navigateToMeterSystem', { detail: {
        pivotId: ${meterSystem.id},
        farmId: ${farmID}
      }});

      window.dispatchEvent(event);
    })()">
      <span style='font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#000;display:flex;flex-direction:row;flex-wrap:wrap;'>
      ${`${m.name} <br> ${meterSystem.name}`}
      </span>
          <div
            style='background-color:${statusColor};font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#fff;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center;'
              >
              ${statusText}
            </div>
    </span>`,
    });

    function renderSvgUrl(
      textPercentage: string,
      waterHeightPercentage: string,
      iconColor: string
    ) {
      const source = `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="55" height="55">
      <defs>
      <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
      <stop offset="${waterHeightPercentage}" stop-color="#808080"/>
      <stop offset="0%" stop-color="${iconColor}"/>
      </linearGradient>
      <linearGradient xlink:href="#a" id="b" spreadMethod="repeat"/>
      </defs>
      <circle cx="26" cy="26" r="25" fill="url(#b)" opacity="0.8" stroke="#808080" stroke-width="1"/>
      <text x="12" y="32" fill="#fff" font-family="Arial" font-weight="normal" font-size="16">${textPercentage}</text>
      </svg>`;

      return "data:image/svg+xml;base64," + btoa(source);
    }

    //////////////
    // Desenhos //
    //////////////

    const textPercentage = `${Math.round(
      m.imeter_set[0].water_level_percentage
    )}%`;
    const waterHeightPercentage = `${(
      100 - m.imeter_set[0].water_level_percentage
    ).toFixed()}%`;
    const maxLimit = m.imeter_set[0].latest_config.max_limit;
    const minLimit = m.imeter_set[0].latest_config.min_limit;
    const lakeLevel = Math.round(m.imeter_set[0].water_level_percentage);

    const iconColor =
      maxLimit !== 0 && minLimit !== 0 // both max and min limits are set
        ? lakeLevel >= maxLimit
          ? "#ff0000" // red
          : lakeLevel <= minLimit
          ? "#ffa500" // orange
          : "#4679ed" // blue
        : "#4679ed"; // only one or none of the limits are set

    let LakeLevelFarmMapIcon = new google.maps.Marker({
      position: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      map: googleMapRef.current,
      icon: {
        url: renderSvgUrl(textPercentage, waterHeightPercentage, iconColor),
      },
    });

    let circle = new google.maps.Circle({
      fillOpacity: 0,
      strokeOpacity: 0.0,
      map: googleMapRef.current,
      center: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      radius: referenceRadius,
    });

    //TODO Setor

    let marker = new google.maps.Marker({
      position: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      map: googleMapRef.current,
      icon: {
        url: whiteMarker,
      },
      visible: false,
    });

    function setPivotVisibility(visibility: boolean) {
      circle.setVisible(visibility);
      LakeLevelFarmMapIcon.setVisible(visibility);
      marker.setVisible(!visibility);
    }

    /////////////////////
    // Event Listeners //
    /////////////////////

    google.maps.event.addListener(
      LakeLevelFarmMapIcon,
      "mouseover",
      function () {
        infowindow.setPosition(centerPositionGMaps);
        infowindow.open(googleMapRef.current, LakeLevelFarmMapIcon);
      }
    );

    google.maps.event.addListener(LakeLevelFarmMapIcon, "click", function () {
      let event = new CustomEvent("navigateToMeterSystem", {
        detail: {
          meterSystemId: meterSystems[index].id,
          farmId: farmID,
        },
      });
      window.dispatchEvent(event);
    });

    google.maps.event.addListener(
      LakeLevelFarmMapIcon,
      "mouseout",
      function () {
        infowindow.close();
      }
    );

    google.maps.event.addListener(marker, "mouseover", function () {
      infowindow.setPosition(centerPositionGMaps);
      infowindow.open(googleMapRef.current, marker);
    });

    google.maps.event.addListener(marker, "click", function () {
      let event = new CustomEvent("navigateToMeterSystem", {
        detail: {
          meterSystemId: meterSystem.id,
          farmId: farmID,
        },
      });
      window.dispatchEvent(event);
    });

    google.maps.event.addListener(marker, "mouseout", function () {
      infowindow.close();
    });

    google.maps.event.addListener(
      googleMapRef.current,
      "zoom_changed",
      function () {
        let zoom = googleMapRef.current.getZoom();
        if (zoom > 11) {
          setPivotVisibility(true);
        }
        if (zoom <= 11) {
          setPivotVisibility(false);
        }
      }
    );

    return {
      type: "MeterSystemShape",
      circle: circle,
    };
  });
}
