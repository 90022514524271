import React from "react";
import { makeStyles } from "@material-ui/styles";

interface Props {
  minLimit: number;
  maxLimit: number;
  graphicMaxValueInMeters: number;
  lakeLevelValueInPercent?: number;
  lakeLevelValueInMeters: number;
}

function LakeLevelIcon(props: Props) {
  const {
    lakeLevelValueInPercent,
    lakeLevelValueInMeters,
    graphicMaxValueInMeters,
    minLimit,
    maxLimit,
  } = props;

  const lakeLevelValuePercent =
    lakeLevelValueInPercent !== undefined
      ? lakeLevelValueInPercent
      : (lakeLevelValueInMeters / graphicMaxValueInMeters) * 100;
  const waterLineHeight = 100 - lakeLevelValuePercent;

  const useStyles =
    maxLimit !== 0 && minLimit !== 0 // both max and min limits are set
      ? lakeLevelValuePercent >= maxLimit
        ? makeStyles({
            maxLimitLine: {
              borderTop: "2px dotted red",
              content: '""',
              marginLeft: "1px",
              position: "relative",
              top: `${102 - maxLimit}px`,
              width: "98px",
              zIndex: 8,
            },
            minLimitLine: {
              borderTop: "2px dotted orange",
              content: '""',
              marginLeft: "3px",
              position: "relative",
              top: `${100 - minLimit}px`,
              width: "94px",
              zIndex: 8,
            },
            text: {
              background: "#808080",
              borderRadius: "50%",
              color: "#fff",
              lineHeight: "60px",
              fontSize: "26px",
              fontWeight: 400,
              height: "60px",
              marginLeft: "20px",
              marginBottom: "10px",
              opacity: "90%",
              position: "relative",
              textAlign: "center",
              width: "60px",
              zIndex: 7,
            },
            box: {
              background: "#808080",
              borderLeft: "1px solid #808080",
              borderBottom: "1px solid #808080",
              borderRight: "1px solid #808080",
              borderBottomLeftRadius: "10%",
              borderBottomRightRadius: "10%",
              content: '""',
              height: "100px",
              overflow: "hidden",
              opacity: "90%",
              position: "relative",
              width: "100px",
              zIndex: 5,
              "&:before": {
                animation: "$fill 1s ease-in-out forwards",
                background: "#f46d75",
                content: '""',
                height: "100px",
                left: "50%",
                position: "absolute",
                width: "100px",
                zIndex: 2,
              },
            },

            "@keyframes fill": {
              from: {
                top: "100px",
                transform: "translateX(-50%)",
              },
              to: {
                top: `${waterLineHeight}%`,
                transform: "translateX(-50%)",
              },
            },
          })
        : lakeLevelValuePercent <= minLimit
        ? makeStyles({
            maxLimitLine: {
              borderTop: "2px dotted red",
              content: '""',
              marginLeft: "1px",
              position: "relative",
              top: `${102 - maxLimit}px`,
              width: "98px",
              zIndex: 8,
            },
            minLimitLine: {
              borderTop: "2px dotted orange",
              content: '""',
              marginLeft: "3px",
              position: "relative",
              top: `${100 - minLimit}px`,
              width: "94px",
              zIndex: 8,
            },
            text: {
              background: "#808080",
              borderRadius: "50%",
              color: "#fff",
              lineHeight: "60px",
              fontSize: "26px",
              fontWeight: 400,
              height: "60px",
              marginLeft: "20px",
              marginBottom: "10px",
              opacity: "90%",
              position: "relative",
              textAlign: "center",
              width: "60px",
              zIndex: 7,
            },
            box: {
              background: "#808080",
              borderLeft: "1px solid #808080",
              borderBottom: "1px solid #808080",
              borderRight: "1px solid #808080",
              borderBottomLeftRadius: "10%",
              borderBottomRightRadius: "10%",
              content: '""',
              height: "100px",
              overflow: "hidden",
              opacity: "90%",
              position: "relative",
              width: "100px",
              zIndex: 5,
              "&:before": {
                animation: "$fill 1s ease-in-out forwards",
                background: "#f7be6d",
                content: '""',
                height: "100px",
                left: "50%",
                position: "absolute",
                width: "100px",
                zIndex: 2,
              },
            },

            "@keyframes fill": {
              from: {
                top: "100px",
                transform: "translateX(-50%)",
              },
              to: {
                top: `${waterLineHeight}%`,
                transform: "translateX(-50%)",
              },
            },
          })
        : makeStyles({
            maxLimitLine: {
              borderTop: "2px dotted red",
              content: '""',
              marginLeft: "1px",
              position: "relative",
              top: `${102 - maxLimit}px`,
              width: "98px",
              zIndex: 8,
            },
            minLimitLine: {
              borderTop: "2px dotted orange",
              content: '""',
              marginLeft: "3px",
              position: "relative",
              top: `${100 - minLimit}px`,
              width: "94px",
              zIndex: 8,
            },
            text: {
              background: "#808080",
              borderRadius: "50%",
              color: "#fff",
              lineHeight: "60px",
              fontSize: "26px",
              fontWeight: 400,
              height: "60px",
              marginLeft: "20px",
              marginBottom: "10px",
              opacity: "90%",
              position: "relative",
              textAlign: "center",
              width: "60px",
              zIndex: 7,
            },
            box: {
              background: "#808080",
              borderLeft: "1px solid #808080",
              borderBottom: "1px solid #808080",
              borderRight: "1px solid #808080",
              borderBottomLeftRadius: "10%",
              borderBottomRightRadius: "10%",
              content: '""',
              height: "100px",
              overflow: "hidden",
              opacity: "90%",
              position: "relative",
              width: "100px",
              zIndex: 5,
              "&:before": {
                animation: "$fill 1s ease-in-out forwards",
                background: "#4679ed",
                content: '""',
                height: "100px",
                left: "50%",
                position: "absolute",
                width: "100px",
                zIndex: 2,
              },
            },

            "@keyframes fill": {
              from: {
                top: "100px",
                transform: "translateX(-50%)",
              },
              to: {
                top: `${waterLineHeight}%`,
                transform: "translateX(-50%)",
              },
            },
          })
      : makeStyles({
          // only one or none of the limits are set
          maxLimitLine: {
            borderTop: "2px dotted red",
            content: '""',
            marginLeft: "1px",
            position: "relative",
            top: `${102 - maxLimit}px`,
            width: "98px",
            zIndex: 8,
          },
          minLimitLine: {
            borderTop: "2px dotted orange",
            content: '""',
            marginLeft: "3px",
            position: "relative",
            top: `${100 - minLimit}px`,
            width: "94px",
            zIndex: 8,
          },
          text: {
            background: "#808080",
            borderRadius: "50%",
            color: "#fff",
            lineHeight: "60px",
            fontSize: "26px",
            fontWeight: 400,
            height: "60px",
            marginLeft: "20px",
            marginBottom: "10px",
            opacity: "90%",
            position: "relative",
            textAlign: "center",
            width: "60px",
            zIndex: 7,
          },
          box: {
            background: "#808080",
            borderLeft: "1px solid #808080",
            borderBottom: "1px solid #808080",
            borderRight: "1px solid #808080",
            borderBottomLeftRadius: "10%",
            borderBottomRightRadius: "10%",
            content: '""',
            height: "100px",
            overflow: "hidden",
            opacity: "90%",
            position: "relative",
            width: "100px",
            zIndex: 5,
            "&:before": {
              animation: "$fill 1s ease-in-out forwards",
              background: "#4679ed",
              content: '""',
              height: "100px",
              left: "50%",
              position: "absolute",
              width: "100px",
              zIndex: 2,
            },
          },

          "@keyframes fill": {
            from: {
              top: "100px",
              transform: "translateX(-50%)",
            },
            to: {
              top: `${waterLineHeight}%`,
              transform: "translateX(-50%)",
            },
          },
        });

  const classes = useStyles();

  return (
    <>
      <div
        className={classes.text}
      >{`${lakeLevelValuePercent.toFixed()}%`}</div>

      {maxLimit !== 0 && minLimit !== 0 ? (
        <>
          <div className={classes.maxLimitLine}></div>
          <div className={classes.minLimitLine}></div>
        </>
      ) : null}

      <div className={classes.box}></div>
    </>
  );
}

export default React.memo(LakeLevelIcon);
